import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { CustomerService } from 'src/app/utils/services/customer.service';
import { actionSelectCustomer } from 'src/app/views/mantle-customer/state/customer.actions';

@Component({
  selector: 'customer-search-input',
  templateUrl: './customer-search-input.component.html',
  styleUrls: ['./customer-search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSearchInputComponent),
      multi: true,
    },
  ],
})
export class CustomerSearchInputComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  public customer_list$: Observable<any>;
  public input$ = new Subject<any | null>();
  private customerSearchSubscription: Subscription;
  private customerSetSubscription: Subscription;

  loadingCustomer: boolean;
  disabled: boolean;
  value: any;

  onChange: any = () => {};
  onTouched: any = () => {};

  @Output() private change = new EventEmitter();
  constructor(
    private customerService: CustomerService,
    private commonService: CommonService,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>
  ) {
    /*this.customer_list$ = this.input$.pipe(
      map((term) => this.searchCustomer(term))
    );*/
  }

  customerSearchForm = this.fb.group({
    customer_id: [],
  });

  ngOnInit(): void {
    this.customerSearchSubscription = this.commonService.external_customer_search.subscribe(
      (res) => {
        if (res != undefined) {
          let customerData = JSON.parse(JSON.stringify(res));
          customerData['full_name'] =
            customerData['first_name'] + ', ' + customerData['last_name'];
          customerData['tooltip'] =
            'Email: ' +
            customerData['email'] +
            ' | Phone: ' +
            customerData['phone'] +
            ' | City : ' +
            customerData['city'];
          let arr: Array<any> = new Array<any>();
          arr.push(customerData);
          //this.input$.next(arr);

          this.customer_list$ = of(arr);
        }
      }
    );

    this.customerSetSubscription = this.commonService.external_customer_set.subscribe(
      (customerData) => {
        this.customerSearchForm.patchValue(customerData);
      }
    );
  }

  searchCustomer($event) {
    const term = $event?.srcElement?.value;
    if (typeof term !== 'string') {
      this.loadingCustomer = false;
      return term;
    }
    const searchTerm = term ? term : '';
    //this.input$.next([]);
    this.customer_list$ = of([]);
    if (searchTerm != '') {
      this.loadingCustomer = true;
      this.commonService.searching_customer.next(true);

      this.customer_list$ = this.customerService.searchCustomer({
        search_term: searchTerm,
      });

      this.customer_list$.subscribe({
        next: (res) => {
          res.map((res: any) => {
            res.full_name = res.first_name + ', ' + res.last_name;
            res.tooltip =
              'Email: ' +
              res.email +
              ' | Phone: ' +
              res.phone +
              ' | City : ' +
              res.city;
            return res;
          });
        },
        error: (err) => {
          this.loadingCustomer = false;
        },
        complete: () => {
          this.loadingCustomer = false;
        },
      });
    }
  }

  changeCustomer($event) {
    this.value = $event?.customer_id;
    const customer = $event;
    this.store.dispatch(actionSelectCustomer({ customer }));
    this.change.emit($event);
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.customerSearchSubscription.unsubscribe();
    this.customerSetSubscription.unsubscribe();
  }
}
