import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-select',
  templateUrl: './mantle-select.component.html',
  styleUrls: ['./mantle-select.component.scss'],
})
export class MantleSelectComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, AfterViewInit {
  constructor(private commonService: CommonService) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  onChange = async ($event: any) => {
  };
}
