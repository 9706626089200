import { Component, Input, OnInit } from '@angular/core';
import { MANTLE_MODAL_NAME, PAYMENT_MERCHANT_TYPES } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-settings-merchant',
  templateUrl: './settings-merchant.component.html',
  styleUrls: ['./settings-merchant.component.scss'],
})
export class SettingsMerchantComponent implements OnInit{
  @Input() merchant_type_id: any;
  MERCHANT_TYPE: any = PAYMENT_MERCHANT_TYPES;
  constructor(
    private commonService: CommonService,) {
    }
  ngOnInit(): void {
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.MERCHANT_SETTINGS_MODAL);
  }
}
