import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';
import { OrderService } from 'src/app/utils/services/order.service';

@Component({
  selector: 'shipping-endicia',
  templateUrl: './shipping-endicia.component.html',
  styleUrls: ['./shipping-endicia.component.scss']
})
export class ShippingEndiciaComponent implements OnInit {
  private stepper: Stepper;
  @Input() order_id:any;
  orderDetails:any
  
  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private orderService:OrderService,
    private endiciaService:EndiciaService) { }

  ngOnInit(): void {
    
    this.stepper = new Stepper(document.querySelector('#shipment-stepper'), {
      linear: false,
      animation: true
    })
    this.getOrderDetails()
  }

  next() {
    this.stepper.next();
  }

  getOrderDetails(){
    this.spinner.show()

    const obs = this.orderService.getOrderById(this.order_id)
    obs.subscribe(res=>{
      this.orderDetails = res
      this.spinner.hide()
    },
      err=>{
        this.spinner.hide()
        this.toastr.error("An error occurred")
        
    })
  }

  closeModal(){
    this.commonService.modal_close.next('MODAL-ENDICIA-SHIPPING-OPTIONS')
  }

}
