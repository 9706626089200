import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { Subject } from 'rxjs';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
declare let $: any;

@Component({
  selector: 'mantle-inventory-list',
  templateUrl: './mantle-inventory-list.component.html',
  styleUrls: ['./mantle-inventory-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MantleInventoryListComponent implements OnInit {
  product_list: Array<any> = [];
  //Temporarily hold the quick edit data before submitting
  quick_edit_data: Array<any> = [];
  selectedChildSku: any;
  has_data: boolean = false;
  resultCount: Number = 0;
  MAX_RESULT: Number = 1000;
  searchParams: any;

  constructor(
    private spinner: NgxSpinnerService,
    private inventoryService: InventoryService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {
    this.inventoryService.filter_result.subscribe((data) => {
      this.init_data(data);
    });
  }

  ngOnInit(): void {}

  init_data(filter_data) {
    this.resultCount = 0;
    try {
      if ($.fn.dataTable.isDataTable('#inventoryDataTable')) {
        var tbl = $('#inventoryDataTable').DataTable();
        //tbl.destroy();
        //tbl.draw();
        tbl.clear();
        tbl.destroy();
      }
    } catch (error) {}

    setTimeout(() => {
      if (!$.fn.dataTable.isDataTable('#inventoryDataTable')) {
        $('#inventoryDataTable').DataTable({
          pagingType: 'full_numbers',
          fixedHeader: true,
          pageLength: 100,
          processing: true,
          lengthMenu: [5, 10, 20, 100, 200, 500],
          bSort: false,
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: ['nosort'],
            },
          ],
          //order:[[1,"desc"]]
        });
      }
    }, 3);

    let result = filter_data['parent_skus'];
    if (result != undefined) {
      this.product_list = result;

      this.product_list.map((x) => {
        this.resultCount += x.child_skus.length;
      });

      if (this.product_list.length > 0) {
        this.has_data = true;
      } else {
        this.has_data = false;
      }
    } else {
      this.has_data = false;
    }
  }

  edit_item(parent_sku, child_sku, brand_id) {
    this.inventoryService.openInventoryModal(brand_id, child_sku, parent_sku);
  }

  quick_edit_item(parent_sku, child_sku) {
    if (parent_sku != undefined && child_sku != undefined) {
      var productExt = this.product_list.find(
        (x) =>
          x.parent_sku == parent_sku &&
          x.child_skus.find((y) => y.child_sku == child_sku)
      );
      //productExt.quick_edit = true;
      var childProductExt = productExt.child_skus.find(
        (y) => y.child_sku == child_sku
      );
      childProductExt.quick_edit = true;
    }
  }

  onArchiveInventory(inventory_id, archived, parent_sku, child_sku, brand_id) {
    this.spinner.show();
    this.inventoryService
      .updateInventory({
        brand_id: brand_id,
        child_sku: child_sku,
        parent_sku: parent_sku,
        inventory_id: inventory_id,
        archived: !archived,
      })
      .subscribe(
        (res) => {
          this.spinner.hide();
        },
        (err) => {
          this.toastr.error(err);
          this.spinner.hide();
        }
      );
  }

  changeChildSku(event) {
    if (event != undefined) {
      var productExt = this.product_list.find(
        (x) =>
          x.parent_sku == event['parent_sku'] &&
          x.child_skus.find((y) => y.child_sku == event['child_sku'])
      );
      productExt.child_sku = event['child_sku'];
      productExt.brand_id = event['brand_id'];
      productExt.status = event['status'];
      productExt.total = event['total'];
      productExt.net_charge = event['net_charge'];
      productExt.sell_price = event['sell_price'];
      productExt.buy_price = event['buy_price'];
      productExt.links = event['links'];
      productExt.quick_edit = false;
    }
  }

  onChangeQuickEdit(event, child_product) {
    /**
     * this.quick_edit_data holds a small subset for quick retrieve
     */
    const parent_sku = child_product.parent_sku;
    const child_sku = child_product.child_sku;
    const brand_id = child_product.brand_id;
    const quantity_reserve = child_product.quantity_reserve;

    if (parent_sku != undefined && child_sku != undefined) {
      let data = this.quick_edit_data.find(
        (x) => x.parent_sku == parent_sku && x.child_sku == child_sku
      );
      if (data != undefined) {
        data[event['target']['name']] = event['target']['value'];
      } else {
        var quickData = {
          parent_sku: parent_sku,
          child_sku: child_sku,
          brand_id: brand_id,
          quantity: child_product.total,
          sell_price: child_product.sell_price,
          quantity_reserve: quantity_reserve,
        };
        this.quick_edit_data.push(quickData);
      }
    } else {
      alert('Cannot Update Values');
    }
  }

  quick_edit_save(parent_sku, child_sku) {
    if (parent_sku != undefined && child_sku != undefined) {
      let data = this.quick_edit_data.find(
        (x) => x.parent_sku == parent_sku && x.child_sku == child_sku
      );
      if (data != undefined) {
        this.spinner.show();
        var obs = this.inventoryService.updateInventory({
          ...{ quickEdit: true },
          ...data,
        });

        obs.subscribe(
          (res) => {
            this.spinner.hide();
            this.toastr.success('Inventory Details Saved', 'Success');
            //Merge Updated records

            /* var productExt = this.product_list.find(
              (x) =>
                x.parent_sku == parent_sku &&
                x.child_skus.find((y) => y.child_sku == child_sku)
            );

            productExt = Object.assign({}, productExt, data); */
          },
          (err) => {
            this.toastr.error(JSON.stringify(err), 'An Error Occurred');
            this.spinner.hide();
          }
        );
      } else {
        this.toastr.info('No Updated Data');
      }
    }
  }

  quick_edit_cancel(parent_sku, child_sku) {
    if (parent_sku != undefined && child_sku != undefined) {
      var productExt = this.product_list.find(
        (x) =>
          x.parent_sku == parent_sku &&
          x.child_skus.find((y) => y.child_sku == child_sku)
      );
      //productExt.quick_edit = false;
      var childProductExt = productExt.child_skus.find(
        (y) => y.child_sku == child_sku
      );
      childProductExt.quick_edit = false;
    }
  }

  toggleChild(parent_sku) {
    if (parent_sku != undefined) {
      var productExt = this.product_list.find(
        (x) => x.parent_sku == parent_sku
      );
      productExt.toggle = !productExt.toggle;
    }
  }
}
