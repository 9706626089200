import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { VendorService } from 'src/app/utils/services/vendor.service';

@Component({
  selector: 'mantle-po-vendor-list',
  templateUrl: './mantle-po-vendor-list.component.html',
  styleUrls: ['./mantle-po-vendor-list.component.scss'],
})
export class MantlePoVendorListComponent implements OnInit, OnDestroy {
  initialized: boolean = false;
  filterParams: any = {};
  private vendorSearchSubscription!: Subscription;
  private modalCloseSubscription!: Subscription;
  vendorsList: Array<any> = [];
  statusOrders: any;

  constructor(
    private vendorService: VendorService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {
    this.modalCloseSubscription = this.commonService.modal_close.subscribe(
      (modalName) => {
        if (modalName === MANTLE_MODAL_NAME.PO_VENDOR_DETAILS) {
          this.ngOnInit();
        }
      }
    );
  }

  ngOnInit(): void {
    if (!this.initialized) {
      this.initData();
      this.initialized = true;
    }

    this.spinner.show();
    this.vendorSearchSubscription = this.vendorService.getVendors().subscribe({
      next: (res) => {
        this.vendorsList = res.data;
      },
      error: (error) => {
        this.toastr.error(error?.error?.message);
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  initData = () => {
    let lastPage = 0;
    let lastSearchText = '';

    try {
      if ($.fn.dataTable.isDataTable('.dataTable')) {
        var tbl = $('.dataTable').DataTable();
        //tbl.destroy();
        //tbl.draw();
        tbl.clear();
        tbl.destroy();
      }
    } catch (error) {}

    setTimeout(() => {
      $('.dataTable thead tr').clone(true).appendTo('.dataTable thead');
      $('.dataTable thead tr:eq(1) th').each((i, element) => {
        var title = $(element).text();
        var id = $(element).attr('id');
        if (id) {
          $(element).html(
            '<input type="text" style="width:100%" placeholder="Search ' +
              title +
              '" id="' +
              id +
              '"/>'
          );
        }

        $('input', element).on('keyup', (event) => {
          if (event.key === 'Enter') {
            const eValue = (event.target as HTMLInputElement).value;
            if (dataTable.column(i).search() !== eValue) {
              this.filterParams[event.target.id] = eValue;

              if (eValue == undefined || eValue == '') {
                delete this.filterParams[event.target.id];
              }
              dataTable.draw();
            }
          }
        });
      });

      var dataTable = $('.dataTable').DataTable({
        orderCellsTop: true,
        pagingType: 'full_numbers',
        pageLength: 100,
        displayStart: lastPage, // Last Selected Page
        search: { search: lastSearchText }, // Last Searched Text
        serverSide: true,
        processing: true,
        searching: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.spinner.show();
          let offset = dataTablesParameters.start;
          this.vendorService
            .getVendors({
              ...this.filterParams,
              ...{
                offset: offset,
                limit: 100,
              },
            })
            .subscribe(
              (res) => {
                this.spinner.hide();
                this.vendorsList = res.data;
                callback({
                  recordsTotal: res.count,
                  recordsFiltered: res.count,
                  data: [],
                });
              },
              (err) => {
                this.spinner.hide();

                this.toastr.error(err?.error?.message);
              }
            );
        },
        lengthMenu: [5, 10, 20, 100],
        // createdRow: (row: Node, data: any) => {
        //   if (data.is_new_record) {
        //     $(row).css('background-color', 'red');
        //   }
        // },
      });
    }, 1);
  };

  viewVendorDetails(vendor_id: any) {
    this.vendorService.modalPoVendorDetails(vendor_id);
  }

  ngOnDestroy(): void {
    this.vendorSearchSubscription?.unsubscribe();
    this.modalCloseSubscription?.unsubscribe();
  }
}
