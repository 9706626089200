import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { BcUpdateInventoryComponent } from '../bc/bc-update-inventory/bc-update-inventory.component';
import { TcgUpdateInventoryComponent } from '../tcg/tcg-update-inventory/tcg-update-inventory.component';
declare let $: any;
//declare let table:any;

@Component({
  selector: 'channel-active-listing',
  templateUrl: './channel-active-listing.component.html',
  styleUrls: ['./channel-active-listing.component.scss']
})
export class ChannelActiveListingComponent implements OnInit {

  productListing:Array<any> = []
  constructor(
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.init_data()
  }

  init_data(){

    let lastPage=0;  
    let lastSearchText="";
    setTimeout(() => {
      $('.dataTable thead tr').clone(true).appendTo( '.dataTable thead' );
      $('.dataTable thead tr:eq(1) th').each( function (i) {
          var title = $(this).text();
          //var columnId = $(this).id();
          $(this).html( '<input type="text" style="width:100%" placeholder="Search '+title+'" />' );
  
          $( 'input', this ).on( 'keyup', function (event) {
            if (event.keyCode === 13){
              if ( dataTable.column(i).search() !== this.value ) {
                dataTable
                      .column(i)
                      .search( this.value )
                      .draw();
              }
            }
          } );
      } );

      var dataTable =  $('.dataTable').DataTable({
          orderCellsTop: true,
          fixedHeader: true,
          pagingType: 'full_numbers',
          pageLength: 10,
          displayStart:lastPage, // Last Selected Page
          search:{search:lastSearchText}, // Last Searched Text
          serverSide: true,
          processing: true,
          searching:false,
          ajax: (dataTablesParameters: any, callback)=>{
            this.spinner.show()
            let offset = dataTablesParameters.start;//Math.ceil(dataTablesParameters.start/dataTablesParameters.length)
            this.channelsService.getProductListing({
              offset:offset,
              limit:10
            }).subscribe((res)=>{
              this.spinner.hide()
              this.productListing = res.data
              callback({
                recordsTotal: res.count,
                recordsFiltered: res.count,
                data:[]
              });
            },(err)=>{
              this.spinner.hide()
              
              this.toastr.error(JSON.stringify(err))
            })
          },
          lengthMenu: [5, 10, 20, 100,200,500],
      });


    },1);
}

  onUpdateDetails(id){
    const productSelected = this.productListing.find(x => x.id === id)

    const channelRef= productSelected.sales_channel.channel_ref
    if(channelRef === "CHANNEL_TCG"){
      const modalRef = this.modalService.open(TcgUpdateInventoryComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      });
      
      modalRef.componentInstance.product = productSelected;
  
      this.commonService.modal_close.subscribe(data =>{
        if(data === 'CHANNEL-TCG-INVENTORY-MODAL'){
          modalRef.close()
        }
      })
    }
    else if(channelRef === "CHANNEL_BC"){
      const modalRef = this.modalService.open(BcUpdateInventoryComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      });
      
      modalRef.componentInstance.product = productSelected;
  
      this.commonService.modal_close.subscribe(data =>{
        if(data === 'CHANNEL-BC-INVENTORY-MODAL'){
          modalRef.close()
        }
      })
    }
  }

  closeModal(){
    this.commonService.modal_close.next('CHANNEL-ACTIVE-LISTING')
  }

}
