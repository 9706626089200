<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12" *ngIf="resultCount >= MAX_RESULT">
        <div class="alert alert-warning" role="alert">
          This search criteria is returning a lot of products, only the first
          {{ MAX_RESULT }} are shown. Please refine your search if products are
          missing.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 table-responsive">
        <table class="table table-hover table-sm" id="catalogDataTable">
          <thead>
            <tr>
              <th scope="col" style="width: 5%">&nbsp;</th>
              <th scope="col" style="width: 30%" class="nosort">Product</th>
              <th scope="col" style="width: 20%">Variant</th>
              <th scope="col">Short Code</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody class="small">
            <ng-container *ngFor="let product of product_list">
              <tr class="table-primary">
                <td>
                  <button
                    *ngIf="!product.toggle"
                    type="button"
                    class="btn btn-tool"
                    (click)="toggleChild(product.parent_sku)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <button
                    *ngIf="product.toggle"
                    type="button"
                    class="btn btn-tool"
                    (click)="toggleChild(product.parent_sku)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                </td>
                <td>
                  <div class="row" (click)="toggleChild(product.parent_sku)">
                    <div class="col">
                      <img
                        src="{{ product.image_url }}"
                        class="img-rounded"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div class="col">
                      <b>{{ product.name }}</b>
                    </div>
                  </div>
                </td>
                <td>
                  <b>{{ product.set_name }}</b>
                </td>
                <td>
                  <b>{{ product.shortCode }}</b>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr
                *ngFor="let child_product of product.child_skus"
                [ngStyle]="{
                  visibility: product.toggle ? 'visible' : 'collapse'
                }"
              >
                <td>&nbsp;</td>
                <td>
                  <div class="small">
                    <button
                      class="btn btn-link btn-sm m-0 p-0 m-2"
                      (click)="
                        edit_item(
                          child_product.parent_sku,
                          child_product.child_sku,
                          child_product.brand_id
                        )
                      "
                    >
                      <i class="fas fa-external-link-alt" aria-hidden="true"></i
                      >Edit
                    </button>
                  </div>
                </td>
                <td>
                  <span>{{ child_product.name }}</span>
                </td>
                <td>
                  <span>{{ child_product.shortCode }}</span>
                </td>
                <td style="text-align: left">
                  <button
                    class="btn btn-outline-secondary btn-sm"
                    (click)="
                      onAddToInventory(
                        child_product.brand_id,
                        child_product.child_sku,
                        child_product.parent_sku
                      )
                    "
                  >
                    Add To Inventory
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
