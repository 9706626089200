import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { SettingEditComponent } from '../setting-edit/setting-edit.component';
import { Subscription } from 'rxjs';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
declare let $: any;

@Component({
  selector: 'mantle-setting-list',
  templateUrl: './setting-list.component.html',
  styleUrls: ['./setting-list.component.scss'],
})
export class SettingListComponent implements OnInit, OnDestroy {
  settingsList: Array<any>;
  settingModalSubscription: Subscription;
  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private settingsService: SettingsService,
    private mantleModalService: MantleModalService
  ) {
    this.settingModalSubscription = this.commonService.systemSettingsUpdated.subscribe(_=>{
      this.initData()
    });
  }
  ngOnInit(): void {
    this.initData();
  }

  initData = () => {
    this.spinner.show();
    this.settingsService.getSystemSettings().subscribe(
      (res) => {
        this.spinner.hide();
        this.settingsList = res;

        setTimeout(() => {
          if (!$.fn.dataTable.isDataTable('.dataTable')) {
            $('.dataTable').DataTable({
              orderCellsTop: false,
              fixedHeader: true,
              pagingType: 'full_numbers',
              pageLength: 100,
              processing: true,
              lengthMenu: [5, 10, 20, 100, 200, 500],
            });
          }
        }, 1);
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  };

  onEdit(id) {
    const modalRef = this.mantleModalService.open(
      SettingEditComponent,
      MANTLE_MODAL_NAME.MODAL_SETTING_EDIT,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      },
      this.initData
    );
    modalRef.componentInstance.id = id;
  }

  onDelete(id: any) {
    this.mantleModalService.confirmDialog(
      `Delete Setting : ${id}`,
      'Confirming this action will result in the deletion of this setting, while the system default setting will remain unaffected.',
      this.delete,
      [id]
    );
  }

  delete = (id: any) => {
    this.settingsService.deleteSystemSettings(id).subscribe(
      (res) => {
        this.initData();
      },
      (err) => {
        this.toastr.error(err.message);
      }
    );
  };

  ngOnDestroy(): void {
    this.settingModalSubscription.unsubscribe();
  }
}
