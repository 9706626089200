<form [formGroup]="customerSearchForm">
    <ng-select 
        [items]="customer_list$ | async" 
        bindLabel="full_name" 
        bindValue="customer_id"
        (keydown.enter)="searchCustomer($event)"
        formControlName="customer_id"
        [loading]="loadingCustomer" 
        (change)="changeCustomer($event)" 
        notFoundText="No Items Found - Press Enter To Search"
        >
        <ng-template ng-option-tmp let-item="item">
            <div [title]="item.tooltip">{{item.full_name}}</div>
        </ng-template>
    </ng-select>
</form>
