import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject } from 'rxjs';
import { ApiErrorComponent } from 'src/app/views/shipping/api-error/api-error.component';
import { ApiService } from './api.service';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})
export class EndiciaService {

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private modalService: NgbModal,) { }

  //Event Emitters
  addressValidated = new Subject()
  labelGenerated = new Subject()
  existingShipmentOpen = new Subject()
  
  endiciaError(error){
    if ('error_reference_id' in error){
      const modalRef = this.modalService.open(ApiErrorComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        //backdrop: 'static',
      });
  
      modalRef.componentInstance.error = error
  
      this.commonService.modal_close.subscribe(data =>{
        if(data === 'ENDICIA-API-ERROR'){
          modalRef.close()
        }
      })
    }
  }
  //Auth Setting
  getAuthSetting(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/auth');
  }

  updateAuthSetting(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/endicia/auth', requestPayload);
  }

  validateAddress(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/endicia/addresses/validate', requestPayload);
  }

  viewShippingRates(requestPayload): Observable<any> {
    /*const testData = of([
      {
        "carrier": "usps",
        "service_type": "usps_first_class_mail",
        "packaging_type": "large_envelope",
        "estimated_delivery_days": "string",
        "estimated_delivery_date": "string",
        "is_guaranteed_service": true,
        "trackable": "yes",
        "is_return_label": true,
        "is_customs_required": true,
        "shipment_cost": {
          "total_amount": 18.3,
          "currency": "usd",
          "cost_details": [
            {
              "fee_code": "string",
              "fee_type": "service",
              "amount": 0
            }
          ]
        }
      }
    ]);
    return testData*/
    return this.apiService.post('/api/v1/endicia/rates', requestPayload);
  }

  createShippingLabel(requestPayload): Observable<any> {
    /*const testData = of({
      "label_id": "122333",
      "tracking_number": "4115515151",
      "carrier": "usps",
      "service_type": "usps_first_class_mail",
      "packaging_type": "large_envelope",
      "estimated_delivery_days": "string",
      "estimated_delivery_date": "string",
      "is_guaranteed_service": true,
      "trackable": "yes",
      "is_return_label": true,
      "is_gap": true,
      "is_smartsaver": true,
      "is_etoe": true,
      "shipment_cost": {
        "total_amount": 0,
        "currency": "usd",
        "cost_details": [
          {
            "fee_code": "string",
            "fee_type": "service",
            "amount": 0
          }
        ]
      },
      "account_balance": {
        "amount_available": 0,
        "max_balance_amount_allowed": 0,
        "currency": "usd"
      },
      "labels": [
        {
          "href":"https://bit.ly/2VRmHgI"
        }
      ],
      "forms": [
        {
          "href": "string"
        }
      ]
    });
    return testData;*/
    return this.apiService.post('/api/v1/endicia/labels', requestPayload);
  }

  voidLabel(label_id):Observable<any>{
    return this.apiService.put('/api/v1/endicia/labels/'+label_id,null);
  }

  getShipments():Observable<any>{
    return this.apiService.get('/api/v1/endicia/shipment');
  }

  createCarrierManifest(requestPayload):Observable<any>{
    return this.apiService.post('/api/v1/endicia/manifests', requestPayload)
  }

  requestCarrierPickup(requestPayload):Observable<any>{
    return this.apiService.post('/api/v1/endicia/pickups', requestPayload)
  }

  getShippingManifest(manifest_id):Observable<any>{
    return this.apiService.get('/api/v1/endicia/manifests/'+manifest_id)
  }

  getPickupDetails(pickup_id):Observable<any>{
    return this.apiService.get('/api/v1/endicia/pickups/'+pickup_id)
  }

  trackPackage(requestPayload):Observable<any>{
    return this.apiService.post('/api/v1/endicia/tracking/', requestPayload)
  }

  getIndeciaLookupValues():Observable<any>{
    return this.apiService.get('/api/v1/endicia/lookups')
  }

}
