import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mantle-inventory-search',
  templateUrl: './mantle-inventory-search.component.html',
  styleUrls: ['./mantle-inventory-search.component.scss']
})
export class MantleInventorySearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
