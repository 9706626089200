import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { MantleConfirmDialogComponent } from '../../mantle-confirm-dialog/mantle-confirm-dialog.component';

@Component({
  selector: 'btn-init-channel-load',
  templateUrl: './btn-init-channel-load.component.html',
  styleUrls: ['./btn-init-channel-load.component.scss']
})
export class BtnInitChannelLoadComponent implements OnInit {
  public init_load: any;
  constructor(private appService: AppService,
    private commonService: CommonService,private settingsService:SettingsService, private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.settingsService.getSystemSettingByName('INIT_CHANNEL_LOAD').subscribe(res=>{
      this.init_load = parseInt(res.value)
    })
  }

  disableLoad(){
    const modalRef = this.modalService.open(MantleConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      backdrop: 'static',
    });
    
    modalRef.componentInstance.header = 'Disable Channel Initial Load';
    modalRef.componentInstance.message = 'By confriming this, channel data will be updated by transactions from other channels';
    this.commonService.modal_close.subscribe((data: any) => {
      if (data.name === 'CONFIRM-DIALOG') {
        if (data.data.action_id == 1) {
          this.spinner.show();
          this.settingsService.updateSystemSetting({
            id:3,
            value:0
          }).subscribe((res)=>{
            this.spinner.hide();
          },(err)=>{
            this.spinner.hide();
            
            this.toastr.error(JSON.stringify(err));
          })
        }
        modalRef.close();
      }
    });

  }

  enableLoad(){
    const modalRef = this.modalService.open(MantleConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      backdrop: 'static',
    });
    
    modalRef.componentInstance.header = 'Enable Channel Initial Load';
    modalRef.componentInstance.message = 'By confriming, this will prevent channel data from being updated by transactions from other channels, this is helpful when adding new channel with unmaintained inventory data';
    this.commonService.modal_close.subscribe((data: any) => {
      if (data.name === 'CONFIRM-DIALOG') {
        if (data.data.action_id == 1) {
          this.spinner.show()
          this.settingsService.updateSystemSetting({
            id:3,
            value:1
          }).subscribe((res)=>{
            this.spinner.hide();
          },(err)=>{
            this.spinner.hide();
            
            this.toastr.error(JSON.stringify(err));
          })
        }
        modalRef.close();
      }
    });
  }

}
