<div class="card">
<div class="card-header">
    <div class="card-tools">
    <button type="button" class="btn btn-tool">
        <button type="button" class="btn btn-success btn-sm" (click)="registerUser()">
            New User
        </button>
    </button>
    </div>
</div>
<div class="card-body">
    <div class="row">
    <div class="col-12">
        <user-list></user-list>
    </div>
    </div>
</div>
</div>
