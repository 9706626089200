import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { PurchaseOrderService } from 'src/app/utils/services/purchase-order.service';
import { MantlePoDetailsComponent } from '../mantle-po-details/mantle-po-details.component';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { MantlePoInvoiceComponent } from '../mantle-po-invoice/mantle-po-invoice.component';
declare let $: any;

@Component({
  selector: 'mantle-po-list',
  templateUrl: './mantle-po-list.component.html',
  styleUrls: ['./mantle-po-list.component.scss'],
})
export class MantlePoListComponent implements OnInit, AfterViewInit, OnDestroy {
  orderList: Array<any> = [];
  orderSearchData = {};
  private orderSearchSubscription: Subscription;
  private searchSubscription: Subscription;
  timezone: string = this.commonService.getTimeZone();

  constructor(
    private commonService: CommonService,
    private poService: PurchaseOrderService,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.orderSearchSubscription = this.poService.searchOrder.subscribe(
      (data) => {
        this.searchOrder(data);
        this.orderSearchData = data;
      }
    );
  }

  ngAfterViewInit(): void {
    this.poService.searchDefault.next({});
  }

  searchOrder = (data) => {
    this.spinner.show();
    this.orderList = [];

    this.searchSubscription = this.poService.searchOrders(data).subscribe(
      (res) => {
        this.groupSearchResult(res);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  };

  groupSearchResult = (data) => {
    //var orderIds = [...new Set(data.map((p) => p['order_id']))];

    var orderStatuses = [...new Set(data.map((p) => p['purchase_status_id']))];
    orderStatuses.forEach((orderStatus, index) => {
      const statusOrders = data.filter(
        (x) => x['purchase_status_id'] === orderStatus
      );
      const status = statusOrders.find(
        (x) => x.purchase_status_id != undefined
      );
      this.orderList.push({
        status_description: status?.purchase_status?.description,
        order_status_id: status?.purchase_status_id,
        orders: statusOrders,
      });
    });
    setTimeout(() => {
      if (!$.fn.dataTable.isDataTable('.dataTable')) {
        $('.dataTable').DataTable();
      }
      this.defaultToggle();
    }, 1);
  };

  defaultToggle() {
    var orderListToggle = localStorage.getItem('PurchaseOrderListToggle');
    var orderListToggleObj = {};

    if (orderListToggle != null) {
      orderListToggleObj = JSON.parse(orderListToggle);
    }

    Object.keys(orderListToggleObj).forEach((key) => {
      try {
        $(`#${key}`).CardWidget(`${orderListToggleObj[key]}`);
      } catch (error) {}
    });
  }
  checkOrder($event, order_id) {
    // this.orderService.order_list_selected_items.next({
    //   action: $event.target.checked,
    //   order_id: order_id,
    // });
  }

  viewOrderDetails = (order_id) => {
    const modalRef = this.mantleModalService.open(
      MantlePoDetailsComponent,
      MANTLE_MODAL_NAME.PO_DETAILS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.order_id = order_id;
  };
  viewInvoice = (order_id) => {
    const modalRef = this.mantleModalService.open(
      MantlePoInvoiceComponent,
      MANTLE_MODAL_NAME.PO_INVOICE,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.order_id = order_id;
  };

  onCollapseToggle($event, order_status_id) {
    var orderListToggle = localStorage.getItem('PurchaseOrderListToggle');
    var orderListToggleObj = {};

    if (orderListToggle != null) {
      orderListToggleObj = JSON.parse(orderListToggle);
    }

    let htmlValue = $event.srcElement.className;

    //Collapsed
    if (htmlValue.includes('fa-plus')) {
      orderListToggleObj[order_status_id] = 'expand';
    } else if (
      htmlValue.includes('fa-minus') ||
      htmlValue.includes('fa-minus')
    ) {
      orderListToggleObj[order_status_id] = 'collapse';
    }

    localStorage.setItem(
      'PurchaseOrderListToggle',
      JSON.stringify(orderListToggleObj)
    );
  }

  formatDate(date) {
    var dateString = moment.utc(date).local().format('DD-MM-YYYY HH:mm');
    return dateString;
  }
  ngOnDestroy(): void {
    this.orderSearchSubscription?.unsubscribe();
    this.searchSubscription?.unsubscribe();
  }
}
