import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-orders',
  templateUrl: './card-orders.component.html',
  styleUrls: ['./card-orders.component.scss']
})
export class CardOrdersComponent implements OnInit {
  @Input() orders_count:any
  constructor() { }

  ngOnInit(): void {
  }

}
