<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title small">{{ reportForm.value.name }}</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <form [formGroup]="reportForm">
          <div class="row">
            <div class="col-12">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="reportForm"
              ></formly-form>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <button
                type="button"
                class="btn btn-outline-success btn-sm"
                (click)="save()"
                [disabled]="reportForm.invalid"
              >
                <i class="fas fa-save"></i> Save & Run
              </button>
            </div>
            <div class="col-4">&nbsp;</div>
            <div class="col-4">
              <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                (click)="delete()"
              >
                <i class="fas fa-trash"></i> Delete
              </button>
            </div>
          </div>
        </form>
        <div class="row">
          <div id="MantleWebDataRocksContainer"></div>
        </div>
      </div>
    </div>
  </div>
</section>
