<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-sm-6">
        <h5>Manage Subscriptions</h5>
      </div>
      <!--div class="col-md-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Tasks</li>
        </ol>
      </div-->
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <button
          (click)="newTask()"
          class="btn btn-success btn-sm float-right mr-4"
          id="right"
          style="color: white"
        >
          New Task <i class="fas fa-plus ml-2"></i>
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                  <th scope="col">Execution Status</th>
                  <th scope="col">Date Set</th>
                  <th scope="col">Last Run</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let task of tasks_data">
                  <th scope="row">{{ task.task_id }}</th>
                  <td>{{ task.task_description }}</td>
                  <td>
                    <div *ngIf="task.is_active == 1">Active</div>
                    <div *ngIf="task.is_active == 0">Inactive</div>
                  </td>
                  <td>{{ task.description }}</td>
                  <td>{{ task.date_set | date: 'short':timezone }}</td>
                  <td>{{ task.date_last_run | date: 'short':timezone }}</td>
                  <td>
                    <button
                      (click)="viewTask(task.task_id)"
                      class="btn btn-sm btn-outline-primary"
                    >
                      <i class="fas fa-eye"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
