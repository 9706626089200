import { Component, Input, OnInit } from '@angular/core';
import { ReportingService } from 'src/app/utils/services/reporting.service';

@Component({
  selector: 'mantle-widget-datatable',
  templateUrl: './mantle-widget-datatable.component.html',
  styleUrls: ['./mantle-widget-datatable.component.scss']
})
export class MantleWidgetDatatableComponent implements OnInit{
  @Input() report_id;
  @Input() report_details;
  report_name: any;
  value: any;
  loading: boolean;
  hasError: boolean;
  errorText: any;
  dataItems:Array<any>;
  headers:Array<any>;
  
  constructor(
    private reportingService: ReportingService,){

  }
  ngOnInit(): void {
    this.getReportDetails();
  }

  getReportDetails = async () => {
    if (this.report_details) {
      this.report_name = this.report_details.name;
      this.getReportData();
    } else {
      this.reportingService.getReportById(this.report_id).subscribe(
        (res) => {
          this.report_name = res.name;
          this.getReportData();
        },
        (error) => {
          this.setError(error);
        }
      );
    }
  };

  getReportData = async () => {
    this.reportingService.getReportData(this.report_id).subscribe(
      (res) => {
        this.loading = false;
        this.dataItems = res;
        if(this.dataItems?.length > 0){
          this.headers = Object.keys(this.dataItems[0]);
        }
        if(this.report_details.refresh_intereval){
          this.autoRefresh(this.report_details.refresh_intereval)
        }
      },
      (error) => {
        this.setError(error);
      }
    );
  };

  setError(error: any) {
    this.hasError = true;
    this.errorText = error?.error?.message
      ? error?.error?.message
      : error.message;
  }

  refreshData(context){
    context.loading = true;
    context.hasError = false;
    context.errorText = '';

    context.getReportDetails();
  }

  autoRefresh(timeout:number){
    setTimeout(this.refreshData, timeout, this);
  }

}
