<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <h5>Product Filters Mapping</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="form-group col-md-6">
        <label class="control-label">Product Line</label>
        <ng-select
          [items]="product_brand"
          [multiple]="false"
          bindLabel="name"
          bindValue="id"
          (change)="changeBrand($event)"
        >
        </ng-select>
      </div>
      <div class="form-group col-md-6">
        <br />
        <button
          type="button"
          class="btn btn-outline-primary btn-sm w-50 mt-2"
          (click)="onBatchUpdate()"
          *ngIf="brand_id"
        >
          Update
        </button>
      </div>
    </div>
    <div class="row m-2">
      <form [formGroup]="mappingForm">
        <div class="row">
          <div class="row">
            <div class="col-md-3">Field Name</div>
            <div class="col-md-3">Data Type</div>
            <div class="col-md-2">Order</div>
            <div class="col-md-3">Label</div>
          </div>
        </div>
        <div class="row">
          <formly-form
            [model]="model"
            [fields]="fields"
            [options]="options"
            [form]="mappingForm"
          ></formly-form>
        </div>
      </form>
    </div>
    <div *ngIf="brand_id">
      <div class="row">
        <div class="col-md-12 small table-responsive">
          <table class="table table-striped dataTable">
            <thead>
              <tr>
                <th>Field Name</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let productCatlogField of productCatlogFieldMappings"
              >
                <tr>
                  <td>{{ productCatlogField.field_name }}</td>
                  <td>
                    <button
                      class="btn btn-outline-info btn-sm"
                      (click)="
                        onAddFilter(productCatlogField.catalog_schema_field_id)
                      "
                    >
                      <i class="fas fa-tasks"></i> Add
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
