import { createAction, props } from '@ngrx/store';
const PREFIX = '[Tiered]';

export const loadTiered = createAction(`${PREFIX} Load Tiered`);
export const loadTieredSuccess = createAction(
  `${PREFIX} Load Tiered Success`,
  props<{ tiered: any[] }>()
);
export const loadTieredFailure = createAction(
  `${PREFIX} Load Tiered Failure`,
  props<{ error: any }>()
);

export const upsertTiered = createAction(
  `${PREFIX} Upsert Tiered`,
  props<{ tier: any }>()
);

export const upsertTieredSuccess = createAction(
  `${PREFIX} Upsert Tiered Success`,
  props<{ tier: any }>()
);

export const upsertTieredFailure = createAction(
  `${PREFIX} Upsert Tiered Failure`,
  props<{ error: any }>()
);

export const deleteTiered = createAction(
  `${PREFIX} Delete Tiered`,
  props<{ point_tiered_id: string }>()
);

export const deleteTieredSuccess = createAction(
  `${PREFIX} Delete Tiered Success`,
  props<{ point_tiered_id: string }>()
);

export const deleteTieredFailure = createAction(
  `${PREFIX} Delete Tiered Failure`,
  props<{ error: any }>()
);
