import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingEditComponent } from './setting-edit/setting-edit.component';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';

@Component({
  selector: 'mantle-settings-system',
  templateUrl: './settings-system.component.html',
  styleUrls: ['./settings-system.component.scss'],
})
export class SettingsSystemComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {}

  ngOnInit(): void {}
  onNewSetting = async () => {
    this.mantleModalService.open(
      SettingEditComponent,
      MANTLE_MODAL_NAME.MODAL_SETTING_EDIT,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static'
      }
    );
  };
}
