import { createAction, props } from '@ngrx/store';
import { Customer } from 'src/app/utils/interfaces/customer.intefaces';
const CUSTOMERS_PREFIX = '[Customers]';
const CUSTOMER_PREFIX = '[Customer]';

// List Customers
export const listCustomers = createAction(
  `${CUSTOMERS_PREFIX} Load Customers`,
  props<{ filterParams: any }>()
);
export const listCustomersSuccess = createAction(
  `${CUSTOMERS_PREFIX} Load Customers Success`,
  props<{ customers: any }>()
);
export const listCustomersFailure = createAction(
  `${CUSTOMERS_PREFIX} Load Customers Failure`,
  props<{ error: any }>()
);

// Add Customer
export const addCustomer = createAction(
  `${CUSTOMERS_PREFIX} Add Customer`,
  props<{ customer: Customer }>()
);
export const addCustomerSuccess = createAction(
  `${CUSTOMERS_PREFIX} Add Customer Success`,
  props<{ customer: any }>()
);
export const addCustomerFailure = createAction(
  `${CUSTOMERS_PREFIX} Add Customer Failure`,
  props<{ error: any }>()
);

// Edit Customer
export const editCustomer = createAction(
  `${CUSTOMERS_PREFIX} Edit Customer`,
  props<{ customer: Customer }>()
);
export const editCustomerSuccess = createAction(
  `${CUSTOMERS_PREFIX} Edit Customer Success`,
  props<{ customer: Customer }>()
);
export const editCustomerFailure = createAction(
  `${CUSTOMERS_PREFIX} Edit Customer Failure`,
  props<{ error: any }>()
);

/**
 * Customer
 */
export const actionSelectCustomer = createAction(
  `${CUSTOMER_PREFIX} Select Customer`,
  props<{ customer: Customer }>()
);
