<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="pickupForm" (ngSubmit)="requestCarrierPickup()">
        <div class="card-header">
          <h3 class="card-title small">Request a Carrier Pickup</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row" formGroupName="pickup_window">
            <div class="form-group col-6">
              <label class="control-label small">Start Date</label>
              <input
                type="datetime-local"
                class="form-control form-control-sm"
                formControlName="start_at"
                IsoDateLocal
              />
            </div>
            <div class="form-group col-6">
              <label class="control-label small">End Date</label>
              <input
                type="datetime-local"
                class="form-control form-control-sm"
                formControlName="end_at"
                IsoDateLocal
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label class="control-label small">Pickup Instructions</label>
              <textarea
                cols="30"
                rows="10"
                formControlName="pickup_instructions"
                class="form-control form-control-sm"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div class="col-6">
              <button
                type="submit"
                class="btn btn-outline-success btn-sm w-50"
                [disabled]="!pickupForm.valid"
              >
                Submit
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                [disabled]="!cancelable"
                class="btn btn-outline-danger btn-sm w-50"
                (click)="cancelPickup()"
              >
                Cancel Pickup
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
