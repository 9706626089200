import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { RewardPointService } from 'src/app/utils/services/rewardpoint.service';
import {
  loadRewardPoints,
  loadRewardPointsFailure,
  loadRewardPointsSuccess,
  saveRewardPoints,
} from './reward_points.actions';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable()
export class RewardPointEffects {
  constructor(
    private actions$: Actions,
    private rewardPointService: RewardPointService,
    private store: Store<AppState>
  ) {}

  loadRewardPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRewardPoints),
      mergeMap(() =>
        this.rewardPointService.get().pipe(
          map((rewardPoint) => loadRewardPointsSuccess({ rewardPoint })),
          catchError((error) => of(loadRewardPointsFailure({ error })))
        )
      )
    )
  );

  saveRewardPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveRewardPoints),
      mergeMap((requestPayload) =>
        this.rewardPointService.updatePoints(requestPayload).pipe(
          map((rewardPoint) => loadRewardPointsSuccess({ rewardPoint })),
          catchError((error) => of(loadRewardPointsFailure({ error })))
        )
      )
    )
  );
}
