<div class="card">
  <div class="card-header">
    <h4>Order Payment Confirmation</h4>
    <div class="card-tools">
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-tool"
        data-card-widget="remove"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div
      class="row"
      *ngIf="!cardPaymentStatusConfirmed && formValue.payment_method_id == 2"
    >
      <div class="col-md-12">
        <div role="alert" class="alert alert-secondary">
          <mantle-loading label="Verifying payment ..."></mantle-loading>
        </div>
      </div>
    </div>

    <div
      class="row"
      *ngIf="cardPaymentStatusConfirmed && formValue.payment_method_id == 2"
    >
      <div class="col-md-12">
        <div
          role="alert"
          [ngClass]="
            paymentStatusAlert(orderDetails?.payment_status)
              ? 'alert alert-danger'
              : 'alert alert-success'
          "
        >
          <b
            >Payment Status : {{ orderDetails?.payment_status?.description }}</b
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h6>
          Confirm order payment for Order No#
          <b>{{ formValue.order_number }}</b>
        </h6>
        <table class="table table-striped table-responsive small">
          <tbody>
            <tr>
              <th>Payment Method</th>
              <td>
                {{ paymentMethod }}
              </td>
            </tr>
            <tr>
              <th>Order Total</th>
              <td>
                {{ formValue.currency_code }}
                {{ formValue.order_total | number: '1.2-2' }}
              </td>
            </tr>
            <tr>
              <th>Paid Amount</th>
              <td>
                {{ formValue.currency_code }}
                {{ formValue.paid_amount | number: '1.2-2' }}
              </td>
            </tr>
            <tr>
              <th>Store Credit Amount</th>
              <td>
                {{ formValue.currency_code }}
                {{ formValue.store_credit_amount | number: '1.2-2' }}
              </td>
            </tr>
            <tr>
              <th>Reward Point Amount</th>
              <td>
                {{ formValue.currency_code }}
                {{ formValue.redeemed_amount | number: '1.2-2' }}
              </td>
            </tr>
            <tr>
              <th>Change Amount</th>
              <td>
                {{ formValue.currency_code }}
                {{ formValue.change_amount | number: '1.2-2' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card-footer text-muted">
    <div class="row">
      <div class="col-md-6">
        <button
          type="button"
          class="btn btn-success btn-sm"
          (click)="onOrderConfirm()"
        >
          <i class="far fa-check-circle"></i> Confirm
        </button>
      </div>
      <div class="col-md-6">
        <button
          type="button"
          class="btn btn-danger btn-sm"
          (click)="closeModal()"
        >
          <i class="fas fa-times"></i> Cancel
        </button>
      </div>
    </div>
  </div>
</div>
