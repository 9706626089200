<div class="card">
  <div class="card-header">
    <h4>Multiple UPC Result</h4>
    <div class="card-tools">
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-tool"
        data-card-widget="remove"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 small table-responsive">
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>SKU</th>
              <th>UPC</th>
              <th>Product Name</th>
              <th>Set Name</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let searchResult of searchResults">
              <tr>
                <!--td>
                  <input
                    type="radio"
                    [(value)]="searchResult.selected"
                    [(ngModel)]="searchResult.selected"
                    [ngModelOptions]="{
                      standalone: true
                    }"
                    name="sr-selected"
                  />
                </td-->
                <td>{{ searchResult['Child Sku'] }}</td>
                <td>{{ searchResult['UPC'] }}</td>
                <td>{{ searchResult['Product Name'] }}</td>
                <td>{{ searchResult['Set Name'] }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-info btn-sm"
                    (click)="onSelectItem(searchResult)"
                  >
                    Select
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
