<section class="content">
  <div class="container-fluid">
    <div class="card" style="background-color: transparent">
      <form [formGroup]="productForm" (ngSubmit)="addProductInventory()">
        <div class="card-header">
          <h3 class="card-title">Product Inventory</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <ng-container>
            <div class="row">
              <div class="form-group col-md-4">
                <input
                  type="hidden"
                  class="form-control form-control-sm"
                  formControlName="id"
                />
                <label for="brand_id" class="control-label"
                  >Product Line *</label
                >
                <ng-select
                  [items]="product_brand"
                  bindLabel="name"
                  bindValue="id"
                  formControlName="brand_id"
                  (change)="changeBrand()"
                >
                </ng-select>
              </div>

              <div class="form-group col-md-6">
                <label for="brand_id" class="control-label"
                  >Product *
                  <small
                    >Type Product name /Set Name / parent or Mantle SKU / Short
                    Code</small
                  ></label
                >
                <ng-select
                  [items]="product_list$ | async"
                  bindLabel="product_name"
                  bindValue="child_sku"
                  formControlName="child_sku"
                  [typeahead]="input$"
                  [loading]="loadingProduct"
                  (change)="changeProduct($event)"
                >
                </ng-select>
              </div>
              <div class="form-group col-md-2">
                <button
                  *ngIf="inventory_only_dialog"
                  type="button"
                  class="btn btn-outline-primary btn-sm mt-4"
                  [disabled]="!productForm.valid"
                  (click)="manageInventory()"
                >
                  Manage Inventory
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="has_inventory_data">
            <div class="row">
              <div class="form-group col-md-3">
                <label class="control-label">Quantity on Hand</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="quantity"
                  (change)="updateProduct($event, 'Quantity on Hand')"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="control-label">Reserve Quantity</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="quantity_reserve"
                  (change)="updateProduct($event, 'Reserve Quantity')"
                />
              </div>
              <!--div class="form-group col-md-3">
                <label class="control-label">Sold</label>
                <input
                  type="number"
                  readonly
                  class="form-control form-control-sm"
                  formControlName="sold"
                  (change)="updateProduct($event, 'sold')"
                />
              </div-->
              <div class="form-group col-md-3">
                <label class="control-label">Available</label>
                <input
                  type="number"
                  readonly
                  class="form-control form-control-sm"
                  formControlName="available"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="control-label">Product Status</label>
                <!--input class="form-control form-control-sm" formControlName="product_status_id"/-->
                <ng-select
                  [items]="product_status"
                  bindLabel="description"
                  bindValue="product_status_id"
                  formControlName="product_status_id"
                >
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label class="control-label">Currency</label>
                <!--input class="form-control form-control-sm" formControlName="currency_code"/-->
                <ng-select
                  [items]="currency"
                  bindLabel="description"
                  bindValue="currency_code"
                  formControlName="currency_code"
                >
                </ng-select>
              </div>
              <div class="form-group col-md-3">
                <label class="control-label">Sale Price</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="sell_price"
                  (change)="updateProduct($event, 'Sale Price')"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="control-label">Buylist Price</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="buy_price"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="control-label">Average Cost</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="avg_cost"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label class="control-label">Reorder Point</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="reorder_point"
                  (change)="updateProduct($event, 'Reorder Point')"
                />
              </div>
              <!--div class="form-group col-md-3">
                <label class="control-label">Discount %</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="discount"
                  max="100"
                  min="1"
                />
              </div-->
              <div class="form-group col-md-3">
                <label class="control-label">Picking Bin</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="picking_bin"
                  (change)="updateProduct($event, 'Picking Bin')"
                />
              </div>
              <!--div class="form-group col-md-3">
                <label class="control-label">ASIN</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="asin"
                  (change)="updateProduct($event, 'ASIN')"
                />
              </div-->
            </div>
            <div class="row">
              <div class="col-md-2 text-muted">
                <h6>Sales Channels</h6>
              </div>
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  (click)="onAddLink()"
                >
                  Link to Channel <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm"
                  (click)="advancedInventory()"
                >
                  Advanced inventory data
                </button>
              </div>
            </div>

            <div
              class="row"
              *ngFor="let link of links; let i = index"
              [ngClass]="{
                border: link.invalid,
                'border-danger': link.invalid
              }"
            >
              <div class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label class="control-label">Sales Channel</label>
                    <!--input type="text" [(ngModel)]="link.name" class="form-control form-control-sm"  [ngModelOptions]="{standalone: true}"/-->
                    <ng-select
                      [items]="sales_channels"
                      bindLabel="sales_channel_description"
                      bindValue="sales_channel_id"
                      [(ngModel)]="link.sales_channel_id"
                      (change)="onChannelChange($event, i)"
                      [ngModelOptions]="{ standalone: true }"
                    >
                    </ng-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label class="control-label">Quantity</label>
                    <input
                      type="number"
                      [(ngModel)]="link.quantity"
                      class="form-control form-control-sm"
                      [ngModelOptions]="{ standalone: true }"
                      readonly
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label class="control-label">Price</label>
                    <input
                      type="number"
                      [(ngModel)]="link.price"
                      class="form-control form-control-sm"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <!--div class="form-group col-md-3">
                            <label class="control-label">URL</label>
                            <input type="url" [(ngModel)]="link.url" class="form-control form-control-sm"  [ngModelOptions]="{standalone: true}"/>
                        </div-->
                  <div class="form-group col-md-2">
                    <br />
                    <button
                      type="button"
                      class="btn btn-outline-info btn-sm mt-2"
                      (click)="onUpdateChannel(i)"
                      [disabled]="processingChannelsCount != 0"
                    >
                      Update Channel
                      <i class="fas fa-edit" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div
                    class="form-group col-md-1"
                    *ngIf="isBChannel(link.sales_channel_id)"
                  >
                    <br />
                    <button
                      type="button"
                      class="btn btn-outline-info btn-sm mt-2"
                      (click)="onMoreBCDetails(i)"
                    >
                      More <i class="fa fa-cog" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="form-group col-md-2">
                    <br />
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-sm mt-2"
                      [disabled]="processingChannelsCount != 0"
                      (click)="onRemoveLink(i)"
                    >
                      Remove <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="link.invalid">
                <div class="col-md-12">
                  <div class="alert alert-warning" role="alert">
                    {{ link.validation_message }}
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="card-footer text-muted" *ngIf="has_inventory_data">
          <div class="row">
            <div class="col-md-6">
              <button
                type="submit"
                class="btn btn-outline-success btn-sm w-50"
                [disabled]="
                  !productForm.valid || processingChannelsCount != 0
                    ? true
                    : false
                "
              >
                Save
              </button>
            </div>
            <div class="col-md-6">
              <!--button type="reset" class="btn btn-outline-secondary btn-sm w-50">Reset</button-->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="this.has_inventory_data">
      <mantle-product-update
        [data]="productForm.getRawValue()"
      ></mantle-product-update>
    </div>
  </div>
</section>
