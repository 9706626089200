import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { ProductEditComponent } from '../product-edit/product-edit.component';

@Component({
  selector: 'btn-new-product',
  templateUrl: './btn-new-product.component.html',
  styleUrls: ['./btn-new-product.component.scss'],
})
export class BtnNewProductComponent implements OnInit, OnDestroy {
  catalogData: any;
  catalogDescription: any;
  btnLabel:any = 'New Product';
  private catalogChange!: Subscription;
  @Input() duplicateData:boolean = false;
  @Input() duplicateChildSKU:any;
  @Input() brand_id:any;

  constructor(
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {}

  ngOnInit(): void {
    if(this.duplicateData){
      this.btnLabel = "Duplicate Product"
    }
    this.catalogChange = this.commonService.catalogDataChanged.subscribe(
      (res) => {
        this.catalogData = res;
        this.brand_id = this.catalogData.brand_id;
      }
    );
  }

  onAddProduct() {
    const modalRef = this.mantleModalService.open(
      ProductEditComponent,
      MANTLE_MODAL_NAME.PRODUCT_EDIT_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.isNewRecord = true;
    modalRef.componentInstance.duplicateData = this.duplicateData;
    modalRef.componentInstance.duplicateChildSKU = this.duplicateChildSKU;
    modalRef.componentInstance.product = {
      ...this.catalogData,
      ...{ own: 1, prev_owned: 0 },
    };
    modalRef.componentInstance.brand_id = this.brand_id;
  }

  ngOnDestroy() {
    this.catalogChange.unsubscribe();
  }
}
