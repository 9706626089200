import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  MANTLE_MODAL_NAME,
  SYSTEM_SETTINGS,
} from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
  selector: 'mantle-order-receipt',
  templateUrl: './mantle-order-receipt.component.html',
  styleUrls: ['./mantle-order-receipt.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MantleOrderReceiptComponent implements OnInit {
  @Input() orderId: any;
  @Input() orderDetails: any;
  @Input() storeDetails: any;
  @Input() userDetails: any;
  @Input() disclaimerHtml: any;
  @Input() autoPrint: boolean = true;
  logoImage: any;
  @ViewChild('printbtn') printbtn!: ElementRef;
  timezone: any = this.commonService.getTimeZone();

  constructor(
    private commonService: CommonService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: any
  ) {}
  ngOnInit(): void {
    this.initValues();
  }

  async initValues() {
    this.logoImage = this.logoImage = this.storageService.getItem(
      SYSTEM_SETTINGS.IMAGE_LOGO
    );

    setTimeout(() => {
      this.getOrderDetails();
    }, 0);
  }

  getOrderDetails() {
    if (this.orderDetails == undefined && this.orderId != undefined) {
      this.spinner.show();
      this.orderService.getOrderById(this.orderId).subscribe(
        (res) => {
          this.orderDetails = res;
          this.storeDetails = res.store;
          this.spinner.hide();
          setTimeout(() => {
            this.printReceipt();
          }, 0);
        },
        (err) => {
          this.spinner.hide();

          this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
        }
      );
    } else {
      //this.toastr.warning('No Orders Selected');
      this.printReceipt();
    }
  }

  ngAfterViewInit() {}
  printReceipt = () => {
    if (this.autoPrint) this.print();
    else {
      let element = this.el.nativeElement.querySelector(
        '#mantle-order-receipt'
      );
      this.renderer.setProperty(element, 'outerHTML', this.getHtmlContent());
    }
  };

  print = async () => {
    //this.printbtn.nativeElement.click();
    const printWindow = window.open();
    printWindow.document.open();
    printWindow.document.write(this.getHtmlContent());
    printWindow.document.close();

    printWindow.onload = function () {
      // necessary if the div contain images

      printWindow.focus(); // necessary for IE >= 10
      printWindow.print();
      //printWindow.close();

      printWindow.onafterprint = function () {
        printWindow.close();
      };
    };

    /* printWindow.focus();
    printWindow.print();

    printWindow.onafterprint = function () {
      //printWindow.close()
    };
    printWindow.close();*/
    this.closeModal();
  };

  getHtmlContent = () => {
    return `
    <html>
      <head>
        <style>
        #mantle-order-receipt *{
                font-size: 10px;
                font-family: 'Times New Roman';
            }
    
            td,
            th,
            tr,
            table {
                border-top: 1px solid black;
                border-collapse: collapse;
            }
    
            td.description,
            th.description {
                width: 125px;
                max-width: 125px;
            }
    
            td.quantity,
            th.quantity {
                width: 25px;
                max-width: 25px;
                word-break: break-all;
            }
    
            td.price,
            th.price {
                width: 50px;
                max-width: 50px;
                word-break: break-all;
            }
    
            .centered {
                text-align: center;
                align-content: center;
            }
    
            .ticket {
                width: 200px;
                max-width: 200px;
            }
    
            img {
                max-width: 40px;
                width: 40px;
            }
    
            @media print {
                body {
                  margin-top: 0;
                }
                .hidden-print,
                .hidden-print * {
                    display: none !important;
                }
            }
        </style>
        <title>${this.orderId}</title>
      </head>
      <body>
        <div id="mantle-order-receipt">
          ${this.document.getElementById('mantle-order-receipt').innerHTML}
        <div>
      </body>
    </html>
  `;
  };
  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.ORDER_RECEIPT);
  }
}
