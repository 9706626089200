import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { ProductService } from 'src/app/utils/services/product.service';

@Component({
  selector: 'settings-product-description',
  templateUrl: './settings-product-description.component.html',
  styleUrls: ['./settings-product-description.component.scss'],
})
export class SettingsProductDescriptionComponent implements OnInit, OnDestroy {
  @Input() brand_id: any;
  product_brand: any = [];
  productLineSelected: any;
  catalogFields: Array<any>;
  catalogFieldMapping: any;
  productCatlogFieldMappings: Array<any> = [];
  productFilters: Array<any> = [];
  private tblDataSubscription!: Subscription;
  descriptionTags: Array<any> = [];

  mappingForm = this.fb.group({});
  fields: FormlyFieldConfig[];
  model: any = {};
  options: FormlyFormOptions = {
    formState: {},
  };
  //separatorKeys: any = ['-', ',', ':', '_'];
  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private productService: ProductService
  ) {}
  ngOnInit(): void {
    this.init_values();
  }

  ngOnDestroy(): void {}

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.SETTINGS_PRODUCT_DESCRIPTION
    );
  }

  init_values() {
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.product_brand = res['product_brand'];
        this.productService.getCatalogFields().subscribe(
          (res) => {
            this.catalogFields = res;
            this.productService.getCatalogFieldMapping().subscribe(
              (res) => {
                this.catalogFieldMapping = res;
                this.spinner.hide();
              },
              (err) => {
                this.spinner.hide();
                this.toastr.error(err);
              }
            );
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err);
          }
        );
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  }

  getCatalogFields = () => {};

  onUpdate = () => {
    this.spinner.show();
    this.productService
      .updateProductDescriptionFields({
        brand_id: this.brand_id,
        fields: this.descriptionTags.map((x) => {
          if (typeof x == 'string' || x instanceof String) return x;
          return x.field_name;
        }),
      })
      .subscribe(
        (res) => {
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  };

  changeBrand($event: any) {
    this.productCatlogFieldMappings = [];
    this.descriptionTags = [];
    this.brand_id = $event?.id;
    if (this.brand_id) {
      this.getCatalogFieldMapping();
      this.getroductDescriptionFields();
    }
  }
  getCatalogFieldMapping = () => {
    //this.spinner.show();
    this.productCatlogFieldMappings = this.catalogFieldMapping
      .find((x) => x.brand_id == this.brand_id)
      ?.catalog_config_fields_export_mappings?.map((field) => {
        const fieldConfig = this.catalogFields?.find(
          (c) => c.catalog_schema_field_id == field.catalog_schema_field_id
        );
        //field.field_id = `{${fieldConfig.field_name}}`;
        return { ...field, ...fieldConfig };
      });
  };

  getroductDescriptionFields = () => {
    this.spinner.show();
    this.productService.getroductDescriptionFields(this.brand_id).subscribe(
      (res) => {
        this.descriptionTags = res?.fields.filter(element => {
          return element !== null && element !== undefined;
        });
        this.spinner.hide();
      },
      (err) => {
        this.toastr.error(err?.error?.message);
        this.spinner.hide();
      }
    );
  };

  onTagEdited($event) {}

  onTagAdded($event) {}

  onTagRemoved($event) {}
}
