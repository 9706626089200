<label for="field.id" class="control-label" *ngIf="props.label">
  {{ props.label }}
  <span aria-hidden="true" class="ng-star-inserted" *ngIf="props.required"
    >*</span
  >
</label>

<ng-select
  [items]="props.options"
  [multiple]="props.multiple"
  bindLabel="label"
  bindValue="value"
  [formControl]="formControl"
  [formlyAttributes]="field"
  (change)="onChange($event)"
>
</ng-select>
