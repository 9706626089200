import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { ChannelOptionsComponent } from '../channel-options/channel-options.component';

@Component({
  selector: 'channel-add',
  templateUrl: './channel-add.component.html',
  styleUrls: ['./channel-add.component.scss']
})
export class ChannelAddComponent implements OnInit {

  constructor(
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  onAddSalesChannel(){
    const modalRef = this.modalService.open(ChannelOptionsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });
    //modalRef.componentInstance.itemRef = index;

    this.commonService.modal_close.subscribe(data =>{
      if(data === 'CHANNEL-OPTIONS-MODAL'){
        modalRef.close()
      }
    })
  }
}
