import { Component } from '@angular/core';

@Component({
  selector: 'plugin-mx-terminal',
  templateUrl: './mx-terminal.component.html',
  styleUrls: ['./mx-terminal.component.scss']
})
export class MxTerminalComponent {

}
