import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
declare let $: any;

@Component({
  selector: 'mantle-order-tax',
  templateUrl: './order-tax.component.html',
  styleUrls: ['./order-tax.component.scss'],
})
export class OrderTaxComponent implements OnInit, OnDestroy {
  @Input() order_id;
  @Input() orderTaxes: Array<any> = [];
  @Input() orderTaxesList;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.orderTaxes = this.orderTaxes.map((x) => {
      return x.sales_tax_name;
    });
    this.orderTaxesList = this.orderTaxesList.map((x) => {
      if (this.orderTaxes.includes(x.sales_tax_name)) x.checked = true;
      return x;
    });
    /* setTimeout(() => {
      if (!$.fn.dataTable.isDataTable('.dataTable')) {
        $('.dataTable').DataTable({
          orderCellsTop: true,
          fixedHeader: true,
          pagingType: 'full_numbers',
          pageLength: 100,
          processing: false,
          lengthMenu: [5, 10, 20, 100, 200, 500],
        });
      }
    }, 1); */
  }

  onSelect = ($event) => {
    const selectedTaxes = this.orderTaxesList.filter((x) => x.checked);
    this.commonService.orderTaxComponentUpdated.next(selectedTaxes);
  };

  ngOnDestroy(): void {}
  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.TAX_COMPONENT_MODAL);
  }
}
