import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/utils/services/api.service';
import { MantleSubscriptionComponent } from '../mantle-subscription/mantle-subscription.component';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'app-mantle-tasks',
  templateUrl: './mantle-tasks.component.html',
  styleUrls: ['./mantle-tasks.component.scss'],
})
export class MantleTasksComponent implements OnInit {
  tasks_data: any = [];
  timezone: string = this.commonService.getTimeZone();

  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.init_values();
  }
  init_values() {
    this.getData();
  }

  getData() {
    this.spinner.show();

    const obs = this.apiService.get('/api/v1/tasks');
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.tasks_data = res;
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  newTask() {
    const modalRef = this.modalService.open(MantleSubscriptionComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    //modalRef.componentInstance.task_data = {"test":1}
    modalRef.result.then(
      (data) => {
        this.getData();
      },
      (reason) => {
        this.getData();
      }
    );
  }

  viewTask(task_id: any) {
    var task_selected = this.tasks_data.filter((x) => x.task_id == task_id);

    if (task_selected.length > 0) {
      task_selected = task_selected[0];

      const modalRef = this.modalService.open(MantleSubscriptionComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      });

      modalRef.componentInstance.task_data = task_selected;

      modalRef.result.then(
        (data) => {
          this.getData();
        },
        (reason) => {
          this.getData();
        }
      );
    } else {
      alert("Something ain't right");
    }
  }
}
