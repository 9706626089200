import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'tcg-update-inventory',
  templateUrl: './tcg-update-inventory.component.html',
  styleUrls: ['./tcg-update-inventory.component.scss'],
})
export class TcgUpdateInventoryComponent implements OnInit {
  @Input() product: any;
  quantityEdited: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  tcgInventoryForm = this.fb.group({
    quantity: ['', Validators.required],
    price: ['', Validators.required],
  });
  ngOnInit(): void {
    this.tcgInventoryForm.patchValue({
      //skuid:this.product.skuid,
      quantity: this.product.quantity,
      price: this.product.price,
      //store_id:this.product.sales_channel.channel_tcg.store_id,
    });
  }
  onAllowQuantityEdit() {
    //Prevent Editing of Quantity
    return;

    this.quantityEdited = !this.quantityEdited;
    if (!this.quantityEdited) {
      this.tcgInventoryForm.patchValue({ quantity: this.product.quantity });
    }
  }
  onUpdateInventory() {
    var requestPayload = {
      channel_tcg_id: this.product.sales_channel.channel_tcg.channel_tcg_id,
      skuid: this.product.skuid,
      price: this.tcgInventoryForm.value.price,
      store_id: this.product.sales_channel.channel_tcg.store_id,
      quantity: this.tcgInventoryForm.value.quantity,
      quantityEdited: this.quantityEdited,
    };
    if (this.quantityEdited) {
      //Validate Product available quantity 1st
    }

    var obs = this.channelsService.updateTcgSkuInventory(requestPayload);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Product Updated');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }
  closeModal() {
    this.commonService.modal_close.next('CHANNEL-TCG-INVENTORY-MODAL');
  }
}
