<form [formGroup]="orderSearchForm" (ngSubmit)="onSubmitSearch()">
  <div class="card">
    <div class="card-header">
      <h6 class="card-title">Purchase Orders Search</h6>
      <div class="card-tools">
        <!-- Collapse Button -->
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
        <button type="button" class="btn btn-tool" data-card-widget="remove">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="row">
        <!-- ENDS LEFT HAND COLUMN -->
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12">
              <!-- textarea -->
              <div style="padding: 15px; background-color: #f1f1f1">
                <label class="control-label small">PO Status</label>
                <div class="form-group">
                  <div>
                    <ng-container
                      *ngFor="let order_status of order_statuses; let i = index"
                    >
                      <div
                        class="col-md-12 form-check"
                        style="margin-right: 20px"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          [value]="order_status.purchase_status_id"
                          (change)="onCheckboxChange($event, 'order_status')"
                          [id]="
                            'order_status_' + order_status.purchase_status_id
                          "
                        />
                        <label
                          class="form-check-label small"
                          [for]="
                            'order_status_' + order_status.purchase_status_id
                          "
                        >
                          {{ order_status.description }}</label
                        >
                      </div>
                    </ng-container>
                  </div>
                  <div class="form-group">
                    <hr />
                    <div
                      class="col-md-12 form-check"
                      style="margin-right: 20px"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="deleted_orders"
                        formControlName="include_deleted"
                      />
                      <label class="form-check-label small" for="deleted_orders"
                        >Include Deleted / Archived Orders</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label small">PO Number</label>
              <input
                class="form-control form-control-sm"
                formControlName="order_number"
                placeholder="Order No#"
              />
            </div>
            <div class="form-group col-md-6">
              <label class="control-label small">Vendor</label>
              <mantle-vendor-search
                formControlName="vendor_id"
              ></mantle-vendor-search>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="form-group col-md-4">
                  <label class="control-label small">Date Range</label>
                  <select
                    class="form-control form-control-sm"
                    (change)="onChangeDateRange($event)"
                  >
                    <option value="">Choose Time Period</option>
                    <option value="1">1 Weeks</option>
                    <option value="2">2 Weeks</option>
                    <option value="3">1 Month</option>
                    <option value="4">3 Months</option>
                    <option value="5">Last Year</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label class="control-label small">From</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    formControlName="order_date_from"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label class="control-label small">To</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    formControlName="order_date_to"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <button type="submit" class="btn btn-primary btn-sm mt-4 w-100">
                <i class="fas fa-search"></i>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->
</form>
