import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { RewardPointService } from 'src/app/utils/services/rewardpoint.service';
import formConfig from '../../../../configs/forms/form_redeem_points.json';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mantle-order-redeem',
  templateUrl: './mantle-order-redeem.component.html',
  styleUrl: './mantle-order-redeem.component.scss',
})
export class MantleOrderRedeemComponent implements OnInit, OnDestroy {
  @Input() order_id: string;
  @Input() customer_id: string;
  @Input() type: string | null;
  private subscription: Subscription = new Subscription();
  customerPoints: any = {};
  constructor(
    private commonService: CommonService,
    private rewardPointService: RewardPointService,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService
  ) {}
  form = this.fb.group({
    customer_id: [],
  });

  options: FormlyFormOptions = {
    formState: {
      store_credit: false,
      reward_points: false,
      max_store_credit: 0,
      max_reward_points: 0,
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  ngOnInit(): void {
    this.fields = formConfig;
    if (this.type == 'REWARD_POINT')
      this.options.formState.reward_points = true;
    if (this.type == 'STORE_CREDIT') this.options.formState.store_credit = true;
    this.initData();
    setTimeout(
      () => this.form.patchValue({ customer_id: this.customer_id }),
      0
    );
  }

  initData = () => {
    this.spinner.show();
    this.subscription.add(
      this.rewardPointService.getCustomerPoints(this.customer_id).subscribe(
        (res) => {
          this.customerPoints = res;
          this.spinner.hide();
          this.options.formState.max_store_credit = this.customerPoints.store_credit;
          this.options.formState.max_reward_points = this.customerPoints.reward_points;
        },
        (error) => this.spinner.hide()
      )
    );
  };

  onRedeem() {
    this.spinner.show();
    var requestPayload = this.form.getRawValue();
    requestPayload.reward_points = (
      (isNaN(requestPayload.reward_points) ? 0 : requestPayload.reward_points) /
      (isNaN(this.customerPoints.points_per_dollar)
        ? 1
        : this.customerPoints.points_per_dollar)
    ).toFixed(2);
    this.subscription.add(
      this.rewardPointService.redeemCustomerPoints(requestPayload).subscribe(
        (res) => {
          this.spinner.hide();
          this.commonService.orderRedeemPoints.next({
            ...this.form.getRawValue(),
            ...{
              points_per_dollar: this.customerPoints.points_per_dollar,
            },
          });
          this.closeModal();
        },
        (error) => this.spinner.hide()
      )
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.ORDER_REDEEM_POINT);
  }
}
