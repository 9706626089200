import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CashRegisterService } from 'src/app/utils/services/cash-register.service';
import { CommonService } from 'src/app/utils/services/common.service';
declare let $: any;

@Component({
  selector: 'mantle-cash-register-transactions',
  templateUrl: './cash-register-transactions.component.html',
  styleUrls: ['./cash-register-transactions.component.scss'],
})
export class CashRegisterTransactionsComponent implements OnInit {
  transactionsList: Array<any> = [];
  @Input() cash_register_id: any;
  @Input() session_id:any;
  dtOptions: any = {};
  inits: boolean = false;

  constructor(
    private cashRegisterService: CashRegisterService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    this.initData();
    setTimeout(() => {
      this.dtOptions = {
        orderCellsTop: true,
        fixedHeader: true,
        pagingType: 'full_numbers',
        pageLength: 500,
        processing: true,
        lengthMenu: [5, 10, 20, 100, 200, 500],
        dom: 'Bfrtip',
        buttons: ['copy', 'csv', 'excel', 'print'],
      };
    }, 1);
  }

  ngAfterViewInit() {}

  initData() {
    this.spinner.show();
    this.cashRegisterService
      .getRegisterTransaction(this.cash_register_id, this.session_id)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.transactionsList = res.transactions;
          this.inits = true;
          /* setTimeout(() => {
            if (!$.fn.dataTable.isDataTable('.dataTable')) {
              $('.dataTable').DataTable({
                orderCellsTop: true,
                fixedHeader: true,
                pagingType: 'full_numbers',
                pageLength: 500,
                processing: true,
                lengthMenu: [5, 10, 20, 100, 200, 500],
                dom: 'Bfrtip',
                buttons: ['copy', 'csv', 'excel', 'print'],
              });
            }
          }, 1); */
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.MODAL_CASHREGISTER_TRANSACTION);
  }
}
