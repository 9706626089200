import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-btn-channel-fetch',
  templateUrl: './mantle-btn-channel-fetch.component.html',
  styleUrls: ['./mantle-btn-channel-fetch.component.scss']
})
export class MantleBtnChannelFetchComponent implements OnInit {

  constructor(
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  onChannelFetch(){

    this.spinner.show();
    const obs = this.channelsService.checkPendingOrders({});
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success("Request Send, background processing for new channel orders")
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }
}
