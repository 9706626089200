import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { ShippingEndiciaComponent } from '../shipping-endicia/shipping-endicia.component';
import { ShippingStampsComponent } from '../shipping-stamps/shipping-stamps.component';

@Component({
  selector: 'shipping-options',
  templateUrl: './shipping-options.component.html',
  styleUrls: ['./shipping-options.component.scss']
})
export class ShippingOptionsComponent implements OnInit {

  @Input() order_id:any = ''
  shipping_options:Array<any> = [{
    id:'ENDICIA',
    description:'Endicia Shipping'
  }/*,
  {
    id:'STAMPS',
    description:'Stamps Shipping'
  }*/]
  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,) { }

  shippingOptionsForm = this.fb.group({
    order_id:['',Validators.required],
    shipping_option:['',Validators.required],
  })
  ngOnInit(): void {
    this.shippingOptionsForm.patchValue({
      order_id:this.order_id
    })
  }
  

  submitOption(){
    const selected = this.shippingOptionsForm.value
    switch (selected.shipping_option) {
      case 'ENDICIA':
        this.endiciaShippingOption(selected.order_id)
        break;
      case 'STAMPS':
        this.stampsShippingOption(selected.order_id)
        break;
      default:
        this.toastr.info("Selected Option Not Available")
        break;
    }
    this. closeModal()
  }

  endiciaShippingOption(order_id){
    
    const modalRef = this.modalService.open(ShippingEndiciaComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.order_id = order_id

    this.commonService.modal_close.subscribe(data =>{
      if(data === 'MODAL-ENDICIA-SHIPPING-OPTIONS'){
        modalRef.close()
      }
    })
  }

  stampsShippingOption(order_id){
    
    const modalRef = this.modalService.open(ShippingStampsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.order_id = order_id

    this.commonService.modal_close.subscribe(data =>{
      if(data === 'MODAL-STAMPS-SHIPPING-OPTIONS'){
        modalRef.close()
      }
    })
  }

  closeModal(){
    this.commonService.modal_close.next('MODAL-SHIPPING-OPTIONS')
  }

}
