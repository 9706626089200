<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <h5>Catalog</h5>
      </div>
      <form [formGroup]="subscriptionForm" (ngSubmit)="onSubmitSubscription()">
        <div class="card-header">
          <div class="form-group col-md-6">
            <input
              class="form-control"
              formControlName="task_id"
              type="hidden"
            />
            <label class="control-label">Task Description *</label>
            <input
              class="form-control form-control-sm"
              formControlName="task_description"
              maxlength="200"
            />
            <small id="task_description_help" class="form-text text-muted"
              >A descriptive text this task</small
            >
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-4">
              <label for="brand_id" class="control-label">Product Line *</label>
              <ng-select
                [items]="product_brand"
                bindLabel="name"
                bindValue="id"
                formControlName="brand_id"
                (change)="changeBrand()"
              >
              </ng-select>
              <!--label for="brand_id" class="control-label">Product Line *</label>
                    <select class="form-control select2-ctr" formControlName="brand_id" id="brand_id">
                        <option value="1">Magic TCG</option>
                        <option value="2">Pokemon TCG</option>
                        <option  value="3">Yugioh</option>
                    </select-->
            </div>

            <div class="form-group col-md-4">
              <label class="control-label">Set Name (s)</label>
              <ng-select
                [items]="product_sets_filtered"
                [multiple]="true"
                bindLabel="name"
                bindValue="id"
                formControlName="set_id"
                (change)="changeSet()"
              >
              </ng-select>
            </div>

            <div class="form-group col-md-4">
              <label class="control-label">Condition (s)</label>
              <ng-select
                [items]="product_conditions_filtered"
                [multiple]="true"
                bindLabel="name"
                bindValue="id"
                formControlName="condition_id"
              >
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label for="rarity_id" class="control-label">Rarity</label>
              <ng-select
                [items]="product_rarity_filtered"
                [multiple]="true"
                bindLabel="name"
                bindValue="id"
                formControlName="rarity_id"
              >
              </ng-select>
            </div>

            <div class="form-group col-md-4">
              <label class="control-label">Language (s)</label>
              <input class="form-control" formControlName="language" />
            </div>

            <div class="form-group col-md-4">
              <label class="control-label">Product Name</label>
              <input class="form-control" formControlName="product_name" />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label class="control-label">Update Frequency *</label>
              <ng-select
                [items]="frequencies"
                bindLabel="name"
                bindValue="id"
                formControlName="frequency_id"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer text-center">
          <div class="row">
            <div class="col-md-2" *ngIf="!is_update">
              <button
                class="btn btn-primary btn-sm"
                id=""
                style="color: white"
                type="submit"
                [disabled]="!subscriptionForm.valid"
              >
                Save <i class="fas fa-save"></i>
              </button>
            </div>
            <div class="col-md-2" *ngIf="is_update">
              <button
                (click)="onUpdateSubscription()"
                class="btn btn-primary btn-sm"
                id=""
                style="color: white"
                type="button"
                [disabled]="!subscriptionForm.valid"
              >
                Update <i class="fas fa-save"></i>
              </button>
            </div>
            <div class="col-md-2" *ngIf="!is_update">
              <button
                (click)="onSubmitDraftSubscription()"
                type="button"
                class="btn btn-warning btn-sm"
                id=""
                style="color: white"
              >
                Save as Draft <i class="fas fa-edit"></i>
              </button>
            </div>
            <div class="col-md-2" *ngIf="is_update">
              <button
                (click)="onUpdateSubscription(active)"
                type="button"
                class="btn btn-outline-primary btn-sm"
                id=""
                style="color: #000"
              >
                <div *ngIf="active == 0">Deactivate Task</div>
                <div *ngIf="active == 1">Activate Task</div>
              </button>
            </div>
            <div class="col-md-2" *ngIf="is_update">
              <button
                (click)="onRefreshTable()"
                type="button"
                class="btn btn-secondary btn-sm"
                id="right"
                style="color: white"
              >
                Refresh Table <i class="fas fa-sync"></i>
              </button>
            </div>
            <div class="col-md-2" *ngIf="is_update">
              <button
                (click)="onDeleteSubscription()"
                type="button"
                class="btn btn-danger btn-sm"
                id="right"
                style="color: white"
              >
                Delete <i class="fas fa-trash"></i>
              </button>
            </div>
            <div class="col-md-2" *ngIf="!is_update">
              <button
                type="reset"
                class="btn btn-danger btn-sm"
                id="right"
                style="color: white"
              >
                Reset <i class="fas fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- /.card-footer-->
    </div>

    <div class="card" *ngIf="is_update">
      <div class="card-header">
        <h6 class="card-title breadcrumb-item">{{ task_description }}</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Date Loaded</th>
                  <th scope="col">No# Records</th>
                  <th scope="col">File Size</th>
                  <th scope="col">Download</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let download_log of download_logs">
                  <td>{{ download_log.time_completed }}</td>
                  <td>{{ download_log.records_count }}</td>
                  <td>{{ download_log.file_size }}</td>
                  <td>
                    <button
                      (click)="onDownloadHistCSV(download_log.task_hist_id)"
                      class="btn btn-sm btn-outline-primary"
                    >
                      Download<i class="fas fa-download ml-2"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
