import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MerchantService } from 'src/app/utils/services/merchant.service';
import { CashRegisterListComponent } from '../../settings-cash-register/cash-register-list/cash-register-list.component';
declare let $: any;
@Component({
  selector: 'mantle-terminal-list',
  templateUrl: './terminal-list.component.html',
  styleUrls: ['./terminal-list.component.scss'],
})
export class TerminalListComponent implements OnInit, OnDestroy {
  @Input() merchant_id: any;
  terminalListSubscription: Subscription;
  terminalUpdatedSubscription: Subscription;
  terminalList: Array<any>;
  deleteTerminalSubscription: Subscription;

  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private mantleModalService: MantleModalService,
    private merchantService: MerchantService
  ) {
    this.terminalUpdatedSubscription = this.commonService.terminalsUpdated.subscribe(
      (data) => {
        this.initData();
      }
    );
  }
  ngOnInit(): void {
    this.initData();
  }

  initData = () => {
    this.spinner.show();
    this.terminalListSubscription = this.merchantService
      .getTerminals(
        this.merchant_id != undefined ? { merchant_id: this.merchant_id } : {}
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.terminalList = res.data;

          setTimeout(() => {
            if (!$.fn.dataTable.isDataTable('.dataTable')) {
              $('.dataTable').DataTable({
                orderCellsTop: true,
                fixedHeader: true,
                pagingType: 'full_numbers',
                pageLength: 100,
                processing: true,
                lengthMenu: [5, 10, 20, 100, 200, 500],
              });
            }
          }, 1);
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  };

  onCashRegister(terminal_id: any) {

    const modalRef = this.mantleModalService.open(
      CashRegisterListComponent,
      MANTLE_MODAL_NAME.CASH_REGISTER_LIST_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        //backdrop: 'static',
      },
      this.initData
    );
  }

  onEdit(terminal_id: any) {
    this.spinner.show();
    const terminal = this.terminalList.find(
      (x) => x.terminal_id == terminal_id
    );
    this.merchantService
      .updateTerminal({
        terminal_id: terminal_id,
        enabled: !terminal.enabled,
      })
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('Record Updated Successfully');
          this.initData();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message);
          this.initData();
        }
      );
  }

  onDelete(terminal_id: any, name?: any) {
    const modalRef = this.mantleModalService.confirmDialog(
      `Delete terminal Merchant : ${name}`,
      'By confriming this, this Terminal will be deleted'
    );

    this.deleteTerminalSubscription = this.commonService.modal_close.subscribe(
      (data: any) => {
        if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
          if (data.data.action_id == 1) {
            this.spinner.show();
            this.merchantService.deleteTerminal(terminal_id).subscribe(
              (res) => {
                this.spinner.hide();
                this.initData();
                modalRef.close();
              },
              (err) => {
                this.toastr.error(err.message);
                this.spinner.hide();
                this.initData();
                modalRef.close();
              }
            );
          }
          modalRef.close();
        }
      }
    );
  }
  ngOnDestroy(): void {
    this.terminalListSubscription.unsubscribe();
    this.terminalUpdatedSubscription.unsubscribe();
    this.deleteTerminalSubscription.unsubscribe();
  }
}
