<div class="parent-container">
  <div class="centered-div">
    <div class="card">
      <div class="card-body register-card-body">
        <p class="login-box-msg">Place Your Order</p>

        <div class="row">
          <div class="col-md-12">
            <order-product-search
              [(itemRef)]="itemRef"
              [notDialog]="true"
            ></order-product-search>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 table-responsive">
            <table class="table table-striped small">
              <thead>
                <tr>
                  <th scope="col" width="25%">Item</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Stock</th>
                  <th scope="col">Total</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of orderItems; index as i">
                  <tr
                    [ngClass]="
                      item.has_errors
                        ? 'table-danger'
                        : item.has_warning
                        ? 'table-warning'
                        : ''
                    "
                  >
                    <td>
                      <mantle-loading
                        *ngIf="item.loading_inventory"
                      ></mantle-loading>
                      {{ item.item_description }}
                    </td>
                    <td>
                      {{ item.unit_price | currency: 'USD':'symbol':'1.2-2' }}
                    </td>
                    <td>
                      <div class="form-group row">
                        <div class="col-md-6">
                          <input
                            type="number"
                            [(value)]="item.quantity"
                            [(ngModel)]="item.quantity"
                            [ngModelOptions]="{
                              standalone: true
                            }"
                            class="form-control form-control-sm float-right"
                            (change)="changeQuantity($event, i)"
                            min="0"
                          />
                        </div>
                      </div>
                    </td>
                    <td>{{ item.stock }}</td>
                    <td>$ {{ item.total }}</td>
                    <td>
                      <div class="btn-toolbar" role="toolbar">
                        <div class="btn-group mr-2" role="group">
                          <button
                            type="button"
                            class="btn btn-sm btn-danger mr-2"
                            (click)="
                              onDeleteOrderItem(
                                i,
                                item.order_item_id,
                                item.order_id
                              )
                            "
                          >
                            <i class="fas fa-trash-alt btn-danger"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3"><strong>Sales Tax:</strong></div>
                  <div class="col-md-3">
                    {{ orderForm.value.sale_tax_pct }} %
                  </div>
                  <div class="col-md-3"><strong>Order Total:</strong></div>
                  <div class="col-md-3">
                    {{
                      orderForm.value.order_total
                        | currency: 'USD':'symbol':'1.2-2'
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <form [formGroup]="customerInfoForm">
                  <div class="input-group mb-3">
                    <input
                      formControlName="fullName"
                      type="text"
                      class="form-control"
                      placeholder="Full name"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-user"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      formControlName="email"
                      type="email"
                      class="form-control"
                      placeholder="Email"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7">
                      <div class="icheck-primary">
                        <input
                          formControlName="terms"
                          type="checkbox"
                          id="agreeTerms"
                          name="terms"
                          value="agree"
                        />
                        <label for="agreeTerms">
                          I agree to the <span>terms</span>
                        </label>
                      </div>
                    </div>
                    <!-- /.col -->
                    <div class="col-md-5">
                      <button
                        type="button"
                        class="btn btn-primary btn-block"
                        [disabled]="!customerInfoForm.valid"
                        (click)="submitOrder()"
                      >
                        <i class="fas fa-save" aria-hidden="true"></i>
                        Submit Order
                      </button>
                    </div>
                    <!-- /.col -->
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-7">&nbsp;</div>
                    <div class="col-md-5">
                      <button
                        type="button"
                        class="btn btn-warning btn-block"
                        (click)="resetOrder()"
                      >
                        <i class="fas fa-undo-alt" aria-hidden="true"></i>
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.form-box -->
    </div>
    <!-- /.card -->
  </div>
</div>
