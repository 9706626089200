import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/utils/services/api.service';
import { AppConfig } from 'src/app/utils/services/app.config';

@Component({
  selector: 'app-endicia-auth-code',
  templateUrl: './endicia-auth-code.component.html',
  styleUrls: ['./endicia-auth-code.component.scss']
})
export class EndiciaAuthCodeComponent implements OnInit {

  code: string;
  message: String;
  constructor(private route: ActivatedRoute, private apiService: ApiService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {

    this.spinner.show()
    this.route.queryParams
      .subscribe(params => {
        this.spinner.hide()
        this.code = params.code;
        this.postCode()
        //window.location.href = `this.apiUrl`;
      }
    );
  }

  postCode(){
    this.spinner.show()
    const obs = this.apiService.get(`/api/v1/endicia/auth-token?code=${this.code}`);
    obs.subscribe((res)=>{
      this.spinner.hide()
      this.message = res.status
    }, (err)=>{
      this.spinner.hide()
      this.message = JSON.stringify("An Error Occured")
    })
  }

}
