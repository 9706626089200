import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MantleWidgetLineGraphComponent } from './mantle-widget-line-graph/mantle-widget-line-graph.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

@NgModule({
  declarations: [MantleWidgetLineGraphComponent],
  imports: [CommonModule,
    NgxEchartsModule.forRoot({ echarts }),],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [MantleWidgetLineGraphComponent]
})
export class MantleReportWidgetModule {}
