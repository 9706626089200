import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from './views/blank/blank.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './views/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppButtonComponent } from './components/app-button/app-button.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';

import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserDropdownMenuComponent } from './pages/main/header/user-dropdown-menu/user-dropdown-menu.component';
import { MantleSubscriptionComponent } from './views/mantle-subscription/mantle-subscription.component';
import { MantleTasksComponent } from './views/mantle-tasks/mantle-tasks.component';
import { FileSaverModule } from 'ngx-filesaver';
import { AppConfig } from './utils/services/app.config';
import { MantleInventoryComponent } from './views/mantle-inventory/mantle-inventory.component';
import { MantleProductFilterComponent } from './views/mantle-inventory/mantle-product-filter/mantle-product-filter.component';
import { MantleInventorySummaryComponent } from './views/mantle-inventory/mantle-inventory-summary/mantle-inventory-summary.component';
import { MantleInventoryListComponent } from './views/mantle-inventory/mantle-inventory-list/mantle-inventory-list.component';
import { MantleInventorySearchComponent } from './views/mantle-inventory/mantle-inventory-search/mantle-inventory-search.component';
import { MantleAddInventoryBtnComponent } from './views/mantle-inventory/mantle-add-inventory-btn/mantle-add-inventory-btn.component';
import { MantleProductInventoryComponent } from './views/mantle-inventory/mantle-product-inventory/mantle-product-inventory.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CardWrapperComponent } from './components/forms/card-wrapper/card-wrapper.component';
import { MantleFavoriteComponent } from './views/mantle-product/mantle-favorite/mantle-favorite.component';
import { MantleFavoriteListComponent } from './views/mantle-product/mantle-favorite/mantle-favorite-list/mantle-favorite-list.component';
import { NgToggleModule } from 'ng-toggle-button';
import { TagInputModule } from 'ngx-chips';
import { MantleOrderComponent } from './views/mantle-order/mantle-order.component';
import { MantleCustomerComponent } from './views/mantle-customer/mantle-customer.component';
import { MantleOrderSearchComponent } from './views/mantle-order/mantle-order-search/mantle-order-search.component';
import { MantleOrderViewComponent } from './views/mantle-order/mantle-order-view/mantle-order-view.component';
import { MantleBtnNewOrderComponent } from './views/mantle-order/mantle-btn-new-order/mantle-btn-new-order.component';
import { MantleBtnNewCustomerComponent } from './views/mantle-customer/mantle-btn-new-customer/mantle-btn-new-customer.component';
import { MantleOrderListComponent } from './views/mantle-order/mantle-order-list/mantle-order-list.component';
import { MantleOrderActionsComponent } from './views/mantle-order/mantle-order-actions/mantle-order-actions.component';
import { MantleOrderCreateComponent } from './views/mantle-order/mantle-order-create/mantle-order-create.component';
import { OrderProductSearchComponent } from './views/mantle-order/mantle-order-create/order-product-search/order-product-search.component';
import { CustomerSearchInputComponent } from './components/forms/customer-search-input/customer-search-input.component';
import { MantleLoadingComponent } from './components/mantle-loading/mantle-loading.component';
import { MantleOrderInvoiceComponent } from './views/mantle-order/mantle-order-invoice/mantle-order-invoice.component';
import { MantleOrderAddressComponent } from './views/mantle-order/mantle-order-address/mantle-order-address.component';
import { MantleOrderPullsheetComponent } from './views/mantle-order/mantle-order-pullsheet/mantle-order-pullsheet.component';
import { NgxPrintModule } from 'ngx-print';
import { ConfirmPaymentComponent } from './views/mantle-order/mantle-order-create/confirm-payment/confirm-payment.component';
import { ChannelComponent } from './views/channel/channel.component';
import { ChannelAddComponent } from './views/channel/channel-add/channel-add.component';
import { ChannelOptionsComponent } from './views/channel/channel-options/channel-options.component';
import { ChannelDashboardComponent } from './views/channel/channel-dashboard/channel-dashboard.component';
import { ChannelPurchaseOrdersComponent } from './views/channel/channel-purchase-orders/channel-purchase-orders.component';
import { ChannelActiveListingComponent } from './views/channel/channel-active-listing/channel-active-listing.component';
import { ActivationComponent } from './views/channel/activation/activation.component';
import { ChannelActivateTcgComponent } from './views/channel/activation/channel-activate-tcg/channel-activate-tcg.component';
import { ChannelListComponent } from './views/channel/channel-list/channel-list.component';
import { CardTotalProductComponent } from './views/channel/channel-dashboard/card-total-product/card-total-product.component';
import { CardValuationComponent } from './views/channel/channel-dashboard/card-valuation/card-valuation.component';
import { CardOrdersComponent } from './views/channel/channel-dashboard/card-orders/card-orders.component';
import { CardRevenueComponent } from './views/channel/channel-dashboard/card-revenue/card-revenue.component';
import { ChannelSalesBreakdownComponent } from './views/channel/channel-dashboard/channel-sales-breakdown/channel-sales-breakdown.component';
import { ChannelStatusSummaryComponent } from './views/channel/channel-dashboard/channel-status-summary/channel-status-summary.component';
import { TcgComponent } from './views/channel/tcg/tcg.component';
import { TcgUpdateInventoryComponent } from './views/channel/tcg/tcg-update-inventory/tcg-update-inventory.component';
import { ChannelActivateBcComponent } from './views/channel/activation/channel-activate-bc/channel-activate-bc.component';
import { BcComponent } from './views/channel/bc/bc.component';
import { ChannelStatusMappingComponent } from './views/channel/channel-status-mapping/channel-status-mapping.component';
import { BtnStatusMappingComponent } from './views/channel/btn-status-mapping/btn-status-mapping.component';
import { BcUpdateInventoryComponent } from './views/channel/bc/bc-update-inventory/bc-update-inventory.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ShippingComponent } from './views/shipping/shipping.component';
import { ShippingOptionsComponent } from './views/shipping/shipping-options/shipping-options.component';
import { ShippingEndiciaComponent } from './views/shipping/shipping-endicia/shipping-endicia.component';
import { ShippingStampsComponent } from './views/shipping/shipping-stamps/shipping-stamps.component';
import { EndiciaValidateAddressComponent } from './views/shipping/shipping-endicia/endicia-validate-address/endicia-validate-address.component';
import { EndiciaCreateShipmentComponent } from './views/shipping/shipping-endicia/endicia-create-shipment/endicia-create-shipment.component';
import { EndiciaGenerateLabelComponent } from './views/shipping/shipping-endicia/endicia-generate-label/endicia-generate-label.component';
import { MantleSettingsComponent } from './views/mantle-settings/mantle-settings.component';
import { SettingsCountryComponent } from './views/mantle-settings/settings-country/settings-country.component';
import { CountryEditComponent } from './views/mantle-settings/settings-country/country-edit/country-edit.component';
import { SettingsCurrencyComponent } from './views/mantle-settings/settings-currency/settings-currency.component';
import { CurrencyEditComponent } from './views/mantle-settings/settings-currency/currency-edit/currency-edit.component';
import { SettingsOrderStatusComponent } from './views/mantle-settings/settings-order-status/settings-order-status.component';
import { OrderStatusEditComponent } from './views/mantle-settings/settings-order-status/order-status-edit/order-status-edit.component';
import { SettingsEndiciaComponent } from './views/mantle-settings/settings-endicia/settings-endicia.component';
import { EndiciaCarrierComponent } from './views/mantle-settings/settings-endicia/endicia-carrier/endicia-carrier.component';
import { EndiciaContentsTypeComponent } from './views/mantle-settings/settings-endicia/endicia-contents-type/endicia-contents-type.component';
import { EndiciaDeliveryConfirmationTypeComponent } from './views/mantle-settings/settings-endicia/endicia-delivery-confirmation-type/endicia-delivery-confirmation-type.component';
import { EndiciaDimensionUnitComponent } from './views/mantle-settings/settings-endicia/endicia-dimension-unit/endicia-dimension-unit.component';
import { EndiciaLabelFormatComponent } from './views/mantle-settings/settings-endicia/endicia-label-format/endicia-label-format.component';
import { EndiciaLabelOutputTypeComponent } from './views/mantle-settings/settings-endicia/endicia-label-output-type/endicia-label-output-type.component';
import { EndiciaLabelSizeComponent } from './views/mantle-settings/settings-endicia/endicia-label-size/endicia-label-size.component';
import { EndiciaNonDeliveryOptionComponent } from './views/mantle-settings/settings-endicia/endicia-non-delivery-option/endicia-non-delivery-option.component';
import { EndiciaPackagingTypeComponent } from './views/mantle-settings/settings-endicia/endicia-packaging-type/endicia-packaging-type.component';
import { EndiciaServiceTypeComponent } from './views/mantle-settings/settings-endicia/endicia-service-type/endicia-service-type.component';
import { EndiciaWeightUnitComponent } from './views/mantle-settings/settings-endicia/endicia-weight-unit/endicia-weight-unit.component';
import { EndiciaCarrierEditComponent } from './views/mantle-settings/settings-endicia/endicia-carrier/endicia-carrier-edit/endicia-carrier-edit.component';
import { EndiciaContentsTypeEditComponent } from './views/mantle-settings/settings-endicia/endicia-contents-type/endicia-contents-type-edit/endicia-contents-type-edit.component';
import { EndiciaDeliveryConfirmationTypeEditComponent } from './views/mantle-settings/settings-endicia/endicia-delivery-confirmation-type/endicia-delivery-confirmation-type-edit/endicia-delivery-confirmation-type-edit.component';
import { EndiciaDimensionUnitEditComponent } from './views/mantle-settings/settings-endicia/endicia-dimension-unit/endicia-dimension-unit-edit/endicia-dimension-unit-edit.component';
import { EndiciaLabelFormatEditComponent } from './views/mantle-settings/settings-endicia/endicia-label-format/endicia-label-format-edit/endicia-label-format-edit.component';
import { EndiciaLabelOutputTypeEditComponent } from './views/mantle-settings/settings-endicia/endicia-label-output-type/endicia-label-output-type-edit/endicia-label-output-type-edit.component';
import { EndiciaLabelSizeEditComponent } from './views/mantle-settings/settings-endicia/endicia-label-size/endicia-label-size-edit/endicia-label-size-edit.component';
import { EndiciaNonDeliveryOptionEditComponent } from './views/mantle-settings/settings-endicia/endicia-non-delivery-option/endicia-non-delivery-option-edit/endicia-non-delivery-option-edit.component';
import { EndiciaPackagingTypeEditComponent } from './views/mantle-settings/settings-endicia/endicia-packaging-type/endicia-packaging-type-edit/endicia-packaging-type-edit.component';
import { EndiciaServiceTypeEditComponent } from './views/mantle-settings/settings-endicia/endicia-service-type/endicia-service-type-edit/endicia-service-type-edit.component';
import { EndiciaWeightUnitEditComponent } from './views/mantle-settings/settings-endicia/endicia-weight-unit/endicia-weight-unit-edit/endicia-weight-unit-edit.component';
import { EndiciaAuthComponent } from './views/mantle-settings/settings-endicia/endicia-auth/endicia-auth.component';
import { OrderShipmentsComponent } from './views/shipping/order-shipments/order-shipments.component';
import { RequestPickupComponent } from './views/shipping/order-shipments/request-pickup/request-pickup.component';
import { ShipmentTrackingComponent } from './views/shipping/order-shipments/shipment-tracking/shipment-tracking.component';
import { NgxTimelineAlbeModule } from 'ngx-timeline-albe';
import { ApiErrorComponent } from './views/shipping/api-error/api-error.component';
import { SettingsUserComponent } from './views/mantle-settings/settings-user/settings-user.component';
import { UserListComponent } from './views/mantle-settings/settings-user/user-list/user-list.component';
import { UserProfileComponent } from './views/mantle-settings/settings-user/user-profile/user-profile.component';
import { ShipmentManifestComponent } from './views/shipping/order-shipments/shipment-manifest/shipment-manifest.component';
import { MagicRightWrapperComponent } from './components/forms/magic-right-wrapper/magic-right-wrapper.component';
import { MantleCheckboxComponent } from './components/forms/mantle-checkbox/mantle-checkbox.component';
import { MantleLogoWrapperComponent } from './components/forms/mantle-logo-wrapper/mantle-logo-wrapper.component';
import { MantleConfirmDialogComponent } from './views/mantle-confirm-dialog/mantle-confirm-dialog.component';
import { ShopifyComponent } from './views/channel/shopify/shopify.component';
import { ChannelActivateShopifyComponent } from './views/channel/activation/channel-activate-shopify/channel-activate-shopify.component';
import { SettingsStoreComponent } from './views/mantle-settings/settings-store/settings-store.component';
import { StoreListComponent } from './views/mantle-settings/settings-store/store-list/store-list.component';
import { StoreDetailsComponent } from './views/mantle-settings/settings-store/store-details/store-details.component';
import { Auth0CallbackComponent } from './pages/auth0-callback/auth0-callback.component';
import { EndiciaAuthCodeComponent } from './views/shipping/shipping-endicia/endicia-auth-code/endicia-auth-code.component';
import { BtnInitChannelLoadComponent } from './views/channel/btn-init-channel-load/btn-init-channel-load.component';
import { MantleBtnChannelFetchComponent } from './views/mantle-order/mantle-btn-channel-fetch/mantle-btn-channel-fetch.component';
import { MantleInventoryUploadComponent } from './views/mantle-inventory/mantle-inventory-upload/mantle-inventory-upload.component';
import { MantleBggSearchBtnComponent } from './views/mantle-product/mantle-bgg-search-btn/mantle-bgg-search-btn.component';
import { MantleBggSearchComponent } from './views/mantle-product/mantle-bgg-search/mantle-bgg-search.component';
import {
  AuthClientConfig,
  AuthModule,
  AuthService,
} from '@auth0/auth0-angular';
import { ChannelActivateAmazonSpComponent } from './views/channel/activation/channel-activate-amazon-sp/channel-activate-amazon-sp.component';
import { PosInstoreComponent } from './components/plugins/pos-instore/pos-instore.component';
import { PluginsComponent } from './components/plugins/plugins.component';
import { PluginSettingComponent } from './components/plugins/plugin-setting/plugin-setting.component';
import { PosPluginsComponent } from './components/plugins/pos-plugins/pos-plugins.component';
import { SettingsCashRegisterComponent } from './views/mantle-settings/settings-cash-register/settings-cash-register.component';
import { CashRegisterListComponent } from './views/mantle-settings/settings-cash-register/cash-register-list/cash-register-list.component';
import { CashRegisterDetailsComponent } from './views/mantle-settings/settings-cash-register/cash-register-details/cash-register-details.component';
import { CashRegisterTransactionsComponent } from './views/mantle-settings/settings-cash-register/cash-register-transactions/cash-register-transactions.component';
import { DataTablesModule } from 'angular-datatables';
import { MantleOrderReceiptComponent } from './views/mantle-order/mantle-order-receipt/mantle-order-receipt.component';
import { SettingsSystemComponent } from './views/mantle-settings/settings-system/settings-system.component';
import { SettingEditComponent } from './views/mantle-settings/settings-system/setting-edit/setting-edit.component';
import { SettingListComponent } from './views/mantle-settings/settings-system/setting-list/setting-list.component';
import { MantleMultipleUpcComponent } from './views/mantle-order/mantle-multiple-upc/mantle-multiple-upc.component';
import { MantleSelectComponent } from './components/forms/mantle-select/mantle-select.component';
import { MantleReportsComponent } from './views/mantle-reports/mantle-reports.component';
import { MantleReportsListComponent } from './views/mantle-reports/mantle-reports-list/mantle-reports-list.component';
import { MantleReportsAdminComponent } from './views/mantle-reports/mantle-reports-admin/mantle-reports-admin.component';
import { WebdatarocksPivotModule } from '@webdatarocks/ngx-webdatarocks';
import { MantleReportRunComponent } from './views/mantle-reports/mantle-report-run/mantle-report-run.component';
import { ReportEditDefaultComponent } from './views/mantle-reports/mantle-reports-admin/report-edit-default/report-edit-default.component';
import { MantleTabularInputsComponent } from './components/forms/mantle-tabular-inputs/mantle-tabular-inputs.component';
import { MantleTagInputComponent } from './components/forms/mantle-tag-input/mantle-tag-input.component';
import { ChannelActivateCardtraderComponent } from './views/channel/activation/channel-activate-cardtrader/channel-activate-cardtrader.component';
import { MantleReportWidgetsComponent } from './views/mantle-reports/mantle-report-widgets/mantle-report-widgets.component';
import { MantleWidgetCardComponent } from './views/mantle-reports/mantle-report-widgets/mantle-widget-card/mantle-widget-card.component';
//import { MantleWidgetLineGraphComponent } from './views/mantle-reports/mantle-report-widgets/mantle-widget-line-graph/mantle-widget-line-graph.component';
import { MantleWidgetPieChartComponent } from './views/mantle-reports/mantle-report-widgets/mantle-widget-pie-chart/mantle-widget-pie-chart.component';
import { MantleWidgetDatatableComponent } from './views/mantle-reports/mantle-report-widgets/mantle-widget-datatable/mantle-widget-datatable.component';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { MantleReportWidgetModule } from './views/mantle-reports/mantle-report-widgets/mantle-report-widget.module';
import { MxMerchantComponent } from './components/plugins/mx-merchant/mx-merchant.component';
import { SettingsMerchantComponent } from './views/mantle-settings/settings-merchant/settings-merchant.component';
import { MerchantListComponent } from './views/mantle-settings/settings-merchant/merchant-list/merchant-list.component';
import { MxMerchantAddComponent } from './components/plugins/mx-merchant/mx-merchant-add/mx-merchant-add.component';
import { TerminalListComponent } from './views/mantle-settings/settings-merchant/terminal-list/terminal-list.component';
import { MxTerminalListComponent } from './components/plugins/mx-merchant/mx-terminal-list/mx-terminal-list.component';
import { MxTerminalComponent } from './components/plugins/mx-merchant/mx-terminal-list/mx-terminal/mx-terminal.component';
import { MantleImageInputComponent } from './components/forms/mantle-image-input/mantle-image-input.component';
import { OrderTaxComponent } from './views/mantle-order/mantle-order-create/order-tax/order-tax.component';
import { BtnNewProductComponent } from './views/mantle-product/btn-new-product/btn-new-product.component';
import { MantleProductComponent } from './views/mantle-product/mantle-product.component';
import { ProductEditComponent } from './views/mantle-product/product-edit/product-edit.component';
import { MantleBarcodeGeneratorComponent } from './views/mantle-product/mantle-barcode-generator/mantle-barcode-generator.component';
import { BtnBarcodeGeneratorComponent } from './views/mantle-product/btn-barcode-generator/btn-barcode-generator.component';
import { CashDenominationComponent } from './views/mantle-settings/settings-cash-register/cash-denomination/cash-denomination.component';
import { MantleCatalogListComponent } from './views/mantle-product/mantle-catalog-list/mantle-catalog-list.component';
import { MantleProductUpdateComponent } from './views/mantle-product/mantle-product-update/mantle-product-update.component';
import { SettingsProductFilterComponent } from './views/mantle-settings/settings-product/settings-product-filter/settings-product-filter.component';
import { MantlePurchaseOrderComponent } from './views/mantle-purchase-order/mantle-purchase-order.component';
import { MantlePoListComponent } from './views/mantle-purchase-order/mantle-po-list/mantle-po-list.component';
import { MantleBtnNewPoComponent } from './views/mantle-purchase-order/mantle-btn-new-po/mantle-btn-new-po.component';
import { MantlePoSearchComponent } from './views/mantle-purchase-order/mantle-po-search/mantle-po-search.component';
import { MantlePoVendorComponent } from './views/mantle-purchase-order/mantle-po-vendor/mantle-po-vendor.component';
import { MantlePoVendorListComponent } from './views/mantle-purchase-order/mantle-po-vendor/mantle-po-vendor-list/mantle-po-vendor-list.component';
import { MantlePoVendorDetailsComponent } from './views/mantle-purchase-order/mantle-po-vendor/mantle-po-vendor-details/mantle-po-vendor-details.component';
import { MantleBtnNewPoVendorComponent } from './views/mantle-purchase-order/mantle-po-vendor/mantle-btn-new-po-vendor/mantle-btn-new-po-vendor.component';
import { MantlePoDetailsComponent } from './views/mantle-purchase-order/mantle-po-details/mantle-po-details.component';
import { MantleVendorSearchComponent } from './components/forms/mantle-vendor-search/mantle-vendor-search.component';
import { MantlePoInvoiceComponent } from './views/mantle-purchase-order/mantle-po-invoice/mantle-po-invoice.component';
import { MantleBarcodeMultipleComponent } from './views/mantle-product/mantle-barcode-multiple/mantle-barcode-multiple.component';
import { MantleOrderReturnComponent } from './views/mantle-order/mantle-order-return/mantle-order-return.component';
import { OrderReturnListComponent } from './views/mantle-order/mantle-order-return/order-return-list/order-return-list.component';
import { OrderReturnDetailsComponent } from './views/mantle-order/mantle-order-return/order-return-details/order-return-details.component';
import { MantlePrintComponent } from './components/mantle-print/mantle-print.component';
import { MantleDashboardWidgetComponent } from './views/dashboard/mantle-dashboard-widget/mantle-dashboard-widget.component';
import { QuickLinksComponent } from './views/dashboard/mantle-dashboard-widget/quick-links/quick-links.component';
import { UserPreferenceComponent } from './views/mantle-settings/settings-user/user-preference/user-preference.component';
import { ManageQuickLinksComponent } from './views/dashboard/mantle-dashboard-widget/quick-links/manage-quick-links/manage-quick-links.component';
import { IsoDateLocalDirective } from './utils/directives/iso-date-local.directive';
import { DatetimeLocalFormlyComponent } from './components/forms/datetime-local-formly/datetime-local-formly.component';
import { SettingsProductComponent } from './views/mantle-settings/settings-product/settings-product.component';
import { SettingsProductDescriptionComponent } from './views/mantle-settings/settings-product/settings-product-description/settings-product-description.component';
import { KioskLayoutComponent } from './pages/kiosk-layout/kiosk-layout.component';
import { KioskOrderComponent } from './pages/kiosk-layout/kiosk-order/kiosk-order.component';
import { ChannelActivateWcComponent } from './views/channel/activation/channel-activate-wc/channel-activate-wc.component';
import { MantleHttpInterceptor } from './utils/interceptors/mantle-http.interceptor';
import { StoreModule } from '@ngrx/store';
import {
  customerReducer,
  customersReducer,
} from './views/mantle-customer/state/customer.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerEffects } from './views/mantle-customer/state/customer.effects';
import { MantleCustomerListComponent } from './views/mantle-customer/mantle-customer-list/mantle-customer-list.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { SettingsRewardPointsComponent } from './views/mantle-settings/settings-reward-points/settings-reward-points.component';
import { TieredListComponent } from './views/mantle-settings/settings-reward-points/tiered-list/tiered-list.component';
import { TieredDetailsComponent } from './views/mantle-settings/settings-reward-points/tiered-list/tiered-details/tiered-details.component';
import { RewardProductListComponent } from './views/mantle-settings/settings-reward-points/product-list/product-list.component';
import { RewardProductDetailsComponent } from './views/mantle-settings/settings-reward-points/product-list/product-details/product-details.component';
import { rewardPointReducer } from './views/mantle-settings/settings-reward-points/state/reward_points.reducers';
import { RewardPointEffects } from './views/mantle-settings/settings-reward-points/state/reward_points.effects';
import { tieredRewardPointReducer } from './views/mantle-settings/settings-reward-points/tiered-list/state/tiered_reward_points.reducers';
import { TieredRewardPointEffects } from './views/mantle-settings/settings-reward-points/tiered-list/state/tiered_reward_points.effects';
import { ProductRewardPointEffects } from './views/mantle-settings/settings-reward-points/product-list/state/product_reward_points.effects';
import { productRewardPointReducer } from './views/mantle-settings/settings-reward-points/product-list/state/product_reward_points.reducers';
import { MantleOrderRedeemComponent } from './views/mantle-order/mantle-order-redeem/mantle-order-redeem.component';
import { MantleInventoryAdvancedComponent } from './views/mantle-inventory/mantle-inventory-advanced/mantle-inventory-advanced.component';
import { InventoryPriceTrendComponent } from './views/mantle-inventory/mantle-inventory-advanced/inventory-price-trend/price-trend.component';
import { InventoryAdvancedSummaryComponent } from './views/mantle-inventory/mantle-inventory-advanced/inventory-summary/inventory-advanced-summary.component';
import { InventoryReturnsComponent } from './views/mantle-inventory/mantle-inventory-advanced/inventory-returns/inventory-returns.component';
import { InventorySalesComponent } from './views/mantle-inventory/mantle-inventory-advanced/inventory-sales/inventory-sales.component';
import { InventoryPeriodSelectorComponent } from './views/mantle-inventory/mantle-inventory-advanced/inventory-period-selector/inventory-period-selector.component';
import { InventoryPoComponent } from './views/mantle-inventory/mantle-inventory-advanced/inventory-po/inventory-po.component';

registerLocaleData(localeEn, 'en-EN');
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    AppButtonComponent,
    UserDropdownMenuComponent,
    MantleSubscriptionComponent,
    MantleTasksComponent,
    MantleInventoryComponent,
    MantleProductFilterComponent,
    MantleInventorySummaryComponent,
    MantleInventoryListComponent,
    MantleInventorySearchComponent,
    MantleAddInventoryBtnComponent,
    MantleProductInventoryComponent,
    CardWrapperComponent,
    MantleFavoriteComponent,
    MantleFavoriteListComponent,
    MantleOrderComponent,
    MantleCustomerComponent,
    MantleOrderSearchComponent,
    MantleOrderViewComponent,
    MantleBtnNewOrderComponent,
    MantleBtnNewCustomerComponent,
    MantleOrderListComponent,
    MantleOrderActionsComponent,
    MantleOrderCreateComponent,
    OrderProductSearchComponent,
    CustomerSearchInputComponent,
    MantleLoadingComponent,
    MantleOrderInvoiceComponent,
    MantleOrderAddressComponent,
    MantleOrderPullsheetComponent,
    ConfirmPaymentComponent,
    ChannelComponent,
    ChannelAddComponent,
    ChannelOptionsComponent,
    ChannelDashboardComponent,
    ChannelPurchaseOrdersComponent,
    ChannelActiveListingComponent,
    ActivationComponent,
    ChannelActivateTcgComponent,
    ChannelListComponent,
    CardTotalProductComponent,
    CardValuationComponent,
    CardOrdersComponent,
    CardRevenueComponent,
    ChannelSalesBreakdownComponent,
    ChannelStatusSummaryComponent,
    TcgComponent,
    TcgUpdateInventoryComponent,
    ChannelActivateBcComponent,
    BcComponent,
    ChannelStatusMappingComponent,
    BtnStatusMappingComponent,
    BcUpdateInventoryComponent,
    ShippingComponent,
    ShippingOptionsComponent,
    ShippingEndiciaComponent,
    ShippingStampsComponent,
    EndiciaValidateAddressComponent,
    EndiciaCreateShipmentComponent,
    EndiciaGenerateLabelComponent,
    MantleSettingsComponent,
    SettingsCountryComponent,
    CountryEditComponent,
    SettingsCurrencyComponent,
    CurrencyEditComponent,
    SettingsOrderStatusComponent,
    OrderStatusEditComponent,
    SettingsEndiciaComponent,
    EndiciaCarrierComponent,
    EndiciaContentsTypeComponent,
    EndiciaDeliveryConfirmationTypeComponent,
    EndiciaDimensionUnitComponent,
    EndiciaLabelFormatComponent,
    EndiciaLabelOutputTypeComponent,
    EndiciaLabelSizeComponent,
    EndiciaNonDeliveryOptionComponent,
    EndiciaPackagingTypeComponent,
    EndiciaServiceTypeComponent,
    EndiciaWeightUnitComponent,
    EndiciaCarrierEditComponent,
    EndiciaContentsTypeEditComponent,
    EndiciaDeliveryConfirmationTypeEditComponent,
    EndiciaDimensionUnitEditComponent,
    EndiciaLabelFormatEditComponent,
    EndiciaLabelOutputTypeEditComponent,
    EndiciaLabelSizeEditComponent,
    EndiciaNonDeliveryOptionEditComponent,
    EndiciaPackagingTypeEditComponent,
    EndiciaServiceTypeEditComponent,
    EndiciaWeightUnitEditComponent,
    EndiciaAuthComponent,
    OrderShipmentsComponent,
    RequestPickupComponent,
    ShipmentTrackingComponent,
    ApiErrorComponent,
    SettingsUserComponent,
    UserListComponent,
    UserProfileComponent,
    ShipmentManifestComponent,
    MagicRightWrapperComponent,
    MantleCheckboxComponent,
    MantleLogoWrapperComponent,
    MantleConfirmDialogComponent,
    ShopifyComponent,
    ChannelActivateShopifyComponent,
    SettingsStoreComponent,
    StoreListComponent,
    StoreDetailsComponent,
    Auth0CallbackComponent,
    EndiciaAuthCodeComponent,
    BtnInitChannelLoadComponent,
    MantleBtnChannelFetchComponent,
    MantleInventoryUploadComponent,
    MantleBggSearchBtnComponent,
    MantleBggSearchComponent,
    ChannelActivateAmazonSpComponent,
    PosInstoreComponent,
    PluginsComponent,
    PluginSettingComponent,
    PosPluginsComponent,
    SettingsCashRegisterComponent,
    CashRegisterListComponent,
    CashRegisterDetailsComponent,
    CashRegisterTransactionsComponent,
    MantleOrderReceiptComponent,
    SettingsSystemComponent,
    SettingEditComponent,
    SettingListComponent,
    MantleMultipleUpcComponent,
    MantleSelectComponent,
    MantleReportsComponent,
    MantleReportsListComponent,
    MantleReportsAdminComponent,
    MantleReportRunComponent,
    ReportEditDefaultComponent,
    MantleTabularInputsComponent,
    MantleTagInputComponent,
    ChannelActivateCardtraderComponent,
    MantleReportWidgetsComponent,
    MantleWidgetCardComponent,
    //MantleWidgetLineGraphComponent,
    MantleWidgetPieChartComponent,
    MantleWidgetDatatableComponent,
    MxMerchantComponent,
    SettingsMerchantComponent,
    MerchantListComponent,
    MxMerchantAddComponent,
    TerminalListComponent,
    MxTerminalListComponent,
    MxTerminalComponent,
    MantleImageInputComponent,
    OrderTaxComponent,
    BtnNewProductComponent,
    MantleProductComponent,
    ProductEditComponent,
    MantleBarcodeGeneratorComponent,
    BtnBarcodeGeneratorComponent,
    CashDenominationComponent,
    MantleCatalogListComponent,
    MantleProductUpdateComponent,
    SettingsProductFilterComponent,
    MantlePurchaseOrderComponent,
    MantlePoListComponent,
    MantleBtnNewPoComponent,
    MantlePoSearchComponent,
    MantlePoVendorComponent,
    MantlePoVendorListComponent,
    MantlePoVendorDetailsComponent,
    MantleBtnNewPoVendorComponent,
    MantlePoDetailsComponent,
    MantleVendorSearchComponent,
    MantlePoInvoiceComponent,
    MantleBarcodeMultipleComponent,
    MantleOrderReturnComponent,
    OrderReturnListComponent,
    OrderReturnDetailsComponent,
    MantlePrintComponent,
    MantleDashboardWidgetComponent,
    QuickLinksComponent,
    UserPreferenceComponent,
    ManageQuickLinksComponent,
    IsoDateLocalDirective,
    DatetimeLocalFormlyComponent,
    SettingsProductComponent,
    SettingsProductDescriptionComponent,
    KioskLayoutComponent,
    KioskOrderComponent,
    ChannelActivateWcComponent,
    MantleCustomerListComponent,
    SettingsRewardPointsComponent,
    TieredListComponent,
    TieredDetailsComponent,
    RewardProductListComponent,
    RewardProductDetailsComponent,
    MantleOrderRedeemComponent,
    MantleInventoryAdvancedComponent,
    InventoryPriceTrendComponent,
    InventoryAdvancedSummaryComponent,
    InventoryReturnsComponent,
    InventorySalesComponent,
    InventoryPeriodSelectorComponent,
    InventoryPoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPrintModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgbModule,
    HttpClientModule,
    NgxSpinnerModule,
    FileSaverModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'card_wrapper', component: CardWrapperComponent },
        { name: 'magic_right_wrapper', component: MagicRightWrapperComponent },
        { name: 'mantle_logo_wrapper', component: MantleLogoWrapperComponent },
      ],
      types: [
        { name: 'mantle_checkbox', component: MantleCheckboxComponent },
        { name: 'mantle-select', component: MantleSelectComponent },
        {
          name: 'mantle-tabular-inputs',
          component: MantleTabularInputsComponent,
        },
        { name: 'mantle-tag-input', component: MantleTagInputComponent },
        { name: 'mantle-image-input', component: MantleImageInputComponent },
        {
          name: 'datetime-local-formly',
          component: DatetimeLocalFormlyComponent,
        },
      ],
      validators: [
        {
          name: 'child_sku',
          validation: (control: FormControl) => {
            return /^[A-Z0-9]+(?:-[A-Z0-9]+){0,}$/.test(control.value)
              ? null
              : { child_sku: true };
          },
        },
        {
          name: 'parent_sku',
          validation: (control: FormControl) => {
            return /^[A-Z0-9]+(?:-[A-Z0-9]+){0,}$/.test(control.value)
              ? null
              : { parent_sku: true };
          },
        },
      ],
      validationMessages: [
        {
          name: 'child_sku',
          message:
            'Input must be uppercase alphanumeric, optionally separated by single hyphens e.g MTG-SET-001',
        },
        {
          name: 'parent_sku',
          message:
            'Input must be uppercase alphanumeric, optionally separated by single hyphens e.g MTG-SET',
        },
      ],
      extras: { lazyRender: true },
    }),
    FormlyBootstrapModule,
    NgxWebstorageModule.forRoot(),
    NgToggleModule,
    CommonModule,
    TagInputModule,
    EditorModule,
    NgxTimelineAlbeModule,
    DataTablesModule,
    WebdatarocksPivotModule,
    NgxEchartsModule.forRoot({ echarts }),
    MantleReportWidgetModule,
    AuthModule.forRoot(),
    StoreModule.forRoot({
      customers: customersReducer,
      customer: customerReducer,
      rewardPoint: rewardPointReducer,
      tieredRewardPoint: tieredRewardPointReducer,
      productRewardPoint: productRewardPointReducer,
    }),
    EffectsModule.forRoot([
      CustomerEffects,
      RewardPointEffects,
      TieredRewardPointEffects,
      ProductRewardPointEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        return new Promise((resolve) => {
          resolve(true);
        });
      },
      deps: [AppConfig],
      multi: true,
    },
    DatePipe,
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MantleHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    //NO_ERRORS_SCHEMA
  ],
})
export class AppModule {}
