import { Component, OnInit } from '@angular/core';
import { ReportingService } from 'src/app/utils/services/reporting.service';
@Component({
  selector: 'mantle-widget-pie-chart',
  templateUrl: './mantle-widget-pie-chart.component.html',
  styleUrls: ['./mantle-widget-pie-chart.component.scss'],
})
export class MantleWidgetPieChartComponent implements OnInit {
  constructor(private reportingService: ReportingService) {}
  ngOnInit(): void {}
}
