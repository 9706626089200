import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss'],
})
export class StoreDetailsComponent implements OnInit {
  @Input() store_id: any;
  constructor(
    private settingsService: SettingsService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  storeForm = this.fb.group({
    store_id: [],
    store_name: ['', Validators.required],
    store_email: [],
    store_phone: [],
    store_address: ['', Validators.required],
    store_address_1: [],
    store_citycode: [],
    store_statecode: [],
    store_zipcode: [],
    store_countrycode: ['US'],
  });

  ngOnInit(): void {
    this.init_data();
  }

  init_data() {
    if (this.store_id) {
      this.spinner.show();
      const obs = this.settingsService.getStore(this.store_id);
      obs.subscribe(
        (res) => {
          this.spinner.hide();
          this.storeForm.patchValue(res);
        },
        (err) => {
          this.spinner.hide();
          
          this.toastr.error('An Error Occurred');
        }
      );
    }
  }

  createStore() {
    this.spinner.show();
    const obs = this.settingsService.addStore(this.storeForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.storeForm.patchValue(res);
        this.store_id = res.store_id;
        this.toastr.success('Store Successfully Created');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error('An Error Occurred');
      }
    );
  }

  updateStore() {
    this.spinner.show();
    const obs = this.settingsService.updateStore(this.storeForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Store Successfully Updated');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error('An Error Occurred');
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('MODAL-STORE-DETAILS');
  }
}
