import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss']
})
export class ApiErrorComponent implements OnInit {

  @Input() error:any

  constructor(private commonService: CommonService,) { }

  ngOnInit(): void {
  }
  closeModal(){
    this.commonService.modal_close.next('ENDICIA-API-ERROR')
  }

}
