import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { AppConfig } from 'src/app/utils/services/app.config';
import { AppService } from 'src/app/utils/services/app.service';
import { Auth0Service } from 'src/app/utils/services/auth0.service';

@Component({
  selector: 'app-user-dropdown-menu',
  templateUrl: './user-dropdown-menu.component.html',
  styleUrls: ['./user-dropdown-menu.component.scss'],
})
export class UserDropdownMenuComponent implements OnInit {
  public user;
  public authType:any;

  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private appService: AppService,
    private config: AppConfig, 
    private auth0Service: Auth0Service
  ) {
    this.authType = this.config.getConfig("authType")
  }

  ngOnInit(): void {
    this.user = this.appService.user;
  }

  toggleDropdownMenu() {
   /*  if (this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.hideDropdownMenu();
    } else {
      this.showDropdownMenu();
    } */
  }

  showDropdownMenu() {
    //this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
  }

  hideDropdownMenu() {
    //this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
  }

  logout() {
    if(this.authType == "auth0"){
      this.auth0Service.logout()
    }
    else{
      this.appService.logout();

    }
  }
}
