import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private commonService: CommonService
  ) {}

  getUsers(): Observable<any> {
    return this.apiService.get('/api/v1/users');
  }

  getUserById(user_id): Observable<any> {
    return this.apiService.get('/api/v1/user/' + user_id);
  }

  registerUser(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/user/register', requestPayload);
  }
  updateUser(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/user/update', requestPayload);
  }

  changeUserPassword(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/user/password', requestPayload);
  }

  updateAuthoUser(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/user/auth0', requestPayload);
  }

  getUserByEmail(email): Observable<any> {
    return this.apiService.get('/api/v1/user/email/' + email);
  }
}
