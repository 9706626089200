<div class="card" style="background-color: transparent">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive w-100">
          <table class="table table-striped dataTable small">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Order No#</th>
                <th>Order Date</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataItems">
                <td>{{ item.child_sku }}</td>
                <td>{{ item.order?.order_number }}</td>
                <td>{{ item.order?.order_date | date: 'short':timezone }}</td>
                <td>{{ item.quantity }}</td>
                <td>
                  {{ item.unit_price | currency: 'USD':'symbol':'1.2-2' }}
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    View Order
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
