import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';

@Component({
  selector: 'mantle-inventory-advanced',
  templateUrl: './mantle-inventory-advanced.component.html',
  styleUrl: './mantle-inventory-advanced.component.scss',
})
export class MantleInventoryAdvancedComponent implements OnInit {
  @Input() inventoryDetails;
  constructor(
    private inventoryService: InventoryService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {}
  ngOnInit(): void {}
  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.INVENTORY_ADVANCED_MODAL
    );
  }
}
