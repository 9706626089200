<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h5>Channel Dashboard</h5>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Channel Dashboard</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
</section>

  <!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="row ">
                    <div class="col-3">
                        <card-total-product [(total_products)]="dashboardData.total_products"></card-total-product>
                    </div>
                    <div class="col-3">
                        <card-valuation [(total_products_without_cost)]="dashboardData.total_products_without_cost"></card-valuation>
                    </div>
                    <div class="col-3">
                        <card-orders [(orders_count)]="dashboardData.orders.count"></card-orders>
                    </div>
                    <div class="col-3">
                        <card-revenue [(orders_revenue)]="dashboardData.orders.revenue"></card-revenue>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <channel-status-summary [(status_summary)]="dashboardData.status_summary"></channel-status-summary>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <channel-sales-breakdown [(sales_channel)]="dashboardData.sales_channel"></channel-sales-breakdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>