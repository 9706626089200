import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'mantle-settings-product',
  templateUrl: './settings-product.component.html',
  styleUrls: ['./settings-product.component.scss']
})
export class SettingsProductComponent {
  @ViewChild('nav') nav;
  active = 0;

}
