import { FormControl } from '@angular/forms';
import moment from 'moment';
import { CommonService } from 'src/app/utils/services/common.service';

export class ISODateFormControl extends FormControl {
  constructor(
    private commonService: CommonService,
    formState?: any,
    validatorOrOpts?: any,
    asyncValidator?: any
  ) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  getRawValue(): any {
    const dateInTimezone = moment.tz(
      this.value,
      this.commonService.getTimeZone(true)
    );
    const result = dateInTimezone.toDate().toISOString();
    return result;
  }
}
