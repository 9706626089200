<form [formGroup]="shopifyConfigForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Activate Shopify sales channel</h5>
    </div>
    <div class="card-body">
      <div class="row small">
        <div class="col-md-12">
          <span
            >How to get an API key:
            <a
              href="https://help.front.com/t/m2249f/how-to-enable-and-use-the-shopify-integration"
              target="_blank"
              title=""
              >https://help.front.com/t/m2249f/how-to-enable-and-use-the-shopify-integration</a
            ></span
          >
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Description</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="channel_shopify_description"
            maxlength="50"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">API Key</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="api_key"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Admin API Access Token</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="api_password"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Store Name </label
          ><small>(https://<strong>storename</strong>.myshopify.com)</small>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="store_name"
          />
        </div>
        <div class="form-check col-md-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="enable_orders_webhook"
            formControlName="enable_orders_webhook"
          />
          <label class="form-check-label small" for="enable_orders_webhook"
            >Enable Orders Webhook</label
          >
        </div>
        <div class="form-group col-md-3">
          <label class="control-label small">Update Frequency (Minutes)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="update_frequency"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Orders Since</label>
          <input
            type="datetime-local"
            class="form-control form-control-sm"
            formControlName="created_at_min"
            IsoDateLocal
          />
        </div>
      </div>
      <ng-container *ngIf="shopifyConfigForm.value.enable_orders_webhook">
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label small">Webhook destination</label>
            <small><i> only https</i></small>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="webhook_destination"
            />
            <input
              type="hidden"
              class="form-control form-control-sm"
              formControlName="webhook_id"
            />
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="form-group col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onValidate()"
            [disabled]="!shopifyConfigForm.valid"
          >
            <i class="fas fa-check-double"></i> Validate
          </button>
        </div>
        <div class="form-group col-md-6">
          <button
            *ngIf="validated && !shopifyConfigForm.value.channel_shopify_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onSave()"
            [disabled]="!shopifyConfigForm.valid"
          >
            <i class="far fa-save"></i> Save
          </button>
          <button
            *ngIf="validated && shopifyConfigForm.value.channel_shopify_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdate()"
            [disabled]="!shopifyConfigForm.valid"
          >
            <i class="far fa-save"></i> Update
          </button>
          <button
            *ngIf="!validated && shopifyConfigForm.value.channel_shopify_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdateFrequency()"
            [disabled]="!shopifyConfigForm.valid"
          >
            <i class="far fa-save"></i> Update Frequency
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
