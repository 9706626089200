import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { EndiciaLabelFormatEditComponent } from './endicia-label-format-edit/endicia-label-format-edit.component';
declare let $: any;
@Component({
  selector: 'endicia-label-format',
  templateUrl: './endicia-label-format.component.html',
  styleUrls: ['./endicia-label-format.component.scss'],
})
export class EndiciaLabelFormatComponent implements OnInit {
  labelformatList: Array<any> = [];
  constructor(
    private settingsService: SettingsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init_data();
  }

  init_data() {
    this.spinner.show();
    const obs = this.settingsService.getLabelFormats();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.labelformatList = res;

        setTimeout(() => {
          $('.dataTable_4 thead tr').clone(true).appendTo('.dataTable_4 thead');
          $('.dataTable_4 thead tr:eq(1) th').each(function (i) {
            var title = $(this).text();
            $(this).html(
              '<input type="text" style="width:100%" placeholder="Search ' +
                title +
                '" />'
            );

            $('input', this).on('keyup change', function () {
              if (dataTable.column(i).search() !== this.value) {
                dataTable.column(i).search(this.value).draw();
              }
            });
          });

          var dataTable = $('.dataTable_4').DataTable({
            orderCellsTop: true,
            fixedHeader: true,
            pagingType: 'full_numbers',
            pageLength: 100,
            processing: true,
            lengthMenu: [5, 10, 20, 100, 200, 500],
          });
        }, 1);
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  init_reload() {
    this.spinner.show();
    const obs = this.settingsService.getLabelFormats();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.labelformatList = res;
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onAdd() {
    const modalRef = this.modalService.open(EndiciaLabelFormatEditComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.isNewRecord = true;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'SETTINGS-EDIT-LABLE-FORMAT-UNIT-MODAL') {
        modalRef.close();
      }
    });
  }

  onEdit(id) {
    const selectedItem = this.labelformatList.find((x) => x.id == id);

    const modalRef = this.modalService.open(EndiciaLabelFormatEditComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.labelformat = selectedItem;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'SETTINGS-EDIT-LABLE-FORMAT-UNIT-MODAL') {
        modalRef.close();
      }
    });
  }

  onDelete(id) {
    const selectedItem = this.labelformatList.find((x) => x.id == id);
    const ref_id = selectedItem.id;
    this.spinner.show();
    const obs = this.settingsService.deleteLabelFormat(ref_id);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.init_reload();
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }
}
