<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="crForm">
        <div class="card-header">
          <h3 class="card-title small">Cash Register Details</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="crForm"
              ></formly-form>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted" *ngIf="!isCashRegisterLog">
          <div class="row">
            <div class="col-3">
              <ng-container *ngIf="!cash_register_id">
                <button
                  type="submit"
                  class="btn btn-outline-success btn-sm w-50"
                  (click)="onSave()"
                >
                  Save
                </button>
              </ng-container>

              <ng-container *ngIf="cash_register_id">
                <button
                  type="submit"
                  class="btn btn-outline-success btn-sm w-50"
                  (click)="onUpdate()"
                >
                  Update
                </button>
              </ng-container>
            </div>
            <ng-container *ngIf="cash_register_id && operating_status == 2">
              <div class="col-3">
                <button
                  type="submit"
                  class="btn btn-outline-success btn-sm w-50"
                  (click)="onStartRegister()"
                >
                  Start / Open Register
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="cash_register_id && operating_status == 1">
              <div class="col-3">
                <button
                  type="submit"
                  class="btn btn-outline-warning btn-sm w-50"
                  (click)="onCloseRegister()"
                >
                  Stop / Close Register
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="cash_register_id">
              <div class="col-3">
                <button
                  type="submit"
                  class="btn btn-outline-primary btn-sm w-50"
                  (click)="onViewTransactions()"
                >
                  View Transactions
                </button>
              </div>
            </ng-container>
            <div class="col-3">
              <button
                type="button"
                class="btn btn-outline-danger btn-sm w-50"
                (click)="closeModal()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted" *ngIf="isCashRegisterLog">

          <div class="row">
            <div class="col-4">
              <button
                type="submit"
                class="btn btn-outline-secondary btn-sm w-50"
                (click)="onViewDenomination()"
              >
                View Cash Denominations
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
