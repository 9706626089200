<form [formGroup]="shippingForm">
  <div class="card">
    <div class="card-body">
      <div class="row" *ngIf="tracking_number">
        <div class="col-12 bg-light text-white rounded">
          <h6 class="float-right">Tracking Number: {{tracking_number}}</h6>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-4">
          <label class="control-label small">Carrier</label>
          <ng-select [items]="carriers" bindLabel="carrier_description" bindValue="carrier"
            [searchable]="true" formControlName="carrier">
          </ng-select>
        </div>
        <div class="form-group col-4">
          <label class="control-label small">Service Type</label>
          <!--input type="text" class="form-control form-control-sm" formControlName="service_type" -->
          <ng-select [items]="service_type" bindLabel="service_type_description" bindValue="service_type"
            [searchable]="true" formControlName="service_type">
          </ng-select>
        </div>
        <div class="col-4">
          <label class="control-label small">Confirmation</label>
          <ng-select [items]="delivery_confirmation_type" bindLabel="delivery_confirmation_type_description" bindValue="delivery_confirmation_type"
            [searchable]="true" formControlName="delivery_confirmation_type" >
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-4">
          <label class="control-label small">Ship Date</label>
          <input type="date" class="form-control form-control-sm" formControlName="ship_date">
        </div>
        <div class="form-group col-4">
        </div>
        <div class="col-4">
        </div>
      </div>

      <div class="row" formGroupName="package">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <small>Package</small>
              <div class="card-tools">
                  <!-- Collapse Button -->
                  <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group  col-2">
                  <label class="control-label small">Package Type</label>
                </div>
                <div class="form-group col-4">
                  <ng-select [items]="package_type" bindLabel="packaging_type_description" bindValue="packaging_type"
                    [searchable]="true" formControlName="packaging_type">
                  </ng-select>
                </div>
              </div>
              
              <div class="row form-group">
                <div class="col-2">
                  <label class="control-label small">Weight</label>
                </div>
                <div class="col-4">
                  <input type="number" class="form-control form-control-sm" formControlName="weight" min="0">
                </div>
                <div class="col-2">
                  <label class="control-label small">Unit</label>
                </div>
                <div class="col-4">
                  <ng-select [items]="weight_unit" bindLabel="weight_unit_description" bindValue="weight_unit"
                    [searchable]="true" formControlName="weight_unit">
                  </ng-select>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-2">
                  <label class="control-label small">Size</label>
                </div>
                <div class="col-1">
                  <label class="control-label small">L</label>
                  <input type="number" class="form-control form-control-sm" formControlName="length" min="0">
                </div>
                <div class="col-1">
                  <label class="control-label small">W</label>
                  <input type="number" class="form-control form-control-sm" formControlName="width" min="0">
                </div>
                <div class="col-2">
                  <label class="control-label small">H</label>
                  <input type="number" class="form-control form-control-sm" formControlName="height" min="0">
                </div>
                <div class="col-2">
                  <label class="control-label small">Unit</label>
                </div>
                <div class="col-4">
                  <label class="control-label small">&nbsp;</label>
                  <ng-select [items]="dimension_unit" bindLabel="dimension_unit_description" bindValue="dimension_unit"
                    [searchable]="true" formControlName="dimension_unit" >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" formGroupName="label_options">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <small>Label Options</small>
              <div class="card-tools">
                  <!-- Collapse Button -->
                  <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group  col-4">
                  <label class="control-label small">Label Size</label>
                  <ng-select [items]="label_size" bindLabel="label_size_description" bindValue="label_size"
                    [searchable]="true" formControlName="label_size" >
                  </ng-select>
                </div>
                <div class="form-group  col-4">
                  <label class="control-label small">Label Format</label>
                  <input type="text" readonly class="form-control form-control-sm" formControlName="label_format">
                </div>
                <div class="form-group  col-4">
                  <label class="control-label small">Label Output Type</label>
                  <input type="text" readonly class="form-control form-control-sm" formControlName="label_output_type">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" formGroupName="insurance">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <small>Insurance</small>
              <div class="card-tools">
                  <!-- Collapse Button -->
                  <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group  col-4">
                  <label class="control-label small">Insurance Provider</label>
                  <ng-select [items]="insurance_provider" bindLabel="insurance_provider_description" bindValue="insurance_provider"
                    [searchable]="true" formControlName="insurance_provider" >
                  </ng-select>
                </div>
                <div class="form-group  col-4" formGroupName="insured_value">
                  <label class="control-label small">Insured Amount</label>
                  <input type="number" class="form-control form-control-sm" formControlName="amount" min="0">
                </div>
                <div class="form-group  col-4" formGroupName="insured_value">
                  <label class="control-label small">Currency</label>
                  <input type="text" class="form-control form-control-sm" formControlName="currency">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" formGroupName="email_label" [hidden]="true">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <small>Email Label</small>
              <div class="card-tools">
                  <!-- Collapse Button -->
                  <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group  col-4">
                  <label class="control-label small">Email</label>
                  <input type="text" class="form-control form-control-sm" formControlName="email">
                </div>
                <div class="form-group  col-4">
                  <label class="control-label small">Email Notes</label>
                  <input type="text" class="form-control form-control-sm" formControlName="email_notes">
                </div>
                <div class="form-group  col-4">
                  <label class="control-label small">BCC</label>
                  <input type="text" class="form-control form-control-sm" formControlName="bcc_email">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="row">
        <div class="col-4">
          <small class="text-success font-weight-bold">Rate: {{shippingRateCurrency}} {{shippingRateTotal}}</small><br>
          <small class="text-muted">Charged Rate: {{orderDetails?.currency_code}} {{orderDetails?.shipment_cost}}</small>
        </div>
        <div class="col-4">
          <button type="button" class="btn btn-outline-secondary  btn-sm" (click)="viewShippingRates()">Get Shipping Rate <i class="fas fa-calculator"></i></button>
        </div>
        <div class="col-4">
          <button type="button" class="btn btn-success  btn-sm" (click)="createShippingLabel()" [disabled]="!rateGenerated">Create + Print Label <i class="fas fa-print"></i></button>
        </div>
      </div>
    </div>
  </div>
</form>
