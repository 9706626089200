import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import queryString from 'query-string';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private apiService: ApiService) {}
  getCustomers(filterParams: any = null): Observable<any> {
    const queryParam = queryString.stringify(filterParams);
    return this.apiService.get(`/api/v1/customers?${queryParam}`);
  }
  getCustomerById(customer_id: any): Observable<any> {
    return this.apiService.get(`/api/v1/customer/${customer_id}`);
  }

  createCustomer(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/customer', requestPayload);
  }

  updateCustomer(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/customer', requestPayload);
  }

  searchCustomer(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/customer/search', requestPayload);
  }
}
