import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../const/constants';
import { IAppConfig } from '../interfaces/IAppConfig';
import { AuthClientConfig } from '@auth0/auth0-angular';

@Injectable()
export class AppConfig {
  constructor(
    @Inject(APP_CONFIG) private readonly _iAppConfig: IAppConfig,
    private _authClientConfig: AuthClientConfig
  ) {
    _authClientConfig.set({
      domain: _iAppConfig.CLIENT_DOMAIN,
      clientId: _iAppConfig.CLIENT_ID,
      issuer: _iAppConfig.ISSUER,
      useRefreshTokens: true,
      authorizationParams: {
        redirect_uri: _iAppConfig.REDIRECT,
        audience: _iAppConfig.AUDIENCE,
      },
      cacheLocation: 'localstorage',
    });
  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this._iAppConfig[key];
  }
}
