<mantle-loading *ngIf="loading"></mantle-loading>
<div class="alert alert-danger" role="alert" *ngIf="hasError">
  {{ errorText }}
</div>

<mantle-widget-card
  *ngIf="report_type_id == '1'"
  [report_details]="report_details"
  [report_id]="report_id"
></mantle-widget-card>

<mantle-widget-line-graph
  *ngIf="report_type_id == '2'"
  [report_details]="report_details"
  [report_id]="report_id"
></mantle-widget-line-graph>

<mantle-widget-pie-chart
  *ngIf="report_type_id == '3'"
  [report_details]="report_details"
  [report_id]="report_id"
></mantle-widget-pie-chart>

<mantle-widget-datatable
  *ngIf="report_type_id == '4'"
  [report_details]="report_details"
  [report_id]="report_id"
></mantle-widget-datatable>
