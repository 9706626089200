import { Component, OnInit } from '@angular/core';
import { MantleProductInventoryComponent } from '../mantle-product-inventory/mantle-product-inventory.component';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';

@Component({
  selector: 'mantle-add-inventory-btn',
  templateUrl: './mantle-add-inventory-btn.component.html',
  styleUrls: ['./mantle-add-inventory-btn.component.scss']
})
export class MantleAddInventoryBtnComponent implements OnInit {

  constructor(private mantleModalService: MantleModalService,) { }

  ngOnInit(): void {
  }

  onAddProduct(){
    this.mantleModalService.open(
      MantleProductInventoryComponent,
      MANTLE_MODAL_NAME.PRODUCT_INVENTORY_ONLY_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
  }

}
