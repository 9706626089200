import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { MantleOrderAddressComponent } from '../mantle-order-address/mantle-order-address.component';
import { MantleOrderInvoiceComponent } from '../mantle-order-invoice/mantle-order-invoice.component';
import { MantleOrderPullsheetComponent } from '../mantle-order-pullsheet/mantle-order-pullsheet.component';

@Component({
  selector: 'mantle-order-actions',
  templateUrl: './mantle-order-actions.component.html',
  styleUrls: ['./mantle-order-actions.component.scss'],
})
export class MantleOrderActionsComponent implements OnInit {
  order_statuses: Array<any> = [];
  allOrderIds: Array<any> = [];
  selectedOrderIds: Array<any> = [];
  sendEmail: boolean = false;
  orderStatusId: any;
  searchFormValues: any;

  constructor(
    private commonService: CommonService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.orderService.order_list_all_items.subscribe((data: Array<any>) => {
      this.allOrderIds = data;
    });

    this.orderService.order_list_selected_items.subscribe((data) => {
      this.updateOrderSelection(data);
    });

    this.orderService.searchOrder.subscribe((data) => {
      this.searchFormValues = data;
    });
    this.init_values();
  }
  init_values() {
    this.getLookupValues();
  }

  getLookupValues() {
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.order_statuses = res['order_status'];
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  updateOrderSelection(data) {
    const action = data['action'];
    const order_id = data['order_id'];
    const clear_selection = data['clear_selection'];

    if (clear_selection == true) {
      this.selectedOrderIds = [];
    } else {
      const index = this.selectedOrderIds.indexOf(order_id);
      if (action == true && index < 0) {
        this.selectedOrderIds.push(order_id);
      } else if (action == false && index > -1) {
        this.selectedOrderIds.splice(index, 1);
      }
    }
  }
  onPrintInvoice() {
    var orderIds = [];
    if (this.selectedOrderIds.length > 0) {
      orderIds = this.selectedOrderIds;
    } else {
      orderIds = this.allOrderIds;
    }
    const modalRef = this.modalService.open(MantleOrderInvoiceComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.orderIds = orderIds;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-INVOICE') {
        modalRef.close();
      }
    });
  }

  onPrintAddresses() {
    var orderIds = [];
    if (this.selectedOrderIds.length > 0) {
      orderIds = this.selectedOrderIds;
    } else {
      orderIds = this.allOrderIds;
    }
    const modalRef = this.modalService.open(MantleOrderAddressComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.orderIds = orderIds;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-ADDRESSES') {
        modalRef.close();
      }
    });
  }
  onPrintPullsheet() {
    var orderIds = [];
    if (this.selectedOrderIds.length > 0) {
      orderIds = this.selectedOrderIds;
    } else {
      orderIds = this.allOrderIds;
    }
    const modalRef = this.modalService.open(MantleOrderPullsheetComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.orderIds = orderIds;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-PULLSHEET') {
        modalRef.close();
      }
    });
  }
  onUpdateStatus() {
    this.spinner.show();

    var orderIds = [];
    if (this.selectedOrderIds.length > 0) {
      orderIds = this.selectedOrderIds;
    } else {
      orderIds = this.allOrderIds;
    }

    const requestPayload = {
      order_status_id: this.orderStatusId,
      send_email: this.sendEmail,
      order_ids: orderIds,
    };

    this.orderService.updateOrderStatus(requestPayload).subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Updated Successfully');
        this.orderService.searchOrder.next(this.searchFormValues);
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }
}
