import { Component, OnInit } from '@angular/core';
import { InventoryService } from 'src/app/utils/services/inventory.service';

@Component({
  selector: 'mantle-inventory-summary',
  templateUrl: './mantle-inventory-summary.component.html',
  styleUrls: ['./mantle-inventory-summary.component.scss']
})
export class MantleInventorySummaryComponent implements OnInit {

  summary_data:any = {}
  has_data:boolean = false
  constructor(private inventoryService:InventoryService) { 
    this.inventoryService.filter_result.subscribe(data => {
      this.init_data(data)
    })
  }

  ngOnInit(): void {
  }
  init_data(filter_data){
    this.summary_data = filter_data["summary"]
    if(this.summary_data != undefined){
      this.has_data = true
    }
    else{
      this.has_data = false
    }
  }

}
