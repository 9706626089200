import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TieredRewardPointState } from 'src/app/utils/interfaces/app.state';

export const selectRewardPointState = createFeatureSelector<TieredRewardPointState>(
  'tieredRewardPoint'
);
export const selectStateStatus = createSelector(
  selectRewardPointState,
  (state) => state.status
);

export const selectTieredList = createSelector(
  selectRewardPointState,
  (state) => state.tiered
);
export const selectedActiveRecord = createSelector(
  selectRewardPointState,
  (state) => state.activeRecord
);

export const selectGetTierByid = (point_tiered_id) =>
  createSelector(selectRewardPointState, (state) =>
    state.tiered.find((t) => t.point_tiered_id === point_tiered_id)
  );
