import { createReducer, on } from '@ngrx/store';
import {
  addCustomer,
  addCustomerFailure,
  addCustomerSuccess,
  editCustomer,
  editCustomerSuccess,
  listCustomers,
  listCustomersFailure,
  listCustomersSuccess,
  actionSelectCustomer,
} from './customer.actions';
import { STATE_STATUS } from 'src/app/utils/enums/mantle-enums';
import { Customer } from 'src/app/utils/interfaces/customer.intefaces';

export interface CustomersState {
  customers: any;
  error: string | null;
  status: STATE_STATUS;
  filterParameters: any;
}

export const initialState: CustomersState = {
  customers: { count: 0, currentPage: 0, totalPages: 0, data: [] },
  error: null,
  status: STATE_STATUS.PENDING,
  filterParameters: null,
};

export interface CustomerState {
  customer: Customer | null;
  error: string | null;
  status: STATE_STATUS;
}

export const initialCustomerState: CustomerState = {
  customer: null,
  error: null,
  status: STATE_STATUS.PENDING,
};

export const customersReducer = createReducer(
  initialState,
  on(listCustomers, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(listCustomersSuccess, (state, { customers }) => ({
    ...state,
    customers: customers,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),

  on(listCustomersFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  })),

  on(addCustomer, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(addCustomerSuccess, (state, { customer }) => {
    const cloneState = JSON.parse(JSON.stringify(state));
    cloneState.customers.data = [...[customer], ...cloneState.customers.data];
    cloneState.status = STATE_STATUS.SUCCESS;
    return cloneState;
  }),

  on(addCustomerFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  })),

  on(editCustomer, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(
    editCustomerSuccess,
    (state, { customer }) => {
      const cloneState = JSON.parse(JSON.stringify(state));
      let index = cloneState.customers.data.findIndex(
        (x) => x.customer_id == customer.customer_id
      );
      if (index !== -1) {
        cloneState.customers.data[index] = customer;
      } else {
        cloneState.customers.data = [
          ...[customer],
          ...cloneState.customers.data,
        ];
      }
      cloneState.status = STATE_STATUS.SUCCESS;
      return cloneState;
    } /*({
    ...state,
    customers: state.customers.data.push(customer),
    error: null,
    status: STATE_STATUS.SUCCESS,
  })*/
  )
);

export const customerReducer = createReducer(
  initialCustomerState,

  on(addCustomer, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(editCustomer, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(actionSelectCustomer, (state, { customer }) => ({
    ...state,
    customer,
  })),

  on(addCustomerSuccess, (state, { customer }) => ({
    ...state,
    customer,
    status: STATE_STATUS.SUCCESS,
  })),

  on(editCustomerSuccess, (state, { customer }) => ({
    ...state,
    customer,
    status: STATE_STATUS.SUCCESS,
  }))
);
