<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form
        [formGroup]="productDetailsForm"
        (ngSubmit)="updateProductDetails()"
      >
        <div class="card-header">
          <h3 class="card-title">Product Details</h3>
          <div class="card-tools">
            <btn-barcode-generator
              [upc]="this.productDetailsForm?.value['UPC']"
              [productDetails]="this.productDetailsForm?.getRawValue()"
            ></btn-barcode-generator>
            <btn-new-product
              [duplicateData]="true"
              [duplicateChildSKU]="this.data['child_sku']"
              [brand_id]="this.data['brand_id']"
            ></btn-new-product>
            <mantle-favorite [data]="data"></mantle-favorite>
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div
          class="card-body col-prod"
          [ngStyle]="{ 'background-image': 'url(' + background_image + ')' }"
        >
          <div
            class="alert alert-warning"
            role="alert"
            *ngIf="!form_data_available"
          >
            No Form Configuration Found for this product - Contact System Admin.
          </div>
          <div class="row">
            <div class="col-12">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="productDetailsForm"
              ></formly-form>
            </div>
          </div>
          <!--div class="row">
              <div class="col-12">
                <mantle-product-inventory></mantle-product-inventory>
              </div>
            </div-->
          <div class="row">
            <div class="col-6">
              <label>Product Photos</label>
              <div class="row">
                <div
                  class="col col-auto"
                  *ngFor="let photo of productPhotos; let i = index"
                >
                  <div class="row">
                    <div class="col">
                      <img
                        [src]="photo.image"
                        class="m-1 img-thumbnail rounded"
                        style="height: 100px; width: 100px"
                      />
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm m-1 h-20"
                        (click)="onRemoveImage(i, photo.id)"
                      >
                        Remove<i class="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <input
                    #file
                    id="file"
                    type="file"
                    accept="image/*"
                    class="form-control"
                    (change)="onFileChange(file.files)"
                  />
                </div>
                <div class="col-3">
                  <img
                    [src]="imageSrc"
                    *ngIf="imageSrc"
                    class="m-2 img-fluid img-thumbnail rounded"
                    style="height: 100px; width: 100px"
                  />
                </div>
                <div class="col-3">
                  <button
                    class="btn btn-outline-primary btn-sm"
                    type="button"
                    *ngIf="imageSrc"
                    (click)="uploadImage()"
                  >
                    Add Photo
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2 bg-light" style="background-color: transparent">
            <div class="col-6">
              <div class="text-muted">
                <h6>Tags</h6>
              </div>
              <tag-input
                [(ngModel)]="productTags"
                [ngModelOptions]="{ standalone: true }"
                [editable]="true"
                (onTagEdited)="onTagEdited($event)"
                (onAdd)="onTagAdded($event)"
                (onRemove)="onTagRemoved($event)"
              ></tag-input>
            </div>
            <div class="col-6">
              <div class="text-muted">
                <h6>Rules</h6>
              </div>
              <div class="form-group form-check row">
                <div class="col-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="taxExempt"
                    name="taxExempt"
                  />
                </div>
                <label for="taxExempt" class="col-10">Tax Exempt</label>
              </div>

              <div class="form-group form-check row">
                <div class="col-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="domesticSalesOnly"
                    name="domesticSalesOnly"
                  />
                </div>
                <label for="domesticSalesOnly" class="col-10"
                  >Domestic Sales Only</label
                >
              </div>

              <div class="form-group form-check row">
                <div class="col-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="inStoreSalesOnly"
                    name="inStoreSalesOnly"
                  />
                </div>
                <label for="inStoreSalesOnly" class="col-10"
                  >In-Store Sales Only</label
                >
              </div>

              <div class="form-group form-check row">
                <div class="col-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="preOrder"
                    name="preOrder"
                  />
                </div>
                <label for="preOrder" class="col-10">Pre-Order</label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row" *ngIf="form_data_available">
            <div class="col-md-6">
              <button
                type="submit"
                class="btn btn-outline-success btn-sm w-50"
                [disabled]="
                  !productDetailsForm.valid || processingChannelsCount != 0
                    ? true
                    : false
                "
              >
                Update Product
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
