<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title small">Advanced Inventory Data</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <mantle-inventory-period-selector></mantle-inventory-period-selector>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <mantle-price-trend
              [(inventoryDetails)]="inventoryDetails"
            ></mantle-price-trend>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mantle-inventory-advanced-summary
              [(inventoryDetails)]="inventoryDetails"
              [type]="'quantity_sold'"
              [title]="'Quantity Sold'"
            ></mantle-inventory-advanced-summary>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mantle-inventory-advanced-summary
              [(inventoryDetails)]="inventoryDetails"
              [type]="'quantity_purchased'"
              [title]="'Quantity Purchased'"
            ></mantle-inventory-advanced-summary>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mantle-inventory-advanced-summary
              [(inventoryDetails)]="inventoryDetails"
              [type]="'quantity_returned'"
              [title]="'Quantity Returned'"
            ></mantle-inventory-advanced-summary>
          </div>
        </div>
      </div>
      <div class="card-footer text-muted">
        <div class="row"></div>
      </div>
    </div>
  </div>
</section>
