import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { MantleModalService } from './mantle-modal.service';

@Injectable({
  providedIn: 'root',
})
export class RewardPointService {
  constructor(private apiService: ApiService) {}
  externalVendorSearch = new Subject();
  externalVendorSet = new Subject();
  vendorSelected = new Subject();

  get(): Observable<any> {
    return this.apiService.get(`/api/v1/reward`);
  }

  updatePoints(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/reward`, requestPayload);
  }

  calculatePoints(order_id): Observable<any> {
    //const query_param = queryString.stringify(filterParams);
    return this.apiService.get(`/api/v1/reward/calculate/${order_id}`);
  }

  getTiered(): Observable<any> {
    return this.apiService.get(`/api/v1/reward/tiered`);
  }

  upsertTiered(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/reward/tiered`, requestPayload);
  }

  deleteTiered(point_tiered_id): Observable<any> {
    return this.apiService.delete(`/api/v1/reward/tiered/${point_tiered_id}`);
  }

  getProductPoints(): Observable<any> {
    return this.apiService.get(`/api/v1/reward/product`);
  }

  upsertProductPoints(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/reward/product`, requestPayload);
  }

  deleteProductPoints(point_product_id): Observable<any> {
    return this.apiService.delete(`/api/v1/reward/product/${point_product_id}`);
  }

  getCustomerPoints(customer_id): Observable<any> {
    return this.apiService.get(`/api/v1/reward/customer/${customer_id}`);
  }

  redeemCustomerPoints(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/reward/redeem`, requestPayload);
  }
}
