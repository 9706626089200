import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { OrderService } from 'src/app/utils/services/order.service';
import moment from 'moment';
import { MantleOrderCreateComponent } from '../mantle-order-create/mantle-order-create.component';
import { MantleOrderInvoiceComponent } from '../mantle-order-invoice/mantle-order-invoice.component';
import { MantleOrderAddressComponent } from '../mantle-order-address/mantle-order-address.component';
import { MantleOrderPullsheetComponent } from '../mantle-order-pullsheet/mantle-order-pullsheet.component';
import { ShippingOptionsComponent } from '../../shipping/shipping-options/shipping-options.component';
import { MantleConfirmDialogComponent } from '../../mantle-confirm-dialog/mantle-confirm-dialog.component';
import { Subscription } from 'rxjs';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { OrderReturnDetailsComponent } from '../mantle-order-return/order-return-details/order-return-details.component';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { MantleOrderReceiptComponent } from '../mantle-order-receipt/mantle-order-receipt.component';
declare let $: any;

@Component({
  selector: 'mantle-order-list',
  templateUrl: './mantle-order-list.component.html',
  styleUrls: ['./mantle-order-list.component.scss'],
})
export class MantleOrderListComponent
  implements OnInit, AfterViewInit, OnDestroy {
  orderList: Array<any> = [];
  @Input() customerOrders: boolean = false;
  orderSearchData = {};
  private orderSearchSubscription: Subscription;
  timezone: any = this.commonService.getTimeZone();
  constructor(
    public commonService: CommonService,
    private apiService: ApiService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private mantleModalService: MantleModalService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.orderSearchSubscription = this.orderService.searchOrder.subscribe(
        (data) => {
          this.searchOrder(data);
          this.orderSearchData = data;
        }
      );

      if (!this.customerOrders) this.orderService.searchDefault.next({});
      this.orderService.order_list_component_initialized.next({});
    });
  }

  searchOrder(data) {
    this.spinner.show();
    this.orderList = [];

    this.orderService.searchOrders(data).subscribe(
      (res) => {
        this.groupSearchResult(res);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }

  groupSearchResult(data) {
    var orderIds = [...new Set(data.map((p) => p['order_id']))];

    var orderStatuses = [...new Set(data.map((p) => p['order_status_id']))];
    orderStatuses.forEach((orderStatus, index) => {
      const statusOrders = data.filter(
        (x) => x['order_status_id'] === orderStatus
      );
      this.orderList.push({
        status_description: statusOrders[0]['status_description'],
        order_status_id: statusOrders[0]['order_status_id'],
        orders: statusOrders,
      });
    });
    setTimeout(() => {
      if (!$.fn.dataTable.isDataTable('.dataTable')) {
        $('.dataTable').DataTable();
      }
      this.defaultToggle();
    }, 1);

    this.orderService.order_list_all_items.next(orderIds);
    this.orderService.order_list_selected_items.next({ clear_selection: true });
  }

  formatDate(date) {
    var dateString = moment.utc(date).local().format('DD-MM-YYYY HH:mm');
    return dateString;
  }

  viewOrderDetails(order_id) {
    const modalRef = this.modalService.open(MantleOrderCreateComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
      container: this.commonService.getFullScreenElement(),
    });

    modalRef.componentInstance.order_id = order_id;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-ADD-NEW') {
        modalRef.close();
      }
    });
  }

  viewInvoice(order_id) {
    const modalRef = this.modalService.open(MantleOrderInvoiceComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.orderIds = [order_id];

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-INVOICE') {
        modalRef.close();
      }
    });
  }

  printAddress(order_id) {
    const modalRef = this.modalService.open(MantleOrderAddressComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.orderIds = [order_id];

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-ADDRESSES') {
        modalRef.close();
      }
    });
  }

  printPullSheet(order_id) {
    const modalRef = this.modalService.open(MantleOrderPullsheetComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.orderIds = [order_id];

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-PULLSHEET') {
        modalRef.close();
      }
    });
  }

  checkOrder($event, order_id) {
    this.orderService.order_list_selected_items.next({
      action: $event.target.checked,
      order_id: order_id,
    });
  }

  checkUncheckAll($event) {}

  orderShipping(order_id) {
    const modalRef = this.modalService.open(ShippingOptionsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.order_id = order_id;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'MODAL-SHIPPING-OPTIONS') {
        modalRef.close();
      }
    });
  }

  deleteOrder(order_id) {
    const modalRef = this.modalService.open(MantleConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      backdrop: 'static',
    });

    modalRef.componentInstance.header = 'Delete Order';
    modalRef.componentInstance.message =
      'Are you sure want to delete this order?';

    this.commonService.modal_close.subscribe((data: any) => {
      if (data.name === 'CONFIRM-DIALOG') {
        if (data.data.action_id == 1) {
          this.orderService.deleteOrder(order_id).subscribe(
            (data) => {
              this.toastr.success('Order Deleted');
              this.searchOrder(this.orderSearchData);
            },
            (error) => {
              this.toastr.error('An Error Occurred');
            }
          );
        }

        modalRef.close();
      }
    });
  }

  reclaimOrder(order_id) {
    const modalRef = this.modalService.open(MantleConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      backdrop: 'static',
    });

    modalRef.componentInstance.header = 'Reclaim Order';
    modalRef.componentInstance.message =
      'Are you sure want to reclaim this order to active state?';

    this.commonService.modal_close.subscribe((data: any) => {
      if (data.name === 'CONFIRM-DIALOG') {
        if (data.data.action_id == 1) {
          this.orderService.reclaimOrder([order_id]).subscribe(
            (data) => {
              this.toastr.success('Order Reclaimed');
              this.searchOrder(this.orderSearchData);
            },
            (error) => {
              this.toastr.error('An Error Occurred');
            }
          );
        }

        modalRef.close();
      }
    });
  }

  orderReturn = (order_id) => {
    const modalRef = this.mantleModalService.open(
      OrderReturnDetailsComponent,
      MANTLE_MODAL_NAME.ORDER_RETURN_DETAILS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.order_id = order_id;
  };

  printReceipt(order_id) {
    const modalRef = this.mantleModalService.open(
      MantleOrderReceiptComponent,
      MANTLE_MODAL_NAME.ORDER_RECEIPT,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.orderId = order_id;
  }

  onCollapseToggle($event, order_status_id) {
    var orderListToggle = localStorage.getItem('OrderListToggle');
    var orderListToggleObj = {};

    if (orderListToggle != null) {
      orderListToggleObj = JSON.parse(orderListToggle);
    }

    let htmlValue = $event.srcElement.className;

    //Collapsed
    if (htmlValue.includes('fa-plus')) {
      orderListToggleObj[order_status_id] = 'expand';
    } else if (
      htmlValue.includes('fa-minus') ||
      htmlValue.includes('fa-minus')
    ) {
      orderListToggleObj[order_status_id] = 'collapse';
    }

    localStorage.setItem('OrderListToggle', JSON.stringify(orderListToggleObj));
  }

  defaultToggle() {
    var orderListToggle = localStorage.getItem('OrderListToggle');
    var orderListToggleObj = {};

    if (orderListToggle != null) {
      orderListToggleObj = JSON.parse(orderListToggle);
    }

    Object.keys(orderListToggleObj).forEach((key) => {
      try {
        $(`#${key}`).CardWidget(`${orderListToggleObj[key]}`);
      } catch (error) {}
    });
  }

  closeModal() {
    this.orderSearchSubscription.unsubscribe();
    this.commonService.modal_close.next('ORDER-LIST-MODAL');
  }

  ngOnDestroy() {
    this.orderSearchSubscription.unsubscribe();
  }
}
