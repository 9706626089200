import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-multiple-upc',
  templateUrl: './mantle-multiple-upc.component.html',
  styleUrls: ['./mantle-multiple-upc.component.scss'],
})
export class MantleMultipleUpcComponent implements OnInit {
  @Input() searchResults;

  constructor(private commonService: CommonService) {}
  ngOnInit(): void {}

  onSelectItem(searchItem) {
    this.commonService.modal_close.next({
      modalName: 'MULTIPLE-UPC-MODAL',
      data: searchItem,
    });
  }

  closeModal() {
    this.commonService.modal_close.next({
      modalName: 'MULTIPLE-UPC-MODAL',
      data: null,
    });
  }
}
