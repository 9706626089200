import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { ReportingService } from 'src/app/utils/services/reporting.service';
import { MantleReportRunComponent } from '../mantle-report-run/mantle-report-run.component';
import { ReportEditDefaultComponent } from '../mantle-reports-admin/report-edit-default/report-edit-default.component';

@Component({
  selector: 'mantle-reports-list',
  templateUrl: './mantle-reports-list.component.html',
  styleUrls: ['./mantle-reports-list.component.scss'],
})
export class MantleReportsListComponent {
  @Input() isAdmin = false;
  initialized: boolean = false;
  filterParams: any = {};
  private reportSearchSubscription!: Subscription;
  reportsList: Array<any> = [];

  constructor(
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService,
    private reportingService: ReportingService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    if (!this.initialized) {
      this.initData();
      this.initialized = true;
    }

    this.spinner.show()
    this.reportSearchSubscription = this.reportingService
      .getReports()
      .subscribe((res) => {
        this.reportsList = res.data;
        this.spinner.hide()
      },error=>{
        this.spinner.hide();
        this.toastr.error(JSON.stringify(error));
      });
  }

  initData() {
    let lastPage = 0;
    let lastSearchText = '';

    try {
      if ($.fn.dataTable.isDataTable('.dataTable')) {
        var tbl = $('.dataTable').DataTable();
        //tbl.destroy();
        //tbl.draw();
        tbl.clear();
        tbl.destroy();
      }
    } catch (error) {
      
    }

    setTimeout(() => {
      $('.dataTable thead tr').clone(true).appendTo('.dataTable thead');
      $('.dataTable thead tr:eq(1) th').each((i, element) => {
        var title = $(element).text();
        var id = $(element).attr('id');

        $(element).html(
          '<input type="text" style="width:100%" placeholder="Search ' +
            title +
            '" id="' +
            id +
            '"/>'
        );

        $('input', element).on('keyup', (event) => {
          if (event.key === 'Enter') {
            const eValue = (event.target as HTMLInputElement).value;
            if (dataTable.column(i).search() !== eValue) {
              this.filterParams[event.target.id] = eValue;

              if (eValue == undefined || eValue == '') {
                delete this.filterParams[event.target.id];
              }
              dataTable.draw();
            }
          }
        });
      });

      var dataTable = $('.dataTable').DataTable({
        orderCellsTop: true,
        pagingType: 'full_numbers',
        pageLength: 50,
        displayStart: lastPage, // Last Selected Page
        search: { search: lastSearchText }, // Last Searched Text
        serverSide: true,
        processing: true,
        searching: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.spinner.show();
          let offset = dataTablesParameters.start;
          this.reportingService
            .getReports({
              ...this.filterParams,
              ...{
                offset: offset,
                limit: 50,
              },
            })
            .subscribe(
              (res) => {
                this.spinner.hide();
                this.reportsList = res.data;
                callback({
                  recordsTotal: res.count,
                  recordsFiltered: res.count,
                  data: [],
                });
              },
              (err) => {
                this.spinner.hide();
                
                this.toastr.error(JSON.stringify(err));
              }
            );
        },
        lengthMenu: [5, 10, 20, 50],
        // createdRow: (row: Node, data: any) => {
        //   if (data.is_new_record) {
        //     $(row).css('background-color', 'red');
        //   }
        // },
      });
    }, 1);
  }

  runReport(report_id) {
    const modalRef = this.mantleModalService.open(
      MantleReportRunComponent,
      MANTLE_MODAL_NAME.RUN_REPORT_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.report_id = report_id;
  }

  adminReport(report_id = null) {
    const modalRef = this.mantleModalService.open(
      ReportEditDefaultComponent,
      MANTLE_MODAL_NAME.EDIT_DEFAULT_REPORT_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.report_id = report_id;
  }

  ngOnDestroy() {
    this.reportSearchSubscription.unsubscribe();
  }
}
