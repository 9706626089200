import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import formConfig from '../../../../../configs/forms/form_cash_register_details.json';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { UserService } from 'src/app/utils/services/user.service';
import { CashRegisterService } from 'src/app/utils/services/cash-register.service';
import { CashRegisterTransactionsComponent } from '../cash-register-transactions/cash-register-transactions.component';
import {
  CASH_REGISTER_TRANSACTION_TYPES,
  MANTLE_MODAL_NAME,
} from 'src/app/utils/enums/mantle-enums';
import { MerchantService } from 'src/app/utils/services/merchant.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { CashDenominationComponent } from '../cash-denomination/cash-denomination.component';

@Component({
  selector: 'mantle-cash-register-details',
  templateUrl: './cash-register-details.component.html',
  styleUrls: ['./cash-register-details.component.scss'],
})
export class CashRegisterDetailsComponent implements OnInit {
  @Input() isCashRegisterLog: boolean = false;
  @Input() cash_register_log_id: any;
  @Input() cash_register_id: any;
  operating_status: number;
  cashRegisterLogDetails:any

  crForm = this.fb.group({
    cash_register_id: [],
  });
  options: FormlyFormOptions = {
    formState: {
      cr_status: [
        { value: 1, label: 'Running' },
        { value: 2, label: 'Closed' },
        { value: 3, label: 'Not in Use' },
      ],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  constructor(
    private cashRegisterService: CashRegisterService,
    private settingsService: SettingsService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private mantleModalService: MantleModalService,
    private spinner: NgxSpinnerService,
    private merchantService: MerchantService
  ) {}
  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.spinner.show();
    this.settingsService.getStores().subscribe(
      (res) => {
        this.options.formState.stores = res.map((x) => {
          return { value: x.store_id, label: x.store_name };
        });
        this.userService.getUsers().subscribe(
          (res) => {
            this.options.formState.users = res.map((x) => {
              return { value: x.user_id, label: x.name };
            });

            this.fields = formConfig;
            this.loadFormData();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err);
            this.fields = formConfig;
            this.loadFormData();
          }
        );
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );

    this.merchantService.getTerminals().subscribe(
      (res) => {
        this.options.formState.terminals = res.data?.map((x) => {
          return { value: x.terminal_id, label: x.name };
        });
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }

  loadFormData() {
    if (this.cash_register_id && !this.isCashRegisterLog) {
      this.cashRegisterService
        .getCashRegisterById(this.cash_register_id)
        .subscribe(
          (res) => {
            this.patchForm(res);
          },
          (err) => {
            this.toastr.error(err);
          }
        );
    } else if (this.isCashRegisterLog && this.cash_register_log_id) {
      this.cashRegisterService
        .getCashRegisterLogById(this.cash_register_log_id)
        .subscribe(
          (res) => {
            this.cashRegisterLogDetails = res
            this.patchForm(res);
            this.crForm.disable();
          },
          (err) => {
            this.toastr.error(err);
          }
        );
    }
  }

  onSave() {
    this.spinner.show();
    var requestPayload = this.crForm.getRawValue();
    //reset system generaated values
    delete requestPayload.cash_register_id;

    const obs = this.cashRegisterService.createCashRegister(requestPayload);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.patchForm(res);
        this.cash_register_id = res.cash_register_id;
        this.toastr.success('Cash Register Successfully Created');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error('An Error Occurred');
      }
    );
  }

  onUpdate() {
    this.spinner.show();
    var requestPayload = this.crForm.getRawValue();
    //reset system generaated values
    delete requestPayload.closing_balance;
    delete requestPayload.status;
    delete requestPayload.session_id;
    delete requestPayload.balance_date;

    this.cashRegisterService
      .updateCashRegister(this.crForm.getRawValue())
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.patchForm(res);
          this.cash_register_id = res.cash_register_id;
          this.toastr.success('Cash Register Successfully Updated');
        },
        (err) => {
          this.spinner.hide();
          
          this.toastr.error('An Error Occurred');
        }
      );
  }

  patchForm(value) {
    if (value.status) {
      this.operating_status = parseInt(value.status);
    }
    this.crForm.patchValue(value);
  }

  onStartRegister() {
    this.spinner.show();
    this.cashRegisterService
      .cashRegisterTransaction({
        cash_register_id: this.cash_register_id,
        transaction_type: CASH_REGISTER_TRANSACTION_TYPES.START_REGISTER,
        credit: this.crForm.getRawValue().opening_balance,
      })
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.initData();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  }

  onCloseRegister() {
    const modalRef = this.mantleModalService.open(
      CashDenominationComponent,
      MANTLE_MODAL_NAME.CASH_DENOMINATION_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      },
      this.closeCashRegister,
      [0.0]
    );
    modalRef.componentInstance.cash_register_id = this.cash_register_id;
  }

  onViewTransactions() {
    const modalRef = this.mantleModalService.open(
      CashRegisterTransactionsComponent,
      MANTLE_MODAL_NAME.MODAL_CASHREGISTER_TRANSACTION,
      { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' }
    );

    modalRef.componentInstance.cash_register_id = this.cash_register_id;
  }

  closeCashRegister = (countTotal: number = 0.0, data) => {
    if (data != undefined) {
      countTotal = data.total_amount;
    }

    this.spinner.show();
    this.cashRegisterService
      .cashRegisterTransaction({
        ...{
          cash_register_id: this.cash_register_id,
          closing_balance_manual: countTotal,
          transaction_type: CASH_REGISTER_TRANSACTION_TYPES.END_REGISTER,
        },
        ...data,
      })
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.initData();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  };

  onViewDenomination(){
    const modalRef = this.mantleModalService.open(
      CashDenominationComponent,
      MANTLE_MODAL_NAME.CASH_DENOMINATION_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.isCashRegisterLog = this.isCashRegisterLog;
    modalRef.componentInstance.cashRegisterLogDetails = this.cashRegisterLogDetails;
  }

  closeModal() {
    this.isCashRegisterLog = false;
    this.cash_register_log_id = null;
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.MODAL_CASHREGISTER_DETAILS
    );
  }
}
