import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'endicia-label-size-edit',
  templateUrl: './endicia-label-size-edit.component.html',
  styleUrls: ['./endicia-label-size-edit.component.scss'],
})
export class EndiciaLabelSizeEditComponent implements OnInit {
  @Input() labelsize: any;
  @Input() isNewRecord: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  labelsizeForm = this.fb.group({
    id: [],
    label_size: ['', Validators.required],
    label_size_description: ['', Validators.required],
  });

  ngOnInit(): void {
    this.labelsizeForm.patchValue(this.labelsize);
  }

  onSave() {
    const data = this.labelsizeForm.value;
    this.spinner.show();
    const obs = this.settingsService.addLabelSize(data);
    obs.subscribe(
      (res) => {
        this.isNewRecord = false;
        this.spinner.hide();
        this.labelsize = res;
        this.toastr.success('Saved Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onUpdate() {
    const data = this.labelsizeForm.value;
    this.spinner.show();
    const obs = this.settingsService.updateLabelSize(data);
    obs.subscribe(
      (res) => {
        this.isNewRecord = false;
        this.spinner.hide();
        this.labelsize = res;
        this.toastr.success('Updated Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('SETTINGS-EDIT-LABEL-SIZE-UNIT-MODAL');
  }
}
