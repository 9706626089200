import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'endicia-auth',
  templateUrl: './endicia-auth.component.html',
  styleUrls: ['./endicia-auth.component.scss']
})
export class EndiciaAuthComponent implements OnInit {

  authSettings:any
  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private settingsService: SettingsService,
    private endiciaService:EndiciaService) { }

  authForm = this.fb.group({
    id:['',Validators.required],
    description:['SERA'],
    client_id:['',Validators.required],
    client_secret:['',Validators.required],
    redirect_uri:['',Validators.required],
  })

  ngOnInit(): void {
    this.getSetting()
  }

  getSetting(){
    this.spinner.show()
    const obs = this.endiciaService.getAuthSetting();
    obs.subscribe(res=>{
      this.spinner.hide()
      this.authSettings = res
      this.authForm.patchValue(res)
    },
      err=>{
        this.spinner.hide()
        this.toastr.error("An Error Occurred")
        
    })
  }

  onSave(){
    this.spinner.show()
    const obs = this.endiciaService.updateAuthSetting(this.authForm.value);
    obs.subscribe(res=>{
      this.spinner.hide()
    },
      err=>{
        this.spinner.hide()
        this.toastr.error("An Error Occurred")
        
    })
  }
  
  closeModal(){

  }

}
