import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomersState, CustomerState } from './customer.reducer';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { Customer } from 'src/app/utils/interfaces/customer.intefaces';

export const selectCustomersState = createFeatureSelector<CustomersState>(
  'customers'
);

export const selectorCustomerState = createFeatureSelector<CustomerState>(
  'customer'
);

//export const selectCustomers = createSelector(selectCustomerState, (state) => state.customers);

//export const selectCustomerState = (state: AppState) => state.customer;
export const selectCustomers = createSelector(selectCustomersState, (state) => {
  return state?.customers?.data;
});

export const selectStateStatus = createSelector(
  selectCustomersState,
  (state) => state.status
);

export const selectfilterParameters = createSelector(
  selectCustomersState,
  (state) => state.filterParameters
);

export const selectRecordsSummary = createSelector(
  selectCustomersState,
  (state) => {
    return {
      recordsTotal: state.customers?.count,
      recordsFiltered: state.customers?.data?.length,
    };
  }
);

export const selectCustomerById = (customer_id: string) =>
  createSelector(selectCustomersState, (state) => {
    return <Customer>(
      state?.customers?.data?.find((x) => x.customer_id === customer_id)
    );
  });

/**
 * Customer Selectors
 */
export const selectCustomerStateStatus = createSelector(
  selectorCustomerState,
  (state) => state.status
);

export const selectCustomer = createSelector(selectorCustomerState, (state) => {
  return state.customer;
});
