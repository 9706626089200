import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { MantleOrderCreateComponent } from '../../mantle-order/mantle-order-create/mantle-order-create.component';
import { EndiciaGenerateLabelComponent } from '../shipping-endicia/endicia-generate-label/endicia-generate-label.component';
import { ShippingEndiciaComponent } from '../shipping-endicia/shipping-endicia.component';
import { RequestPickupComponent } from './request-pickup/request-pickup.component';
import { ShipmentManifestComponent } from './shipment-manifest/shipment-manifest.component';
import { ShipmentTrackingComponent } from './shipment-tracking/shipment-tracking.component';
declare let $: any;

@Component({
  selector: 'order-shipments',
  templateUrl: './order-shipments.component.html',
  styleUrls: ['./order-shipments.component.scss'],
})
export class OrderShipmentsComponent implements OnInit {
  shipmentList: Array<any> = [];

  selectedShipments: Array<any> = [];
  timezone: string = this.commonService.getTimeZone();

  constructor(
    private toastr: ToastrService,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private settingsService: SettingsService,
    private endiciaService: EndiciaService
  ) {}

  ngOnInit(): void {
    this.init_data();
  }
  init_data() {
    this.spinner.show();
    const obs = this.endiciaService.getShipments();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.shipmentList = res;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error('An Error Occurred');
      }
    );

    setTimeout(() => {
      if (!$.fn.dataTable.isDataTable('.dataTable')) {
        $('.dataTable thead tr').clone(true).appendTo('.dataTable thead');
        $('.dataTable thead tr:eq(1) th').each(function (i) {
          var title = $(this).text();
          $(this).html(
            '<input type="text" style="width:100%" placeholder="Search ' +
              title +
              '" />'
          );

          $('input', this).on('keyup change', function () {
            if (dataTable.column(i).search() !== this.value) {
              dataTable.column(i).search(this.value).draw();
            }
          });
        });

        var dataTable = $('.dataTable').DataTable({
          orderCellsTop: true,
          fixedHeader: true,
          pagingType: 'full_numbers',
          pageLength: 100,
          processing: true,
          lengthMenu: [5, 10, 20, 100, 200, 500],
        });
      }
    }, 1);
  }

  checkShipment($event, i) {
    const label_id = this.shipmentList[i].label_id;

    const index = this.selectedShipments.indexOf(label_id);

    if ($event.target.checked && index < 0) {
      this.selectedShipments.push(label_id);
    } else {
      if (index > -1) {
        this.selectedShipments.splice(index, 1);
      }
    }
  }

  createCarrierManifest() {
    if (this.selectedShipments.length > 1) {
      const requestPayload = {
        label_ids: this.selectedShipments,
        label_options: {
          label_format: 'png',
        },
      };
      this.spinner.show();
      const obs = this.endiciaService.createCarrierManifest(requestPayload);
      obs.subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('Carrier Manifest Successful');
          this.init_data();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error('An Error Occurred');
          if (err.error) {
            this.endiciaService.endiciaError(err.error);
          }
        }
      );
    } else {
      this.toastr.warning('At least Two Shipment Items Required for manifest');
    }
  }

  requestCarrierPickup() {
    if (this.selectedShipments.length > 0) {
      const modalRef = this.modalService.open(RequestPickupComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: 'static',
      });

      modalRef.componentInstance.label_ids = this.selectedShipments;

      this.commonService.modal_close.subscribe((data) => {
        if (data === 'SHIPPING-PICKUP-REQUEST') {
          modalRef.close();
          this.init_data();
        }
      });
    } else {
      this.toastr.warning('At least Two Shipment Items Required for Pickup');
    }
  }

  openShipment(i, order_id = null) {
    const modalRef = this.modalService.open(ShippingEndiciaComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.order_id = order_id;
    this.commonService.modal_close.subscribe((data) => {
      if (data === 'MODAL-ENDICIA-SHIPPING-OPTIONS') {
        modalRef.close();
      }
    });

    //Who blinks 1st

    this.endiciaService.existingShipmentOpen.subscribe((data) => {
      if (data === true) {
        this.endiciaService.existingShipmentOpen.next(this.shipmentList[i]);
      }
    });

    return false;
  }

  openOrder(order_id) {
    const modalRef = this.modalService.open(MantleOrderCreateComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.order_id = order_id;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-ADD-NEW') {
        modalRef.close();
      }
    });
    return false;
  }

  openTracking(i) {
    const modalRef = this.modalService.open(ShipmentTrackingComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.shipment = this.shipmentList[i];

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'SHIPMENT-TRACKING') {
        modalRef.close();
      }
    });
    return false;
  }

  openManifest(manifest_id) {
    const modalRef = this.modalService.open(ShipmentManifestComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.manifest_id = manifest_id;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'MODAL-SHIPPING-MANIFEST') {
        modalRef.close();
      }
    });
    return false;
  }

  openPickup(pickup_id) {
    const modalRef = this.modalService.open(RequestPickupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      backdrop: 'static',
    });

    modalRef.componentInstance.pickup_id = pickup_id;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'SHIPPING-PICKUP-REQUEST') {
        modalRef.close();
      }
    });

    return false;
  }

  viewLabel(label_id) {
    const shipment = this.shipmentList.find((x) => x.label_id == label_id);

    const modalRef = this.modalService.open(EndiciaGenerateLabelComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: true,
    });

    modalRef.componentInstance.shippingLabel = {
      label_id: label_id,
      labels: [
        {
          href: shipment.label_url,
        },
      ],
    };
  }

  voidLabel(label_id) {
    this.viewLabel(label_id);
  }
}
