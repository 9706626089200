<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool">
        <button
          type="button"
          class="btn btn-success btn-sm"
          (click)="registerStore()"
        >
          New Store
        </button>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <store-list></store-list>
      </div>
    </div>
  </div>
</div>
