import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BggService {

  constructor(
    private apiService: ApiService,) { 

    }

    searchBgg(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/bgg/search',requestPayload);
  }
}
