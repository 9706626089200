import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-revenue',
  templateUrl: './card-revenue.component.html',
  styleUrls: ['./card-revenue.component.scss']
})
export class CardRevenueComponent implements OnInit {
  @Input() orders_revenue:any
  constructor() { }

  ngOnInit(): void {
  }

}
