import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import env from 'src/configs/env/env.json';
import { IAppConfig } from './app/utils/interfaces/IAppConfig';
import { APP_CONFIG } from './app/utils/const/constants';

if (environment.production) {
  enableProdMode();
}
fetch(`/configs/env/env.${env.env}.json`)
  .then((response) => response.json())
  .then((config: IAppConfig) => {
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
//https://stackoverflow.com/questions/66859984/angular-imported-modules-do-not-wait-for-app-initializer
