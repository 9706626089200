<!--button #printbtn type="button" class="btn btn-outline-secondary d-none">
    Print <i class="fa fa-print mt-2" aria-hidden="true"></i>
</button>

<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">

    <title>Receipt #{{ orderDetails?.order_number }}</title>
</head-->
<div id="mantle-order-receipt">
  <div class="ticket">
    <p class="centered">
      <img [(src)]="logoImage" alt="Logo" *ngIf="logoImage" />
    </p>
    <p class="centered">
      {{ storeDetails?.store_name }}
      <br />{{ storeDetails?.store_phone }} <br />{{
        storeDetails?.store_address
      }}
      <br />{{ storeDetails?.store_citycode }} ,
      {{ storeDetails?.store_statecode }} {{ storeDetails?.store_zipcode }}
      <br />{{ storeDetails?.store_email }} <br />{{
        storeDetails?.store_website
      }}
    </p>
    <table>
      <thead>
        <tr>
          <th class="quantity">Qty.</th>
          <th class="description">Description</th>
          <th class="price">$$</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let orderItems of orderDetails?.order_items">
          <tr>
            <td class="quantity">{{ orderItems.quantity }}</td>
            <td class="description">{{ orderItems.item_description }}</td>
            <td class="price">
              {{ orderItems.total | currency }}
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <td class="quantity"></td>
          <td class="description">Discount</td>
          <td class="price">
            <ng-container *ngIf="orderDetails?.is_pct_discount">
              {{ orderDetails?.discount_pct | number: '1.2-2' }} %
            </ng-container>
            <ng-container *ngIf="!orderDetails?.is_pct_discount">
              {{ orderDetails?.discount_total_total | currency }}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td class="quantity"></td>
          <td class="description">Shipping</td>
          <td class="price">
            {{ orderDetails?.shipment_cost | currency }}
          </td>
        </tr>
        <tr>
          <td class="quantity"></td>
          <td class="description">Store Credit</td>
          <td class="price">
            {{ orderDetails?.store_credit_amount | currency }}
          </td>
        </tr>
        <tr>
          <td class="quantity"></td>
          <td class="description">Reward Point</td>
          <td class="price">
            {{ orderDetails?.redeemed_amount | currency }}
          </td>
        </tr>
        <tr>
          <td class="quantity"></td>
          <td class="description">Subtotal</td>
          <td class="price">
            {{ orderDetails?.sub_total | currency }}
          </td>
        </tr>
        <tr>
          <td class="quantity"></td>
          <td class="description">
            {{ orderDetails?.sale_tax_pct | number: '1.2-2' }} % Sales Tax
          </td>
          <td class="price">
            {{ orderDetails?.sale_tax | currency }}
          </td>
        </tr>
        <tr>
          <td class="quantity">&nbsp;</td>
          <td class="description">TOTAL</td>
          <td class="price">
            {{ orderDetails?.order_total | currency }}
          </td>
        </tr>
        <tr>
          <td class="quantity">&nbsp;</td>
          <td class="description">Amount Tendered:</td>
          <td class="price">
            {{ orderDetails?.paid_amount | currency }}
          </td>
        </tr>
        <tr>
          <td class="quantity"></td>
          <td class="description">Change Given:</td>
          <td class="price">
            {{ orderDetails?.change_amount | currency }}
          </td>
        </tr>
      </tfoot>
    </table>
    <p class="">
      Receipt #: &nbsp;&nbsp; {{ orderDetails?.order_number }} <br />
      Order Date: &nbsp;&nbsp;
      {{ orderDetails?.order_date | date: 'short':timezone }} <br />
    </p>
    <p class="centered" style="font-size: 6px" [innerHTML]="disclaimerHtml"></p>
  </div>
</div>
