import { Component } from '@angular/core';

@Component({
  selector: 'mantle-order-return',
  templateUrl: './mantle-order-return.component.html',
  styleUrls: ['./mantle-order-return.component.scss']
})
export class MantleOrderReturnComponent {

}
