import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { UserService } from 'src/app/utils/services/user.service';
import { UserProfileComponent } from '../user-profile/user-profile.component';
declare let $: any;

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  userList: Array<any> = [];
  timezone: string = this.commonService.getTimeZone();
  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init_data();
  }

  init_data() {
    this.spinner.show();
    const obs = this.userService.getUsers();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.userList = res;

        setTimeout(() => {
          $('.dataTable thead tr').clone(true).appendTo('.dataTable thead');
          $('.dataTable thead tr:eq(1) th').each(function (i) {
            var title = $(this).text();
            $(this).html(
              '<input type="text" style="width:100%" placeholder="' +
                title +
                '" />'
            );

            $('input', this).on('keyup change', function () {
              if (dataTable.column(i).search() !== this.value) {
                dataTable.column(i).search(this.value).draw();
              }
            });
          });

          var dataTable = $('.dataTable').DataTable({
            orderCellsTop: true,
            fixedHeader: true,
            pagingType: 'full_numbers',
            pageLength: 100,
            processing: true,
            lengthMenu: [5, 10, 20, 100, 200, 500],
          });
        }, 1);
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onEdit(user_id) {
    this.userProfModal(user_id);
  }
  onChangePassword(user_id) {
    this.userProfModal(user_id, true);
  }

  userProfModal(user_id, allowPwdChange = false) {
    const modalRef = this.modalService.open(UserProfileComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.user_id = user_id;
    modalRef.componentInstance.allowPwdChange = allowPwdChange;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'MODAL-USER-PROFILE') {
        modalRef.close();
      }
    });
  }
}
