import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'channel-dashboard',
  templateUrl: './channel-dashboard.component.html',
  styleUrls: ['./channel-dashboard.component.scss'],
})
export class ChannelDashboardComponent implements OnInit {
  dashboardData: any = {};
  constructor(
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initDashboardData();
  }

  initDashboardData() {
    var obs = this.channelsService.getDashboardData();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.dashboardData = res;
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(err.error.message);
      }
    );
  }
}
