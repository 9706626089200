<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <mantle-print
        [printSectionId]="'print-section'"
        cssClass="btn btn-outline-secondary btn-sm m-2"
      ></mantle-print>
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <h5>{{ model.barCodeValue }}</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div id="print-section">
          <div style="text-align: center">
            <p style="font-family: 'Source Code Pro', monospace">
              {{ model.headerText }}
            </p>
            <img
              *ngIf="model.barCodeValue && model.code"
              alt="{{ barCodeValue }}"
              src="https://barcode.tec-it.com/barcode.ashx?data={{
                model.barCodeValue
              }}&code={{ model.code }}&translate-esc=on"
              width="{{ model.width }}"
              height="{{ model.height }}"
            />

            <p style="font-family: 'Source Code Pro', monospace">
              {{ model.footerText }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row"></div>
    <div class="row mt-4">
      <div class="col-12">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="form"
        ></formly-form>
      </div>
    </div>
  </div>
</div>
