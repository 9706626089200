import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimelineItem } from 'ngx-timeline-albe';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';

@Component({
  selector: 'shipment-tracking',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss'],
})
export class ShipmentTrackingComponent implements OnInit {
  @Input() shipment: any;
  carrier: any;
  tracking_number: any;
  tracking_data: any;

  tracking_timeline: Array<TimelineItem> | String;
  timezone: string = this.commonService.getTimeZone();

  constructor(
    private toastr: ToastrService,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private endiciaService: EndiciaService
  ) {}

  ngOnInit(): void {
    this.tracking_number = this.shipment.tracking_number;
    this.carrier = this.shipment.endicia_carrier?.carrier;
    this.initData();
  }

  initData() {
    this.spinner.show();
    const obs = this.endiciaService.trackPackage({
      carrier: this.carrier,
      tracking_number: this.tracking_number,
    });

    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.tracking_data = res;

        var events = [];
        res.tracking_events.forEach((tracking_event) => {
          events.push({
            datetime: tracking_event.occurred_at,
            header: tracking_event.event_description,
            body: [
              {
                tag: 'p',
                content:
                  '<strong>City:</strong>' +
                  tracking_event.city +
                  ' , <strong>Province:</strong>' +
                  tracking_event.state_province +
                  ' , <strong>Carrier Status Code:</strong>' +
                  tracking_event.carrier_status_code +
                  ' , <strong>signed By:</strong>' +
                  tracking_event.signed_by,
              },
            ],
          });
        });

        this.tracking_timeline = JSON.stringify(events);
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error('An Error Occurred');

        if (err.error) {
          this.endiciaService.endiciaError(err.error);
        }
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('SHIPMENT-TRACKING');
  }
}
