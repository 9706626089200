import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'request-pickup',
  templateUrl: './request-pickup.component.html',
  styleUrls: ['./request-pickup.component.scss'],
})
export class RequestPickupComponent implements OnInit {
  @Input() label_ids: Array<any>;
  @Input() pickup_id:any
  cancelable:boolean = false

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private endiciaService: EndiciaService
  ) {}

  pickupForm = this.fb.group({
    pickup_window:this.fb.group({
      start_at:['', Validators.required],
      end_at:['', Validators.required]
    }),
    pickup_instructions:[]
  });
  ngOnInit(): void {
    if(this.pickup_id){
      this.getPickupDetails();
      this.cancelable = true
      this.pickupForm.disable()
    }
  }

  getPickupDetails(){

    const obs = this.endiciaService.getPickupDetails(this.pickup_id)
    obs.subscribe(res=>{
      this.spinner.hide()
      this.pickupForm.patchValue(res)
    },err=>{
      this.spinner.hide()
      this.toastr.error("An Error Occurred")
      
    })
  }

  requestCarrierPickup() {
    var requestPayload = this.pickupForm.value;
    requestPayload.label_ids = this.label_ids
    this.spinner.show()
    const obs = this.endiciaService.requestCarrierPickup(requestPayload);
    obs.subscribe(res=>{
      this.spinner.hide()
      this.toastr.success("Carrier Request Successful")
      this.pickupForm.disable()

    },err=>{
      this.spinner.hide()
      this.toastr.error("An Error Occurred")
      
      if(err.error){
        this.endiciaService.endiciaError(err.error)
      }
      
    })
  }

  cancelPickup(){

  }

  closeModal(){
    this.toastr.warning("Pickup Request Cancelled")
    this.commonService.modal_close.next('SHIPPING-PICKUP-REQUEST')
  }
}
