import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleBggSearchComponent } from '../mantle-bgg-search/mantle-bgg-search.component';

@Component({
  selector: 'mantle-bgg-search-btn',
  templateUrl: './mantle-bgg-search-btn.component.html',
  styleUrls: ['./mantle-bgg-search-btn.component.scss']
})
export class MantleBggSearchBtnComponent implements OnInit {

  constructor(private modalService: NgbModal,
    private commonService: CommonService,) { }

  ngOnInit(): void {
  }

  onSearchBgg(){

    const modalRef = this.modalService.open(MantleBggSearchComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'MODAL-BGG-SEARCH') {
        modalRef.close();
      }
    });
  }

}
