import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ReportingService } from 'src/app/utils/services/reporting.service';
import * as echarts from 'echarts';

@Component({
  selector: 'mantle-widget-line-graph',
  templateUrl: './mantle-widget-line-graph.component.html',
  styleUrls: ['./mantle-widget-line-graph.component.scss'],
})

/**
 * https://www.ngdevelop.tech/data-visualization-with-echarts-in-angular-using-ngx-echarts/#google_vignette
 */

export class MantleWidgetLineGraphComponent implements OnInit {
  @Input() report_id;
  @Input() report_details;
  report_name: any;
  chartOption: EChartsOption = {
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
    },
    series: [],
  };

  loading: boolean;
  hasError: boolean;
  errorText: any;

  constructor(private reportingService: ReportingService) {}
  ngOnInit(): void {
    this.getReportDetails();
  }

  getReportDetails = async () => {
    if (this.report_details) {
      this.report_name = this.report_details.name;
      this.getReportData();
    } else {
      this.reportingService.getReportById(this.report_id).subscribe(
        (res) => {
          this.report_name = res.name;
          this.getReportData();
        },
        (error) => {
          this.setError(error);
        }
      );
    }
  };

  getReportData = async () => {
    this.reportingService.getReportData(this.report_id).subscribe(
      (res) => {
        this.loading = false;
        this.formatChartData(res);
        if (this.report_details.refresh_intereval) {
          this.autoRefresh(this.report_details.refresh_intereval);
        }
      },
      (error) => {
        this.setError(error);
      }
    );
  };

  setError(error: any) {
    this.hasError = true;
    this.errorText = error?.error?.message
      ? error?.error?.message
      : error.message;
  }

  refreshData(context) {
    context.loading = true;
    context.hasError = false;
    context.errorText = '';

    context.getReportDetails();
  }

  autoRefresh(timeout: number) {
    setTimeout(this.refreshData, timeout, this);
  }

  formatChartData(res) {
    const labels = res.map((x) => {
      return x.label;
    });
    var seriesData = [];
    var series = [];
    if (res.length > 0) {
      series = Object.keys(res[0]);
    }

    series.splice(series.indexOf('label'), 1);
    series.forEach((sr) => {
      if (sr != 'label') {
        seriesData.push({
          name: sr,
          data: res.map((x) => {
            return x[sr];
          }),
          type: 'line',
        });
      }
    });

    this.chartOption = {
      title: {
        //text: this.report_name,
      },
      legend: {
        data: series
      },
      xAxis: {
        type: 'category',
        data: labels,
      },
      yAxis: {
        type: 'value',
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: function (params) {
          return `<b>${params['name']}</b> : ${params['value']}`;
        },
      },
      series: seriesData,
    };
  }
}
