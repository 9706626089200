<div class="row">
  <div class="col-md-12 table-responsive small">
    <table class="table table-striped dataTable">
      <thead>
        <tr>
          <th id="name">Name</th>
          <th id="email">Email</th>
          <th id="phone">Phone</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let vendor of vendorsList">
          <tr>
            <td>{{ vendor.name }}</td>
            <td>{{ vendor.email }}</td>
            <td>{{ vendor.phone }}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-outline btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Action
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="viewVendorDetails(vendor.vendor_id)"
                  >
                    <i class="fas fa-info-circle"></i> View Details
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
