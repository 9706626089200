import { Component, Inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleOrderCreateComponent } from '../mantle-order-create/mantle-order-create.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'mantle-btn-new-order',
  templateUrl: './mantle-btn-new-order.component.html',
  styleUrls: ['./mantle-btn-new-order.component.scss']
})
export class MantleBtnNewOrderComponent implements OnInit {
  elem: any;
  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {
    //this.elem = document.getElementById('pos-fullscreen');
  }

  onNewOrder(){
    const modalRef = this.modalService.open(MantleOrderCreateComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      //fullscreen: true,
      backdrop: 'static',
      container: this.commonService.getFullScreenElement()
    });

    this.commonService.modal_close.subscribe(data =>{
      if(data === 'ORDER-ADD-NEW'){
        modalRef.close()
      }
    })
  }
}
