<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title small">Cash register Details</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 small">
            <ng-container *ngIf="inits">
              <table
                class="table table-striped table-sm hover dataTable table-responsive"
                datatable="ng"
                [dtOptions]="dtOptions"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Transaction Type</th>
                    <th>Sys. Order ID</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let transaction of transactionsList">
                    <td>
                      {{ transaction.transaction_date | date: 'short' }}
                    </td>
                    <td>
                      {{
                        transaction.cash_register_transaction_type
                          ?.transaction_name
                      }}
                    </td>
                    <td>{{ transaction.order_id }}</td>
                    <td>{{ transaction.debit }}</td>
                    <td>{{ transaction.credit }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
