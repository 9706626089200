import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-kiosk-layout',
  templateUrl: './kiosk-layout.component.html',
  styleUrls: ['./kiosk-layout.component.scss'],
})
export class KioskLayoutComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {
    history.pushState(null, '');
    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
        this.toastr.warning(`You can't make changes or go back at this time.`);
      });
  }
  ngOnDestroy(): void {
    this.unsubscriber.next();
  this.unsubscriber.complete();
  }
  
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    event.preventDefault();
  }
}
