import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { ProductService } from 'src/app/utils/services/product.service';

@Component({
  selector: 'mantle-order-pullsheet',
  templateUrl: './mantle-order-pullsheet.component.html',
  styleUrls: ['./mantle-order-pullsheet.component.scss'],
})
export class MantleOrderPullsheetComponent implements OnInit {
  @Input() orderIds: Array<any>;
  invoiceOrders: Array<any> = [];
  payload: Array<any> = [];
  orderItemTotals: Array<any> = [];
  pullSheetData: Array<any> = [];
  orderItems: Array<any> = [];
  inventoryData: any = null;
  productData: any = null;

  constructor(
    private commonService: CommonService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private inventoryService:InventoryService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.init_values();
  }

  init_values() {
    this.getOrderDetails();
  }

  getOrderDetails() {
    if (this.orderIds != undefined) {
      this.spinner.show();
      const payload = {
        order_ids: this.orderIds,
      };
      this.orderService.getOrders(payload).subscribe(
        (res) => {
          this.invoiceOrders = res;
          this.getInventory();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          
          this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
        }
      );
    } else {
      this.toastr.warning('No Orders Selected');
    }
  }

  getInventory() {
    this.inventoryData = null;
    this.productData = null;
    this.orderItemTotals = [];
    this.orderItems = [];

    this.payload = [];
    this.orderItems = [
      ...new Set(this.invoiceOrders.map((p) => p['order_items'])),
    ];
    this.orderItems.forEach((orderItem) => {
      orderItem.forEach((item) => {
        var exist = this.payload.findIndex(
          (p) =>
            p['brand_id'] == item['brand_id'] &&
            p['child_sku'] == item['child_sku']
        );
        if (exist < 0) {
          this.payload.push({
            brand_id: item['brand_id'],
            child_sku: item['child_sku'],
          });
        }

        //Calculate Totals
        var t_exist = this.orderItemTotals.findIndex(
          (p) =>
            p['brand_id'] == item['brand_id'] &&
            p['child_sku'] == item['child_sku']
        );
        if (t_exist < 0) {
          this.orderItemTotals.push({
            brand_id: item['brand_id'],
            child_sku: item['child_sku'],
            total_quantity: item['quantity'],
          });
        } else {
          const total =
            parseInt(this.orderItemTotals[t_exist].total_quantity) +
            parseInt(item['quantity']);
          this.orderItemTotals[t_exist].total_quantity = total;
        }
      });
    });

    this.spinner.show();
    this.inventoryService.getInventoriesDetails(this.payload).subscribe(
      (res) => {
        this.inventoryData = res;
        this.generatePullSheet();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );

    this.productService.productDetailsList(this.payload).subscribe(
      (res) => {
        this.productData = res;
        this.generatePullSheet();
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }

  generatePullSheet() {
    if (this.productData != null && this.inventoryData != null) {
      this.payload.forEach((pullItem) => {
        const inventory = this.inventoryData.find(
          (x) =>
            x.brand_id == pullItem.brand_id && x.child_sku == pullItem.child_sku
        );
        const product = this.productData.find(
          (x) =>
            x.mantle_data.brand_id == pullItem.brand_id &&
            x.mantle_data['Child Sku'] == pullItem.child_sku
        );
        const totals = this.orderItemTotals.find(
          (x) =>
            x.brand_id == pullItem.brand_id && x.child_sku == pullItem.child_sku
        );

        this.pullSheetData.push({
          stock_item_description:
            product.mantle_data['Product Name'] +
            ', ' +
            product.mantle_data['Set Name'],
          child_sku: inventory.child_sku,
          inventory_available: inventory.available,
          inventory_picking_bin: inventory.picking_bin,
          inventory_collect: totals.total_quantity,
        });
      });
    }
  }

  getSubTotals(child_sku): Array<any> {
    const res = this.orderItems.map((x) =>
      x.filter((y) => y.child_sku == child_sku)
    );
    var combined: Array<any> = [];
    res.forEach((element) => {
      element.forEach((subEl) => {
        combined.push(subEl);
      });
    });
    return combined;
  }

  getOrderNumber(order_id) {
    return this.invoiceOrders.find((x) => x.order_id == order_id).order_number;
  }
  closeModal() {
    this.commonService.modal_close.next('ORDER-PULLSHEET');
  }
}
