<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card" style="background-color: transparent">
            <div class="card-header">
              <div class="card-title">
                <h5>Returned Orders</h5>
              </div>
              <!-- /.card-tools -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <order-return-list></order-return-list>
        </div>
      </div>
    </div>
  </section>