import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'bc-update-inventory',
  templateUrl: './bc-update-inventory.component.html',
  styleUrls: ['./bc-update-inventory.component.scss'],
})
export class BcUpdateInventoryComponent implements OnInit {
  @Input() product: any;
  productDetails: any = {};
  has_data = false;
  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  init = {
    height: 300,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print',
      'preview anchor searchreplace visualblocks code',
      'fullscreen insertdatetime media table paste',
      'help wordcount',
    ],
    toolbar:
      'undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | help',
  };

  bcInventoryForm = this.fb.group({
    id: ['', Validators.required],
    brand_id: ['', Validators.required],
    sales_channel_id: [],
    name: [],
    price: [],
    sku: [],
    upc: [],
    base_variant_id: [],
    bin_picking_number: [],
    description: [],
    inventory_level: [],
    inventory_warning_level: [],
    is_free_shipping: [],
    is_preorder_only: [],
    is_price_hidden: [],
    is_visible: [],
    mpn: [],
  });

  ngOnInit(): void {
    this.getProductDetails();
  }

  getProductDetails() {
    const sales_channel_id = this.product.sales_channel_id;
    this.spinner.show();
    const requestPayload = {
      sales_channel_id: sales_channel_id,
      skuid: this.product.skuid,
    };

    const obs = this.channelsService.getBCProductDetails(requestPayload);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.productDetails = res.data[0];
        if (this.productDetails?.id != undefined) {
          this.has_data = true;
        }
        this.productDetails.sales_channel_id = sales_channel_id;
        this.bcInventoryForm.patchValue(this.productDetails);
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onUpdateInventory() {
    this.spinner.show();
    var requestPayload = this.commonService.getDirtyValues(
      this.bcInventoryForm
    ); //this.getDirtyValues(this.bcInventoryForm);
    requestPayload['sales_channel_id'] = this.product.sales_channel_id;
    requestPayload['skuid'] = this.product.skuid;
    //Prevent updating of quantity, remove inventory level
    delete requestPayload['inventory_level'];

    const obs = this.channelsService.updateBCProduct(requestPayload);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Product Updated');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  /*getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls)
          dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }*/

  closeModal() {
    this.commonService.modal_close.next('CHANNEL-BC-INVENTORY-MODAL');
  }
}
