import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'endicia-non-delivery-option-edit',
  templateUrl: './endicia-non-delivery-option-edit.component.html',
  styleUrls: ['./endicia-non-delivery-option-edit.component.scss'],
})
export class EndiciaNonDeliveryOptionEditComponent implements OnInit {
  @Input() deliveryoption: any;
  @Input() isNewRecord: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  deliveryoptionForm = this.fb.group({
    id: [],
    non_delivery_option: ['', Validators.required],
    non_delivery_option_description: ['', Validators.required],
  });

  ngOnInit(): void {
    this.deliveryoptionForm.patchValue(this.deliveryoption);
  }

  onSave() {
    const data = this.deliveryoptionForm.value;
    this.spinner.show();
    const obs = this.settingsService.addNonDeliveryOption(data);
    obs.subscribe(
      (res) => {
        this.isNewRecord = false;
        this.spinner.hide();
        this.deliveryoption = res;
        this.toastr.success('Saved Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onUpdate() {
    const data = this.deliveryoptionForm.value;
    this.spinner.show();
    const obs = this.settingsService.updateNonDeliveryOption(data);
    obs.subscribe(
      (res) => {
        this.isNewRecord = false;
        this.spinner.hide();
        this.deliveryoption = res;
        this.toastr.success('Updated Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next(
      'SETTINGS-EDIT-NON-DELIVERY-OPTION-UNIT-MODAL'
    );
  }
}
