<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="shippingOptionsForm" (ngSubmit)="submitOption()">
        <div class="card-header">
          <h3 class="card-title small">Order Number {{order_id}} : Shipping Options</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <input type="hidden" class="form-control form-control-sm" formControlName="order_id" />
            <div class="form-group col-12">
              <label class="control-label small">Shipping Option</label>
              <ng-select [items]="shipping_options" 
                    bindLabel="description" 
                    bindValue="id" 
                    formControlName="shipping_option">
                </ng-select>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div class="col-6">
              <button type="submit" class="btn btn-outline-success btn-sm w-50"
                [disabled]="!shippingOptionsForm.valid">Continue</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
