import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import formConfig from '../../../../configs/forms/form_barcode_options_multi.json';
import { ProductService } from 'src/app/utils/services/product.service';

@Component({
  selector: 'mantle-barcode-multiple',
  templateUrl: './mantle-barcode-multiple.component.html',
  styleUrls: ['./mantle-barcode-multiple.component.scss'],
})
export class MantleBarcodeMultipleComponent implements OnInit, OnDestroy {
  @Input() productItems: Array<any> = [];
  printing: boolean = false;
  constructor(
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private fb: UntypedFormBuilder,
    private productService: ProductService
  ) {}

  form = this.fb.group({});

  options: FormlyFormOptions = {
    formState: {
      code: [
        { value: 'UPCA', label: 'UPC-A' },
        { value: 'UPCE', label: 'UPC-E' },
        { value: 'Code128', label: 'Code 128' },
        { value: 'QRCode', label: 'QR Code' },
      ],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  ngOnInit(): void {
    setTimeout(() => {
      this.fields = formConfig;
      this.initData();
    }, 1);
  }
  initData = () => {
    this.productItems = this.productItems.map((x) => {
      return {
        ...x,
        ...{
          loading: true,
          editing: false,
        },
      };
    });

    this.productItems.forEach(async (productItem, index) => {
      this.getProductDetails(productItem, index);
    });
  };

  getProductDetails = async (productItem, index) => {
    this.productService
      .getProductDetails({
        brand_id: productItem['brand_id'],
        child_sku: productItem['child_sku'],
        parent_sku: productItem['parent_sku'],
      })
      .subscribe(
        (res) => {
          var client_data = this.commonService.JSONNullEmptyCleanup(
            res['client_data'],
            true
          );
          var mantle_data = this.commonService.JSONNullEmptyCleanup(
            res['mantle_data']
          );

          var productdetails = {
            ...mantle_data,
            ...client_data,
          };
          productItem.barCodeValue = productdetails['UPC'];
          productItem.headerText = productdetails['Product Name'];
          this.getInventoyDetails(productItem, index);
        },
        (err) => {
          productItem.loading = false;
        }
      );
  };

  getInventoyDetails = async (productItem, index) => {
    this.inventoryService
      .getInventoryData({
        brand_id: productItem['brand_id'],
        child_sku: productItem['child_sku'],
      })
      .subscribe(
        (res) => {
          if (res != null) {
            if ('sell_price' in res) {
              productItem.footerText = `${res.currency_code}  ${res.sell_price}`;
            }
            productItem.loading = false;
          } else {
            productItem.loading = false;
          }
        },
        (err) => {
          productItem.loading = false;
        }
      );
  };

  printingStarted = () => {
    this.printing = true;

    setTimeout(() => {
      this.printing = false;
    }, 2);
  };

  onEditItem = (productItem) => {
    productItem.editing = !productItem.editing;
  };

  ngOnDestroy(): void {}

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.BARCODE_GENERATOR_MODAL
    );
  }
}
