import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/utils/services/common.service';
import { StoreDetailsComponent } from './store-details/store-details.component';

@Component({
  selector: 'app-settings-store',
  templateUrl: './settings-store.component.html',
  styleUrls: ['./settings-store.component.scss'],
})
export class SettingsStoreComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {}

  registerStore() {
    const modalRef = this.modalService.open(StoreDetailsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'MODAL-STORE-DETAILS') {
        modalRef.close();
      }
    });
  }
}
