<div class="card card-primary card-outline">
  <div class="card-body">
    <div class="row">
      <div class="form-group col-md-6">
        <label class="control-label small">Enable Quick Links</label> &nbsp;
        <ng-toggle
          #NgToggle
          [value]="quickLinksEnabled"
          [width]="40"
          [color]="{ unchecked: '#939da2', checked: '#1ba841' }"
          [labels]="{ checked: '', unchecked: '' }"
          (change)="toggleQuickLink($event)"
        ></ng-toggle>
      </div>
      <div class="col-md-6">
        <button
          *ngIf="quickLinksEnabled"
          type="button"
          class="btn btn-outline-secondary btn-sm"
          (click)="onEditQuickLinks()"
        >
          <i class="fas fa-cog"></i>
        </button>
      </div>
      <div class="dropdown-divider"></div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label class="control-label small">Timezone</label> &nbsp;
        <ng-select
          [items]="timezones"
          [multiple]="false"
          [(ngModel)]="timezone"
          bindLabel="name"
          bindValue="name"
          (change)="onTimeZoneChange($event)"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>
