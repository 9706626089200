<div class="card card-default">
    <div class="card-body">

        <div class="row">
            <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" placeholder="Search For Any Product">
            </div>
            <div class="col-sm-2"> <button type="submit" class="btn btn-primary btn-sm">Submit</button></div>
            <div class="col-sm-2"> <button type="submit" class="btn btn-primary btn-sm" style="background-color:#273C93;border:0px">+ Add Product</button></div>
        </div>
    </div>
</div>