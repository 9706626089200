<ng-container *ngIf="widgetLocation == 'menu'">
  <li class="nav-item dropdown">
    <a class="nav-link" data-toggle="dropdown" (click)="toggleDropdownMenu()">
      <i class="far fa-bookmark"></i>
      <!--span class="badge badge-success navbar-badge"> Quick Links</span-->
    </a>
    <div
      #dropdownMenu
      class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
    >
      <span class="dropdown-item dropdown-header">Quick Links</span>

      <div *ngFor="let link of quickLinks" (click)="onClickLink(link.link_id)">
        <div class="dropdown-divider"></div>
        <p class="dropdown-item small">
          <i
            class="fas fa-external-link-alt mr-2"
            *ngIf="link?.link_type == 'external'"
          ></i>
          <i class="fas fa-link mr-2" *ngIf="link?.link_type != 'external'"></i>
          {{ link.description }}

          <!--span class="float-right text-muted text-sm">
            {{ link.description }}
          </span-->
        </p>
      </div>
      <div class="dropdown-divider"></div>
    </div>
  </li>
</ng-container>
<ng-container *ngIf="widgetLocation != 'menu'">
  <div class="info-box">
    <span class="info-box-icon bg-info"><i class="far fa-bookmark"></i></span>
    <div class="info-box-content">
      <span class="info-box-text font-weight-bold">Quick Links</span>
      <div *ngFor="let link of quickLinks | slice:0:3" (click)="onClickLink(link.link_id)">
        <div class="dropdown-divider"></div>
        <div
          style="cursor: pointer"
          [class.btn-info]="link.mouseOvered"
          (mouseover)="link.mouseOvered = true"
          (mouseout)="link.mouseOvered = false"
        >
          <i
            class="fas fa-external-link-alt mr-2"
            *ngIf="link?.link_type == 'external'"
          ></i>
          <i class="fas fa-link mr-2" *ngIf="link?.link_type != 'external'"></i>
          {{ link.description }}
        </div>
      </div>
      <div class="dropdown-divider"></div>
      <div class="small-box-footer">
      </div>
    </div>
  </div>
</ng-container>
