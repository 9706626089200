<label for="field.id" class="control-label">
  {{ props.label }}
  <span aria-hidden="true" class="ng-star-inserted" *ngIf="props.required"
    >*</span
  >
</label>
<input
  type="datetime-local"
  class="form-control"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [class.is-invalid]="showError"
  [attr.aria-describedby]="id + '-formly-validation-error'"
  IsoDateLocal
/>
