import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-logo-wrapper',
  templateUrl: './mantle-logo-wrapper.component.html',
  styleUrls: ['./mantle-logo-wrapper.component.scss']
})
export class MantleLogoWrapperComponent extends FieldWrapper implements OnInit {

  @ViewChild('fieldComponent', {read: ViewContainerRef}) fieldComponent: ViewContainerRef;
  
  textValue:String = ""
  constructor(private commonService: CommonService,) {
    super();
  }

  ngOnInit(): void {

    this.commonService.product_form_value.subscribe(data=>{
      if(this.to.txt_property != undefined){
        this.textValue = data[this.to.txt_property] != undefined?data[this.to.txt_property]:"";
      }
      
    })
  }

}
