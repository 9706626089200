<div class="col-md-12 table-responsive small">
  <table class="table table-striped dataTable">
    <thead>
      <tr>
        <th width="40%">Status</th>
        <th width="30%">Status Count</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let status of status_summary">
        <tr>
          <td>{{ status.status_description }}</td>
          <td>{{ status.count }}</td>
          <td>
            <button
              class="btn btn-sm btn-outline-primary w-50"
              (click)="onViewStatusDetails(status.order_status_id)"
            >
              View Details <i class="fas fa-angle-double-right"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
