<div class="card">
  <div class="card-header">
    <h4 style="float: left">Tax Components</h4>
    <div class="card-tools">
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-tool"
        data-card-widget="remove"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 small table-responsive">
        <table class="table table-striped dataTable">
          <thead>
            <th>Name</th>
            <th>Value</th>
            <th>Apply</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let tax of orderTaxesList">
              <tr>
                <td>
                  {{ tax.sales_tax_description }} - {{ tax.sales_tax_name }}
                </td>
                <td>{{ tax.sales_tax_pct }}</td>
                <td>
                  <input
                    type="checkbox"
                    class="select-checkbox"
                    [(ngModel)]="tax.checked"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onSelect($event)"
                  />
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
