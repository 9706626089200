import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import formConfig from '../../../../configs/forms/form_barcode_options.json';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'mantle-barcode-generator',
  templateUrl: './mantle-barcode-generator.component.html',
  styleUrls: ['./mantle-barcode-generator.component.scss'],
})
export class MantleBarcodeGeneratorComponent implements OnInit, AfterViewInit {
  @Input() barCodeValue;
  @Input() headerText;
  @Input() footerText;
  constructor(
    private commonService: CommonService,
    private fb: UntypedFormBuilder
  ) {}

  form = this.fb.group({});

  options: FormlyFormOptions = {
    formState: {
      code: [
        { value: 'UPCA', label: 'UPC-A' },
        { value: 'UPCE', label: 'UPC-E' },
        { value: 'Code128', label: 'Code 128' },
        { value: 'QRCode', label: 'QR Code' },
      ],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  ngOnInit(): void {
    setTimeout(() => {
      this.fields = formConfig;
    }, 1);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.patchForm({
        barCodeValue: this.barCodeValue,
        headerText: this.headerText,
        footerText: this.footerText
      });
    }, 1);
  }

  patchForm(value) {
    this.form.patchValue(value);
  }

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.BARCODE_GENERATOR_MODAL
    );
  }
}
