import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { BlankComponent } from './views/blank/blank.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './views/profile/profile.component';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
//import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { MantleTasksComponent } from './views/mantle-tasks/mantle-tasks.component';
import { MantleInventoryComponent } from './views/mantle-inventory/mantle-inventory.component';
import { MantleOrderComponent } from './views/mantle-order/mantle-order.component';
import { ChannelDashboardComponent } from './views/channel/channel-dashboard/channel-dashboard.component';
import { ChannelComponent } from './views/channel/channel.component';
import { ChannelActiveListingComponent } from './views/channel/channel-active-listing/channel-active-listing.component';
import { SettingsCountryComponent } from './views/mantle-settings/settings-country/settings-country.component';
import { SettingsCurrencyComponent } from './views/mantle-settings/settings-currency/settings-currency.component';
import { SettingsOrderStatusComponent } from './views/mantle-settings/settings-order-status/settings-order-status.component';
import { ChannelStatusMappingComponent } from './views/channel/channel-status-mapping/channel-status-mapping.component';
import { SettingsEndiciaComponent } from './views/mantle-settings/settings-endicia/settings-endicia.component';
import { OrderShipmentsComponent } from './views/shipping/order-shipments/order-shipments.component';
import { SettingsUserComponent } from './views/mantle-settings/settings-user/settings-user.component';
import { SettingsStoreComponent } from './views/mantle-settings/settings-store/settings-store.component';
import { Auth0CallbackComponent } from './pages/auth0-callback/auth0-callback.component';
import { EndiciaAuthCodeComponent } from './views/shipping/shipping-endicia/endicia-auth-code/endicia-auth-code.component';
import { MantleInventoryUploadComponent } from './views/mantle-inventory/mantle-inventory-upload/mantle-inventory-upload.component';
import { PluginsComponent } from './components/plugins/plugins.component';
import { MantleOrderCreateComponent } from './views/mantle-order/mantle-order-create/mantle-order-create.component';
import { SettingsCashRegisterComponent } from './views/mantle-settings/settings-cash-register/settings-cash-register.component';
import { SettingsSystemComponent } from './views/mantle-settings/settings-system/settings-system.component';
import { MantleReportsListComponent } from './views/mantle-reports/mantle-reports-list/mantle-reports-list.component';
import { MantleReportsAdminComponent } from './views/mantle-reports/mantle-reports-admin/mantle-reports-admin.component';
import { MantleProductComponent } from './views/mantle-product/mantle-product.component';
import { MantlePurchaseOrderComponent } from './views/mantle-purchase-order/mantle-purchase-order.component';
import { MantlePoVendorComponent } from './views/mantle-purchase-order/mantle-po-vendor/mantle-po-vendor.component';
import { MantleOrderReturnComponent } from './views/mantle-order/mantle-order-return/mantle-order-return.component';
import { SettingsProductComponent } from './views/mantle-settings/settings-product/settings-product.component';
import { KioskLayoutComponent } from './pages/kiosk-layout/kiosk-layout.component';
import { KioskOrderComponent } from './pages/kiosk-layout/kiosk-order/kiosk-order.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { MantleCustomerListComponent } from './views/mantle-customer/mantle-customer-list/mantle-customer-list.component';
import { SettingsRewardPointsComponent } from './views/mantle-settings/settings-reward-points/settings-reward-points.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'catalog/subscription',
        component: MantleTasksComponent,
      },
      {
        path: 'catalog/manage',
        component: MantleProductComponent,
      },
      {
        path: 'inventory',
        component: MantleInventoryComponent,
      },
      {
        path: 'inventory-upload',
        component: MantleInventoryUploadComponent,
      },
      {
        path: 'order',
        component: MantleOrderComponent,
      },
      {
        path: 'order/pos',
        component: MantleOrderCreateComponent,
      },
      {
        path: 'order-shipments',
        component: OrderShipmentsComponent,
      },
      {
        path: 'return-order',
        component: MantleOrderReturnComponent,
      },
      {
        path: 'channels-dashboard',
        component: ChannelDashboardComponent,
      },
      {
        path: 'channels-manage',
        component: ChannelComponent,
      },
      {
        path: 'channels-listing',
        component: ChannelActiveListingComponent,
      },
      {
        path: 'reports',
        component: MantleReportsListComponent,
      },
      {
        path: 'settings/country',
        component: SettingsCountryComponent,
      },
      {
        path: 'settings/currency',
        component: SettingsCurrencyComponent,
      },
      {
        path: 'settings/order-status',
        component: SettingsOrderStatusComponent,
      },
      {
        path: 'settings/order-status-mapping',
        component: ChannelStatusMappingComponent,
      },
      {
        path: 'settings/endicia-settings',
        component: SettingsEndiciaComponent,
      },
      {
        path: 'settings/stores',
        component: SettingsStoreComponent,
      },
      {
        path: 'settings/users',
        component: SettingsUserComponent,
      },
      {
        path: 'settings/cash-register',
        component: SettingsCashRegisterComponent,
      },
      {
        path: 'settings/reports-admin',
        component: MantleReportsAdminComponent,
      },
      {
        path: 'settings/system-setting',
        component: SettingsSystemComponent,
      },
      {
        path: 'settings/product-settings',
        component: SettingsProductComponent,
      },
      {
        path: 'settings/reward-settings',
        component: SettingsRewardPointsComponent,
      },
      {
        path: 'po/orders',
        component: MantlePurchaseOrderComponent,
      },
      {
        path: 'po/vendors',
        component: MantlePoVendorComponent,
      },
      {
        path: 'plugin',
        component: PluginsComponent,
      },
      {
        path: 'customers',
        component: MantleCustomerListComponent,
      },
      {
        path: 'blank',
        component: BlankComponent,
      },
      {
        path: '',
        component: DashboardComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'callback',
    component: Auth0CallbackComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'api/v1/endicia/auth-token',
    component: EndiciaAuthCodeComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'kiosk',
    component: KioskLayoutComponent,
    children: [
      {
        path: '',
        component: KioskOrderComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
