<div class="row">
  <div class="col-md-12 small table-responsive">
    <table class="table table-striped dataTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Group</th>
          <th>Value</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let setting of settingsList">
          <tr>
            <td>{{ setting.name }}</td>
            <td>{{ setting.type }}</td>
            <td>{{ setting.group_name }}</td>
            <td *ngIf="setting.type == 'image'">
              <img
                [(src)]="setting.value"
                class="m-2 img-fluid img-thumbnail rounded"
                style="height: 100px; width: 100px"
              />
            </td>
            <td *ngIf="setting.type != 'image'">
              {{ setting.value | slice: 0:50 }}
              {{ setting.value.length > 50 ? '...' : '' }}
            </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-outline btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Action
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onEdit(setting.id)"
                  >
                    <i class="fas fa-edit"></i> View / Edit
                  </button>
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onDelete(setting.id)"
                  >
                    <i class="fas fa-trash"></i> Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
