<div class="card" style="background-color: transparent">
  <div class="card-header">
    <h3 class="card-title">Sale price History</h3>
  </div>
  <mantle-loading *ngIf="loading"></mantle-loading>
  <div class="card-body p-0">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive w-100">
          <table class="table table-striped dataTable small m-0">
            <thead>
              <tr>
                <th>Sale Price</th>
                <th>Updated Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataItems">
                <td>
                  {{ item.sell_price | currency: 'USD':'symbol':'1.2-2' }}
                </td>
                <td>{{ item.updated_at | date: 'short':timezone }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
