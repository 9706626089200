<div class="card">
    <div class="card-header">
        <div class="card-tools">
            <btn-mx-merchant-add *ngIf="merchant_type_id == MERCHANT_TYPE.PAYMENT_MX_MERCHANT" class="mr-2"></btn-mx-merchant-add>
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <mantle-merchant-list [(merchant_type_id)]="merchant_type_id"></mantle-merchant-list>
            </div>
        </div>
    </div>
</div>
