import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'channel-sales-breakdown',
  templateUrl: './channel-sales-breakdown.component.html',
  styleUrls: ['./channel-sales-breakdown.component.scss']
})
export class ChannelSalesBreakdownComponent implements OnInit {

  @Input() sales_channel:any
  constructor() { }

  ngOnInit(): void {
  }

}
