<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <h5>Integrate your sales channel</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <ng-container *ngFor="let channel of channelOptions; let i = index">
        <div class="col-md-4" (click)="onSelectChannel(channel.channel_ref, i)">
          <img
            src="{{ channel.channel_image_url }}"
            alt="Snow"
            style="width: 100%"
            class="img-thumbnail"
          />
          <p>{{ channel.channel_name }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
