import { Component } from '@angular/core';

@Component({
  selector: 'app-plugin-setting',
  templateUrl: './plugin-setting.component.html',
  styleUrls: ['./plugin-setting.component.scss']
})
export class PluginSettingComponent {

}
