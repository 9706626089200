import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'channel-status-mapping',
  templateUrl: './channel-status-mapping.component.html',
  styleUrls: ['./channel-status-mapping.component.scss']
})
export class ChannelStatusMappingComponent implements OnInit {

  salesChannelSelected:boolean = false
  sales_channels:Array<any> = []
  order_statuses:Array<any> = []
  sales_channel_statuses:Array<any> = []
  channel_order_status:Array<any> = []
  selected_sales_channel_id:any = ''

  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.init_data()
  }

  init_data(){
    this.spinner.show()
    const obs = this.channelsService.getChannels()
    obs.subscribe(res=>{
      this.spinner.hide()
      this.sales_channels = res

    },err=>{
      this.spinner.hide()
      
      this.toastr.error(JSON.stringify(err))
    })
    

    const lookups_obs = this.commonService.getLookupValues();
    lookups_obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.order_statuses = res['order_status'];
        this.order_statuses.forEach(element => {
          this.sales_channel_statuses.push({order_status_id:element.order_status_id})
        });
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  

  changeSalesChannel($event){
    this.salesChannelSelected = true
    //Clear previous map values
    for(var i=0;i < this.sales_channel_statuses.length;i++){
      this.sales_channel_statuses[i].status_map = null
    }
    this.spinner.show()
    this.selected_sales_channel_id = $event.sales_channel_id
    const obs = this.channelsService.getSalesChannelOrderStatus($event.sales_channel_id)
    obs.subscribe(res=>{
      this.spinner.hide()
      this.channel_order_status = res['channel_order_status']
      const channel_order_status_mapping = res['channel_order_status_mapping']
      channel_order_status_mapping.forEach(mapping => {
        const ind = this.sales_channel_statuses.findIndex(x => x.order_status_id == mapping.order_status_id)
        if(ind > -1){
          this.sales_channel_statuses[ind].status_map = JSON.parse(mapping.status_map)
        }
      });

    },err=>{
      this.spinner.hide()
      
      this.toastr.error(JSON.stringify(err))
    })
  }

  onChangeMapping($event, order_status_id){
    var mappings = []
    //var sales_channel_id=''
    $event.forEach(mapping => {
      mappings.push(mapping.channel_status_code)
      //sales_channel_id = mapping.sales_channel_id
    });

    const payload ={
      sales_channel_id:this.selected_sales_channel_id,
      order_status_id:order_status_id,
      status_map:JSON.stringify(mappings)
    }

    const obs = this.channelsService.updateSalesChannelOrderStatus(payload);
    obs.subscribe(
      (res) => {
      },
      (err) => {
        this.toastr.error(JSON.stringify(err))
      }
    );
  }

  closeModal(){
    this.commonService.modal_close.next('STATUS-MAPPING-MODAL')
  }

}
