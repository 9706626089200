import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-inventory-po',
  templateUrl: './inventory-po.component.html',
  styleUrl: './inventory-po.component.scss',
})
export class InventoryPoComponent {
  @Input() dataItems: any = [];

  timezone: any = this.commonService.getTimeZone();
  constructor(private commonService: CommonService) {}
}
