<div class="card">
    <div class="card-header">
        {{header}}
    </div>
    <div class="card-body">
        {{message}}
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6 col-sm-1">
                <button class="btn btn-outline-primary" (click)="dialogAction(1)"><i class="far fa-check-circle"></i> Confirm</button>
            </div>
            <div class="col-md-6 col-sm-1">
                <button class="btn btn-outline-secondary" (click)="dialogAction(0)"><i class="far fa-thumbs-down"></i> Reject</button>
            </div>
        </div>
    </div>
</div>
