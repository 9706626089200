import { Component } from '@angular/core';

@Component({
  selector: 'mantle-purchase-order',
  templateUrl: './mantle-purchase-order.component.html',
  styleUrls: ['./mantle-purchase-order.component.scss']
})
export class MantlePurchaseOrderComponent {

}
