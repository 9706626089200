import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'magic-right-wrapper',
  templateUrl: './magic-right-wrapper.component.html',
  styleUrls: ['./magic-right-wrapper.component.scss'],
})
export class MagicRightWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  cardImage: String = '';
  cardColor: String = 'black';
  cardStyle: any = {
    'background-image': 'linear-gradient(' + this.cardColor + ', #ffffff)',
    'background-color': this.cardColor,
    border: '5px solid ' + this.cardColor,
  };

  constructor(private commonService: CommonService) {
    super();
  }

  ngOnInit(): void {
    this.commonService.product_form_value.subscribe((data) => {
      if (data['Color'] != null && data['Color'] != undefined) {
        this.cardColor = data['Color'];
      }
      if (data['Image'] != null && data['Image'] != undefined) {
        this.cardImage = data['Image'];
      }
      this.cardStyle = {
        'background-image': 'linear-gradient(' + this.cardColor + ', #ffffff)',
        'background-color': this.cardColor,
        border: '5px solid ' + this.cardColor,
      };
    });
  }
}
