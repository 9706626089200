import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/app/utils/services/app.config';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'channel-activate-bc',
  templateUrl: './channel-activate-bc.component.html',
  styleUrls: ['./channel-activate-bc.component.scss'],
})
export class ChannelActivateBcComponent implements OnInit {
  @Input() channelOption: any = {};
  validated: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private config: AppConfig,
    private settingsService: SettingsService
  ) {}

  bcConfigForm = this.fb.group({
    channel_bc_id: [],
    channel_ref: [],
    access_token: ['', Validators.required],
    client_id: ['', Validators.required],
    client_secret: ['', Validators.required],
    channel_bc_description: ['BigCommerce Channel'],
    store_id: ['', Validators.required],
    update_frequency: [30],
    enable_orders_webhook: [false],
    enable_inventory_webhook: [false],
    webhook_destination: [
      this.config.getConfig('api_root') + '/api/v1/channel/bc/webhook',
    ],
    webhook_username: [],
    webhook_password: [],
    min_date_created: [],
  });

  ngOnInit(): void {
    if (this.channelOption != undefined) {
      setTimeout(() => {
        this.bcConfigForm.patchValue(this.channelOption);
      }, 0);
    }
  }

  onValidate() {
    this.validated = false;
    this.spinner.show();

    const obs = this.channelsService.validateBC(this.bcConfigForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        if (res != undefined) {
          if (res.is_valid) {
            this.validated = true;

            this.toastr.success('Validation Successful');
          } else {
            this.validated = false;
            this.toastr.error('Validation Failed', 'Validation Failed');
          }
        } else {
          this.validated = false;
          this.toastr.error('Validation Failed', 'Validation Failed');
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  onSave() {
    this.spinner.show();
    const obs = this.channelsService.createBCChannel(this.bcConfigForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Channel Added Successfully');
        this.closeModal();
        this.channelsService.channelAdded.next({});
        this.settingsService.statusMappingDialog();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  onUpdate() {
    this.spinner.show();
    const obs = this.channelsService.updateBCChannel(this.bcConfigForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
        //this.channelsService.channelAdded.next({})
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }
  onUpdateFrequency() {
    this.spinner.show();
    const obs = this.channelsService.updateBCChannel({
      channel_bc_id: this.bcConfigForm.value['channel_bc_id'],
      channel_ref: this.bcConfigForm.value['channel_ref'],
      update_frequency: this.bcConfigForm.value['update_frequency'],
    });
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
        //this.channelsService.channelAdded.next({})
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('BC-ACTIVATION-MODAL');
  }
}
