import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { MantleProductInventoryComponent } from 'src/app/views/mantle-inventory/mantle-product-inventory/mantle-product-inventory.component';
import { MANTLE_MODAL_NAME } from '../enums/mantle-enums';
import { MantleModalService } from './mantle-modal.service';
import { CommonService } from './common.service';
import queryString from 'query-string';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  filter_result = new Subject();
  inventory_item_selected = new Subject();
  favorites_updated = new Subject();
  product_edit_loaded = new Subject();
  product_update_btn_clicked = new Subject();
  inventory_item_updated = new Subject();
  processing_channel_counter = new Subject();
  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {}

  openInventoryModal = (brand_id: any, child_sku: any, parent_sku: any) => {
    this.inventory_item_selected = new Subject();
    this.product_edit_loaded = new Subject();

    const modalRef = this.mantleModalService.open(
      MantleProductInventoryComponent,
      MANTLE_MODAL_NAME.PRODUCT_INVENTORY_ONLY_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.externalData = {
      brand_id: brand_id,
      parent_sku: parent_sku,
      child_sku: child_sku,
    };

    this.commonService.modal_close.subscribe((data) => {
      if (data === MANTLE_MODAL_NAME.PRODUCT_INVENTORY_MODAL) {
        this.inventory_item_selected.complete();
        this.product_edit_loaded.complete();
      }
    });
  };

  updateInventory(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/inventory/update', requestPayload);
  }

  bulkUploadInventory(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/inventory/upload',
      requestPayload,
      'none'
    );
  }

  getUploadHistory(): Observable<any> {
    return this.apiService.get('/api/v1/inventory/upload/history');
  }

  getUploadProgress(): Observable<any> {
    return this.apiService.get('/api/v1/inventory/upload/progress');
  }

  downloadFile(requestPayload): Observable<any> {
    return this.apiService.downloadPost(
      '/api/v1/inventory/upload/download',
      requestPayload
    );
  }

  validateChannelCatalog(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/inventory/validate-channel-catalog',
      requestPayload
    );
  }

  getInventoryData(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/inventory/inventory_details',
      requestPayload
    );
  }

  searchProductByUpc(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/inventory/search-inventory-upc',
      requestPayload
    );
  }

  searchProductInventory = (requestPayload): Observable<any> => {
    return this.apiService.post(
      '/api/v1/inventory/search_product',
      requestPayload
    );
  };

  getInventoryChannelLinks = (inventory_id): Observable<any> => {
    return this.apiService.get(`/api/v1/inventory/link/${inventory_id}`);
  };

  upsertLinks = (requestPayload): Observable<any> => {
    return this.apiService.post('/api/v1/inventory/link', requestPayload);
  };

  deleteLink = (requestPayload): Observable<any> => {
    return this.apiService.delete('/api/v1/inventory/link', requestPayload);
  };

  getInventoriesDetails = (requestPayload): Observable<any> => {
    return this.apiService.post(
      '/api/v1/inventory/inventory_details_list',
      requestPayload
    );
  };

  inventoryProductFilter = (
    requestPayload,
    sortBy = '',
    sortOrder = '',
    searchLimit = ''
  ): Observable<any> => {
    var firstQP = true;
    var endpoint = '/api/v1/inventory/product_filter';

    if (sortBy != null && sortBy?.trim() !== '') {
      if (firstQP) {
        endpoint = endpoint + `/?orderBy=` + sortBy;
        firstQP = false;
      } else {
        endpoint = endpoint + `&orderBy=` + sortBy;
      }
    }
    if (sortOrder != null && sortOrder.trim() !== '') {
      if (firstQP) {
        endpoint = endpoint + `/?sortOrder=` + sortOrder;
        firstQP = false;
      } else {
        endpoint = endpoint + `&sortOrder=` + sortOrder;
      }
    }
    if (
      searchLimit != null &&
      searchLimit != undefined &&
      searchLimit?.toString()?.trim() !== ''
    ) {
      if (firstQP) {
        endpoint = endpoint + `/?limit=` + searchLimit;
        firstQP = false;
      } else {
        endpoint = endpoint + `&limit=` + searchLimit;
      }
    }
    return this.apiService.post(endpoint, requestPayload);
  };

  getAdvancedInventory = (type, child_sku, filters = {}): Observable<any> => {
    const query_param = queryString.stringify({
      ...{ type: type, child_sku: child_sku },
      ...filters,
    });
    return this.apiService.get(`/api/v1/inventory/advanced?${query_param}`);
  };
}
