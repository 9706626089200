import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import queryString from 'query-string';
import { MantlePoVendorDetailsComponent } from 'src/app/views/mantle-purchase-order/mantle-po-vendor/mantle-po-vendor-details/mantle-po-vendor-details.component';
import { MANTLE_MODAL_NAME } from '../enums/mantle-enums';
import { MantleModalService } from './mantle-modal.service';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  constructor(
    private apiService: ApiService,
    private mantleModalService: MantleModalService
  ) {}
  externalVendorSearch = new Subject();
  externalVendorSet = new Subject();
  vendorSelected = new Subject();

  getVendorById(vendor_id): Observable<any> {
    return this.apiService.get(`/api/v1/vendor/${vendor_id}`);
  }

  getVendors(filterParams = null): Observable<any> {
    const query_param = queryString.stringify(filterParams);
    return this.apiService.get(`/api/v1/vendor?${query_param}`);
  }

  searchVendors(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/vendor/search`, requestPayload);
  }

  createVendor(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/vendor`, requestPayload);
  }

  updateVendor(requestPayload): Observable<any> {
    return this.apiService.patch(`/api/v1/vendor`, requestPayload);
  }

  deleteVendor(vendor_id): Observable<any> {
    return this.apiService.delete(`/api/v1/vendor/${vendor_id}`);
  }

  /**
   * Common Modals
   */
  modalPoVendorDetails(vendor_id: any = null) {
    const modalRef = this.mantleModalService.open(
      MantlePoVendorDetailsComponent,
      MANTLE_MODAL_NAME.PO_VENDOR_DETAILS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.vendor_id = vendor_id;

    return modalRef;
  }
}
