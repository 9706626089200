import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { UserPreferenceService } from 'src/app/utils/services/user-preference.service';

@Component({
  selector: 'mantle-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit, OnDestroy {
  onAddLink() {
    throw new Error('Method not implemented.');
  }
  @Input() widgetLocation;
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef?.nativeElement?.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }
  mouseOvered: boolean = false;

  quickLinks: Array<any> = [];
  userProfile: any;
  constructor(
    private auth0Service: Auth0Service,
    private userPreferenceService: UserPreferenceService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private _commonService: CommonService
  ) {
    this._commonService.systemSettingsUpdated.next((_) => {
      this.initData();
    });
  }

  onClickLink = (link_id) => {
    if (this.widgetLocation == 'menu') this.hideDropdownMenu();
    const link = this.quickLinks.find((x) => x.link_id == link_id);

    if (link.link_type == 'external') {
      window.open(link.href, '_blank');
      return;
    }
    this.router.navigate([link.href], {
      queryParams: link.queryParams,
    });
  };

  ngOnInit(): void {
    this.initData();
  }
  initData = () => {
    this.userProfile = this.auth0Service.getProfile();
    if (this.userProfile?.user_id != undefined) {
      this.userPreferenceService
        .getUserQuickLinks(this.userProfile.user_id)
        .subscribe(
          (res) => {
            this.quickLinks = res;
          },
          (err) => {}
        );
    } else {
      setTimeout(() => {
        this.initData();
      }, 5000);
    }
  };

  toggleDropdownMenu() {
    if (this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.hideDropdownMenu();
    } else {
      this.showDropdownMenu();
    }
  }

  showDropdownMenu() {
    if (this.dropdownMenu?.nativeElement)
      this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
  }

  hideDropdownMenu() {
    if (this.dropdownMenu?.nativeElement)
      this.renderer.removeClass(this.dropdownMenu?.nativeElement, 'show');
  }

  ngOnDestroy(): void {}
}
