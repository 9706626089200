import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'country-edit',
  templateUrl: './country-edit.component.html',
  styleUrls: ['./country-edit.component.scss'],
})
export class CountryEditComponent implements OnInit {
  @Input() country: any;
  @Input() isNewRecord: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  countryForm = this.fb.group({
    country_code: ['', Validators.required],
    country_name: ['', Validators.required],
    currency_code: [],
    currency_name: [],
  });

  ngOnInit(): void {
    this.countryForm.patchValue(this.country);
  }
  onSave() {
    const data = this.countryForm.value;
    this.spinner.show();
    const obs = this.settingsService.addCountry(data);
    obs.subscribe(
      (res) => {
        this.isNewRecord = false;
        this.spinner.hide();
        this.country = res;
        this.toastr.success('Saved Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onUpdate() {
    const data = this.countryForm.value;
    this.spinner.show();
    const obs = this.settingsService.updateCountry(data);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.country = res;
        this.toastr.success('Updated Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('SETTINGS-EDIT-COUNTRY-MODAL');
  }
}
