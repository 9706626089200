import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BggService } from 'src/app/utils/services/bgg.service';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-bgg-search',
  templateUrl: './mantle-bgg-search.component.html',
  styleUrls: ['./mantle-bgg-search.component.scss']
})
export class MantleBggSearchComponent implements OnInit {

  resultList:any = {
    "bgg_data":[

    ]
  }

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private bggService:BggService) { }

    bggSearchForm = this.fb.group({
      bggIds: ['', Validators.required],
      saveResult:[]
    });

  ngOnInit(): void {
  }

  onSearch(){
    var data = JSON.stringify(this.bggSearchForm.value);

    var dataJson = JSON.parse(data);

    dataJson.bggIds = dataJson.bggIds.split(',').map(Number);
    
    if(dataJson.bggIds.length > 10){
      this.toastr.warning("Maximum of 10 Ids Allowed")
      return;
    }
    this.spinner.show();
    this.bggService.searchBgg(dataJson).subscribe(res=>{
      this.spinner.hide();
      this.resultList = res.data;
      this.toastr.success('Search Completed');

    },err=>{
      this.spinner.hide();
      
      this.toastr.error(JSON.stringify(err));
    })
  }

  closeModal() {
    this.commonService.modal_close.next('MODAL-BGG-SEARCH');
  }

}
