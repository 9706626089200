import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { EndiciaContentsTypeEditComponent } from './endicia-contents-type-edit/endicia-contents-type-edit.component';
declare let $: any;
@Component({
  selector: 'endicia-contents-type',
  templateUrl: './endicia-contents-type.component.html',
  styleUrls: ['./endicia-contents-type.component.scss'],
})
export class EndiciaContentsTypeComponent implements OnInit {
  contentstypeList: Array<any> = [];
  constructor(
    private settingsService: SettingsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init_data();
  }

  init_data() {
    this.spinner.show();
    const obs = this.settingsService.getContenttypes();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.contentstypeList = res;

        setTimeout(() => {
          if (!$.fn.dataTable.isDataTable('.dataTable_0')) {
            $('.dataTable_0 thead tr')
              .clone(true)
              .appendTo('.dataTable_0 thead');
            $('.dataTable_0 thead tr:eq(1) th').each(function (i) {
              var title = $(this).text();
              $(this).html(
                '<input type="text" style="width:100%" placeholder="Search ' +
                  title +
                  '" />'
              );

              $('input', this).on('keyup change', function () {
                if (dataTable.column(i).search() !== this.value) {
                  dataTable.column(i).search(this.value).draw();
                }
              });
            });

            var dataTable = $('.dataTable_0').DataTable({
              orderCellsTop: true,
              fixedHeader: true,
              pagingType: 'full_numbers',
              pageLength: 100,
              processing: true,
              lengthMenu: [5, 10, 20, 100, 200, 500],
            });
          }
        }, 1);
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  init_reload() {
    this.spinner.show();
    const obs = this.settingsService.getContenttypes();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.contentstypeList = res;
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onAdd() {
    const modalRef = this.modalService.open(EndiciaContentsTypeEditComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.isNewRecord = true;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'SETTINGS-EDIT-CONTENTS_TYPE-MODAL') {
        modalRef.close();
      }
    });
  }

  onEdit(id) {
    const selectedContent = this.contentstypeList.find((x) => x.id == id);

    const modalRef = this.modalService.open(EndiciaContentsTypeEditComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.contenttype = selectedContent;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'SETTINGS-EDIT-CONTENTS_TYPE-MODAL') {
        modalRef.close();
      }
    });
  }

  onDelete(id) {
    const selectedContentstype = this.contentstypeList.find((x) => x.id == id);
    const ref_id = selectedContentstype.id;
    this.spinner.show();
    const obs = this.settingsService.deleteContenttype(ref_id);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.init_reload();
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }
}
