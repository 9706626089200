<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="form" (ngSubmit)="onSave()">
        <div class="card-header">
          <h3 class="card-title small">Product Point Details</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="form"
              ></formly-form>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div class="col-6">
              <button
                type="submit"
                class="btn btn-success btn-sm w-50"
                [disabled]="!form.valid"
              >
                Save
              </button>
            </div>
            <div class="col-6">
              <!--button type="reset" class="btn btn-outline-secondary btn-sm w-50">Reset</button-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
