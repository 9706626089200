<section class="content">
    <div class="container-fluid">
        <div class="card">
        <form [formGroup]="userForm">
            <div class="card-header">
            <h3 class="card-title small">User Profile</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="closeModal()">
                <i class="fas fa-times"></i>
                </button>
            </div>
            </div>
            <div class="card-body">
            <div class="row">
                <div class="form-group col-4">
                    <label class="control-label small">Full Name</label>
                    <input type="text" class="form-control form-control-sm" formControlName="name" />
                </div>
                <div class="form-group col-4">
                    <label class="control-label small">Email</label>
                    <input type="text" class="form-control form-control-sm" formControlName="email" />
                </div>
                <div class="form-group col-4">
                    <label class="control-label small">Short Name</label>
                    <input type="text" class="form-control form-control-sm" formControlName="short_name" />
                </div>
            </div>
            <div class="row">
                <div class="form-group col-4">
                    <label class="control-label small">Main Phone</label>
                    <input type="text" class="form-control form-control-sm" formControlName="main_phone" />
                </div>
                <div class="form-group col-4">
                    <label class="control-label small">Active</label>
                    <select class="form-control form-control-sm" id="active" formControlName="active">
                        <option value="1">Active</option>
                        <option value="0">Disabled</option>
                    </select>
                </div>
                <div class="form-group col-4">
                    <label class="control-label small">Role</label>
                    <select class="form-control form-control-sm" id="active" formControlName="role">
                        <option value="1">Administrator</option>
                        <option value="0">User</option>
                    </select>
                </div>
            </div>
            <div class="row" *ngIf="allowPwdChange">
                <div class="form-group col-6">
                    <label class="control-label small">Password</label>
                    <input type="password" class="form-control form-control-sm" formControlName="password" />
                </div>
                <div class="form-group col-6">
                    <label class="control-label small">Confirm Password</label>
                    <input type="password" class="form-control form-control-sm" formControlName="password_confirm" />
                </div>
            </div>


            </div>
            <div class="card-footer text-muted">
            <div class="row">
                <div class="col-6">
                    <button type="submit" class="btn btn-outline-success btn-sm w-50" *ngIf="!user_id" (click)="createUser()"> Create User</button>
                    <button type="submit" class="btn btn-outline-success btn-sm w-50" *ngIf="user_id && !allowPwdChange" (click)="updateUser()"> Update User</button>
                    <button type="submit" class="btn btn-outline-success btn-sm w-50" *ngIf="user_id && allowPwdChange" (click)="changePassword()" > Change Password</button>
                </div>
                <div class="col-6">
                    <button type="button" class="btn btn-outline-danger btn-sm w-50" (click)="closeModal()" >Cancel</button>
                </div>
            </div>
            </div>
        </form>
        </div>
    </div>
</section>
