<div class="card">
    <div class="card-header">
        <div class="card-title small">
            <h5>Manage Vendors</h5>
        </div>
        <div class="card-tools">
            <mantle-btn-new-po-vendor></mantle-btn-new-po-vendor>
        </div>
    </div>
    <div class="card-body">
        <mantle-po-vendor-list></mantle-po-vendor-list>
    </div>
</div>