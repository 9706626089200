import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-image-input',
  templateUrl: './mantle-image-input.component.html',
  styleUrls: ['./mantle-image-input.component.scss'],
})
export class MantleImageInputComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, AfterViewInit {
  imageSrc: any;
  constructor(
    private commonService: CommonService,
    private toastr: ToastrService
  ) {
    super();
  }

  onFileChange(files: any) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.warning('Only images are supported');
      this.onRemoveImage();
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageSrc = reader.result;
      this.formControl.setValue(this.imageSrc);
    };
  }

  uploadImage() {}
  onRemoveImage() {
    this.imageSrc = null;
    this.formControl.setValue(null);
  }

  ngOnInit(): void {
    this.imageSrc = this.formControl.getRawValue();
  }
  ngAfterViewInit(): void {}
}
