<form [formGroup]="deliveryconfirmationtypeForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Delivery Confirmation Type</h5>
    </div>
    <div class="card-body">
      <div class="row m-2">
        <div class="form-group col-6">
          <label class="control-label small">Delivery Confirmation Type</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="delivery_confirmation_type"
          />
        </div>
        <div class="form-group col-6">
          <label class="control-label small"
            >Delivery Confirmation Type Description</label
          >
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="delivery_confirmation_type_description"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <button
            type="button"
            *ngIf="!isNewRecord"
            [disabled]="!deliveryconfirmationtypeForm.valid"
            class="btn btn-sm btn-info w-50"
            (click)="onUpdate()"
          >
            <i class="fas fa-save"></i> Update
          </button>
          <button
            type="button"
            *ngIf="isNewRecord"
            [disabled]="!deliveryconfirmationtypeForm.valid"
            class="btn btn-sm btn-info w-50"
            (click)="onSave()"
          >
            <i class="fas fa-save"></i> Save
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
