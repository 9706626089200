<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()" role="button"
        ><i class="fas fa-bars"></i
      ></a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/']" class="nav-link">Home</a>
    </li>
    <!--li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/blank']" class="nav-link">Contact</a>
    </li-->
  </ul>
  <div class="alert alert-warning ml-3" role="alert" *ngIf="init_load == 1">
    Initial Load Enabled! <i>This prevents updating of existing sales channel until all initial data is loaded</i> <button type="button" (click)="disableLoad()" class="btn btn-outline-primary btn-sm ml-2">Finish Load</button>
  </div>

  <!--form class="form-inline ml-3" [formGroup]="searchForm">
    <div class="input-group input-group-sm">
      <input
        class="form-control form-control-navbar"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form-->

  <ul class="navbar-nav ml-auto mr-4">
    <!--app-messages-dropdown-menu></app-messages-dropdown-menu-->
    <!--app-notifications-dropdown-menu></app-notifications-dropdown-menu!-->
    <mantle-quick-links [widgetLocation]="'menu'"></mantle-quick-links>
    <mantle-favorite-list></mantle-favorite-list>
    <app-user-dropdown-menu></app-user-dropdown-menu>
    <!--li class="nav-item">
      <a
        class="nav-link"
        data-widget="control-sidebar"
        data-slide="true"
        href="#"
        role="button"
      >
        <i class="fas fa-th-large"></i>
      </a>
    </li-->
  </ul>
</nav>
