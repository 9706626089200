import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { UserService } from 'src/app/utils/services/user.service';
import formConfig from '../../../../../configs/forms/form_system_setting.json';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';

@Component({
  selector: 'mantle-setting-edit',
  templateUrl: './setting-edit.component.html',
  styleUrls: ['./setting-edit.component.scss'],
})
export class SettingEditComponent implements OnInit {
  @Input() id: any;

  constructor(
    private settingsService: SettingsService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {}

  settingForm = this.fb.group({
    id: [],
  });

  options: FormlyFormOptions = {
    formState: {
      settings_type: [
        { value: 'int', label: 'Integer' },
        { value: 'string', label: 'String' },
        { value: 'text', label: 'Text' },
        { value: 'float', label: 'Number / Float' },
        { value: 'json', label: 'JSON' },
        { value: 'html', label: 'HTML' },
        { value: 'xml', label: 'XML' },
        { value: 'image', label: 'Image' },
      ],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  ngOnInit(): void {
    this.initData();
  }
  initData() {
    this.fields = formConfig;

    if (this.id) {
      this.spinner.show();
      this.settingsService.getSystemSettingById(this.id).subscribe(
        (res) => {
          this.settingForm.patchValue(res);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
    }
  }

  onSave() {
    const frmValue = this.settingForm.getRawValue();
    this.spinner.show();
    this.settingsService.createSystemSetting(frmValue).subscribe(
      (res) => {
        this.id = res.id;
        this.settingForm.patchValue(res);
        this.spinner.hide();
        this.toastr.success('Record Created Sucessfully');
        this.commonService.systemSettingsUpdated.next(null);
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  }

  onUpdate() {
    const frmValue = this.settingForm.getRawValue();
    this.spinner.show();
    this.settingsService.updateSystemSetting(frmValue).subscribe(
      (res) => {
        this.settingForm.patchValue(res);
        this.spinner.hide();
        this.toastr.success('Record Updated Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.MODAL_SETTING_EDIT);
  }
}
