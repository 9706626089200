import { Component } from '@angular/core';

@Component({
  selector: 'mantle-po-vendor',
  templateUrl: './mantle-po-vendor.component.html',
  styleUrls: ['./mantle-po-vendor.component.scss']
})
export class MantlePoVendorComponent {

}
