<form [formGroup]="bggSearchForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Search Board Games</h5>
    </div>
    <div class="card-body">
      <div class="row m-2">
        <div class="form-group col-6">
          <label class="control-label small">BGG Ids (Comma Separated)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="bggIds"
          />
        </div>
        <div class="form-group form-check col-6">
          <label class="control-label small">Save Result</label><br />
          <input type="checkbox" formControlName="saveResult" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 table-responsive small">
          <table class="table table-striped dataTable">
            <thead>
              <tr>
                <th>BGG ID</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let result of resultList">
                <tr>
                  <td>{{ result['Child Sku'] }}</td>
                  <td>{{ result['Product Name'] }}</td>
                  <td>{{ result['mantle-status'] }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="form-group col-6">
          <button
            type="button"
            [disabled]="!bggSearchForm.valid"
            class="btn btn-sm btn-info w-50"
            (click)="onSearch()"
          >
            <i class="fas fa-save"></i> Search
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
