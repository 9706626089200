import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/utils/services/common.service';
import { PurchaseOrderService } from 'src/app/utils/services/purchase-order.service';

@Component({
  selector: 'mantle-po-search',
  templateUrl: './mantle-po-search.component.html',
  styleUrls: ['./mantle-po-search.component.scss'],
})
export class MantlePoSearchComponent implements OnInit, OnDestroy {
  order_statuses: any;

  private searchDefaultSubscription: Subscription;
  private storesSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private poService: PurchaseOrderService,
    private spinner: NgxSpinnerService
  ) {}
  orderSearchForm = this.fb.group({
    order_number: [],
    vendor_id: [],
    order_date_from: [],
    order_date_to: [],
    order_status: this.fb.array([]),
    include_deleted: [],
  });

  ngOnInit(): void {
    this.searchDefaultSubscription = this.poService.searchDefault.subscribe(
      (data) => {
        this.defaultSearch();
      }
    );
    this.initValues();
  }
  initValues = () => {
    this.spinner.show();
    this.storesSubscription = this.poService.getPOStatuses().subscribe(
      (res) => {
        this.order_statuses = res;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  };

  onCheckboxChange($event, source) {
    const multiCheckbox: UntypedFormArray = this.orderSearchForm.get(
      source
    ) as UntypedFormArray;

    if ($event.target.checked) {
      multiCheckbox.push(new UntypedFormControl($event.target.value));
    } else {
      let i: number = 0;
      multiCheckbox.controls.forEach((item: UntypedFormControl) => {
        if (item.value == $event.target.value) {
          multiCheckbox.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onChangeDateRange($event) {
    var today = moment().format('YYYY-MM-DD');
    var startDate = '';
    const range = $event.target.value;
    if (range) {
      switch (parseInt(range)) {
        case 1:
          startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
          break;
        case 2:
          startDate = moment().subtract(2, 'weeks').format('YYYY-MM-DD');
          break;
        case 3:
          startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
          break;
        case 4:
          startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
          break;
        case 5:
          startDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
          break;
        default:
          break;
      }
    } else {
      today = null;
      startDate = null;
    }

    this.orderSearchForm.patchValue({
      order_date_from: startDate,
      order_date_to: today,
    });
  }

  defaultSearch = () => {
    this.orderSearchForm.patchValue({});
    this.orderSearchForm.setControl(
      'order_status',
      this.fb.array([1, 2] || [])
    );
    this.onSubmitSearch();
    //Remove Default value after search
    this.orderSearchForm.setControl('order_status', this.fb.array([]));
  };

  onSubmitSearch = () => {

    const requestPayload = {
      ...this.orderSearchForm.value,
      ...{
        timezone: this.commonService.getTimeZone(),
      },
    };

    this.poService.searchOrder.next(requestPayload);
  };

  ngOnDestroy(): void {
    this.searchDefaultSubscription?.unsubscribe();
    this.storesSubscription?.unsubscribe();
  }
}
