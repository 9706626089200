import { Component } from '@angular/core';

@Component({
  selector: 'app-pos-instore',
  templateUrl: './pos-instore.component.html',
  styleUrls: ['./pos-instore.component.scss']
})
export class PosInstoreComponent {

}
