import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import formConfig from '../../../../configs/forms/form_settings_reward_points.json';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { Store } from '@ngrx/store';
import {
  selectRewardPoint,
  selectStateStatus,
} from './state/reward_points.selectors';
import {
  loadRewardPoints,
  saveRewardPoints,
} from './state/reward_points.actions';
import { STATE_STATUS } from 'src/app/utils/enums/mantle-enums';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'settings-reward-points',
  templateUrl: './settings-reward-points.component.html',
  styleUrl: './settings-reward-points.component.scss',
})
export class SettingsRewardPointsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private spinner: NgxSpinnerService
  ) {
    this.store.dispatch(loadRewardPoints());
  }
  form = this.fb.group({
    id: [1],
  });
  options: FormlyFormOptions = {
    formState: {},
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  ngOnInit(): void {
    setTimeout(() => {
      this.fields = formConfig;
    }, 1);

    this.subscription.add(
      this.store.select(selectRewardPoint).subscribe((rewardPoint) => {
        if (rewardPoint != null) {
          this.patchForm(rewardPoint);
        }
      })
    );

    this.subscription.add(
      this.store.select(selectStateStatus).subscribe((status) => {
        if (status == STATE_STATUS.LOADING) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      })
    );
  }

  onSave() {
    const rewardPoint = this.form.getRawValue();
    this.store.dispatch(saveRewardPoints(rewardPoint));
  }

  patchForm(value) {
    this.form.patchValue(value);
  }

  ngAfterViewInit(): void {
    //throw new Error('Method not implemented.');
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
