<div class="row m-2">
  <div class="col-md-11" style="text-align: center">
    <mantle-print [printSectionId]="'print-section'"></mantle-print>
  </div>
  <div class="col-md-1">
    <div class="card-tools">
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-tool"
        data-card-widget="remove"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</div>
<div id="print-section">
  <ng-container *ngFor="let order of invoiceOrders">
    <div class="page" size="A4">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!--div class="col-md-1">
              <img
                [(src)]="logoImage"
                alt="Logo"
                style="max-width: 40px; width: 40px"
              />
            </div-->
            <div class="col-6">
              <address>
                <ng-container *ngIf="order?.billing_address?.name"
                  ><strong>{{ order?.billing_address?.name }}</strong
                  ><br
                /></ng-container>
                <ng-container *ngIf="order?.billing_address?.street_address"
                  >{{ order?.billing_address?.street_address }} <br
                /></ng-container>
                <ng-container *ngIf="order?.billing_address?.street_address_2"
                  >{{ order?.billing_address?.street_address_2 }}<br
                /></ng-container>
                <ng-container *ngIf="order?.billing_address?.zipcode"
                  >{{ order?.billing_address?.zipcode }}<br
                /></ng-container>
                <ng-container *ngIf="order?.billing_address?.city">{{
                  order?.billing_address?.city
                }}</ng-container>
                <ng-container *ngIf="order?.billing_address?.state"
                  >{{ order?.billing_address?.state }}<br
                /></ng-container>
                <ng-container *ngIf="order?.billing_address?.country_code"
                  >{{ order?.billing_address?.country_code }}<br
                /></ng-container>
                <ng-container *ngIf="order?.billing_address?.email"
                  >{{ order?.billing_address?.email }}<br
                /></ng-container>
                <ng-container *ngIf="order?.billing_address?.phone">{{
                  order?.billing_address?.phone
                }}</ng-container>
                <ng-container *ngIf="order?.billing_address?.mobile">
                  {{ order?.billing_address?.mobile }}</ng-container
                >
              </address>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-md-3">DATE</div>
                <div class="col-md-9">
                  {{ order?.order_date | date: 'short' }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">PO No#</div>
                <div class="col-md-9">{{ order?.order_number }}</div>
              </div>
              <div class="row">
                <div class="col-md-3">STATUS</div>
                <div class="col-md-9">
                  {{ order?.purchase_status?.description }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <h6><strong>VENDOR</strong></h6>
              <hr class="hr" />
              <address>
                <ng-container *ngIf="order?.vendor_address?.name"
                  >{{ order?.vendor_address?.name }}<br
                /></ng-container>
                <ng-container *ngIf="order?.vendor_address?.street_address"
                  >{{ order?.vendor_address?.street_address }} <br
                /></ng-container>
                <ng-container *ngIf="order?.vendor_address?.street_address_2"
                  >{{ order?.vendor_address?.street_address_2 }}<br
                /></ng-container>
                <ng-container *ngIf="order?.vendor_address?.zipcode"
                  >{{ order?.vendor_address?.zipcode }}<br
                /></ng-container>
                <ng-container *ngIf="order?.vendor_address?.city">{{
                  order?.vendor_address?.city
                }}</ng-container>
                <ng-container *ngIf="order?.vendor_address?.state"
                  >{{ order?.vendor_address?.state }}<br
                /></ng-container>
                <ng-container *ngIf="order?.vendor_address?.country_code"
                  >{{ order?.vendor_address?.country_code }}<br
                /></ng-container>
                <ng-container *ngIf="order?.vendor_address?.email"
                  >{{ order?.vendor_address?.email }}<br
                /></ng-container>
                <ng-container *ngIf="order?.vendor_address?.phone">{{
                  order?.vendor_address?.phone
                }}</ng-container>
                <ng-container *ngIf="order?.vendor_address?.mobile">
                  {{ order?.vendor_address?.mobile }}</ng-container
                >
              </address>
            </div>
            <div class="col-6">
              <h6><strong>SHIP TO</strong></h6>
              <hr class="hr" />
              <address>
                <ng-container *ngIf="order?.shipping_address?.name"
                  >{{ order?.shipping_address?.name }}<br
                /></ng-container>
                <ng-container *ngIf="order?.shipping_address?.street_address"
                  >{{ order?.shipping_address?.street_address }} <br
                /></ng-container>
                <ng-container *ngIf="order?.shipping_address?.street_address_2"
                  >{{ order?.shipping_address?.street_address_2 }}<br
                /></ng-container>
                <ng-container *ngIf="order?.shipping_address?.zipcode"
                  >{{ order?.shipping_address?.zipcode }}<br
                /></ng-container>
                <ng-container *ngIf="order?.shipping_address?.city">{{
                  order?.shipping_address?.city
                }}</ng-container>
                <ng-container *ngIf="order?.shipping_address?.state"
                  >{{ order?.shipping_address?.state }}<br
                /></ng-container>
                <ng-container *ngIf="order?.shipping_address?.country_code"
                  >{{ order?.shipping_address?.country_code }}<br
                /></ng-container>
                <ng-container *ngIf="order?.shipping_address?.email"
                  >{{ order?.shipping_address?.email }}<br
                /></ng-container>
                <ng-container *ngIf="order?.shipping_address?.phone">{{
                  order?.shipping_address?.phone
                }}</ng-container>
                <ng-container *ngIf="order?.shipping_address?.mobile">
                  {{ order?.shipping_address?.mobile }}</ng-container
                >
              </address>
            </div>
          </div>
          <div class="row table-responsive">
            <div class="col-md-12">
              <table class="table table-striped table-sm text-sm">
                <thead class="bg-secondary">
                  <tr>
                    <th scope="col">Item #</th>
                    <th scope="col" class="w-15" style="text-align: left">
                      Product
                    </th>
                    <th scope="col">Ordered QTY</th>
                    <th scope="col">Received QTY</th>
                    <th scope="col" style="text-align: right">Unit Price</th>
                    <th scope="col" style="text-align: right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let orderItems of order.order_items">
                    <tr>
                      <td>
                        {{ orderItems.child_sku }}
                      </td>
                      <td style="text-align: left">
                        {{ orderItems.item_description }}
                      </td>
                      <td>
                        {{ orderItems.quantity }}
                      </td>
                      <td>
                        {{ orderItems.quantity_received }}
                      </td>
                      <td style="text-align: right">
                        $ {{ orderItems?.unit_price | number: '1.2-2' }}
                      </td>
                      <td style="text-align: right">
                        $ {{ orderItems?.total | number: '1.2-2' }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="5" style="text-align: right">Subtotal</td>
                    <td colspan="1" style="text-align: right">
                      $ {{ order?.sub_total | number: '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" style="text-align: right">Discount</td>
                    <td colspan="1" style="text-align: right">
                      $ {{ order?.discount_amount | number: '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" style="text-align: right">Shipping</td>
                    <td colspan="1" style="text-align: right">
                      $ {{ order?.shipment_amount | number: '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="5" style="text-align: right">Total</th>
                    <td colspan="1" style="text-align: right">
                      ${{ order?.order_total | number: '1.2-2' }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-6" *ngIf="order.notes">
              <b>Customer note to Seller:</b>
              <span>
                <p>{{ order.notes }}</p></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <p style="page-break-after: always">&nbsp;</p>
    <p style="page-break-before: always">&nbsp;</p>
  </ng-container>
</div>
