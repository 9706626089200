import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/utils/services/api.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { ProductService } from 'src/app/utils/services/product.service';

@Component({
  selector: 'mantle-favorite',
  templateUrl: './mantle-favorite.component.html',
  styleUrls: ['./mantle-favorite.component.scss'],
})
export class MantleFavoriteComponent implements OnInit {
  @Input() data: any;
  isFavorite: boolean = false;

  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private inventoryService: InventoryService,
    private productService:ProductService
  ) {}

  ngOnInit(): void {
    this.favoriteCheck();
  }

  toggleFavorite($event) {
    if ($event.target.checked) {
      this.productService.createFavorite(this.data).subscribe(
        (res) => {
          this.toastr.success('Item bookmarked as favorite');
          this.inventoryService.favorites_updated.next(true);
        },
        (err) => {
          
        }
      );
    } else {
      this.productService.deleteFavorite(this.data).subscribe(
        (res) => {
          this.toastr.success('Favorite item removed');
          this.inventoryService.favorites_updated.next(true);
        },
        (err) => {
          
        }
      );
    }
  }

  favoriteCheck() {
    this.productService.isFavorite(this.data).subscribe(
      (res) => {
        if (parseInt(res['count']) > 0) {
          this.isFavorite = true;
        } else {
          this.isFavorite = false;
        }
      },
      (err) => {
        
      }
    );
  }
}
