import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  constructor(private apiService: ApiService) {}

  getMerchants(filters = {}, offset = 0, limit = 100): Observable<any> {
    return this.apiService.get(
      `/api/v1/merchant?offset=${offset}&limit=${limit}&${Object.entries(
        filters
      )
        .map((e) => e.join('='))
        .join('&')}`
    );
  }

  getMerchantById(id): Observable<any> {
    return this.apiService.get(`/api/v1/merchant/${id}`);
  }

  createMerchant(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/merchant`, requestPayload);
  }

  updateMerchant(requestPayload): Observable<any> {
    return this.apiService.patch(`/api/v1/merchant`, requestPayload);
  }

  deleteMerchant(id): Observable<any> {
    return this.apiService.delete(`/api/v1/merchant/${id}`);
  }

  getTerminals(filters = {}, offset = 0, limit = 100): Observable<any> {
    return this.apiService.get(
      `/api/v1/terminal?offset=${offset}&limit=${limit}&${Object.entries(
        filters
      )
        .map((e) => e.join('='))
        .join('&')}`
    );
  }

  getTerminalById(id): Observable<any> {
    return this.apiService.get(`/api/v1/merchant/${id}`);
  }

  createTerminal(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/terminal`, requestPayload);
  }

  updateTerminal(requestPayload): Observable<any> {
    return this.apiService.patch(`/api/v1/terminal`, requestPayload);
  }

  deleteTerminal(id): Observable<any> {
    return this.apiService.delete(`/api/v1/terminal/${id}`);
  }

  pushCardPayment(
    order_id,
    terminal_id,
    transaction_type = 'Sale',
    amount = 0,
  ): Observable<any> {
    const requestPayload = {
      order_id: order_id,
      terminal_id: terminal_id,
      transaction_type: transaction_type,
      amount: amount
    };
    return this.apiService.post(
      '/api/v1/terminal/payment/push',
      requestPayload
    );
  }

  /**
   * MX Merchant Specific EndPoints
   */

  mxValidateCredentials(requestPayload): Observable<any> {
    return this.apiService.post(
      `/api/v1/merchant/mx-merchant/validate`,
      requestPayload
    );
  }

  mxGetTerminals(merchant_id): Observable<any> {
    return this.apiService.get(
      `/api/v1/merchant/mx-merchant/terminals/${merchant_id}`
    );
  }
}
