<div class="row m-2">
  <div class="col-11" style="text-align: center">
    <mantle-print [printSectionId]="'print-section'"></mantle-print>
  </div>
  <div class="col-1">
    <div class="card-tools">
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-tool"
        data-card-widget="remove"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</div>
<div id="print-section">
  <ng-container *ngFor="let order of invoiceOrders">
    <div class="page" size="A4">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <img
                src="assets/img/Mantle-Logo-icon.png"
                style="width: 5em; height: 3em"
              />
            </div>
            <div class="col-6"></div>
            <div class="col-3">
              <span>Shop Address Line 1</span>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <h4>Invoice: INV-{{ order.order_number }}</h4>
            </div>
            <div class="col-6">
              <h4>{{ order.order_status.status_description }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <h6>Order Date: {{ order.order_date }}</h6>
            </div>
            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-6" *ngIf="order.billing_address">
              <h6><strong>Billing Address</strong></h6>
              <address>
                {{ order.billing_address.billing_first_name }}
                {{ order.billing_address.billing_last_name }}<br />
                {{ order.billing_address.billing_street_address }} <br />
                {{ order.billing_address.billing_street_address_2 }} <br />
                {{ order.billing_address.billing_zipcode }}
                {{ order.billing_address.billing_city }},
                {{ order.billing_address.billing_country_code }} <br />
                {{ order.billing_address.billing_email }}<br />
                {{ order.billing_address.billing_phone }} /
                {{ order.billing_address.billing_mobile }}<br />
              </address>
            </div>
            <div class="col-md-6" *ngIf="order.shipment_addres">
              <h6><strong>Shipping Address</strong></h6>
              <address>
                {{ order.shipment_address.shipment_first_name }}
                {{ order.shipment_address.shipment_last_name }}<br />
                {{ order.shipment_address.shipment_street_address }} <br />
                {{ order.shipment_address.shipment_street_address_2 }} <br />
                {{ order.shipment_address.shipment_zipcode }}
                {{ order.shipment_address.shipment_city }},
                {{ order.shipment_address.shipment_country_code }} <br />
                {{ order.shipment_address.shipment_email }}<br />
                {{ order.shipment_address.shipment_phone }} /
                {{ order.shipment_address.shipment_mobile }}<br />
              </address>
            </div>
          </div>
          <div class="row table-responsive">
            <table class="table table-striped">
              <thead class="bg-secondary">
                <tr>
                  <th scope="col">SKU</th>
                  <th scope="col" class="w-30">Product</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let orderItems of order.order_items">
                  <tr>
                    <td>
                      {{ orderItems.child_sku }}
                    </td>
                    <td>
                      {{ orderItems.item_description }}
                    </td>
                    <td>
                      {{ orderItems.quantity }}
                    </td>
                    <td style="text-align: center">
                      {{ orderItems.currency_code }} {{ orderItems.total }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" style="text-align: right">Subtotal</td>
                  <td style="text-align: center">
                    {{ order.currency_code }} {{ order.sub_total }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: right">Discount</td>
                  <td style="text-align: center">
                    {{ order.currency_code }} {{ order.discount_total_total }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: right">Shipping</td>
                  <td style="text-align: center">
                    {{ order.currency_code }} {{ order.shipment_cost }}
                  </td>
                </tr>
                <!--tr>
                      <td colspan="3" style="text-align:right">Payment Method</td>
                      <td style="text-align:center">{{order.payment_method_id}}</td>
                    </tr-->
                <tr>
                  <td colspan="3" style="text-align: right">Total</td>
                  <td style="text-align: center">
                    {{ order.currency_code }} {{ order.order_total }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <p style="page-break-after: always">&nbsp;</p>
    <p style="page-break-before: always">&nbsp;</p>
  </ng-container>
</div>
