import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'mantle-tag-input',
  templateUrl: './mantle-tag-input.component.html',
  styleUrls: ['./mantle-tag-input.component.scss'],
})
export class MantleTagInputComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, AfterViewInit {
  constructor() {
    super();
  }
  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  onTagAdded($event: any) {
    this.formatValues();
  }

  onTagRemoved($event: any) {
    this.formatValues();
  }

  onTagEdited($event: any) {
    this.formatValues();
  }
  onValidationError($event: string) {}
  formatValues() {
    let values = this.formControl.getRawValue();

    values = values?.map((x) => {
      if (x.value != undefined) return x.value;

      return x;
    });

    this.formControl.setValue(values);
  }
}
