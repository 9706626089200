import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'endicia-validate-address',
  templateUrl: './endicia-validate-address.component.html',
  styleUrls: ['./endicia-validate-address.component.scss']
})
export class EndiciaValidateAddressComponent implements OnInit {

  @Input() orderDetails:any
  storeAddresses:Array<any>= []
  fromAddress:any = {}
  validFromAddress:boolean = false
  validToAddress:boolean = false
  fromValidationMessage:any = ""
  toValidationMessage:any = ""
  fromHighlight:any = ""
  toHighlight:any = ""
  canEdit:boolean = true

  constructor(
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private settingsService:SettingsService,
    private endiciaService:EndiciaService) { }

  ngOnInit(): void {
    this.getStores();

    this.endiciaService.existingShipmentOpen.subscribe(data=>{
      if(data !== true){

        this.existingData(data)
      }
      
    })
    //Who blinks 1st
    this.endiciaService.existingShipmentOpen.next(true)
  }

  getStores(){
    this.spinner.show()

    const obs = this.settingsService.getStores()
    obs.subscribe(res=>{
      this.storeAddresses = res
      this.spinner.hide()
    },
      err=>{
        this.spinner.hide()
        this.toastr.error("An error occurred")
        
    })
  }

  onSelectAddress($event){
    this.validFromAddress = false
    this.fromAddress = $event
  }

  validateAddress(type){
    var endicia_address = {}
    var requestPayload:any = {}
    //Do address Mapping
    if(type == 'FROM'){
      
      requestPayload = this.fromAddress

      endicia_address = {
        company_name:this.fromAddress.store_name,
        name:this.fromAddress.store_name,
        address_line1:this.fromAddress.store_address,
        address_line2:this.fromAddress.store_address_1,
        city:this.fromAddress.store_citycode,
        state_province:this.fromAddress.store_statecode,
        postal_code:this.fromAddress.store_zipcode,
        country_code:this.fromAddress.store_countrycode,
      }

      this.endiciaService.addressValidated.next({
        from_address:endicia_address
      })

      this.endiciaService.addressValidated.next({
        return_address:endicia_address
      })
    }
    if(type == 'TO'){
      requestPayload = this.orderDetails.shipment_address
     

      endicia_address = {
        //company_name:requestPayload.shipment_first_name+" "+requestPayload.shipment_last_name,
        name: requestPayload.shipment_first_name+" "+requestPayload.shipment_last_name,
        address_line1:requestPayload.shipment_street_address,
        address_line2:requestPayload.shipment_street_address_2,
        city:requestPayload.shipment_city,
        state_province:requestPayload.shipment_state,
        postal_code:requestPayload.shipment_zipcode,
        country_code:requestPayload.shipment_country_code,
      }

      this.endiciaService.addressValidated.next({
        to_address:endicia_address
      })
    }

    //Make call
    this.spinner.show()
    const obs = this.endiciaService.validateAddress(endicia_address);
    obs.subscribe(res=>{
      this.spinner.hide();

      const result_code = res[0]?.validation_results?.result_code;
      const result_description = res[0]?.validation_results?.result_description;
      const result_details = res[0]?.validation_results?.result_details;

      if(type == 'FROM'){
        this.validFromAddress = true
        this.fromHighlight = this.resultCodeColor(result_code)
        this.fromValidationMessage = result_description
      }
      else if(type == 'TO'){
        this.validToAddress = true
        this.toHighlight = this.resultCodeColor(result_code)
        this.toValidationMessage = result_description
      }


    },err=>{
      this.spinner.hide();
      
      this.toastr.error('An Error has occurred');
      if(err.error){
        this.endiciaService.endiciaError(err.error)
      }
    })
    
  }

  resultCodeColor(result_code){
    var bsClass = "text-danger"
    switch (result_code.charAt(0)) {
      case "V":
        bsClass = "text-success"
        break;
      case "P":
          bsClass = "text-warning"
          break;
      case "E":
          bsClass = "text-danger"
          break;
      default:
        break;
    }
    return bsClass;
  }

  existingData(data){
    this.canEdit = false
    if(data.request_payload?.from_address){
      const fromAd = data.request_payload?.from_address;
      this.fromAddress = {
        store_name:fromAd.company_name ??fromAd.name,
        store_address: fromAd.address_line1,
        store_address_1: fromAd.address_line2,
        store_citycode:fromAd.city,
        store_statecode:fromAd.state_province,
        store_zipcode:fromAd.postal_code,
        store_countrycode: fromAd.country_code,
      }
    }

    if(data.request_payload?.to_address){
      const toAd = data.request_payload?.to_address;
      const toAddress = {
        shipment_first_name:toAd.company_name ??toAd.name,
        shipment_street_address: toAd.address_line1,
        shipment_street_address_2: toAd.address_line2,
        shipment_city:toAd.city,
        shipment_state:toAd.state_province,
        shipment_zipcode:toAd.postal_code,
        shipment_country_code: toAd.country_code,
      }
      this.orderDetails = {}
      this.orderDetails.shipment_address = toAddress;
    }
  }

}
