import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MantleProductUpdateComponent } from '../mantle-product-update/mantle-product-update.component';
declare let $: any;
@Component({
  selector: 'mantle-catalog-list',
  templateUrl: './mantle-catalog-list.component.html',
  styleUrls: ['./mantle-catalog-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MantleCatalogListComponent implements OnInit {
  product_list: Array<any> = [];
  selectedChildSku: any;
  has_data: boolean = false;
  resultCount: Number = 0;
  MAX_RESULT: Number = 1000;

  constructor(
    private spinner: NgxSpinnerService,
    private inventoryService: InventoryService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {
    this.inventoryService.filter_result.subscribe((data) => {
      this.init_data(data);
    });
  }

  ngOnInit(): void {}

  init_data(filter_data) {
    this.resultCount = 0;
    try {
      if ($.fn.dataTable.isDataTable('#catalogDataTable')) {
        var tbl = $('#catalogDataTable').DataTable();
        //tbl.destroy();
        //tbl.draw();
        tbl.clear();
        tbl.destroy();
      }
    } catch (error) {}

    setTimeout(() => {
      if (!$.fn.dataTable.isDataTable('#catalogDataTable')) {
        $('#catalogDataTable').DataTable({
          pagingType: 'full_numbers',
          fixedHeader: true,
          pageLength: 100,
          processing: true,
          lengthMenu: [5, 10, 20, 100, 200, 500],
          bSort: false,
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: ['nosort'],
            },
          ],
          //order:[[1,"desc"]]
        });
      }
    }, 3);

    let result = filter_data['parent_skus'];
    if (result != undefined) {
      this.product_list = result;

      this.product_list.map((x) => {
        this.resultCount += x.child_skus.length;
      });

      if (this.product_list.length > 0) {
        this.has_data = true;
      } else {
        this.has_data = false;
      }
    } else {
      this.has_data = false;
    }
  }

  toggleChild(parent_sku) {
    if (parent_sku != undefined) {
      var productExt = this.product_list.find(
        (x) => x.parent_sku == parent_sku
      );
      productExt.toggle = !productExt.toggle;
    }
  }

  edit_item(parent_sku, child_sku, brand_id) {
    this.inventoryService.inventory_item_selected = new Subject();
    this.inventoryService.product_edit_loaded = new Subject();

    const modalRef = this.mantleModalService.open(
      MantleProductUpdateComponent,
      MANTLE_MODAL_NAME.PRODUCT_UPDATE_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.data = {
      brand_id: brand_id,
      parent_sku: parent_sku,
      child_sku: child_sku,
    };

    this.commonService.modal_close.subscribe((data) => {
      if (data === MANTLE_MODAL_NAME.PRODUCT_UPDATE_MODAL) {
        this.inventoryService.inventory_item_selected.complete();
        this.inventoryService.product_edit_loaded.complete();
      }
    });
  }

  onAddToInventory(brand_id: any, child_sku: any, parent_sku: any) {
    this.spinner.show();
    this.inventoryService
      .getInventoryData({
        brand_id: brand_id,
        child_sku: child_sku,
      })
      .subscribe(
        (res) => {
          if (res == null || res == undefined) res = {};
          this.spinner.hide();
          if ('inventory_id' in res) {
            this.openInventoryModal(brand_id, child_sku, parent_sku);
          } else {
            this.addEmptyInventory(brand_id, child_sku, parent_sku);
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  }

  addEmptyInventory = (brand_id, child_sku, parent_sku) => {
    this.spinner.show();
    this.inventoryService
      .updateInventory({
        brand_id: brand_id,
        parent_sku: parent_sku,
        child_sku: child_sku,
        quantity: 0,
        sold: 0,
        currency_code: 'USD',
        product_status_id:3

      })
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.openInventoryModal(brand_id, child_sku, parent_sku);
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  };

  openInventoryModal = (brand_id: any, child_sku: any, parent_sku: any) => {
    this.inventoryService.openInventoryModal(brand_id, child_sku,parent_sku);
  };
}
