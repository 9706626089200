<div class="card">
  <div class="card-header">
    System Settings
    <div class="card-tools">
      <button type="button" class="btn btn-tool">
        <button
          type="button"
          class="btn btn-success btn-sm"
          (click)="onNewSetting()"
        >
          New Setting
        </button>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <mantle-setting-list></mantle-setting-list>
      </div>
    </div>
  </div>
</div>
