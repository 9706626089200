import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { OrderService } from 'src/app/utils/services/order.service';

@Component({
  selector: 'mantle-order-invoice',
  templateUrl: './mantle-order-invoice.component.html',
  styleUrls: ['./mantle-order-invoice.component.scss'],
})
export class MantleOrderInvoiceComponent implements OnInit {
  @Input() orderIds: Array<any>;
  invoiceOrders: Array<any> = [];
  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init_values();
  }

  init_values() {
    this.getOrderDetails();
  }

  getOrderDetails() {
    if (this.orderIds != undefined) {
      this.spinner.show();
      const payload = {
        order_ids: this.orderIds,
      };
      this.orderService.getOrders(payload).subscribe(
        (res) => {
          this.invoiceOrders = res;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          
          this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
        }
      );
    } else {
      this.toastr.warning('No Orders Selected');
    }
  }

  closeModal() {
    this.commonService.modal_close.next('ORDER-INVOICE');
  }
}
