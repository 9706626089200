<div class="card">
  <div class="card-header">
    <h3 class="card-title small">Customers</h3>
    <div class="card-tools">
      <mantle-btn-new-customer></mantle-btn-new-customer>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 table-responsive small">
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th id="first_name">First Name</th>
              <th id="last_name">Last Name</th>
              <th id="email">Email</th>
              <th id="phone">Phone</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let customer of customersList$ | async; index as i"
            >
              <tr>
                <td>{{ customer.first_name }}</td>
                <td>{{ customer.last_name }}</td>
                <td>{{ customer.email }}</td>
                <td>{{ customer.phone }}</td>
                <td>
                  <div class="dropdown">
                    <button
                      class="btn btn-outline btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="viewCustonerDetails(customer.customer_id)"
                      >
                        <i class="fas fa-info-circle"></i> View Details
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
