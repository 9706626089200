import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { UserPreferenceService } from 'src/app/utils/services/user-preference.service';
import formConfig from '../../../../../../configs/forms/form_user_link.json';

@Component({
  selector: 'mantle-manage-quick-links',
  templateUrl: './manage-quick-links.component.html',
  styleUrls: ['./manage-quick-links.component.scss'],
})
export class ManageQuickLinksComponent implements OnInit, OnDestroy {
  private getDefaulsSubscription: Subscription;
  userProfile: any;
  defaultQuickLinks: Array<any> = [];
  quickLinks: Array<any> = [];

  linkForm = this.fb.group({
    link_id: [],
  });

  options: FormlyFormOptions = {
    formState: {
      link_type: [
        { value: 'internal', label: 'Internal' },
        { value: 'external', label: 'External' },
      ],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  constructor(
    private auth0Service: Auth0Service,
    private userPreferenceService: UserPreferenceService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.userProfile = this.auth0Service.getProfile();
    this.fields = formConfig;
    this.initData();
  }

  initData = () => {
    this.linkForm.reset();
    this.spinner.show();
    this.getDefaulsSubscription = this.userPreferenceService
      .getDefaultQuickLinks()
      .subscribe(
        (res) => {
          this.defaultQuickLinks = res;
          this.userPreferenceService
            .getUserQuickLinks(this.userProfile.user_id)
            .subscribe(
              (res) => {
                this.quickLinks = res;
                this.filterDefaultLinks();
                this.spinner.hide();
              },
              (err) => {
                this.toastr.error(err.message);
                this.spinner.hide();
              }
            );
        },
        (err) => {
          this.toastr.error(err.message);
          this.spinner.hide();
        }
      );
  };

  filterDefaultLinks = () => {
    this.defaultQuickLinks = this.defaultQuickLinks.filter(
      (x) => this.quickLinks.filter((y) => y.href == x.href).length <= 0
    );
  };
  onAddLink(link: any) {
    delete link.link_id;
    link.user_id = this.userProfile.user_id;
    this.spinner.show();
    this.userPreferenceService.createUserQuickLinks(link).subscribe(
      (res) => {
        this.spinner.hide();
        this.initData();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
        this.initData();
      }
    );
  }
  onUpdateLink(link: any) {
    link.user_id = this.userProfile.user_id;
    this.spinner.show();
    this.userPreferenceService.updateUserQuickLinks(link).subscribe(
      (res) => {
        this.spinner.hide();
        this.initData();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
        this.initData();
      }
    );
  }
  onDeleteLink(link_id: any) {
    this.spinner.show();
    this.userPreferenceService.deleteUserQuickLinks(link_id).subscribe(
      (res) => {
        this.spinner.hide();
        this.initData();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
        this.initData();
      }
    );
  }
  onEditLink = (link) => {
    this.linkForm.reset();
    this.linkForm.patchValue(link);
  };

  ngOnDestroy(): void {
    this.getDefaulsSubscription?.unsubscribe();
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.MANAGE_QUICK_LINKS);
  }
}
