<form [formGroup]="bcInventoryForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Update Product Catalog</h5>
    </div>
    <div class="card-body">
      <div class="row small text-muted m-2">
        <div class="alert alert-warning col-12" role="alert" *ngIf="!has_data">
          Product Details Not Found.
        </div>
      </div>
      <div class="row small text-muted m-2">
        <div class="col-3">
          <strong>Product ID:</strong> {{ productDetails.id }}
        </div>
        <div class="col-3"><strong>SKU:</strong> {{ productDetails.sku }}</div>
        <div class="col-3">
          <strong>Product Name:</strong> {{ productDetails.name }}
        </div>
        <div class="col-3">
          <strong>Parent Sku:</strong> {{ productDetails.upc }}
        </div>
      </div>
      <div class="row m-2">
        <div class="form-group col-4">
          <label class="control-label small">Quantity</label>
          <input
            type="number"
            readonly
            class="form-control form-control-sm"
            formControlName="inventory_level"
            min="0"
          />
        </div>
        <div class="form-group col-4">
          <label class="control-label small">Price</label>
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="price"
            min="0"
          />
        </div>
        <div class="form-group col-4">
          <label class="control-label small">Bin Picking Number (BPN)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="bin_picking_number"
          />
        </div>
      </div>
      <div class="row m-2">
        <div class="form-check col-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="is_free_shipping"
            formControlName="is_free_shipping"
          />
          <label class="form-check-label small" for="is_free_shipping"
            >Free Shipping</label
          >
        </div>
        <div class="form-check col-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="is_preorder_only"
            formControlName="is_preorder_only"
          />
          <label class="form-check-label small" for="is_preorder_only"
            >Pre-Order Only</label
          >
        </div>
        <div class="form-check col-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="is_price_hidden"
            formControlName="is_price_hidden"
          />
          <label class="form-check-label small" for="is_price_hidden"
            >Price Hidden</label
          >
        </div>
        <div class="form-check col-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="is_visible"
            formControlName="is_visible"
          />
          <label class="form-check-label small" for="is_visible">Visible</label>
        </div>
      </div>
      <div class="row m-2">
        <div class="form-check col-12">
          <editor
            formControlName="description"
            apiKey="ovk1m37plzvikz7zrlm3fisni49v778kpoakxkiid37oqthf"
            [init]="init"
          ></editor>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <button
            type="button"
            [disabled]="!bcInventoryForm.valid"
            class="btn btn-sm btn-info w-50"
            (click)="onUpdateInventory()"
          >
            <i class="fas fa-save"></i> Update
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
