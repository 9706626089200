import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/utils/services/common.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { MantleOrderListComponent } from 'src/app/views/mantle-order/mantle-order-list/mantle-order-list.component';

@Component({
  selector: 'channel-status-summary',
  templateUrl: './channel-status-summary.component.html',
  styleUrls: ['./channel-status-summary.component.scss'],
})
export class ChannelStatusSummaryComponent implements OnInit {
  @Input() status_summary: any;

  constructor(
    private commonService: CommonService,
    private orderService: OrderService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {}

  onViewStatusDetails(order_status_id) {
    var modalRef = this.modalService.open(MantleOrderListComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    });

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'ORDER-LIST-MODAL') {
        modalRef.close();
      }
    });
    this.orderService.searchOrder.next({
      order_sources: ['7', '8', '9'],
      order_status: [order_status_id],
    });

    this.orderService.order_list_component_initialized.subscribe(() => {
      this.orderService.searchOrder.next({
        order_sources: ['7', '8', '9'],
        order_status: [order_status_id],
      });

      this.orderService.order_list_component_initialized = new Subject();
    });
  }
}
