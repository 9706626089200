import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { LOCAL_TORAGE_KEYS, SYSTEM_SETTINGS } from 'src/app/utils/enums/mantle-enums';
import { AppConfig } from 'src/app/utils/services/app.config';
import { AppService } from 'src/app/utils/services/app.service';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { StorageService } from 'src/app/utils/services/storage.service';
declare var $: any;
@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  user_details: any = {};
  public authType: any;
  logoImage: any;

  constructor(
    public appService: AppService,
    private config: AppConfig,
    private auth0Service: Auth0Service,
    private storageService: StorageService,
    private _commonService: CommonService
  ) {
    this.authType = this.config.getConfig('authType');
    this._commonService.systemSettingsUpdated.subscribe((_) => {
      this.initData();
    });
  }

  ngOnInit() {
    $('[data-widget="treeview"]').Treeview('init');
    this.initData();
  }

  initData = () => {
    if (this.authType == 'auth0') {
      let auth0Profile = JSON.parse(localStorage.getItem(LOCAL_TORAGE_KEYS.USER_PROFILE)); //this.auth0Service.userProfile
      if (auth0Profile != null) {
        this.user_details = {
          name: auth0Profile.name,
          image: auth0Profile.picture,
        };
      }
    } else {
      this.user_details = JSON.parse(localStorage.getItem('USER_PROFILE'));
    }
    this.logoImage = this.storageService.getItem(SYSTEM_SETTINGS.IMAGE_LOGO);
  };

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }
}
