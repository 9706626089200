import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, map, catchError, of } from 'rxjs';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { RewardPointService } from 'src/app/utils/services/rewardpoint.service';
import {
  deleteProductReward,
  deleteProductRewardSuccess,
  loadProductReward,
  loadProductRewardFailure,
  loadProductRewardSuccess,
  upsertProductReward,
  upsertProductRewardSuccess,
} from './product_reward_points.actions';

@Injectable()
export class ProductRewardPointEffects {
  constructor(
    private actions$: Actions,
    private rewardPointService: RewardPointService,
    private store: Store<AppState>
  ) {}

  loadProductReward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProductReward),
      mergeMap(() =>
        this.rewardPointService.getProductPoints().pipe(
          map((products) => loadProductRewardSuccess({ products })),
          catchError((error) => of(loadProductRewardFailure({ error })))
        )
      )
    )
  );

  upsertTiered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertProductReward),
      mergeMap((requestPayload) =>
        this.rewardPointService.upsertProductPoints(requestPayload).pipe(
          map((productReward) => upsertProductRewardSuccess({ productReward })),
          catchError((error) => of(loadProductRewardFailure(error)))
        )
      )
    )
  );

  deleteProductReward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProductReward),
      mergeMap((point_product_id) =>
        this.rewardPointService
          .deleteProductPoints(point_product_id.point_product_id)
          .pipe(
            map(() => deleteProductRewardSuccess(point_product_id)),
            catchError((error) => of(loadProductRewardFailure(error)))
          )
      )
    )
  );
}
