import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Auth0Service } from 'src/app/utils/services/auth0.service';

@Component({
  selector: 'app-auth0-callback',
  templateUrl: './auth0-callback.component.html',
  styleUrls: ['./auth0-callback.component.scss'],
})
export class Auth0CallbackComponent implements OnInit {
  constructor(
    private auth0: Auth0Service,
    public auth: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  ngOnInit(): void {
    this.auth0.handleAuth();
  }
  ngOnDestroy() {
    this.spinner.hide();
  }
}
