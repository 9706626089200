<div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
        <div class="container">
            <div id="shipment-stepper" class="bs-stepper">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#validate-address">
                        <button class="step-trigger">
                          <span class="bs-stepper-circle">1</span>
                          <span class="bs-stepper-label">Validate Addresses</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#create-shipment">
                      <button class="step-trigger">
                        <span class="bs-stepper-circle">2</span>
                        <span class="bs-stepper-label">Create Shipment</span>
                      </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#generate-label">
                      <button class="step-trigger">
                        <span class="bs-stepper-circle">3</span>
                        <span class="bs-stepper-label">Generate Label</span>
                      </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <div id="validate-address" class="content">
                        <endicia-validate-address [(orderDetails)]="orderDetails"></endicia-validate-address>
                        <button (click)="next()" class="btn btn-primary">Next</button>
                    </div>
        
                    <div id="create-shipment" class="content">
                        <endicia-create-shipment [(orderDetails)]="orderDetails"></endicia-create-shipment>
                        <!--button (click)="next()" class="btn btn-primary">Next</button-->
                    </div>
        
                    <div id="generate-label" class="content text-center">
                        <endicia-generate-label></endicia-generate-label>
                        <button (click)="next()" class="btn btn-primary">Finish</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
