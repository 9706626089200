<div class="card">
  <div class="card-header">
    <h3 class="card-title small">Reports</h3>
    <div class="card-tools">
      <button
        *ngIf="isAdmin"
        type="button"
        class="btn btn-success btn-sm mr-4"
        (click)="adminReport()"
      >
        New Report<i class="fas fa-plus ml-2"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 table-responsive small">
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th id="name">Report Name</th>
              <th id="report_type.description">Type</th>
              <th id="help_text">Description</th>
              <th>Run</th>
              <th *ngIf="isAdmin">Admin</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let report of reportsList">
              <tr>
                <td>{{ report['name'] }}</td>
                <td>{{ report.report_type.description }}</td>
                <td>{{ report.help_text }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    (click)="runReport(report.report_id)"
                  >
                    <i class="fas fa-play"></i> Run
                  </button>
                </td>
                <td *ngIf="isAdmin">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    (click)="adminReport(report.report_id)"
                  >
                    <i class="fas fa-cog"></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
