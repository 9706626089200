import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductRewardPointState } from 'src/app/utils/interfaces/app.state';

export const selectProductRewardPointState = createFeatureSelector<ProductRewardPointState>(
  'productRewardPoint'
);
export const selectStateStatus = createSelector(
  selectProductRewardPointState,
  (state) => state.status
);

export const selectProductList = createSelector(
  selectProductRewardPointState,
  (state) => state.products
);

export const selectedActiveRecord = createSelector(
  selectProductRewardPointState,
  (state) => state.activeRecord
);
export const selectGetProductPointByid = (point_product_id) =>
  createSelector(selectProductRewardPointState, (state) =>
    state.products.find((t) => t.point_product_id === point_product_id)
  );
