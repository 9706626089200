<div class="row">
  <div class="col-md-12 small table-responsive">
    <table class="table table-striped dataTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Merchant</th>
          <th>Description</th>
          <th>Enabled</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let terminal of terminalList">
          <tr>
            <td>{{ terminal.name }}</td>
            <td>{{ terminal.merchant?.merchant_name }}</td>
            <td>{{ terminal.description }}</td>
            <td>{{ terminal.enabled }}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-outline btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Action
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onCashRegister(terminal.terminal_id)"
                  >
                    <i class="fas fa-trash"></i> Assign to Cash Registers
                  </button>
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onEdit(terminal.terminal_id)"
                  >
                    <i class="fas fa-edit"></i> Enable / Disable
                  </button>
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onDelete(terminal.terminal_id, terminal.name)"
                  >
                    <i class="fas fa-trash"></i> Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
