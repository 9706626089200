import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AppService } from '../../utils/services/app.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { AppConfig } from 'src/app/utils/services/app.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: UntypedFormGroup;
  public isAuthLoading = false;
  public authType:any ="";
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private auth0Service: Auth0Service,
    private config: AppConfig
  ) {
    this.authType = this.config.getConfig("authType")
  }

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
    });
  }

  login() {
    if(this.authType == 'auth0'){
      this.auth0Service.login()
    }
    else{
      if (this.loginForm.valid) {
        this.appService.login(this.loginForm.value);
      } else {
        this.toastr.error('Invalid Inputs!', 'Invalid Login Inputs!');
      }
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }
}
