import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { UserService } from 'src/app/utils/services/user.service';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input() user_id:any
  @Input() allowPwdChange:boolean = false
  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,) { }

  

  userForm = this.fb.group({
    user_id:[],
    email:['',Validators.required],
    name:['',Validators.required],
    short_name:[],
    active:['1'],
    role:[],
    main_phone:[],
    password:[],
    password_confirm:[]

  });
  ngOnInit(): void {
    this.init_data()
  }

  init_data(){
    if(this.user_id){
      const obs = this.userService.getUserById(this.user_id);
      obs.subscribe(res=>{
        this.userForm.patchValue(res);

        if(this.allowPwdChange){
          this.userForm.disable()
          this.userForm.controls['password'].enable();
          this.userForm.controls['password_confirm'].enable();
        }
      },err=>{
        
        this.toastr.error("An Error Occurred")
      })
    }
    else{
      this.allowPwdChange = true
    }
  }

  createUser(){
    if(this.validatePassword()){
      this.spinner.show()
      const obs = this.userService.registerUser(this.userForm.value)
      obs.subscribe(res=>{
        this.spinner.hide()
        this.userForm.patchValue(res)
        this.user_id = res.user_id
        this.toastr.success("User Successfully Created")
      },err=>{
        this.spinner.hide()
        
        this.toastr.error("An Error Occurred")
      })
    }
  }

  updateUser(){
    this.spinner.show()
    const obs = this.userService.updateUser(this.userForm.value)
    obs.subscribe(res=>{
      this.spinner.hide()
      this.toastr.success("User Successfully Updated")
    },err=>{
      this.spinner.hide()
      
      this.toastr.error("An Error Occurred")
    })
  }
  

  changePassword(){
    if(this.validatePassword()){
      this.spinner.show()
      const obs = this.userService.changeUserPassword({...{user_id:this.user_id}, ...this.userForm.value})
      obs.subscribe(res=>{
        this.spinner.hide()
        this.toastr.success("User password Successfully changed")
      },err=>{
        this.spinner.hide()
        
        this.toastr.error("An Error Occurred")
      })
    }
  }

  validatePassword():boolean{
    const frmUser = this.userForm.value
    if(frmUser.password != '' && frmUser.password != undefined && frmUser.password === frmUser.password_confirm){
     
      return true
    }
    this.toastr.error("The password and confirmation password do not match")
    return false
  }

  closeModal(){
    this.commonService.modal_close.next('MODAL-USER-PROFILE')
  }
}
