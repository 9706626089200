<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h5>Manage Orders</h5>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
</section>

  <!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-tools">
                            <div class="row">
                                <div class="col-12">
                                    <mantle-btn-new-customer class="p-2"></mantle-btn-new-customer>
                                    <mantle-btn-new-order></mantle-btn-new-order>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <mantle-pos-plugins></mantle-pos-plugins>
                            </div>
                        </div>
                        <!-- /.card-tools -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mantle-order-search></mantle-order-search>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mantle-order-actions></mantle-order-actions>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mantle-order-list></mantle-order-list>
            </div>
        </div>    
    </div>
</section>