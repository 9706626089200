import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PluginService {

  constructor(private apiService: ApiService) { }

  getPlugins(): Observable<any> {
    return this.apiService.get('/api/v1/plugin');
  }

  getPluginById(id): Observable<any> {
    return this.apiService.get('/api/v1/plugin/' + id);
  }

  createPlugin(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/plugin', requestPayload);
  }

  updatePlugin(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/plugin', requestPayload);
  }

  filterPlugin(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/plugin/filter', requestPayload);
  }
}
