import { Component, Input, OnInit } from '@angular/core';
import formConfig from '../../../../../configs/forms/form_currency_denominations.json';
import { FormArray, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { Subscription } from 'rxjs';
import { CashRegisterService } from 'src/app/utils/services/cash-register.service';

@Component({
  selector: 'cash-denomination',
  templateUrl: './cash-denomination.component.html',
  styleUrls: ['./cash-denomination.component.scss'],
})
export class CashDenominationComponent implements OnInit {
  @Input() cash_register_id;
  @Input() isCashRegisterLog: boolean = false;
  @Input() cashRegisterLogDetails: any;

  cashForm = this.fb.group({});

  fields: FormlyFieldConfig[];
  model: any = {};
  options: FormlyFormOptions = {
    formState: {},
  };
  private formUpdatesSubscription: Array<Subscription> = [];
  totalAmount: number = 0;
  closingBalanceAmount: number = 0;
  amountMargin: number = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private settingsService: SettingsService,
    private cashRegisterService: CashRegisterService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.fields = formConfig;
    if (!this.isCashRegisterLog) {
      this.spinner.show();
      this.settingsService.getCurrencyDenominations().subscribe(
        (res) => {
          const value = {
            cash_denominations: res,
          };
          this.model = { ...this.model, ...value };

          this.cashForm.patchValue({
            cash_denominations: res,
          });

          setTimeout(() => {
            this.onFormvaluesUpdated();
          }, 1);
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error);
        }
      );

      this.cashRegisterService
        .getClosingBalance(this.cash_register_id)
        .subscribe(
          (res) => {
            this.closingBalanceAmount = res.closing_balance;
            this.calculateTotal();
          },
          (err) => {
            this.toastr.warning('Unabale to Get Cash Register Balance');
          }
        );
    } else {
      this.model = this.cashRegisterLogDetails;
      this.totalAmount = this.cashRegisterLogDetails?.closing_balance_manual;
      this.closingBalanceAmount = this.cashRegisterLogDetails?.closing_balance;
      this.amountMargin = this.cashRegisterLogDetails?.balance_margin;
      this.cashForm.patchValue(this.cashRegisterLogDetails);
      setTimeout(() => {
        this.cashForm.disable();
      }, 1);
    }
  }

  onFormvaluesUpdated = () => {
    var fArray = this.cashForm.get('cash_denominations') as FormArray;

    fArray.controls.forEach((control) => {
      var sub = control.get('count').valueChanges.subscribe((value) => {
        this.calculateTotal();
      });
      this.formUpdatesSubscription.push(sub);
    });
  };

  calculateTotal = () => {
    setTimeout(() => {
      if (this.cashForm.valid) {
        const formValue = this.cashForm.getRawValue();

        this.totalAmount = formValue.cash_denominations?.reduce(
          (prev: any, next: any) => {
            return (
              parseFloat(prev) + parseFloat(next.value) * parseFloat(next.count)
            );
          },
          0
        );

        this.model?.cash_denominations?.map((x, index) => {
          var total = parseFloat(x.value) * parseFloat(x.count);
          if (isNaN(total)) {
            total = 0.0;
          } else {
            total = parseFloat(total.toFixed(2));
          }
          ((this.cashForm.get('cash_denominations') as FormArray).at(
            index
          ) as FormGroup).patchValue({
            total: total,
          });
          return x;
        });

        this.amountMargin = this.totalAmount - this.closingBalanceAmount;
        this.cashForm.patchValue({
          total_amount: this.totalAmount,
          balance_margin: this.amountMargin,
        });
      }
    }, 1);
  };

  save() {
    this.closeModal(1);
  }

  closeModal(action_id = 0) {
    this.formUpdatesSubscription?.forEach((sub) => {
      sub?.unsubscribe();
    });

    this.commonService.modal_close.next({
      name: MANTLE_MODAL_NAME.CASH_DENOMINATION_MODAL,
      data: {
        ...{
          action_id: action_id,
        },
        ...this.cashForm.getRawValue(),
      },
    });
  }
}
