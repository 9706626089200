<div class="small-box">
  <mantle-loading *ngIf="loading"></mantle-loading>
  <div class="inner text-center">
    <p>{{ title }}</p>
    <h3>{{ count }}</h3>
    <span>
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary w-25"
        (click)="isToggled = !isToggled"
      >
        <i
          class="fa fa-angle-double-down"
          aria-hidden="true"
          *ngIf="!isToggled"
        ></i>
        <i
          class="fa fa-angle-double-up"
          aria-hidden="true"
          *ngIf="isToggled"
        ></i>
      </button>
    </span>
    <div *ngIf="isToggled">
      <mantle-inventory-sales
        *ngIf="type == 'quantity_sold'"
        [dataItems]="dataItems"
      ></mantle-inventory-sales>
      <mantle-inventory-po
        *ngIf="type == 'quantity_purchased'"
        [dataItems]="dataItems"
      ></mantle-inventory-po>
      <mantle-inventory-returns
        *ngIf="type == 'quantity_returned'"
        [dataItems]="dataItems"
      ></mantle-inventory-returns>
    </div>
  </div>
</div>
