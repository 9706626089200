<div class="small-box {{ bg }}">
  <mantle-loading *ngIf="loading"></mantle-loading>
  <div class="alert alert-danger" role="alert" *ngIf="hasError">
    {{ errorText }}
  </div>
  <div class="inner">
    <h3>{{ value }}</h3>

    <p>{{ report_name }}</p>
  </div>
  <div class="icon" *ngIf="icon">
    <i class="ion ion-bag"></i>
  </div>
  <div class="small-box-footer">
    <span>&nbsp;</span>
    <a href="{{ link }}" *ngIf="link"
      >{{ linkText }} <i class="fas fa-arrow-circle-right"></i
    ></a>
  </div>
</div>
