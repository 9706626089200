<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title small">{{ reportName }}</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Filter Parameters
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <form [formGroup]="reportForm">
                    <div class="row">
                      <div class="col-12">
                        <formly-form
                          [model]="model"
                          [fields]="fields"
                          [options]="options"
                          [form]="reportForm"
                        ></formly-form>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <button
                          type="button"
                          class="btn btn-outline-success btn-sm"
                          (click)="runReport()"
                          [disabled]="reportForm.invalid"
                        >
                          <i class="fas fa-play"></i> Run Report
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="MantleWebDataRocksContainer"></div>
      </div>
    </div>
  </div>
</section>
