import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { CustomerService } from 'src/app/utils/services/customer.service';
import {
  listCustomers,
  listCustomersSuccess,
  listCustomersFailure,
  addCustomer,
  addCustomerSuccess,
  addCustomerFailure,
  editCustomerSuccess,
  editCustomer,
} from './customer.actions';
import {
  catchError,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { select, Store } from '@ngrx/store';
import { selectfilterParameters } from './customer.selectors';

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private store: Store<AppState>
  ) {}

  listCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listCustomers),
      withLatestFrom(this.store.pipe(select(selectfilterParameters))),
      mergeMap(([filter]) =>
        this.customerService.getCustomers(filter.filterParams).pipe(
          map((customers) => listCustomersSuccess({ customers })),
          catchError((error) => of(listCustomersFailure({ error })))
        )
      )
    )
  );

  addCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCustomer),
      mergeMap((requestPayload) =>
        this.customerService.createCustomer(requestPayload).pipe(
          map((customer) => addCustomerSuccess({ customer })),
          catchError((error) => of(addCustomerFailure({ error })))
        )
      )
    )
  );

  editCustomer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editCustomer),
        mergeMap((requestPayload) =>
          this.customerService.updateCustomer(requestPayload).pipe(
            map((customer) => editCustomerSuccess({ customer })),
            catchError((error) => of(addCustomerFailure({ error })))
          )
        )
      )
    //{ dispatch: true }
  );
}
