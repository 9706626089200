import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-total-product',
  templateUrl: './card-total-product.component.html',
  styleUrls: ['./card-total-product.component.scss']
})
export class CardTotalProductComponent implements OnInit {

  @Input() total_products:any
  constructor() { }

  ngOnInit(): void {
  }

}
