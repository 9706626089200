<div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Product Description Fields</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-6">
          <label class="control-label">Product Line</label>
          <ng-select
            [items]="product_brand"
            [multiple]="false"
            bindLabel="name"
            bindValue="id"
            (change)="changeBrand($event)"
          >
          </ng-select>
        </div>
        <div class="form-group col-6">
          <br />
          <button
            type="button"
            class="btn btn-outline-primary btn-sm w-50 mt-2"
            (click)="onUpdate()"
            *ngIf="brand_id"
          >
            Update
          </button>
        </div>
      </div>
      <div class="row m-2">
        <div class="col-6" *ngIf="brand_id">
            <div class="text-muted">
              <h6>Description Fields</h6>
            </div>
            <tag-input
              [(ngModel)]="descriptionTags"
              [ngModelOptions]="{ standalone: true }"
              [editable]="true"
              (onTagEdited)="onTagEdited($event)"
              (onAdd)="onTagAdded($event)"
              (onRemove)="onTagRemoved($event)"
              secondaryPlaceholder='Fields'
              placeholder='Select Fields'
            >
            <tag-input-dropdown  [appendToBody]="true" [showDropdownIfEmpty]="true"
            
			    [autocompleteItems]="productCatlogFieldMappings" [identifyBy]="'field_name'" [displayBy]="'field_name'">
		    </tag-input-dropdown>
        </tag-input>
          </div>
      </div>
    </div>
  </div>
  