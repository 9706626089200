import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-valuation',
  templateUrl: './card-valuation.component.html',
  styleUrls: ['./card-valuation.component.scss']
})
export class CardValuationComponent implements OnInit {
  @Input() total_products_without_cost:any
  constructor() { }

  ngOnInit(): void {
  }

}
