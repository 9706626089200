import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  MANTLE_MODAL_NAME,
  REPORTING_DEFAULT_OPTIONS,
  REPORTING_DEFAULT_PROPERTY,
  REPORTING_LOOKUP,
} from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { ReportingService } from 'src/app/utils/services/reporting.service';
import formConfig from '../../../../../configs/forms/form_report_definition_default.json';
import { FormArray, UntypedFormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MantleConfirmDialogComponent } from 'src/app/views/mantle-confirm-dialog/mantle-confirm-dialog.component';

@Component({
  selector: 'report-edit-default',
  templateUrl: './report-edit-default.component.html',
  styleUrls: ['./report-edit-default.component.scss'],
})
export class ReportEditDefaultComponent {
  @Input() report_id: any;
  private jsonData: any;
  private webDataRocksConfig?: any;
  reportName: any;
  private webOptions: any;
  private reportDetailsSubscription!: Subscription;
  private reportDataSubscription!: Subscription;
  private tblDataSubscription!: Subscription;
  private rmvFilterSubscription!: Subscription;
  private lookupSubscription!: Subscription;
  pivotTable: any;

  reportForm = this.fb.group({
    report_id: [],
    //options: [],
    //properties: [],
    //report_filters: this.fb.array([]),
  });

  fields: FormlyFieldConfig[];
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      report_type: REPORTING_LOOKUP.REPORT_TYPE,
      dataType: REPORTING_LOOKUP.DATA_TYPES,
      lookupKeys: [],
      lookupValues: {},
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private reportingService: ReportingService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {}

  ngOnInit(): void {
    this.initData();
    this.tblDataSubscription = this.commonService.tabularInputRemoved.subscribe(
      (data) => {
        if (data['name'] == 'report_filters') {
          this.removeFilter(data['index']);
        }
      }
    );

    this.lookupSubscription = this.commonService.getLookupValues().subscribe(
      (res) => {
        let lookupKeys = [];
        delete res.init_load;
        Object.keys(res).forEach((item) => {
          lookupKeys.push({
            value: item,
            label: item,
          });
        });
        this.options.formState.lookupKeys = lookupKeys;
        this.options.formState.lookupValues = this.commonService.lookupToValueLabel(
          res
        );
      },
      (err) => {
      }
    );
  }

  initData() {
    this.fields = formConfig;
    this.loadFormData();
  }

  frmFilters(): FormArray {
    return this.reportForm.get('report_filters') as FormArray;
  }

  removeFilter(index) {
    var filt = this.reportForm.getRawValue().report_filters[index];

    const modalRef = this.mantleModalService.open(
      MantleConfirmDialogComponent,
      MANTLE_MODAL_NAME.CONFIRM_DIALOG,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'sm',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.header = 'Remove Filter Items';
    modalRef.componentInstance.message =
      'By confriming this, report filter item will be deleted';
    this.rmvFilterSubscription = this.commonService.modal_close.subscribe(
      (data: any) => {
        if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
          if (data.data.action_id == 1) {
            if (filt.filter_id != undefined) {
              this.spinner.show();
              this.reportingService.deleteFilter(filt.filter_id).subscribe(
                (res) => {
                  this.spinner.hide();
                },
                (err) => {
                  this.spinner.hide();
                  this.toastr.error(err);
                }
              );
            }
            //this.frmFilters().removeAt(index);
          } else {
            //return back the item to list
            this.model.report_filters.splice(index, 0, filt);
          }
          modalRef.close();
        }
      }
    );
  }

  loadFormData() {
    if (this.report_id) {
      this.spinner.show();
      this.reportingService.getReportById(this.report_id).subscribe(
        (res) => {
          res.options = JSON.stringify(res.options);
          res.properties = JSON.stringify(res.properties);
          this.spinner.hide();
          this.patchForm(res);
          this.initReportData(res.report_filters);
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
    } else {
      this.initReportData();
    }
  }

  patchForm(value) {
    // this.frmFilters().clear();
    // value.report_filters?.forEach((element) => {
    //   this.frmFilters().push(
    //     this.fb.group({
    //       filter_id: [],
    //       report_id: [],
    //     })
    //   );
    // });
    value.report_filters?.forEach((filter, index) => {
      if (this.commonService.isValidJSON(filter.default_value)) {
        value.report_filters[index].default_value = JSON.parse(
          filter.default_value
        );
      }
    });
    this.model = { ...this.model, ...value };

    this.reportForm.patchValue(value);
  }

  save() {
    this.spinner.show();
    let payload = this.reportForm.getRawValue();
    if (this.commonService.isValidJSON(payload.options))
      payload.options = JSON.parse(payload.options);
    if (this.commonService.isValidJSON(payload.properties))
      payload.properties = JSON.parse(payload.properties);
    if (this.commonService.isValidJSON(payload.properties?.dataSource))
      payload.properties.dataSource.data = [];
    //if(this.commonService.isValidJSON(payload.report_filters))
    payload?.report_filters?.forEach((filter, index) => {
      if (this.commonService.isValidJSON(filter.default_value)) {
        payload.report_filters[index].default_value = JSON.stringify(
          filter.default_value
        );
      }
    });

    if (payload.report_id) {
      return this.update(payload);
    }

    delete payload.report_id;
    this.reportingService.createReport(payload).subscribe(
      (res) => {
        this.report_id = res.report_id;
        this.spinner.hide();
        this.initData();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error);
      }
    );
  }

  update(payload) {
    this.spinner.show();
    this.reportingService.updateReport(payload).subscribe(
      (res) => {
        this.report_id = res.report_id;
        this.initData();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error);
      }
    );
  }

  initReportData(reportFilters = null) {
    if (this.report_id) {
      let filterParams = {};
      reportFilters?.forEach((filter, index) => {
        filterParams[filter.name] = filter.default_value;
      });

      this.spinner.show();
      this.reportDataSubscription = this.reportingService
        .getReportData(this.report_id, filterParams)
        .subscribe(
          (res) => {
            this.jsonData = res;

            this.reportDetailsSubscription = this.reportingService
              .getReportById(this.report_id)
              .subscribe(
                (res) => {
                  this.webDataRocksConfig =
                    Object.keys(res.properties).length === 0
                      ? REPORTING_DEFAULT_PROPERTY
                      : res.properties;
                  this.webDataRocksConfig.options =
                    Object.keys(res.properties).length === 0
                      ? REPORTING_DEFAULT_OPTIONS
                      : res.options;
                  this.webDataRocksConfig.dataSource.data = this.jsonData;
                  this.setReport();
                  this.spinner.hide();
                },
                (error) => {
                  this.spinner.hide();
                  this.toastr.error(error);
                }
              );
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(error);
          }
        );
    }
    //For new default report varibales
    else {
      this.webDataRocksConfig = REPORTING_DEFAULT_PROPERTY;
      this.webDataRocksConfig.options = REPORTING_DEFAULT_OPTIONS;
      this.patchForm({
        options: JSON.stringify(REPORTING_DEFAULT_OPTIONS),
        properties: JSON.stringify(REPORTING_DEFAULT_PROPERTY),
      });
    }
  }

  setReport(): void {
    setTimeout(() => {
      this.pivotTable = new window.WebDataRocks({
        container: `#MantleWebDataRocksContainer`,
        width: '100%',
        height: '100%',
        toolbar: true,
        beforetoolbarcreated: this.customizeToolbar,
        report: this.webDataRocksConfig,
      });

      this.pivotTable.on('reportchange', () => {
        var options = this.pivotTable.getOptions();
        var report = this.pivotTable.getReport();

        this.patchForm({
          options: JSON.stringify(options),
          properties: JSON.stringify(report),
        });
      });

      /* this.pivotTable.exportTo('excel', {
        filename: `${this.reportForm.value.name}-${Date.now()}.xlsx`,
      }); */
    }, 1000);
  }

  customizeToolbar(toolbar) {
    const deleteTools = ['wdr-tab-connect', 'wdr-tab-open', 'wdr-tab-save'];
    const filename = `${Date.now()}`;
    var tabs = toolbar.getTabs();

    toolbar.getTabs = function () {
      tabs.forEach((tab, index) => {
        if (deleteTools.indexOf(tab.id) != -1) delete tabs[index];

        if (tab.id === 'wdr-tab-export') {
          let exportToPdfTab = tab.menu.find(
            (tab) => tab.id == 'wdr-tab-export-pdf'
          );
          exportToPdfTab.handler = () => {
            this.pivot.exportTo('pdf', {
              filename: `${filename}.pdf`,
            });
          };
          let exportToExcelTab = tab.menu.find(
            (tab) => tab.id == 'wdr-tab-export-excel'
          );
          exportToExcelTab.handler = () => {
            this.pivot.exportTo('excel', {
              filename: `${filename}.xlsx`,
            });
          };
          let exportTohtmlTab = tab.menu.find(
            (tab) => tab.id == 'wdr-tab-export-html'
          );
          exportTohtmlTab.handler = () => {
            this.pivot.exportTo('html', {
              filename: `${filename}.html`,
            });
          };
        }
      });

      return tabs;
    };
  }

  delete() {
    const modalRef = this.mantleModalService.open(
      MantleConfirmDialogComponent,
      MANTLE_MODAL_NAME.CONFIRM_DIALOG,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'sm',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.header = 'Delete Report';
    modalRef.componentInstance.message =
      'By confriming this, this report will be deleted';
    this.rmvFilterSubscription = this.commonService.modal_close.subscribe(
      (data: any) => {
        if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
          if (data.data.action_id == 1) {
            if (this.report_id) {
              this.spinner.show();
              this.reportingService.deleteReport(this.report_id).subscribe(
                (res) => {
                  this.spinner.hide();
                  this.closeModal();
                },
                (err) => {
                  this.spinner.hide();
                  this.toastr.error(err);
                }
              );
            } else {
              this.closeModal();
            }
          }
          modalRef.close();
        }
      }
    );
  }
  ngOnDestroy() {
    this.tblDataSubscription?.unsubscribe();
    this.rmvFilterSubscription?.unsubscribe();
  }

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.EDIT_DEFAULT_REPORT_MODAL
    );
  }
}
