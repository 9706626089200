import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-settings-endicia',
  templateUrl: './settings-endicia.component.html',
  styleUrls: ['./settings-endicia.component.scss'],
})
export class SettingsEndiciaComponent implements OnInit {
  @ViewChild('nav') nav;
  active = 1;
  constructor() {}

  ngOnInit(): void {}
}
