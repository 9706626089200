import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import queryString from 'query-string';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private userTimezone: any;
  constructor(
    private apiService: ApiService,
    private commonService: CommonService
  ) {
    this.userTimezone = this.commonService.getTimeZone();
  }

  getReports(filterParams = null): Observable<any> {
    const query_param = queryString.stringify(filterParams);
    return this.apiService.get(`/api/v1/reports?${query_param}`);
  }

  getReportById(id): Observable<any> {
    return this.apiService.get(`/api/v1/report/${id}`);
  }

  getReportData(id, params = {}): Observable<any> {
    params = { ...params, ...{ timezone: this.userTimezone } };
    const query_param = queryString.stringify(params);
    return this.apiService.get(`/api/v1/report/${id}/data?${query_param}`);
  }
  createReport(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/report`, requestPayload);
  }
  updateReport(requestPayload): Observable<any> {
    return this.apiService.patch(`/api/v1/report`, requestPayload);
  }
  deleteReport(report_id: any): Observable<any> {
    return this.apiService.delete(`/api/v1/report/${report_id}`);
  }
  deleteFilter(filter_id: any): Observable<any> {
    return this.apiService.delete(`/api/v1/report/filter/${filter_id}`);
  }
}
