<a (click)="logout()" class="btn btn-default btn-flat float-right "> <i class="fas fa-sign-out-alt"></i> </a>
<!--li class="nav-item dropdown user-menu">
  <a
    class="nav-link dropdown-toggle"
    data-toggle="dropdown"
    (click)="toggleDropdownMenu()"
  >
    <img
      [src]="user.image"
      class="user-image img-circle elevation-2"
      alt="User Image"
    />
  </a>
  <ul #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    < !-- User image ->
    <li class="user-header bg-primary">
      <img [src]="user.image" class="img-circle elevation-2" alt="User Image" />

      <p>
        Alexander Pierce - Web Developer
        <small>Member since Nov. 2012</small>
      </p>
    </li>
    <!-- Menu Body ->
    <li class="user-body">
      <div class="row">
        <div class="col-4 text-center">
          <a href="#">Followers</a>
        </div>
        <div class="col-4 text-center">
          <a href="#">Sales</a>
        </div>
        <div class="col-4 text-center">
          <a href="#">Friends</a>
        </div>
      </div>
      <!-- /.row ->
    </li>
    <!-- Menu Footer->
    <li class="user-footer">
      <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
        Profile
      </a>
      <a (click)="logout()" class="btn btn-default btn-flat float-right"
        >Sign out</a
      >
    </li>
  </ul>
</li-->
