<div class="col-prod-item prod-item-colour side-card" [ngStyle]="cardStyle">
    <div class="row">

        <div class="col-sm-12 img-card">
            <img [src]="cardImage">
        </div>
        <ng-container #fieldComponent></ng-container>
  </div>

</div>
