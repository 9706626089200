<form [formGroup]="orderForm" (ngSubmit)="submitOrder()">
  <div class="card" style="max-height: fit-content">
    <div class="card-header">
      <h3 class="card-title small">Order Details</h3>
      <div class="row" *ngIf="isPosMode">
        <div class="col-2">
          <h6>{{ posPluginData.name }}</h6>
        </div>
        <div class="col-10" *ngIf="activeRegisters.length > 0">
          <div class="row" *ngIf="displayCashRegister">
            <ng-container
              *ngFor="let register of activeRegisters; let i = index"
            >
              <div class="col-4">
                <ng-toggle
                  [value]="register.active"
                  [width]="40"
                  [color]="{ unchecked: '#939da2', checked: '#1ba841' }"
                  [labels]="{ checked: '', unchecked: '' }"
                  (change)="toggleActiveRegister($event, register, i)"
                ></ng-toggle>
                {{ register.description }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="cash_register_id == null && order_id == undefined">
        <div class="row">
          <div class="alert alert-danger" role="alert">
            Your profile does not have an active cash register.

            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="onCashRigisterOverride()"
            >
              Override Cash Register
            </button>
          </div>
        </div>
      </ng-container>
      <div class="card-tools">
        <ng-container *ngIf="isPosMode">
          <button
            type="button"
            class="btn btn-sm btn-outline"
            (click)="openFullscreen()"
          >
            <i class="fas fa-expand-alt"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-outline"
            (click)="closeFullscreen()"
          >
            <i class="fas fa-compress-alt"></i>
          </button>
        </ng-container>
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="card">
        <div class="card-header">
          <div class="row" *ngIf="orderForm.value.order_number">
            <div class="col-md-12">
              <h6>Order No#: {{ orderForm.value.order_number }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <div class="row">
                <div class="col-4">
                  <label class="control-label small">Customer</label>
                </div>
                <div class="col-8">
                  <h6 [hidden]="isNewRecord">
                    {{ customerData?.first_name }}
                    {{ customerData?.last_name }}
                  </h6>
                  <!--input type="text" class="form-control form-control-sm" /-->
                  <customer-search-input
                    [hidden]="!isNewRecord"
                    (change)="customerSelected($event)"
                    ([customerId])="(customerData?.customer_id)"
                    formControlName="customer_id"
                  ></customer-search-input>
                </div>
              </div>
              <!--div class="row" *ngIf="canEdit">
                <div class="col-12">
                  <span class="small font-italic"
                    >Search using customer name / email / phone / nick
                    name</span
                  >
                </div>
              </div-->
            </div>
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                (click)="onViewCustomer()"
                *ngIf="customerData?.customer_id"
              >
                <i class="far fa-eye"></i>
              </button>

              <!--button
                type="button"
                class="btn btn-sm btn-secondary ml-2"
                (click)="onViewCustomer(true)"
              >
                New Customer
                <i class="fas fa-user-plus"></i>
              </button!-->
              <mantle-btn-new-customer class="ml-2"></mantle-btn-new-customer>
            </div>
            <div class="col-md-3">
              <div class="btn-toolbar" role="toolbar">
                <ng-cotainer *ngIf="canEdit">
                  <div
                    class="btn-group mr-2 mb-2"
                    role="group"
                    *ngIf="awaitingPayment"
                  >
                    <button
                      type="button"
                      class="btn btn-sm btn-secondary float-right"
                      (click)="onConfirmPayment()"
                    >
                      <i class="far fa-money-bill-alt"></i> Confirm Payment
                    </button>
                  </div>
                  <div class="btn-group mr-2 mb-2" role="group">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      (click)="submitOrder()"
                    >
                      <i class="fas fa-save"></i> Confirm Order
                    </button>
                  </div>
                  <div class="btn-group mr-2 mb-2" role="group">
                    <button
                      type="button"
                      class="btn btn-sm btn-info float-right"
                      (click)="submitOrder(true)"
                      *ngIf="canEdit"
                    >
                      <i class="fas fa-envelope-open-text"></i> Save as Draft
                    </button>
                  </div>
                </ng-cotainer>
                <div class="btn-group mr-2 mb-2" role="group">
                  <input
                    title="Auto Print"
                    type="checkbox"
                    class="select-checkbox"
                    [(ngModel)]="autoPrintReceipt"
                    [ngModelOptions]="{ standalone: true }"
                  />

                  <button
                    type="button"
                    class="btn btn-sm btn-secondary float-right"
                    *ngIf="order_id"
                    (click)="printReceipt(true)"
                  >
                    <i class="far fa-print-alt"></i> Print Receipt
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <fieldset [disabled]="!canEdit">
          <div class="card-body">
            <!--ng-container *ngIf="isCustomerSelected"-->
            <ng-container>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="control-label small">Order Status</label>
                  <ng-select
                    [items]="orderStatus"
                    bindLabel="description"
                    bindValue="order_status_id"
                    formControlName="order_status_id"
                    style="height: calc(1.8125rem + 2px)"
                  >
                  </ng-select>
                </div>
                <div class="form-group col-md-3">
                  <label class="control-label small">Order Source</label>

                  <ng-select
                    [items]="orderSource"
                    bindLabel="description"
                    bindValue="order_source_id"
                    formControlName="order_source_id"
                    style="height: calc(1.8125rem + 2px)"
                  >
                  </ng-select>
                </div>
                <div class="form-group col-md-3">
                  <label class="control-label small">Payment Method</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="payment_method_id"
                  >
                    <ng-container *ngFor="let payment of paymentMethod">
                      <option value="{{ payment.payment_method_id }}">
                        {{ payment.description }}
                      </option>
                    </ng-container>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label class="control-label small">Order Date</label>
                  <input
                    type="datetime-local"
                    class="form-control form-control-sm"
                    formControlName="order_date"
                    IsoDateLocal
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <!-- ORDER ITEMS-->
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <order-product-search
                            [(itemRef)]="itemRef"
                            [notDialog]="true"
                          ></order-product-search>
                        </div>
                      </div>
                      <table class="table table-striped small table-responsive">
                        <thead>
                          <tr>
                            <th scope="col" width="25%">Item</th>
                            <th scope="col">Cost</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Stock</th>
                            <th scope="col">Reserve</th>
                            <th scope="col">Total</th>
                            <th scope="col">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container
                            *ngFor="let item of orderItems; index as i"
                          >
                            <tr
                              [ngClass]="
                                item.has_errors
                                  ? 'table-danger'
                                  : item.has_warning
                                  ? 'table-warning'
                                  : ''
                              "
                            >
                              <td>
                                <mantle-loading
                                  *ngIf="item.loading_inventory"
                                ></mantle-loading>
                                {{ item.item_description }}
                                <!--ng-container
                                  *ngIf="item.edit && !isPosMode"
                                >
                                  <button
                                    type="button"
                                    id="{{ i }}"
                                    class="btn btn-sm btn-primary-outline"
                                    (click)="onSearchOrderItem($event, i)"
                                  >
                                    <i class="fas fa-search"></i> Find
                                    Product
                                  </button>
                                </ng-container!-->

                                <ng-container *ngIf="item.edit">
                                  <div class="input-group mb-md-3">
                                    <!--div class="input-group-prepend">
                                      <button
                                        type="button"
                                        id="{{ i }}"
                                        class="btn btn-sm btn-primary-outline input-group-text"
                                        (click)="
                                          onSearchOrderItem($event, i)
                                        "
                                      >
                                        <i class="fas fa-search"></i>
                                      </button>
                                    </div-->

                                    <input
                                      #upcInput
                                      type="text"
                                      [(value)]="item.upc"
                                      [(ngModel)]="item.upc"
                                      placeholder="UPC"
                                      autofocus
                                      [ngModelOptions]="{
                                        standalone: true
                                      }"
                                      class="form-control form-control-sm float-right"
                                      (change)="searchProductByUpc($event, i)"
                                      (keydown.enter)="
                                        searchProductByUpc($event, i)
                                      "
                                    />
                                  </div>
                                </ng-container>
                              </td>
                              <td *ngIf="!item.edited">
                                $
                                {{ item.unit_price }}
                              </td>
                              <td *ngIf="item.edited" class="text-left">
                                <div class="form-group row">
                                  <div class="col-md-6">
                                    {{ item.currency_code }}
                                  </div>
                                  <div class="col-md-6">
                                    <input
                                      type="number"
                                      [(value)]="item.unit_price"
                                      [(ngModel)]="item.unit_price"
                                      [ngModelOptions]="{
                                        standalone: true
                                      }"
                                      class="form-control form-control-sm float-right"
                                      (change)="changePrice($event, i)"
                                      min="0"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="form-group row">
                                  <div class="col-md-6">
                                    <input
                                      type="number"
                                      [(value)]="item.quantity"
                                      [(ngModel)]="item.quantity"
                                      [ngModelOptions]="{
                                        standalone: true
                                      }"
                                      class="form-control form-control-sm float-right"
                                      (change)="changeQuantity($event, i)"
                                      min="0"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>{{ item.stock }}</td>
                              <td>{{ item.reserve }}</td>
                              <td>$ {{ item.total }}</td>
                              <td>
                                <div class="btn-toolbar" role="toolbar">
                                  <div class="btn-group mr-2" role="group">
                                    <button
                                      *ngIf="!item.edited || canEdit"
                                      type="button"
                                      class="btn btn-sm btn-primary mr-2"
                                      (click)="onEditOrderItem($event, i)"
                                    >
                                      <i class="fas fa-edit"></i>
                                    </button>

                                    <div class="dropdown">
                                      <button
                                        class="btn btn-outline btn-sm dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i class="fas fa-ellipsis-h"></i>
                                      </button>
                                      <div
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <button
                                          type="button"
                                          class="dropdown-item btn btn-sm"
                                          (click)="
                                            onDeleteOrderItem(
                                              i,
                                              item.order_item_id,
                                              item.order_id
                                            )
                                          "
                                          *ngIf="canEdit"
                                        >
                                          <i
                                            class="fas fa-trash-alt btn-danger"
                                          ></i>
                                          Remove Item
                                        </button>
                                        <button
                                          type="button"
                                          class="dropdown-item btn btn-sm"
                                          (click)="onResetItemPrice($event, i)"
                                        >
                                          <i class="fas fa-file-invoice"></i>
                                          Use Default Price
                                        </button>
                                        <button
                                          type="button"
                                          class="dropdown-item btn btn-sm"
                                          (click)="onViewInventory($event, i)"
                                        >
                                          <i class="far fa-address-card"></i>
                                          Product Inventory
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="card-footer text-muted">
                      <div class="row">
                        <div class="col-4">
                          <button
                            type="button"
                            *ngIf="canEdit"
                            class="btn btn-secondary btn-sm"
                            (click)="onAddOrderItem()"
                          >
                            <i class="fas fa-cart-plus"></i> Add Item(s)
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <div class="row">
                            <div class="col-md-4">
                              <h5 style="float: left">
                                {{
                                  orderForm.value.order_total
                                    | currency: 'USD':'symbol':'1.2-2'
                                }}
                              </h5>
                            </div>
                            <div class="col-md-8">
                              <div class="card-tools" style="float: right">
                                <button
                                  type="button"
                                  (click)="onTaxComponent()"
                                  *ngIf="canEdit"
                                  class="btn btn-tool btn-outline-primary btn-sm"
                                >
                                  <i class="fas fa-percent"></i> Tax Component
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="dropdown-divider"></div>

                          <ng-container
                            *ngIf="
                              orderForm.value.to_pay_amount !=
                                defaultCustomerId && canEdit
                            "
                          >
                            <div
                              class="row"
                              *ngIf="customerData?.reward_points >= 1"
                            >
                              <div class="col-md-12">
                                <span class="badge badge-warning w-100">
                                  Reward points available ({{
                                    customerData.reward_points
                                      | currency: 'USD':'symbol':'1.2-2'
                                  }})
                                  <br />
                                </span>

                                <button
                                  type="button"
                                  class="btn btn-tool btn-outline-info btn-sm mt-md-2"
                                  style="float: right"
                                  (click)="redeemPoints('REWARD_POINT')"
                                >
                                  Reward Points Redeemed
                                </button>
                              </div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div
                              class="row mt-4"
                              *ngIf="customerData?.store_credit >= 1"
                            >
                              <div class="col-md-12">
                                <span class="badge badge-warning w-100">
                                  {{
                                    customerData.store_credit
                                      | currency: 'USD':'symbol':'1.2-2'
                                  }}
                                  store credit available.
                                  <br />
                                </span>

                                <button
                                  type="button"
                                  class="btn btn-tool btn-outline-info btn-sm mt-md-2"
                                  style="float: right"
                                  (click)="redeemPoints('STORE_CREDIT')"
                                >
                                  Store Credit Redeemed
                                </button>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="form-group col-md-12 m-0">
                              <label class="control-label small m-0">
                                Amount to Pay:
                                {{
                                  orderForm.value.to_pay_amount
                                    | currency: 'USD':'symbol':'1.2-2'
                                }}
                              </label>
                            </div>
                          </div>
                          <div class="row">
                            <ng-container
                              *ngIf="orderForm.value.payment_method_id == 1"
                            >
                              <div class="row">
                                <div class="form-group col-md-12 m-0">
                                  <label class="control-label small m-0"
                                    >Paid Amount:

                                    <span
                                      class="badge badge-pill badge-warning"
                                      *ngIf="showVerifyPaidAmount"
                                      >Verify Paid Amount
                                      <input
                                        title="Verify Amount"
                                        type="checkbox"
                                        class="select-checkbox ml-6"
                                        [(ngModel)]="verifyPaidAmount"
                                        [ngModelOptions]="{ standalone: true }"
                                    /></span>
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control form-control-sm"
                                    min="0"
                                    formControlName="paid_amount"
                                    (change)="calculateTotal()"
                                  />
                                </div>
                              </div>

                              <div class="row">
                                <div class="form-group col-md-12 m-0">
                                  <label class="control-label small m-0"
                                    >Change Amount:</label
                                  >
                                  <input
                                    type="number"
                                    class="form-control form-control-sm"
                                    formControlName="change_amount"
                                    readonly
                                  />
                                </div>
                              </div>
                            </ng-container>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-12 m-3 form-check">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="is_pct_discount"
                                formControlName="is_pct_discount"
                                (change)="calculateTotal()"
                              />

                              <label
                                class="control-label form-check-label small m-0"
                                for="is_pct_discount"
                              >
                                Use % Based Discount</label
                              >
                            </div>
                          </div>

                          <div
                            class="row"
                            *ngIf="orderForm.value.is_pct_discount != 1"
                          >
                            <div class="form-group col-md-12 m-0">
                              <label class="control-label small m-0"
                                >Discount Amount:</label
                              >
                              <input
                                type="number"
                                min="0"
                                class="form-control form-control-sm"
                                formControlName="discount_total_total"
                                (change)="calculateTotal()"
                              />
                            </div>
                          </div>

                          <div
                            class="row"
                            *ngIf="orderForm.value.is_pct_discount == 1"
                          >
                            <div class="form-group col-md-12 m-0">
                              <label class="control-label small m-0"
                                >Discount %:</label
                              >
                              <input
                                type="number"
                                min="0"
                                class="form-control form-control-sm"
                                formControlName="discount_pct"
                                (change)="calculateTotal()"
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-12 m-0">
                              <label class="control-label small m-0"
                                >Shipment Amount:</label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                formControlName="shipment_cost"
                                (change)="calculateTotal()"
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-12 m-0">
                              <label class="control-label small m-0"
                                >Sales Tax
                                {{ orderForm.value.sale_tax_pct }}
                                %:
                                <span
                                  class="badge badge-pill badge-info"
                                  *ngIf="taxExempted"
                                  >Tax Exempted</span
                                ></label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                formControlName="sale_tax"
                                readonly
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-12 m-0">
                              <label class="control-label small m-0"
                                >Store Credit Amount:</label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                formControlName="store_credit_amount"
                                readonly
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-12 m-0">
                              <label class="control-label small m-0"
                                >Reward Point Amount:</label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                formControlName="redeemed_amount"
                                readonly
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-12 m-0">
                              <label class="control-label small m-0"
                                >Total Amount:</label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                formControlName="order_total"
                                readonly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <span class="small"><b>Billing Address</b></span>

                          <div class="card-tools">
                            <!-- Collapse Button -->
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fas fa-minus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body" id="billingFormCard">
                          <form [formGroup]="billingForm">
                            <div class="row">
                              <div
                                class="col-md-12 form-check"
                                style="margin-right: 20px"
                              >
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  (change)="onCopyCustomerAddress($event, 1)"
                                  id="use_customer_billing"
                                />
                                <label
                                  class="form-check-label small"
                                  for="use_customer_billing"
                                >
                                  Use Customer Address</label
                                >
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >First Name</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_first_name"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Last Name</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_last_name"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label class="control-label small"
                                  >Address Line 1</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_street_address"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label class="control-label small"
                                  >Address Line 2</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_street_address_2"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Town / City</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_city"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Postal Code</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_zipcode"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label small">State</label>
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_state"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Country</label
                                >
                                <!--input type="text" class="form-control form-control-sm" formControlName="billing_country_code"/-->
                                <ng-select
                                  [items]="countries"
                                  bindLabel="country_name"
                                  bindValue="country_code"
                                  formControlName="billing_country_code"
                                >
                                </ng-select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Contact No#</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_phone"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Email Address</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="billing_email"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <span class="small"><b>Shipping Address</b></span>

                          <div class="card-tools">
                            <!-- Collapse Button -->
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fas fa-minus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body" id="shippingFormCard">
                          <form [formGroup]="shipmentForm">
                            <div class="row">
                              <div
                                class="col-md-12 form-check"
                                style="margin-right: 20px"
                              >
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="use_customer_shipping"
                                  (change)="onCopyCustomerAddress($event, 2)"
                                />
                                <label
                                  class="form-check-label small"
                                  for="use_customer_shipping"
                                >
                                  Use Customer Address</label
                                >
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >First Name</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_first_name"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Last Name</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_last_name"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label class="control-label small"
                                  >Address Line 1</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_street_address"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label class="control-label small"
                                  >Address Line 2</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_street_address_2"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Town / City</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_city"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Postal Code</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_zipcode"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label small">State</label>
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_state"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Country</label
                                >
                                <ng-select
                                  [items]="countries"
                                  bindLabel="country_name"
                                  bindValue="country_code"
                                  formControlName="shipment_country_code"
                                >
                                </ng-select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Contact No#</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_phone"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label small"
                                  >Email Address</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="shipment_email"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <span class="small"><b>Staff Note</b></span>

                          <div class="card-tools">
                            <!-- Collapse Button -->
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fas fa-minus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body" id="staffNoteCard">
                          <div class="row">
                            <div class="form-group col-md-12">
                              <textarea
                                class="form-control form-control-sm"
                                formControlName="order_note"
                                id=""
                                cols="30"
                                rows="10"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END OF RIGHT PANEL-->
              </div>
            </ng-container>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
  <!-- /.card -->
</form>
