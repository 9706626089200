import { Component } from '@angular/core';

@Component({
  selector: 'app-mantle-reports-admin',
  templateUrl: './mantle-reports-admin.component.html',
  styleUrls: ['./mantle-reports-admin.component.scss'],
})
export class MantleReportsAdminComponent {
  isAdmin: boolean = true;
}
