import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CashRegisterService {
  constructor(private apiService: ApiService) {}

  getCashRegisters(): Observable<any> {
    return this.apiService.get('/api/v1/cash-register');
  }

  createCashRegister(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/cash-register', requestPayload);
  }

  getCashRegisterById(id): Observable<any> {
    return this.apiService.get('/api/v1/cash-register/' + id);
  }

  updateCashRegister(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/cash-register', requestPayload);
  }

  filterCashRegister(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/cash-register/filter', requestPayload);
  }

  deleteCashRegister(id): Observable<any> {
    return this.apiService.delete('/api/v1/cash-register/' + id);
  }

  cashRegisterTransaction(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/cash-register/transation',
      requestPayload
    );
  }

  getRegisterTransaction(cash_register_id, session_id = null): Observable<any> {
    return this.apiService.get(
      `/api/v1/cash-register/transation/${cash_register_id}?session_id=${session_id}`
    );
  }

  getClosingBalance(cash_register_id): Observable<any> {
    return this.apiService.get(`/api/v1/cash-register/closing-balance/${cash_register_id}`);
  }

  getCashRegisterLog(cash_register_id): Observable<any> {
    return this.apiService.get(`/api/v1/cash-register/logs/${cash_register_id}`);
  }
  getCashRegisterLogById(cash_register_log_id): Observable<any> {
    return this.apiService.get(`/api/v1/cash-register/log/${cash_register_log_id}`);
  }
}
