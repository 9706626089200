import { createReducer, on } from '@ngrx/store';
import { STATE_STATUS } from 'src/app/utils/enums/mantle-enums';
import { ProductRewardPointState } from 'src/app/utils/interfaces/app.state';
import {
  deleteProductReward,
  deleteProductRewardSuccess,
  loadProductReward,
  loadProductRewardFailure,
  loadProductRewardSuccess,
  upsertProductReward,
  upsertProductRewardSuccess,
} from './product_reward_points.actions';
export const initialTieredState: ProductRewardPointState = {
  products: [],
  error: null,
  status: STATE_STATUS.PENDING,
};

export const productRewardPointReducer = createReducer(
  initialTieredState,
  on(loadProductReward, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(loadProductRewardSuccess, (state, { products }) => ({
    ...state,
    products,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),

  on(loadProductRewardFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  })),

  on(upsertProductReward, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(upsertProductRewardSuccess, (state, { productReward }) => ({
    ...state,
    products: mergeStateArray(state.products, productReward),
    activeRecord: productReward,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),

  on(deleteProductReward, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(deleteProductRewardSuccess, (state, { point_product_id }) => ({
    ...state,
    tiered: state.products?.filter(
      (x) => x.point_product_id != point_product_id
    ),
    activeRecord: null,
    error: null,
    status: STATE_STATUS.SUCCESS,
  }))
);

const mergeStateArray = (products: any[], producct) => {
  const cloneState = JSON.parse(JSON.stringify(products));

  const index = cloneState.findIndex(
    (x) => x.point_product_id == producct.point_product_id
  );
  if (index < 0) {
    cloneState.push(producct);
  } else {
    cloneState[index] = producct;
  }
  return cloneState.sort((a, b) => b.multiplier - a.multiplier);
};
