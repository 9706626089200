import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  MANTLE_MODAL_NAME,
  PAYMENT_MERCHANT_TYPES,
} from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MerchantService } from 'src/app/utils/services/merchant.service';
import { MxMerchantComponent } from 'src/app/components/plugins/mx-merchant/mx-merchant.component';
import { MantleConfirmDialogComponent } from 'src/app/views/mantle-confirm-dialog/mantle-confirm-dialog.component';
declare let $: any;

@Component({
  selector: 'mantle-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.scss'],
})
export class MerchantListComponent implements OnInit, OnDestroy {
  @Input() merchant_type_id: any;
  merchantList: Array<any> = [];
  merchantListSubscription: Subscription;
  deleteMerchantSubscription: Subscription;

  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private mantleModalService: MantleModalService,
    private merchantService: MerchantService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData = () => {
    this.spinner.show();
    this.merchantListSubscription = this.merchantService
      .getMerchants(
        this.merchant_type_id != undefined
          ? { merchant_type_id: this.merchant_type_id }
          : {}
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.merchantList = res.data;

          setTimeout(() => {
            if (!$.fn.dataTable.isDataTable('.dataTable')) {
              $('.dataTable').DataTable({
                orderCellsTop: true,
                fixedHeader: true,
                pagingType: 'full_numbers',
                pageLength: 100,
                processing: true,
                lengthMenu: [5, 10, 20, 100, 200, 500],
              });
            }
          }, 1);
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  };

  onEdit(merchant_id: any) {
    const merchantDetails = this.merchantList.find(
      (x) => x.merchant_id == merchant_id
    );
    const modalRef = this.mantleModalService.open(
      MxMerchantComponent,
      MANTLE_MODAL_NAME.MX_MERCHANT_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      },
      this.initData
    );
    modalRef.componentInstance.merchantDetails = merchantDetails;
  }

  onDelete(merchant_id: any, name?: any) {
    const modalRef = this.mantleModalService.confirmDialog(
      `Delete Merchant : ${name}`,
      'By confriming this, this Merchant will be deleted'
    );

    this.deleteMerchantSubscription = this.commonService.modal_close.subscribe(
      (data: any) => {
        if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
          if (data.data.action_id == 1) {
            this.spinner.show();
            this.merchantService.deleteMerchant(merchant_id).subscribe(
              (res) => {
                this.spinner.hide();
                this.initData();
                modalRef.close();
              },
              (err) => {
                this.spinner.hide();
                this.toastr.error(err.message);
                this.initData();
                modalRef.close();
              }
            );
          }
          modalRef.close();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.merchantListSubscription.unsubscribe();
    this.deleteMerchantSubscription.unsubscribe();
  }
}
