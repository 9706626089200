import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/utils/interfaces/app.state';
import {
  selectGetTierByid,
  selectStateStatus,
  selectTieredList,
} from './state/tiered_reward_points.selectors';
import {
  MANTLE_MODAL_NAME,
  STATE_STATUS,
} from 'src/app/utils/enums/mantle-enums';
import { deleteTiered, loadTiered } from './state/tiered_reward_points.actions';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { TieredDetailsComponent } from './tiered-details/tiered-details.component';

@Component({
  selector: 'reward-tiered-list',
  templateUrl: './tiered-list.component.html',
  styleUrl: './tiered-list.component.scss',
})
export class TieredListComponent implements OnInit, OnDestroy {
  public tieredList$: Observable<any>;

  constructor(
    private store: Store<AppState>,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService
  ) {
    this.tieredList$ = this.store.select(selectTieredList);

    this.store.select(selectStateStatus).subscribe((status) => {
      if (status == STATE_STATUS.LOADING) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
    this.store.dispatch(loadTiered());
  }
  ngOnInit(): void {}

  viewDetails(point_tiered_id: any) {
    const tSub = this.store
      .select(selectGetTierByid(point_tiered_id))
      .subscribe((tier) => {
        this.onOpenTier(tier);
      });
    tSub.unsubscribe();
  }

  onOpenTier(tier = null) {
    const modalRef = this.mantleModalService.open(
      TieredDetailsComponent,
      MANTLE_MODAL_NAME.TIERED_REWARD_POINT_DETAILS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
      }
    );

    modalRef.componentInstance.tier = tier;
  }
  onDelete(point_tiered_id: any) {
    this.store.dispatch(deleteTiered({ point_tiered_id }));
  }

  ngOnDestroy(): void {}
}
