<!--section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h5>Purchase Orders</h5>
        </div>
      </div>
    </div>
  
</section-->

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card" style="background-color: transparent">
          <div class="card-header">
            <div class="card-title">
              <h5>Manage Purchase Orders</h5>
            </div>
            <div class="card-tools">
              <div class="row">
                <div class="col-12">
                  <mantle-btn-new-po></mantle-btn-new-po>
                </div>
              </div>
            </div>
            <!-- /.card-tools -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mantle-po-search></mantle-po-search>
      </div>
    </div>
    <!--div class="row">
            <div class="col-12">
                <mantle-order-actions></mantle-order-actions>
            </div>
        </div-->
    <div class="row">
      <div class="col-12">
        <mantle-po-list></mantle-po-list>
      </div>
    </div>
  </div>
</section>
