import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PluginService } from 'src/app/utils/services/plugin.service';

@Component({
  selector: 'mantle-pos-plugins',
  templateUrl: './pos-plugins.component.html',
  styleUrls: ['./pos-plugins.component.scss']
})
export class PosPluginsComponent {

  posPlugins = []
  constructor(
    private pluginService: PluginService,private toastr: ToastrService, private router: Router) {
  }
  ngOnInit(): void {
    this.getActivePosPlugins()
  }

  getActivePosPlugins() {
    this.pluginService.filterPlugin({
      enabled: true,
      type: "POS"
    }).subscribe(
      (res)=>{
        this.posPlugins = res;
      },
      (err)=>{
        this.toastr.error("Error fetching PoS plugins")
      }
    )
  }

  onPosClick(pluginData){
    this.router.navigate(['/order/pos'], {state: pluginData});
  }
}
