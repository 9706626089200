import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME, PAYMENT_MERCHANT_TYPES } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { PluginService } from 'src/app/utils/services/plugin.service';
import { SettingsMerchantComponent } from 'src/app/views/mantle-settings/settings-merchant/settings-merchant.component';

@Component({
  selector: 'mantle-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.scss'],
})
export class PluginsComponent {
  activatedPlugins: any = [];
  availablePlugins: any = [];
  constructor(
    private pluginService: PluginService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initValues();
  }

  initValues() {
    this.spinner.show();
    const obs = this.pluginService.getPlugins();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.activatedPlugins = res.filter((x) => x.enabled == true);
        this.availablePlugins = res.filter((x) => x.enabled == false);
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  toggleFavorite(plugin) {
    this.spinner.show();

    const obs = this.pluginService.updatePlugin({
      plugin_id: plugin.plugin_id,
      enabled: !plugin.enabled,
    });
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.initValues();
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
        this.initValues();
      }
    );
  }

  openSettings($event) {
    switch ($event.norm_id) {
      case 'PAYMENT_MX_MERCHANT':
        const modalRef = this.mantleModalService.open(
          SettingsMerchantComponent,
          MANTLE_MODAL_NAME.MERCHANT_SETTINGS_MODAL,
          {
            ariaLabelledBy: 'modal-basic-title',
            size: 'xl',
            backdrop: 'static',
          }
        );
        modalRef.componentInstance.merchant_type_id = PAYMENT_MERCHANT_TYPES.PAYMENT_MX_MERCHANT;
        break;

      default:
        break;
    }
  }
}
