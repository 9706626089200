import { Component } from '@angular/core';
import { VendorService } from 'src/app/utils/services/vendor.service';

@Component({
  selector: 'mantle-btn-new-po-vendor',
  templateUrl: './mantle-btn-new-po-vendor.component.html',
  styleUrls: ['./mantle-btn-new-po-vendor.component.scss'],
})
export class MantleBtnNewPoVendorComponent {
  constructor(private vendorService: VendorService) {}

  onNewVendor() {
    this.vendorService.modalPoVendorDetails();
  }
}
