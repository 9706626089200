import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-tabular-inputs',
  templateUrl: './mantle-tabular-inputs.component.html',
  styleUrls: ['./mantle-tabular-inputs.component.scss'],
})
export class MantleTabularInputsComponent
  extends FieldArrayType
  implements OnInit {
    constructor(private commonService: CommonService){
      super();
    }
  ngOnInit(): void {}
  onChange(event) {
  }
  remove(index){
    this.commonService.tabularInputRemoved.next({
      name:this.field.key,
      index: index
    })

    super.remove(index);
  }
}
