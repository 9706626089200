import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { VendorService } from 'src/app/utils/services/vendor.service';
import { OrderReturnDetailsComponent } from '../order-return-details/order-return-details.component';

@Component({
  selector: 'order-return-list',
  templateUrl: './order-return-list.component.html',
  styleUrls: ['./order-return-list.component.scss'],
})
export class OrderReturnListComponent implements OnInit, OnDestroy {
  initialized: boolean = false;
  filterParams: any = {};
  private orderSearchSubscription!: Subscription;
  private modalCloseSubscription!: Subscription;
  ordersList: Array<any> = [];
  timezone: string = this.commonService.getTimeZone();

  constructor(
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private mantleModalService: MantleModalService,
    private commonService: CommonService
  ) {
    this.modalCloseSubscription = this.commonService.modal_close.subscribe(
      (modalName) => {
        if (modalName === MANTLE_MODAL_NAME.ORDER_RETURN_DETAILS) {
          this.ngOnInit();
        }
      }
    );
  }
  ngOnInit(): void {
    if (!this.initialized) {
      this.initData();
      this.initialized = true;
    }

    this.spinner.show();
    this.orderSearchSubscription = this.orderService
      .getReturnOrders()
      .subscribe(
        (res) => {
          this.ordersList = res.data;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.error?.message);
        }
      );
  }

  initData = () => {
    let lastPage = 0;
    let lastSearchText = '';

    try {
      if ($.fn.dataTable.isDataTable('.dataTable')) {
        var tbl = $('.dataTable').DataTable();
        //tbl.destroy();
        //tbl.draw();
        tbl.clear();
        tbl.destroy();
      }
    } catch (error) {}

    setTimeout(() => {
      $('.dataTable thead tr').clone(true).appendTo('.dataTable thead');
      $('.dataTable thead tr:eq(1) th').each((i, element) => {
        var title = $(element).text();
        var id = $(element).attr('id');
        if (id) {
          $(element).html(
            '<input type="text" style="width:100%" placeholder="Search ' +
              title +
              '" id="' +
              id +
              '"/>'
          );
        }

        $('input', element).on('keyup', (event) => {
          if (event.key === 'Enter') {
            const eValue = (event.target as HTMLInputElement).value;
            if (dataTable.column(i).search() !== eValue) {
              this.filterParams[event.target.id] = eValue;

              if (eValue == undefined || eValue == '') {
                delete this.filterParams[event.target.id];
              }
              dataTable.draw();
            }
          }
        });
      });

      var dataTable = $('.dataTable').DataTable({
        orderCellsTop: true,
        pagingType: 'full_numbers',
        pageLength: 100,
        displayStart: lastPage, // Last Selected Page
        search: { search: lastSearchText }, // Last Searched Text
        serverSide: true,
        processing: true,
        searching: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.spinner.show();
          let offset = dataTablesParameters.start;
          this.orderService
            .getReturnOrders({
              ...this.filterParams,
              ...{
                offset: offset,
                limit: 100,
              },
            })
            .subscribe(
              (res) => {
                this.spinner.hide();
                this.ordersList = res.data;
                callback({
                  recordsTotal: res.count,
                  recordsFiltered: res.count,
                  data: [],
                });
              },
              (err) => {
                this.spinner.hide();

                this.toastr.error(JSON.stringify(err));
              }
            );
        },
        lengthMenu: [5, 10, 20, 100],
        // createdRow: (row: Node, data: any) => {
        //   if (data.is_new_record) {
        //     $(row).css('background-color', 'red');
        //   }
        // },
      });
    }, 1);
  };

  viewOrderDetails(order_return_id: any) {
    const modalRef = this.mantleModalService.open(
      OrderReturnDetailsComponent,
      MANTLE_MODAL_NAME.ORDER_RETURN_DETAILS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.order_return_id = order_return_id;
  }

  ngOnDestroy(): void {
    this.orderSearchSubscription?.unsubscribe();
    this.modalCloseSubscription?.unsubscribe();
  }
}
