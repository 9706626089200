import { Component } from '@angular/core';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MxMerchantComponent } from '../mx-merchant.component';

@Component({
  selector: 'btn-mx-merchant-add',
  templateUrl: './mx-merchant-add.component.html',
  styleUrls: ['./mx-merchant-add.component.scss'],
})
export class MxMerchantAddComponent {
  constructor(private mantleModalService: MantleModalService) {}
  onAddMerchant() {
    const modalRef = this.mantleModalService.open(
      MxMerchantComponent,
      MANTLE_MODAL_NAME.MX_MERCHANT_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
  }
}
