<div class="row m-2">
  <div class="col-11" style="text-align: center">
    <mantle-print [printSectionId]="'print-section'"></mantle-print>
  </div>
  <div class="col-md-1">
    <div class="card-tools">
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-tool"
        data-card-widget="remove"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</div>
<div id="print-section">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Inventory Pullsheet</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Item</th>
                    <th>Picking Bin</th>
                    <th>Available Quantity</th>
                    <th>Pick Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let pullSheet of pullSheetData">
                    <tr>
                      <td>{{ pullSheet.child_sku }}</td>
                      <td>{{ pullSheet.stock_item_description }}</td>
                      <td>{{ pullSheet.inventory_picking_bin }}</td>
                      <td>{{ pullSheet.inventory_available }}</td>
                      <td>{{ pullSheet.inventory_collect }}</td>
                    </tr>
                    <ng-container
                      *ngFor="
                        let subTotals of getSubTotals(pullSheet.child_sku);
                        let i = index
                      "
                    >
                      <tr *ngIf="i == 0" class="text-muted small">
                        <td colspan="3">&nbsp;</td>
                        <td>Orders No#</td>
                        <td>Quantity</td>
                      </tr>
                      <tr>
                        <td colspan="3">&nbsp;</td>
                        <td>
                          {{ getOrderNumber(subTotals.order_id) }}
                        </td>
                        <td>
                          {{ subTotals.quantity }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
