import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { ReportingService } from 'src/app/utils/services/reporting.service';

@Component({
  selector: 'mantle-widget-card',
  templateUrl: './mantle-widget-card.component.html',
  styleUrls: ['./mantle-widget-card.component.scss'],
})
export class MantleWidgetCardComponent implements OnInit {
  @Input() report_id;
  @Input() report_details;
  @Input() bg = 'bg-info';
  @Input() link;
  @Input() linkText;
  loading: boolean = true;
  hasError: boolean = false;
  value: any;
  report_name: any;
  icon: any;
  errorText: any;
  constructor(
    private reportingService: ReportingService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getReportDetails();
  }

  getReportDetails = async () => {
    if (this.report_details) {
      this.report_name = this.report_details.name;
      this.getReportData();
    } else {
      this.reportingService.getReportById(this.report_id).subscribe(
        (res) => {
          this.report_name = res.name;
          this.getReportData();
        },
        (error) => {
          this.setError(error);
        }
      );
    }
  };

  getReportData = async () => {
    this.reportingService.getReportData(this.report_id).subscribe(
      (res) => {
        this.value = res.find((x) => x.value != undefined)?.value;
        this.loading = false;
        if(this.report_details.refresh_intereval){
          this.autoRefresh(this.report_details.refresh_intereval)
        }
      },
      (error) => {
        this.setError(error);
      }
    );
  };

  setError(error: any) {
    this.hasError = true;
    this.errorText = error?.error?.message
      ? error?.error?.message
      : error.message;
  }

  refreshData(context){
    context.loading = true;
    context.hasError = false;
    context.errorText = '';

    context.getReportDetails();
  }

  autoRefresh(timeout:number){
    setTimeout(this.refreshData, timeout, this);
  }
}
