<div class="row">
  <div class="col-md-12 small table-responsive">
    <table class="table table-striped dataTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Device Type</th>
          <th>Description</th>
          <th>Enabled</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let terminal of terminalList">
          <tr>
            <td>{{ terminal.name }}</td>
            <td>{{ terminal.deviceTypeName }}</td>
            <td>{{ terminal.description }}</td>
            <td>{{ terminal.enabled }}</td>
            <td>
              <button
                class="btn btn-outline-info btn-sm"
                (click)="onAllocateTerminal(terminal.id)"
              >
                <i class="fas fa-tasks"></i> Allocate
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
