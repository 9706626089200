<div class="card">
  <div class="card-header">
    <div class="row" *ngIf="shippingLabel">
      <div class="col-5">
        <mantle-print
          [printSectionId]="'print-section'"
          btnLabel="Print Labels"
          cssClass="btn btn-outline-secondary btn-sm w-100"
        ></mantle-print>
      </div>
      <div class="col-2">&nbsp;</div>
      <div class="col-5">
        <button
          type="button"
          class="btn btn-outline-danger btn-sm w-100"
          (click)="onVoidLabel()"
        >
          Void Label <i class="fa fa-times mt-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row" *ngIf="shippingLabel == undefined">
      <div class="col-12">
        <p class="bg-warning rounded">No Shipment Labels Available</p>
      </div>
    </div>
    <div class="row" *ngIf="shippingLabel" id="print-section">
      <div class="col-12 d-flex justify-content-center">
        <ng-container *ngFor="let label of shippingLabel.labels">
          <img src="{{ label.href }}" />
        </ng-container>
      </div>
    </div>
  </div>
</div>
