import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'datetime-local-formly',
  templateUrl: './datetime-local-formly.component.html',
  styleUrls: ['./datetime-local-formly.component.scss']
})
export class DatetimeLocalFormlyComponent  extends FieldType<FieldTypeConfig> {

  constructor() {
    super();

    console.log(this.field)

    console.log(this.defaultOptions)
  }

}
