<section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <h5>Manage Inventory</h5>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
</section>

  <!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <mantle-product-filter [product_filter_level] = "1"></mantle-product-filter>
            </div>
        </div>
        <!--div class="row">
            <div class="col-12">
                <mantle-inventory-summary></mantle-inventory-summary>
            </div>
        </div-->
        <div class="row">
            <div class="col-12">
                <mantle-inventory-list></mantle-inventory-list>
            </div>
        </div>    
    </div>
</section>