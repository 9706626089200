import { createAction, props } from '@ngrx/store';
const PREFIX = '[Product Reward]';

export const loadProductReward = createAction(`${PREFIX} Load Product Reward`);
export const loadProductRewardSuccess = createAction(
  `${PREFIX} Load Product Reward Success`,
  props<{ products: any[] }>()
);
export const loadProductRewardFailure = createAction(
  `${PREFIX} Load Product Reward Failure`,
  props<{ error: any }>()
);

export const upsertProductReward = createAction(
  `${PREFIX} Upsert Product Reward`,
  props<{ productReward: any }>()
);

export const upsertProductRewardSuccess = createAction(
  `${PREFIX} Upsert Product Reward Success`,
  props<{ productReward: any }>()
);

export const upsertProductRewardFailure = createAction(
  `${PREFIX} Upsert Product Reward Failure`,
  props<{ error: any }>()
);

export const deleteProductReward = createAction(
  `${PREFIX} Delete Product Reward`,
  props<{ point_product_id: string }>()
);

export const deleteProductRewardSuccess = createAction(
  `${PREFIX} Delete Product Reward Success`,
  props<{ point_product_id: string }>()
);

export const deleteProductRewardFailure = createAction(
  `${PREFIX} Delete Product Reward Failure`,
  props<{ error: any }>()
);
