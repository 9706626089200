import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MerchantService } from 'src/app/utils/services/merchant.service';
declare let $: any;

@Component({
  selector: 'plugin-mx-terminal-list',
  templateUrl: './mx-terminal-list.component.html',
  styleUrls: ['./mx-terminal-list.component.scss'],
})
export class MxTerminalListComponent {
  @Input() merchant_id: any;
  terminalListSubscription: Subscription;
  terminalUpdatedSubscription: Subscription;
  terminalList: Array<any>;

  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private mantleModalService: MantleModalService,
    private merchantService: MerchantService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData = () => {
    this.spinner.show();
    this.terminalListSubscription = this.merchantService
      .mxGetTerminals(this.merchant_id)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.terminalList = res;

          setTimeout(() => {
            if (!$.fn.dataTable.isDataTable('.dataTable')) {
              $('.dataTable').DataTable({
                orderCellsTop: true,
                fixedHeader: true,
                pagingType: 'full_numbers',
                pageLength: 100,
                processing: true,
                lengthMenu: [5, 10, 20, 100, 200, 500],
              });
            }
          }, 1);
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  };

  onAllocateTerminal(id: any) {
    this.spinner.show();

    this.merchantService
      .getTerminals({
        mx_id: id,
      })
      .subscribe(
        (res) => {
          if (res.count > 0) {
            this.spinner.hide();
            this.toastr.warning('Terminal Already Allocated');
          } else {
            const terminal = this.terminalList.find(x => x.id == id);
            this.merchantService
              .createTerminal({
                merchant_id: this.merchant_id,
                mx_id: terminal.id,
                name: terminal.name,
                description: terminal.description,
                mx_merchantid: terminal.merchantId,
                mx_unique_identifier: terminal.uniqueIdentifier,
              })
              .subscribe(
                (res) => {
                  this.spinner.hide()
                  this.toastr.success('Terminal Successfully Added');
                  this.commonService.terminalsUpdated.next(res);
                },
                (err) => {
                  this.spinner.hide();
                  this.toastr.error(err.message);}
              );
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message);
        }
      );
  }
  ngOnDestroy(): void {
    this.terminalListSubscription.unsubscribe();
    this.terminalUpdatedSubscription.unsubscribe();
  }
}
