<li class="nav-item dropdown">
    <a class="nav-link" data-toggle="dropdown" (click)="toggleDropdownMenu()">
        <i class="far fa-star"></i>
        <span class="badge badge-success navbar-badge">{{favoriteCount}}</span>
    </a>
    <div #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
      <span class="dropdown-item dropdown-header">{{favoriteCount}} Favorites</span>
      
      <div *ngFor="let favorite of favoriteList" (click)="viewProduct(favorite.brand_id, favorite.child_sku, favorite.parent_sku)">
        <div class="dropdown-divider"></div>
        <p class="dropdown-item small">
            <i class="fas fa-gamepad mr-2"></i> {{favorite.description}}
            <span class="float-right text-muted text-sm"> {{favorite.child_sku}} </span>
        </p>
      </div>

    </div>
</li>

