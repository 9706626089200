import { createReducer, on } from '@ngrx/store';
import { STATE_STATUS } from 'src/app/utils/enums/mantle-enums';
import { RewardPointState } from 'src/app/utils/interfaces/app.state';
import {
  loadRewardPoints,
  loadRewardPointsFailure,
  loadRewardPointsSuccess,
  saveRewardPoints,
} from './reward_points.actions';

const initialState: RewardPointState = {
  rewardPoint: {
    id: 1,
    points_per_dollar: 0,
    enabled: false,
  },
  error: null,
  status: STATE_STATUS.PENDING,
};

export const rewardPointReducer = createReducer(
  initialState,
  on(loadRewardPoints, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
    error: null,
  })),
  on(loadRewardPointsSuccess, (state, { rewardPoint }) => ({
    ...state,
    rewardPoint: rewardPoint,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),
  on(loadRewardPointsFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  })),
  on(saveRewardPoints, (state, { rewardPoint }) => ({
    ...state,
    status: STATE_STATUS.LOADING,
    error: null,
  }))
);
