import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PAYMENT_METHOD } from 'src/app/utils/enums/mantle-enums';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { OrderService } from 'src/app/utils/services/order.service';

@Component({
  selector: 'confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss'],
})
export class ConfirmPaymentComponent implements OnInit {
  @Input() order_id;
  @Input() formValue;
  @Input() paymentMethods;
  @Input() paymentTerminal;
  paymentMethod: any = '';
  orderDetails: any = {};
  cardPaymentStatusConfirmed = false;

  constructor(
    private commonService: CommonService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init_values();
  }

  init_values() {
    //this.getOrderDetails();
    this.orderDetails = this.formValue;
    this.paymentMethod = this.paymentMethods.find(
      (x) => x.payment_method_id == this.formValue.payment_method_id
    )?.description;

    this.getOrderPaymentStatus();
  }

  getOrderDetails() {
    this.spinner.show();
    this.orderService.getOrderById(this.order_id).subscribe(
      (res) => {
        this.orderDetails = res;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }

  getOrderPaymentStatus(continuesPoll = true, retryCount = 0) {
    //this.cardPaymentStatusConfirmed = false;
    if (this.cardPaymentStatusConfirmed) return;

    if (this.formValue.payment_method_id == PAYMENT_METHOD.CREDIT_DEBIT_CARD) {
      if (retryCount > 60) {
        this.cardPaymentStatusConfirmed = true;

        this.orderDetails.payment_status = {
          payment_status_id: 99,
          description: 'Unable To Very Payment',
        };
        return;
      }

      this.orderService.getOrderPaymentStatus(this.order_id).subscribe(
        async(res) => {
          if (res.payment_status_id == null) {
            if (continuesPoll) {
              this.getOrderPaymentStatus(true, ++retryCount);
              await new Promise(f => setTimeout(f, 1000));
            }
          } else {
            this.orderDetails = { ...this.orderDetails, ...res };
            this.cardPaymentStatusConfirmed = true;
            return;
          }
        },
        async(error) => {
          this.getOrderPaymentStatus(true, ++retryCount);
          await new Promise(f => setTimeout(f, 1000));
        }
      );
    } else {
      this.cardPaymentStatusConfirmed = true;
      return;
    }
  }

  onOrderConfirm() {
    this.spinner.show();

    this.orderService
      .confirmPayment({ order_id: this.order_id, action_code: 1 })
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.commonService.modal_close.next('ORDER-CONFIRM-PAYMENT');
        },
        (err) => {
          this.spinner.hide();
          
          this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
        }
      );
  }

  cancelPaymentStatusCheck() {
    this.cardPaymentStatusConfirmed = true;

    this.orderDetails.payment_status = {
      payment_status_id: 99,
      description: 'Payment Verification Cnacelled By User',
    };
  }

  paymentStatusAlert(statusCode) {
    switch (statusCode) {
      case 99:
      case 2:
        return true;
        break;
      default:
        break;
    }
    return false;
  }

  closeModal() {
    this.commonService.modal_close.next('ORDER-CONFIRM-PAYMENT-CANCEL');
  }
}
