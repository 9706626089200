<div *ngIf="!to.hidden">
  <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
    <div class="col-md-10">
      <formly-field class="col" [field]="field"></formly-field>
    </div>
    <div
      class="col-md-2 d-flex align-items-center"
      *ngIf="to.canRemove || to.canRemove == undefined"
    >
      <button class="btn btn-danger btn-sm" type="button" (click)="remove(i)">
        Remove <i class="fas fa-trash ml-2"></i>
      </button>
    </div>
  </div>
  <div style="margin: 30px 0" *ngIf="to.canAdd || to.canAdd == undefined">
    <button class="btn btn-primary btn-sm" type="button" (click)="add()">
      {{ to.addText }} <i class="fas fa-plus ml-2"></i>
    </button>
  </div>
</div>
