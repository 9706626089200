import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'endicia-create-shipment',
  templateUrl: './endicia-create-shipment.component.html',
  styleUrls: ['./endicia-create-shipment.component.scss'],
})
export class EndiciaCreateShipmentComponent implements OnInit {
  rateGenerated: boolean = false;
  shippingRateCurrency: any;
  shippingRateTotal: any = 0.0;
  tracking_number: any;

  @Input() orderDetails: any;
  carriers: Array<any> = [];
  service_type: Array<any> = [];
  package_type: Array<any> = [];
  weight_unit: Array<any> = [];

  dimension_unit: Array<any> = [];
  delivery_confirmation_type: Array<any> = [];
  insurance_provider: Array<any> = [
    {
      insurance_provider: 'stamps_com',
      insurance_provider_description: 'stamps_com',
    },
    {
      insurance_provider: 'carrier',
      insurance_provider_description: 'carrier',
    },
    {
      insurance_provider: 'parcel_guard',
      insurance_provider_description: 'Parcel Guard',
    },
  ];
  label_size: Array<any> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private settingsService: SettingsService,
    private endiciaService: EndiciaService
  ) {}

  shippingForm = this.fb.group({
    order_id: [],
    carrier: ['usps', Validators.required],
    from_address: this.fb.group({
      company_name: [],
      name: [],
      address_line1: [],
      address_line2: [],
      address_line3: [],
      city: [],
      state_province: [],
      postal_code: ['', Validators.required],
      country_code: ['', Validators.required],
      phone: [],
      email: [],
      residential_indicator: [],
    }),
    return_address: this.fb.group({
      company_name: [],
      name: [],
      address_line1: [],
      address_line2: [],
      address_line3: [],
      city: [],
      state_province: [],
      postal_code: ['', Validators.required],
      country_code: ['', Validators.required],
      phone: [],
      email: [],
      residential_indicator: [],
    }),
    to_address: this.fb.group({
      company_name: [],
      name: [],
      address_line1: [],
      address_line2: [],
      address_line3: [],
      city: [],
      state_province: [],
      postal_code: ['', Validators.required],
      country_code: ['', Validators.required],
      phone: [],
      email: [],
      residential_indicator: [],
    }),
    service_type: ['', Validators.required],
    package: this.fb.group({
      packaging_type: ['package', Validators.required],
      weight: [0, Validators.required],
      weight_unit: ['ounce', Validators.required],
      length: [0, Validators.required],
      width: [0, Validators.required],
      height: [0, Validators.required],
      dimension_unit: ['inch', Validators.required],
    }),
    delivery_confirmation_type: [],
    insurance: this.fb.group({
      insurance_provider: [],
      insured_value: this.fb.group({
        amount: [],
        currency: [],
      }),
    }),
    customs: this.fb.group({
      contents_type: [],
      contents_description: [],
      non_delivery_option: [],
      //sender_info
      //recipient_info
      //customs_items
    }),
    ship_date: ['', Validators.required],
    is_return_label: [],
    //advanced_options
    label_options: this.fb.group({
      label_size: ['4x6', Validators.required],
      label_format: ['png'],
      label_logo_image_id: [0],
      label_output_type: ['url', Validators.required],
    }),
    email_label: this.fb.group({
      email: [],
      email_notes: [],
      bcc_email: [],
    }),
    order_details: this.fb.group({
      order_source: [],
      order_number: [],
      order_date: [],
      //items_ordered
    }),
    //Change to false on deployment
    is_test_label: [true],
  });

  ngOnInit(): void {
    this.endiciaService.addressValidated.subscribe((data) => {
      this.shippingForm.patchValue(data);
    });

    this.spinner.show();

    const obs = this.endiciaService.getIndeciaLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.carriers = res?.carrier;
        this.service_type = res?.service_type;
        this.package_type = res?.packaging_type;
        this.weight_unit = res?.weight_unit;
        this.dimension_unit = res?.dimension_unit;
        this.delivery_confirmation_type = res?.delivery_confirmation_type;
        //this.insurance_provider= res?.insurance_provider;
        this.label_size = res?.label_size;
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error('An Error has occurred');
      }
    );
    this.endiciaService.existingShipmentOpen.subscribe((data) => {
      if (data !== true) {
        this.existingData(data);
      }
    });
    //Who blinks 1st
    this.endiciaService.existingShipmentOpen.next(true);
  }

  viewShippingRates() {
    this.shippingRateTotal = 0.0;
    this.shippingRateCurrency = '';

    this.spinner.show();
    const obs = this.endiciaService.viewShippingRates(this.shippingForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.rateGenerated = true;
        res.forEach((rates) => {
          this.shippingRateTotal += parseFloat(
            rates.shipment_cost?.total_amount
          );
          this.shippingRateCurrency = rates.shipment_cost?.currency.toUpperCase();
        });
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error('An Error has occurred');
        if (err.error) {
          this.endiciaService.endiciaError(err.error);
        }
      }
    );
  }

  createShippingLabel() {
    this.spinner.show();
    //Patch order_id
    this.shippingForm.patchValue({
      order_id: this.orderDetails.order_id,
      order_details: {
        order_date: this.orderDetails.order_date,
      },
    });
    const obs = this.endiciaService.createShippingLabel(
      this.shippingForm.value
    );
    obs.subscribe(
      (res) => {
        this.spinner.hide();

        this.tracking_number = res.tracking_number;
        this.endiciaService.labelGenerated.next({
          label_id: res.label_id,
          labels: res.labels,
        });
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error('An Error has occurred');
        if (err.error) {
          this.endiciaService.endiciaError(err.error);
        }
      }
    );
  }

  existingData(data) {
    this.shippingForm.patchValue(data.request_payload);
    this.shippingForm.disable();

    this.tracking_number = data.response_payload?.tracking_number;

    /*this.endiciaService.labelGenerated.next({
      "label_id":data.response_payload?.label_id,
      "labels":data.response_payload?.labels
    })*/
  }
}
