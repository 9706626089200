import { Component } from '@angular/core';

@Component({
  selector: 'mantle-product',
  templateUrl: './mantle-product.component.html',
  styleUrls: ['./mantle-product.component.scss']
})
export class MantleProductComponent {

}
