<div class="card">
  <div class="card-header">
    <h5 class="card-title">Bulk Actions</h5>

    <div class="card-tools">
      <button type="button" class="btn btn-tool" data-card-widget="collapse">
        <i class="fas fa-minus"></i>
      </button>
      <button type="button" class="btn btn-tool" data-card-widget="remove">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <!-- /.card-header -->

  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-9">
            <div class="form-group">
              <label class="control-label small">Bulk Status Update</label>
              <select
                class="form-control form-control-sm"
                [(ngModel)]="orderStatusId"
                [ngModelOptions]="{ standalone: true }"
              >
                <option>--Update Status To--</option>
                <ng-container
                  *ngFor="let order_status of order_statuses; let i = index"
                >
                  <option [value]="order_status.order_status_id">
                    {{ order_status.description }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <br />
            <button
              type="button"
              class="btn btn-sm btn-success float-left w-80 mt-2"
              (click)="onUpdateStatus()"
            >
              Update All
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 form-check mt-2">
            <input
              type="checkbox"
              class="form-check-input"
              id="send_email"
              [(ngModel)]="sendEmail"
              [ngModelOptions]="{ standalone: true }"
            />
            <label class="form-check-label small" for="send_email"
              >Send Email to Customer</label
            >
          </div>
        </div>
        <!-- /.chart-responsive -->
      </div>
      <!-- /.col -->

      <div class="col-md-6">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <label>Printing</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mt-1">
              <button
                type="button"
                class="btn btn-sm btn-success float-left"
                (click)="onPrintInvoice()"
              >
                Print Invoices
              </button>
            </div>
            <div class="col-md-4 mt-1">
              <button
                type="button"
                class="btn btn-sm btn-success float-left"
                (click)="onPrintAddresses()"
              >
                Print Addresses
              </button>
            </div>
            <div class="col-md-4 mt-1">
              <button
                type="button"
                class="btn btn-sm btn-success float-left"
                (click)="onPrintPullsheet()"
              >
                Print Pullsheet
              </button>
            </div>
          </div>
          <p class="small font-italic">
            If no orders are selected, all orders in your current search will be
            selected for printing.
          </p>
        </div>
        <!-- /.chart-responsive -->
      </div>
    </div>
    <!-- /.row -->
  </div>
</div>
<!-- /.card -->
