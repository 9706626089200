<div class="card">
  <form [formGroup]="form" (ngSubmit)="onRedeem()">
    <div class="card-header">
      <h5 style="float: left">Redeem Points</h5>
      <div class="card-tools">
        <button
          type="button"
          (click)="closeModal()"
          class="btn btn-tool"
          data-card-widget="remove"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <p *ngIf="type == 'REWARD_POINT'">
            Reward points available ({{
              customerPoints.reward_points | currency: 'USD':'symbol':'1.2-2'
            }})
          </p>
          <p *ngIf="type == 'STORE_CREDIT'">
            {{ customerPoints.store_credit | currency: 'USD':'symbol':'1.2-2' }}
            store credit available.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 small">
          <formly-form
            [model]="model"
            [fields]="fields"
            [options]="options"
            [form]="form"
          ></formly-form>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
      <div class="row">
        <div class="col-md-6">
          <button
            type="submit"
            class="btn btn-success btn-sm w-100"
            [disabled]="!form.valid"
          >
            Redeem
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
