import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'endicia-generate-label',
  templateUrl: './endicia-generate-label.component.html',
  styleUrls: ['./endicia-generate-label.component.scss']
})
export class EndiciaGenerateLabelComponent implements OnInit {

  @Input() shippingLabel:any
  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private endiciaService: EndiciaService) { }

  ngOnInit(): void {
    
    this.endiciaService.labelGenerated.subscribe(data=>{
      this.shippingLabel = data
    });
    
    this.endiciaService.existingShipmentOpen.subscribe(data=>{
      if(data !== true){

        this.existingData(data)
      }
      
    })
    //Who blinks 1st
    this.endiciaService.existingShipmentOpen.next(true)
  }

  onVoidLabel(){
    this.spinner.show()
    const obs = this.endiciaService.voidLabel(this.shippingLabel.label_id);
    obs.subscribe(res=>{
      this.spinner.hide();
      this.toastr.success("Label Voided");
    },err=>{
      this.spinner.hide();
      this.toastr.error("An Error Occurred");
      
      if(err.error){
        this.endiciaService.endiciaError(err.error)
      }
    })
  }

  existingData(data){
    this.shippingLabel = {
      label_id:data.response_payload?.label_id,
      labels:data.response_payload?.labels,
    }
  }

}
