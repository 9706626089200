<div class="row m-2">
  <div class="col-11" style="text-align: center">
    <mantle-print [printSectionId]="'print-section'"></mantle-print>
  </div>
  <div class="col-1">
    <div class="card-tools">
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-tool"
        data-card-widget="remove"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</div>
<div id="print-section">
  <div class="row">
    <ng-container *ngFor="let order of invoiceOrders">
      <div class="col-4">
        <div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <address>
                    {{ order.shipment_address.shipment_first_name }}
                    {{ order.shipment_address.shipment_last_name }}<br />
                    {{ order.shipment_address.shipment_street_address }} <br />
                    {{ order.shipment_address.shipment_street_address_2 }}
                    <br />
                    {{ order.shipment_address.shipment_zipcode }}
                    {{ order.shipment_address.shipment_city }},
                    {{ order.shipment_address.shipment_state }} ,
                    {{ order.shipment_address.shipment_country_code }} <br />
                    {{ order.shipment_address.shipment_email }}<br />
                    {{ order.shipment_address.shipment_phone }} /
                    {{ order.shipment_address.shipment_mobile }}<br />
                  </address>
                  <hr />
                  <span class="text-muted small"
                    >Order No#: {{ order.order_number }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
