import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-inventory-sales',
  templateUrl: './inventory-sales.component.html',
  styleUrl: './inventory-sales.component.scss',
})
export class InventorySalesComponent {
  @Input() dataItems: any = [];

  timezone: any = this.commonService.getTimeZone();
  constructor(private commonService: CommonService) {}
}
