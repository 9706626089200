<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body" *ngIf="error">
    <div class="row">
      <div class="col-md-12">
        <p>Error Ref: {{ error.error_reference_id }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 table-responsive">
        <table>
          <thead>
            <tr>
              <th>Error No#</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let err of error.errors">
              <tr>
                <td>{{ err.error_code }}</td>
                <td>{{ err.error_message }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
