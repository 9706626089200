<ng-toggle
  *ngIf="isFavorite"
  [value]="true"
  [width]="40"
  [color]="{
    unchecked: '#939da2',
    checked: '#1ba841'
  }"
  [labels]="{checked: '', unchecked: '. '}"
  (change)="toggleFavorite($event)"
></ng-toggle>

<ng-toggle
  *ngIf="!isFavorite"
  [value]="false"
  [width]="40"
  [color]="{
    unchecked: '#939da2',
    checked: '#1ba841'
  }"
  [labels]="{checked: '', unchecked: ''}"
  (change)="toggleFavorite($event)"
></ng-toggle>