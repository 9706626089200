import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bc',
  templateUrl: './bc.component.html',
  styleUrls: ['./bc.component.scss']
})
export class BcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
