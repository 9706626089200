import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'channel-activate-tcg',
  templateUrl: './channel-activate-tcg.component.html',
  styleUrls: ['./channel-activate-tcg.component.scss'],
})
export class ChannelActivateTcgComponent implements OnInit {
  @Input() channelOption: any = {};
  validated: boolean = false;
  storeOptions: Array<any> = [];
  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private settingsService: SettingsService
  ) {}

  tcgConfigForm = this.fb.group({
    channel_tcg_id: [],
    channel_ref: [],
    client_id: ['', Validators.required],
    client_secret: ['', Validators.required],
    channel_tcg_description: ['TCG Player Channel'],
    authorization_code: [],
    x_tcg_access_token: [],
    store_id: ['', Validators.required],
    update_frequency: [30],
    is_demo: [0],
  });

  ngOnInit(): void {
    if (this.channelOption.channel_option_id == 999) {
      this.channelOption['is_demo'] = 1;
    }
    setTimeout(() => {
      this.tcgConfigForm.patchValue(this.channelOption);
    }, 0);
  }
  onValidate() {
    this.storeOptions = [];
    this.validated = false;
    this.spinner.show();

    const obs = this.channelsService.validateTCG(this.tcgConfigForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        if (res != undefined) {
          if (res.token && res.token.access_token) {
            this.validated = true;

            this.tcgConfigForm.patchValue({
              x_tcg_access_token: res.authorization,
            });
            if (res.store != undefined) {
              if (res.store.success) {
                res.store.results.forEach((store) => {
                  this.storeOptions.push(store);
                });
              }
            }

            this.toastr.success('Validation Successful');
          } else {
            this.validated = false;
            var error = '';
            if (res.error_description != undefined) {
              error = res.error_description;
            }
            if (res.errors != undefined) {
              error = error + res.errors.join();
            }
            this.toastr.error(error, 'Validation Failed');
          }
        } else {
          this.validated = false;
          this.toastr.error('Validation Failed', 'Validation Failed');
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  onSave() {
    this.spinner.show();
    const obs = this.channelsService.createTCGChannel(this.tcgConfigForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Validation Successful');
        this.closeModal();
        this.channelsService.channelAdded.next({});
        this.settingsService.statusMappingDialog();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  onUpdate() {
    this.spinner.show();
    const obs = this.channelsService.updateTCGChannel(this.tcgConfigForm.value);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
        //this.channelsService.channelAdded.next({})
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  onUpdateFrequency() {
    this.spinner.show();
    const obs = this.channelsService.updateTCGChannel({
      channel_tcg_id: this.tcgConfigForm.value['channel_tcg_id'],
      channel_ref: this.tcgConfigForm.value['channel_ref'],
      update_frequency: this.tcgConfigForm.value['update_frequency'],
    });
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
        //this.channelsService.channelAdded.next({})
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('TCG-ACTIVATION-MODAL');
  }
}
