import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MantleModalService } from './mantle-modal.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  constructor(private apiService: ApiService) {}

  getUserPreferences = (user_id): Observable<any> => {
    return this.apiService.get(`/api/v1/preference/${user_id}`);
  };

  updateUserPreferences = (requestPayload): Observable<any> => {
    return this.apiService.post(`/api/v1/preference/`, requestPayload);
  };

  getDefaultQuickLinks = (): Observable<any> => {
    return this.apiService.get(`/api/v1/preference/quick-links/default`);
  };

  getUserQuickLinks = (user_id): Observable<any> => {
    return this.apiService.get(`/api/v1/preference/quick-links/${user_id}`);
  };

  createUserQuickLinks = (requestPayload): Observable<any> => {
    return this.apiService.post(
      `/api/v1/preference/quick-links/`,
      requestPayload
    );
  };

  updateUserQuickLinks = (requestPayload): Observable<any> => {
    return this.apiService.patch(
      `/api/v1/preference/quick-links/`,
      requestPayload
    );
  };

  deleteUserQuickLinks = (link_id): Observable<any> => {
    return this.apiService.delete(`/api/v1/preference/quick-links/${link_id}`);
  };
}
