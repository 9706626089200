<div class="card">
  <div class="card-header">
    <h3 class="card-title small">Quick Links</h3>
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="linkForm"
        ></formly-form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <button
          type="button"
          [disabled]="!linkForm.valid"
          class="btn btn-outline-primary btn-sm"
          (click)="onAddLink(this.linkForm.getRawValue())"
          *ngIf="!linkForm.value.link_id"
        >
          Add
        </button>
        <button
          type="button"
          [disabled]="!linkForm.valid"
          class="btn btn-outline-primary btn-sm"
          (click)="onUpdateLink(this.linkForm.getRawValue())"
          *ngIf="linkForm.value.link_id"
        >
          Update
        </button>
      </div>
    </div>
    <div class="row rounded mt-2">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <h6>My Links</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 small table-responsive">
            <table class="table table-striped dataTable">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Order</th>
                  <th>Link</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let link of quickLinks">
                  <tr>
                    <td>{{ link.description }}</td>
                    <td>{{ link.link_type }}</td>
                    <td>{{ link.link_order }}</td>
                    <td>{{ link.href | slice: 0:20 }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm mr-2"
                        (click)="onEditLink(link)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm"
                        (click)="onDeleteLink(link.link_id)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row rounded mt-2">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <h6 class="mt-2">Available Links</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 small table-responsive">
            <table class="table table-striped dataTable">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Link</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let link of defaultQuickLinks">
                  <tr>
                    <td>{{ link.description }}</td>
                    <td>{{ link.link_type }}</td>
                    <td>{{ link.href }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-outline-success btn-sm"
                        (click)="onAddLink(link)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
