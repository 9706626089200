<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <h5 class="card-title small" *ngIf="!manifest_id">
      Carrier Manifest: {{ manifest_id }}
    </h5>
    <br />
    <mantle-print
      [printSectionId]="'print-section'"
      btnLabel="Print Manifest"
    ></mantle-print>
  </div>
  <div class="card-body">
    <ng-container *ngIf="manifest">
      <div class="row">
        <div class="col-4">Carrier: {{ manifest.carrier }}</div>
        <div class="col-4">
          Ship Date: {{ manifest.ship_date | date: 'short':timezone }}
        </div>
        <div class="col-4">
          No# Items: {{ manifest.number_of_items_in_manifest }}
        </div>
      </div>

      <div class="row" id="print-section">
        <div class="col-12 d-flex justify-content-center">
          <ng-container *ngFor="let label of manifest.labels">
            <img src="{{ label.href }}" />
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
