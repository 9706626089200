import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  selectGetProductPointByid,
  selectProductList,
  selectStateStatus,
} from './state/product_reward_points.selectors';
import {
  deleteProductReward,
  loadProductReward,
} from './state/product_reward_points.actions';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import {
  STATE_STATUS,
  MANTLE_MODAL_NAME,
} from 'src/app/utils/enums/mantle-enums';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { RewardProductDetailsComponent } from './product-details/product-details.component';
import { AppState } from 'src/app/utils/interfaces/app.state';

@Component({
  selector: 'reward-product-list',
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss',
})
export class RewardProductListComponent implements OnInit, OnDestroy {
  public productList$: Observable<any>;
  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService
  ) {
    this.productList$ = this.store.select(selectProductList);

    this.subscription.add(
      this.store.select(selectStateStatus).subscribe((status) => {
        if (status == STATE_STATUS.LOADING) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      })
    );

    this.store.dispatch(loadProductReward());
  }
  ngOnInit(): void {}

  viewDetails(point_product_id: any) {
    const tSub = this.store
      .select(selectGetProductPointByid(point_product_id))
      .subscribe((tier) => {
        this.onOpenProduct(tier);
      });
    tSub.unsubscribe();
  }

  onOpenProduct(product = null) {
    const modalRef = this.mantleModalService.open(
      RewardProductDetailsComponent,
      MANTLE_MODAL_NAME.PRODUCT_REWARD_POINT_DETAILS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
      }
    );

    modalRef.componentInstance.product = product;
  }
  onDelete(point_product_id: any) {
    this.store.dispatch(deleteProductReward({ point_product_id }));
  }

  summarizeArray(arr) {
    if (arr.length > 3) {
      return [...arr.slice(0, 3), '...'];
    } else {
      return arr.slice(0, 3);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
