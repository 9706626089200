import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RewardPointState } from 'src/app/utils/interfaces/app.state';

export const selectRewardPointState = createFeatureSelector<RewardPointState>(
  'rewardPoint'
);
export const selectStateStatus = createSelector(
  selectRewardPointState,
  (state) => state.status
);

export const selectRewardPoint = createSelector(
  selectRewardPointState,
  (state) => state.rewardPoint
);
