import { Injectable } from '@angular/core';
import { EncryptStorage } from 'encrypt-storage';

const encryptStorage = new EncryptStorage('cf83e1357eefb8bdf1542850d66d8007d620e4050b5715dc83f4a921d36ce9ce47d0d13c5d85f2b0ff8318d2877eec2f63b931bd47417a81a538327af927da3e', {
  storageType: 'sessionStorage',
  prefix: '@mantle',
});
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  
  constructor() { }

  setItem(key,value:any){
    encryptStorage.setItem(key, value);
  }

  getItem(key):any{
    return encryptStorage.getItem(key)
  }

  removeItem(key){
    encryptStorage.removeItem(key);
  }

  clear(){
    encryptStorage.clear()
  }
}
