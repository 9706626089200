import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  MANTLE_MODAL_NAME,
  PAYMENT_MERCHANT_TYPES,
} from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { MerchantService } from 'src/app/utils/services/merchant.service';
import formConfig from '../../../../configs/forms/form_mx_merchant_details.json';

@Component({
  selector: 'plugin-mx-merchant',
  templateUrl: './mx-merchant.component.html',
  styleUrls: ['./mx-merchant.component.scss'],
})
export class MxMerchantComponent implements OnInit, OnDestroy {
  @Input() merchant_id: any;
  @Input() merchantDetails: any;
  isvalidated: any = false;

  mxForm = this.fb.group({
    merchant_id: [],
    mx_auth_type: [1],
    merchant_type_id: [PAYMENT_MERCHANT_TYPES.PAYMENT_MX_MERCHANT],
  });

  options: FormlyFormOptions = {
    formState: {
      environment: [
        { value: 'sandbox', label: 'Sandbox' },
        { value: 'production', label: 'Production' },
      ],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private merchantService: MerchantService
  ) {}

  ngOnInit(): void {
    this.initData();
  }
  initData = () => {
    this.fields = formConfig;

    if (this.merchantDetails != undefined) {
      this.merchant_id = this.merchantDetails.merchant_id;
      setTimeout(() => {
        this.patchForm(this.merchantDetails);
      }, 1);
    } else if (this.merchant_id != undefined) {
      this.loadFormData();
    }
  };

  loadFormData = () => {
    this.spinner.show();
    this.merchantService.getMerchantById(this.merchant_id).subscribe(
      (res) => {
        this.patchForm(res);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  };

  ngOnDestroy(): void {}

  onValidate() {
    this.spinner.show();
    const requestPayload = this.mxForm.getRawValue();
    this.merchantService.mxValidateCredentials(requestPayload).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.recordCount > 0) {
          this.isvalidated = true;
          this.toastr.success('Valid Credentials');
          if (res.recordCount == 1) {
            const data = res.records[0];
            this.merchantDetails = {
              ...requestPayload,
              ...{
                mx_id: data.id,
                mx_dba: data.dba,
                enabled: data.canProcessPayment,
              },
            };

            this.patchForm(this.merchantDetails);
          } else {
            //Not Sure if same credentials can have multiple merchant
            this.toastr.warning('Multiple Merchants Found');
          }
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  }

  onUpdate() {
    this.spinner.show();
    const requestPayload = this.mxForm.getRawValue();
    this.merchantService.updateMerchant(requestPayload).subscribe(
      (res) => {
        this.patchForm(res);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  }

  onSave() {
    this.spinner.show();
    var requestPayload = this.mxForm.getRawValue();
    delete requestPayload.merchant_id;
    this.merchantService.createMerchant(requestPayload).subscribe(
      (res) => {
        this.merchant_id = res.merchant_id;
        this.patchForm(res);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  }

  patchForm(value) {
    this.mxForm.patchValue(value);
  }
  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.MX_MERCHANT_MODAL);
  }
}
