<form [formGroup]="orderForm" (ngSubmit)="submitOrder()">
  <div class="card" style="max-height: fit-content">
    <div class="card-header">
      <h3 class="card-title small">Purchase Order</h3>
      <div class="card-tools">
        <btn-barcode-generator
          [multipleBarcodes]="true"
          [(productItems)]="orderItems"
        ></btn-barcode-generator>
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="card">
        <div class="card-header">
          <div class="row mb-4" *ngIf="orderDetails?.order_number">
            <div class="col-md-4">
              <h6>PO No#: {{ orderDetails?.order_number }}</h6>
            </div>
            <div class="col-md-8">
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Button group with nested dropdown"
              >
                <ng-container
                  *ngIf="order_id != null && updateStatuses.length > 0"
                >
                  <ng-container
                    *ngFor="
                      let status of updateStatuses | slice: 0:3;
                      index as i
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm me-2"
                      (click)="onStatusUpdate(status.purchase_status_id)"
                    >
                      {{ status.description }}
                    </button>
                  </ng-container>
                  <button
                    *ngIf="updateStatuses.length > 3"
                    id="btnGroupDrop1"
                    type="button"
                    class="btn btn-outline-primary btn-sm dropdown-toggle w-2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    ...
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <ng-container
                      *ngFor="
                        let status of updateStatuses | slice: 3:100;
                        index as i
                      "
                    >
                      <li>
                        <button
                          type="button"
                          class="dropdown-item btn btn-primary btn-sm me-2"
                          (click)="onStatusUpdate(status.purchase_status_id)"
                        >
                          {{ status.description }}
                        </button>
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-5">
              <div class="row">
                <div class="col-md-4">
                  <label class="control-label small">Vendor</label>
                </div>
                <div class="col-md-8">
                  <h6 [hidden]="isNewRecord">
                    {{ vendorData?.name }}
                  </h6>
                  <!--input type="text" class="form-control form-control-sm" /-->
                  <mantle-vendor-search
                    [hidden]="!isNewRecord"
                    (change)="vendorSelected($event)"
                    ([customerId])="(vendorData?.vendor_id)"
                    formControlName="vendor_id"
                  ></mantle-vendor-search>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                (click)="onViewVendor(vendorData?.vendor_id)"
                *ngIf="vendorData?.vendor_id"
              >
                <i class="far fa-eye"></i>
              </button>

              <button
                type="button"
                class="btn btn-sm btn-secondary ml-2"
                (click)="onViewVendor()"
              >
                New Vendor
                <i class="fas fa-user-plus"></i>
              </button>
            </div>
            <div class="col-md-5">
              <div class="row">
                <div class="col-md-12" *ngIf="canEdit">
                  <span>Save PO As:</span>
                  <div class="btn-toolbar" role="toolbar">
                    <div class="btn-group btn-group-sm mr-2" role="group">
                      <button
                        *ngIf="canEdit"
                        type="button"
                        class="btn btn-success"
                        (click)="submitOrder()"
                      >
                        <i class="fas fa-save"></i> Draft
                      </button>
                    </div>
                    <div
                      class="btn-group btn-group-sm mr-2"
                      role="group"
                      aria-label="Second group"
                    >
                      <button
                        *ngIf="canEdit"
                        type="button"
                        class="btn btn-success"
                        (click)="saveAs(2)"
                      >
                        Submitted
                      </button>
                    </div>
                    <div
                      class="btn-group btn-group-sm mr-2"
                      role="group"
                      aria-label="Second group"
                    >
                      <button
                        *ngIf="canEdit"
                        type="button"
                        class="btn btn-success"
                        (click)="saveAs(3)"
                      >
                        Accepted
                      </button>
                    </div>
                    <div
                      class="btn-group btn-group-sm mr-2"
                      role="group"
                      aria-label="Second group"
                    >
                      <button
                        *ngIf="canEdit"
                        type="button"
                        class="btn btn-success"
                        (click)="saveAs(4)"
                      >
                        Completed
                      </button>
                    </div>
                  </div>
                </div>
                <!--ng-container *ngIf="order_id != null">
                  <div class="col-md-5">
                    <ng-select
                      [items]="updateStatuses"
                      bindLabel="description"
                      bindValue="purchase_status_id"
                      formControlName="purchase_update_status_id"
                    >
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <div class="btn-group mb-2" role="group">
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary ml-2"
                        (click)="updateStatus()"
                      >
                        <i class="fas fa-save"></i> Update PO Status
                      </button>
                    </div>
                  </div>
                </ng-container-->
              </div>
            </div>
          </div>
        </div>
        <fieldset
          [disabled]="
            !canEdit || orderForm.getRawValue()?.vendor_id == undefined
          "
        >
          <div class="card-body">
            <div
              class="row"
              *ngIf="canEdit && orderForm.getRawValue()?.vendor_id == undefined"
            >
              <div class="col-md-12 alert alert-warning" role="alert">
                No vendor is selected!
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label class="control-label small">PO Status</label>

                <ng-select
                  [items]="orderStatuses"
                  bindLabel="description"
                  bindValue="purchase_status_id"
                  formControlName="purchase_status_id"
                  style="height: calc(1.8125rem + 2px)"
                  readonly="true"
                >
                </ng-select>
              </div>
              <div class="form-group col-md-3">
                <label class="control-label small">Order Date</label>
                <input
                  type="datetime-local"
                  class="form-control form-control-sm"
                  formControlName="order_date"
                  IsoDateLocal
                />
              </div>
              <div class="form-group col-md-3">
                <label class="control-label small">Requisitioner</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="requisitioner"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="control-label small">Currency</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="currency_code"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <order-product-search
                          [itemRef]="itemRef"
                          [notDialog]="true"
                          [have_invetory]="false"
                        ></order-product-search>
                      </div>
                    </div>
                    <table class="table table-striped small table-responsive">
                      <thead>
                        <th scope="col" width="25%">Item</th>
                        <th scope="col">Cost</th>
                        <th scope="col">Order Qty</th>
                        <th scope="col">Received Qty</th>
                        <th scope="col">Sale Price</th>
                        <th scope="col">On-Order Qty</th>
                        <th scope="col">Total</th>
                        <th scope="col">&nbsp;</th>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngFor="let item of orderItems; index as i"
                        >
                          <tr>
                            <td>
                              <mantle-loading
                                *ngIf="item.loading_inventory"
                              ></mantle-loading>
                              {{ item.item_description }}

                              <ng-container *ngIf="item.edit">
                                <div class="input-group mb-3">
                                  <input
                                    #upcInput
                                    type="text"
                                    [(value)]="item.upc"
                                    [(ngModel)]="item.upc"
                                    placeholder="UPC"
                                    autofocus
                                    [ngModelOptions]="{
                                      standalone: true
                                    }"
                                    class="form-control form-control-sm float-right"
                                    (change)="searchProductByUpc($event, i)"
                                    (keydown.enter)="
                                      searchProductByUpc($event, i)
                                    "
                                  />
                                </div>
                              </ng-container>
                            </td>
                            <td>
                              <div class="form-group row">
                                <div class="col-md-12">
                                  <input
                                    type="number"
                                    [(value)]="item.unit_price"
                                    [(ngModel)]="item.unit_price"
                                    [ngModelOptions]="{
                                      standalone: true
                                    }"
                                    class="form-control form-control-sm float-right"
                                    (change)="onItemUpdated($event, i)"
                                    min="0"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group row">
                                <div class="col-md-12">
                                  <input
                                    type="number"
                                    [(value)]="item.quantity"
                                    [(ngModel)]="item.quantity"
                                    [ngModelOptions]="{
                                      standalone: true
                                    }"
                                    class="form-control form-control-sm float-right"
                                    (change)="onItemUpdated($event, i)"
                                    min="0"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group row">
                                <div class="col-md-12">
                                  <input
                                    type="number"
                                    [(value)]="item.quantity_received"
                                    [(ngModel)]="item.quantity_received"
                                    [ngModelOptions]="{
                                      standalone: true
                                    }"
                                    class="form-control form-control-sm float-right"
                                    (change)="onItemUpdated($event, i)"
                                    min="0"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group row">
                                <div class="col-md-12">
                                  <input
                                    type="number"
                                    [(value)]="item.sale_price"
                                    [(ngModel)]="item.sale_price"
                                    [ngModelOptions]="{
                                      standalone: true
                                    }"
                                    class="form-control form-control-sm float-right"
                                    min="0"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group row">
                                <div class="col-md-12">
                                  {{ item.onOrderQty }}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group row">
                                <div class="col-md-12">$ {{ item.total }}</div>
                              </div>
                            </td>
                            <td>
                              <button
                                type="button"
                                class="dropdown-item btn btn-sm"
                                (click)="
                                  onDeleteOrderItem(
                                    i,
                                    item.order_item_id,
                                    item.order_id
                                  )
                                "
                                *ngIf="canEdit"
                              >
                                <i class="fas fa-trash-alt btn-danger"></i>
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer text-muted">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label class="control-label small">PO. Note</label>

                        <textarea
                          cols="30"
                          rows="3"
                          class="form-control form-control-sm"
                          formControlName="notes"
                        >
                        </textarea>
                        <!--button
                          type="button"
                          *ngIf="canEdit"
                          class="btn btn-secondary btn-sm"
                          (click)="onAddOrderItem()"
                        >
                          <i class="fas fa-cart-plus"></i> Add Item(s)
                        </button-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-md-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card collapsed-card">
                      <div class="card-header">
                        <h5 style="float: left">
                          $ {{ orderForm.value.order_total }}
                        </h5>
                        <div class="card-tools">
                          <!-- Collapse Button -->
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-12 m-0">
                            <label class="control-label small m-0"
                              >Shipment Amount:</label
                            >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              formControlName="shipment_amount"
                              (change)="calculateTotal()"
                            />
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-12 m-0">
                            <label class="control-label small m-0"
                              >Discount Amount:</label
                            >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              formControlName="discount_amount"
                              (change)="calculateTotal()"
                            />
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-12 m-0">
                            <label class="control-label small m-0"
                              >Tax Amount:
                            </label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              formControlName="tax_amount"
                              (change)="calculateTotal()"
                            />
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-12 m-0">
                            <label class="control-label small m-0"
                              >Total Amount:</label
                            >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              formControlName="order_total"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card collapsed-card">
                      <div class="card-header">
                        <span class="small"><b>Billing Address</b></span>

                        <div class="card-tools">
                          <!-- Collapse Button -->
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body" formGroupName="billing_address">
                        <div class="row">
                          <label class="control-label small">Store</label>
                          <ng-select
                            [items]="stores"
                            bindLabel="store_name"
                            bindValue="store_id"
                            (change)="onSelectStore($event, 'billing_address')"
                          >
                          </ng-select>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small">Name</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="name"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small"
                              >Address Line 1</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="street_address"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small"
                              >Address Line 2</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="street_address_2"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small">City</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="city"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small">Zip Code</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="zipcode"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small">State</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="state"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small">Country</label>
                            <ng-select
                              [items]="countries"
                              bindLabel="country_name"
                              bindValue="country_code"
                              formControlName="country_code"
                            >
                            </ng-select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small"
                              >Contact No#</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="phone"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small"
                              >Email Address</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card collapsed-card">
                      <div class="card-header">
                        <span class="small"><b>Shipping Address</b></span>

                        <div class="card-tools">
                          <!-- Collapse Button -->
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body" formGroupName="shipping_address">
                        <div class="row">
                          <label class="control-label small">Store</label>
                          <ng-select
                            [items]="stores"
                            bindLabel="store_name"
                            bindValue="store_id"
                            (change)="onSelectStore($event, 'shipping_address')"
                          >
                          </ng-select>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small">Name</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="name"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small"
                              >Address Line 1</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="street_address"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small"
                              >Address Line 2</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="street_address_2"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small">City</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="city"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small">Zip Code</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="zipcode"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small">State</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="state"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small">Country</label>
                            <ng-select
                              [items]="countries"
                              bindLabel="country_name"
                              bindValue="country_code"
                              formControlName="country_code"
                            >
                            </ng-select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small"
                              >Contact No#</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="phone"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small"
                              >Email Address</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card collapsed-card">
                      <div class="card-header">
                        <span class="small"><b>Vendor Address</b></span>

                        <div class="card-tools">
                          <!-- Collapse Button -->
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body" formGroupName="vendor_address">
                        <!--div class="row">
                                <div
                                  class="col-md-12 form-check"
                                  style="margin-right: 20px"
                                >
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    (change)="onCopyCustomerAddress($event, 1)"
                                    id="use_customer_billing"
                                  />
                                  <label
                                    class="form-check-label small"
                                    for="use_customer_billing"
                                  >
                                    Use Customer Address</label
                                  >
                                </div>
                              </div-->
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small">Name</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="name"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small"
                              >Address Line 1</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="street_address"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label small"
                              >Address Line 2</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="street_address_2"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small">City</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="city"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small">Zip Code</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="zipcode"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small">State</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="state"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small">Country</label>
                            <ng-select
                              [items]="countries"
                              bindLabel="country_name"
                              bindValue="country_code"
                              formControlName="country_code"
                            >
                            </ng-select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label small"
                              >Contact No#</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="phone"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label small"
                              >Email Address</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</form>
