<form [formGroup]="vendorSearchForm">
    <ng-select 
        [items]="vendor_list$ | async" 
        bindLabel="name" 
        bindValue="vendor_id"
        (keydown.enter)="searchVendor($event)"
        formControlName="vendor_id"
        [loading]="loadingVendor" 
        (change)="changeVendor($event)" 
        notFoundText="No Items Found - Press Enter To Search"
        >
        <ng-template ng-option-tmp let-item="item">
            <div [title]="item.name">{{item.name}}</div>
        </ng-template>
    </ng-select>
</form>