<div class="card">
  <div
    class="card-header"
    style="
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
      background-color: #fff;
    "
  >
    <!--h3 class="card-title small">Cash Register</h3-->
    <div class="row">
      <table lass="table table-responsive">
        <thead>
          <tr>
            <th>Drawer Total (Expected)</th>
            <th>Drawer Total (Counted)</th>
            <th>Over / Short</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ closingBalanceAmount | number: '1.2-2' }}</td>
            <td>{{ totalAmount | number: '1.2-2' }}</td>
            <td>{{ amountMargin | number: '1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body overflow-auto">
    <form [formGroup]="cashForm">
      <div class="row">
        <div class="row">
          <div class="col-4">Denomination</div>
          <div class="col-4">Count</div>
          <div class="col-4">Total</div>
        </div>
      </div>
      <div class="row">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="cashForm"
        ></formly-form>
      </div>
    </form>
  </div>
  <div class="card-footer" *ngIf="!isCashRegisterLog">
    <div class="row">
      <div class="col-4">&nbsp;</div>
      <div class="col-4">
        <button
          type="button"
          class="btn btn-outline-success btn-sm"
          (click)="save()"
          [disabled]="cashForm.invalid"
        >
          <i class="fas fa-save"></i> Save & Close Register
        </button>
      </div>
      <div class="col-4">Total {{ totalAmount | number: '1.2-2' }}</div>
    </div>
  </div>
</div>
