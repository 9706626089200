<div class="card" *ngIf="has_data">
    <div class="card-header">
        <h3 class="card-title">Summary</h3>
        <div class="card-tools">
            <!-- Collapse Button -->
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
        </div>
        <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">
        <div class="row">
            <div class="col-4">
                <div class="info-box">
                    <span class="info-box-icon bg-info"><i class="fas fa-shopping-cart"></i></span>
                    <div class="info-box-content">
                      <span class="info-box-text">TOTAL PRODUCTS</span>
                      <span class="info-box-number">{{summary_data.total_product}}</span>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="info-box">
                    <span class="info-box-icon bg-warning"><i class="fas fa-exclamation"></i></span>
                    <div class="info-box-content">
                      <span class="info-box-text">LOW STOCK PRODUCTS</span>
                      <span class="info-box-number">{{summary_data.low_stock}}</span>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="info-box">
                    <span class="info-box-icon bg-danger"><i class="far fa-envelope"></i></span>
                    <div class="info-box-content">
                      <span class="info-box-text">OUT OF STOCK PRODUCTS</span>
                      <span class="info-box-number">{{summary_data.out_of_stock}}</span>
                    </div>
                </div>
            </div>
        </div>
          
    </div>
    <!-- /.card-body -->
</div>
<!-- /.card -->