import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/utils/services/api.service';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';

@Component({
  selector: 'mantle-inventory-upload',
  templateUrl: './mantle-inventory-upload.component.html',
  styleUrls: ['./mantle-inventory-upload.component.scss'],
})
export class MantleInventoryUploadComponent implements OnInit {
  channelList: Array<any> = [];
  headers: any = [];
  salesChannelHeaders: any = [];
  validCsvfile: boolean = false;
  file: File = null;
  uploadHistory = [];
  uploadInProgress = false;
  progresspct = 0;
  updateOnlyLocalInv = false;
  timezone = this.commonService.getTimeZone();

  constructor(
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private _FileSaverService: FileSaverService
  ) {}

  ngOnInit(): void {
    this.init_data();
  }

  init_data() {
    this.spinner.show();
    const obs = this.channelsService.getChannels();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.channelList = res;
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(JSON.stringify(err));
      }
    );

    this.getUploadProgress();
    this.getUploadHistory();
  }

  uploadListener($event: any) {
    this.headers = [];
    this.salesChannelHeaders = [];
    this.validCsvfile = false;

    let text = [];
    let files = $event.srcElement.files;
    this.file = files[0];

    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();

      reader.onload = (progressEvent) => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/).slice(0, 1);

        this.headers = this.getHeaderArray(csvRecordsArray);

        let headermsg = this.validateHeaders();

        if (headermsg != '') {
          this.toastr.error(headermsg);
          return;
        }

        this.validCsvfile = true;
      };
      reader.onerror = () => {
        this.toastr.error('error is occured while reading file!');
      };

      reader.readAsText(input.files[0]);
    } else {
      this.toastr.error('Please import valid .csv file.');
      //this.fileReset();
    }
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  validateHeaders() {
    var erroMessage = '';

    if (!this.headers.includes('Mantle SKU')) {
      erroMessage = erroMessage.concat(
        '\r\n',
        'Mantle SKU - Data Column Missing'
      );
    }
    if (!this.headers.includes('Reserve Quantity')) {
      erroMessage = erroMessage.concat(
        '\r\n',
        'Reserve Quantity - Data Column Missing'
      );
    }
    if (!this.headers.includes('Sale Price')) {
      erroMessage = erroMessage.concat(
        '\r\n',
        'Sale Price - Data Column Missing'
      );
    }

    this.salesChannelHeaders = this.headers.filter((x) =>
      x.toString().includes('Sales Channel')
    );

    if (this.salesChannelHeaders.length < 1) {
      // erroMessage = erroMessage.concat(
      //   '\r\n',
      //   'No Data Column Matches sales column'
      // );
    } else {
      this.salesChannelHeaders = this.salesChannelHeaders.map((x) => {
        return {
          columnName: x,
          sales_channel_id: null,
        };
      });
    }
    return erroMessage;
  }

  onChannelChange($event, i) {
    if ($event != undefined) {
      var exists = this.salesChannelHeaders.filter(
        (x) => x.sales_channel_id == $event['sales_channel_id']
      );
      if (exists.length > 1) {
        this.toastr.error('The selected Sales Channel already mapped');
        this.salesChannelHeaders[i]['sales_channel_id'] = '';
      }
    }
  }

  onRemoveColumn(index) {
    this.salesChannelHeaders.splice(index, 1);
  }

  onUpload() {
    this.spinner.show();
    var unMapped = this.salesChannelHeaders.filter(
      (x) =>
        x.sales_channel_id == null ||
        x.sales_channel_id == '' ||
        x.sales_channel_id == undefined
    );

    if (unMapped.length > 0 && !this.updateOnlyLocalInv) {
      this.toastr.error(
        'Not all columns are mapped to a channel, Please map or remove'
      );
      this.spinner.hide();
      return;
    }
    const formData = new FormData();
    formData.append('file', this.file, this.file.name);
    formData.append('body', JSON.stringify(this.salesChannelHeaders));
    formData.append('updateOnlyLocal', this.updateOnlyLocalInv.toString());

    const obs = this.inventoryService.bulkUploadInventory(formData);

    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('File uploaded, processing in progress');
        this.getUploadProgress();
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  getUploadHistory() {
    this.spinner.show();
    const obs = this.inventoryService.getUploadHistory();

    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.uploadHistory = res;
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  getUploadProgress() {
    const obs = this.inventoryService.getUploadProgress();
    obs.subscribe(
      (res) => {
        this.uploadInProgress = res.uploadInProgress;
        this.progresspct = res.pctComplete;

        if (this.uploadInProgress) {
          setTimeout(() => {
            this.getUploadProgress();
          }, 1000);
        } else {
          this.getUploadHistory();
        }
      },
      (err) => {
        this.toastr.error(JSON.stringify(err));
        // setTimeout(() => {
        //   this.getUploadProgress()
        // }, 1000)
      }
    );
  }

  downloadFile(source, fileName) {
    this.spinner.show();

    const obs = this.inventoryService.downloadFile({
      fileName: fileName,
      source: source,
    });

    obs.subscribe(
      (res) => {
        this._FileSaverService.save(<any>res, fileName);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      }
    );
  }

  updateOnlyLocal($event) {
    this.updateOnlyLocalInv = $event.target.checked;
  }
}
