import { createAction, props } from '@ngrx/store';
const RW_PREFIX = '[Reward]';

export const loadRewardPoints = createAction(
  `[${RW_PREFIX}] Load Reward Points`
);
export const loadRewardPointsSuccess = createAction(
  `[${RW_PREFIX}] Load Reward Success`,
  props<{ rewardPoint: any }>()
);
export const loadRewardPointsFailure = createAction(
  `[${RW_PREFIX}] Load Reward Failure`,
  props<{ error: any }>()
);

export const saveRewardPoints = createAction(
  `[${RW_PREFIX}] Save Reward Points`,
  props<{ rewardPoint: any }>()
);
