import { Component, Input, OnInit } from '@angular/core';
import { ReportingService } from 'src/app/utils/services/reporting.service';

@Component({
  selector: 'mantle-report-widget',
  templateUrl: './mantle-report-widgets.component.html',
  styleUrls: ['./mantle-report-widgets.component.scss'],
})
export class MantleReportWidgetsComponent implements OnInit {
  @Input() report_id;
  report_details: any;
  report_type_id: any;
  loading: boolean = true;
  hasError: any = false;
  errorText: any;

  constructor(private reportingService: ReportingService) {}
  ngOnInit() {
    this.getReportDetails();
  }

  getReportDetails = async () => {
    this.reportingService.getReportById(this.report_id).subscribe(
      (res) => {
        this.report_type_id = res.report_type_id;
        this.report_details = res;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.setError(error);
      }
    );
  };
  setError(error: any) {
    this.hasError = true;
    this.errorText = error?.error?.message
      ? error?.error?.message
      : error.message;
  }
}
