import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  MANTLE_MODAL_NAME,
  STATE_STATUS,
} from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { CustomerService } from 'src/app/utils/services/customer.service';
import { OrderService } from 'src/app/utils/services/order.service';
import formConfig from '../../../configs/forms/form_customer_details.json';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { addCustomer, editCustomer } from './state/customer.actions';
import {
  selectCustomer,
  selectCustomerStateStatus,
  selectStateStatus,
} from './state/customer.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mantle-customer',
  templateUrl: './mantle-customer.component.html',
  styleUrls: ['./mantle-customer.component.scss'],
})
export class MantleCustomerComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() customerData;
  countries: Array<any> = [];
  private subscription: Subscription = new Subscription();
  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private customerService: CustomerService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private store: Store<AppState>
  ) {}
  ngAfterViewInit(): void {}

  customerForm = this.fb.group({
    customer_id: [],
  });
  options: FormlyFormOptions = {
    formState: {
      countries: [{ value: 'US', label: 'USA' }],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  ngOnInit(): void {
    this.fields = formConfig;
    setTimeout(() => {
      this.patchForm(this.customerData);
    }, 1);
    this.getLookupValues();

    setTimeout(() => {
      this.subscription.add(
        this.store.select(selectCustomer).subscribe((activeCustomer) => {
          if (activeCustomer) {
            this.patchForm(activeCustomer);
            this.orderService.searchOrder.next({
              customer_id: activeCustomer.customer_id,
            });
            this.emmitCustomer(); //To be reviewed
          }
        })
      );
      this.subscription.add(
        this.store.select(selectCustomerStateStatus).subscribe((status) => {
          if (status == STATE_STATUS.LOADING) {
            this.spinner.show();
          } else {
            this.spinner.hide();
          }
        })
      );

      if (this.customerData?.customer_id) {
        this.orderService.searchOrder.next({
          customer_id: this.customerData.customer_id,
        });
      }
    }, 0);
  }

  submitCustomer() {
    const requestPayload = JSON.parse(
      JSON.stringify(this.customerForm.getRawValue())
    );
    if (requestPayload.customer_id) {
      setTimeout(() => {
        this.store.dispatch(editCustomer(requestPayload));
      });
    } else {
      setTimeout(() => {
        this.store.dispatch(addCustomer(requestPayload));
      });
    }

    /*this.spinner.show();

    const data = this.customerForm.getRawValue();
    if (data.customer_id) {
      this.customerService.updateCustomer(data).subscribe(
        (res) => {
          this.patchForm(res);
          this.emmitCustomer();
          this.spinner.hide();
          this.closeModal();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(JSON.stringify(err.message), 'An Error Occurred');
        }
      );
    } else {
      this.customerService.createCustomer(data).subscribe(
        (res) => {
          this.patchForm(res);
          this.emmitCustomer();
          this.spinner.hide();
          this.closeModal();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(JSON.stringify(err.message), 'An Error Occurred');
        }
      );
    }*/
  }

  getLookupValues() {
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.options.formState.countries = res['countries'].map((x) => {
          return {
            value: x.country_code,
            label: x.country_name,
          };
        });
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  emmitCustomer() {
    const customer = this.customerForm.getRawValue();
    this.commonService.external_customer_search.next(customer);
    this.commonService.external_customer_set.next(customer);
    this.orderService.customer_selected.next(customer);
  }
  patchForm(value) {
    this.customerForm.patchValue(value);
    this.model = { ...this.model, ...value };
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.CUSTOMER_MODAL);
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
