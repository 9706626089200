import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import queryString from 'query-string';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private apiService: ApiService) {}

  product_search_result = new Subject();
  customer_selected = new Subject();
  searchOrder = new Subject();
  searchDefault = new Subject();
  order_list_all_items = new Subject();
  order_list_selected_items = new Subject();
  order_list_component_initialized = new Subject();

  getOrders(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/order/orders', requestPayload);
  }

  createOrder(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/order', requestPayload);
  }

  updateOrder(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/order', requestPayload);
  }

  getOrderById(order_id): Observable<any> {
    return this.apiService.get('/api/v1/order/' + order_id);
  }

  searchOrders(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/order/search', requestPayload);
  }

  deleteOrder(order_id): Observable<any> {
    return this.apiService.delete('/api/v1/order/' + order_id);
  }

  deleteOrderItem(order_item_id, order_id): Observable<any> {
    return this.apiService.delete('/api/v1/order/order_item', {
      order_item_id: order_item_id,
      order_id: order_id,
    });
  }

  updateOrderStatus(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/order/update_status', requestPayload);
  }

  confirmPayment(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/order/confirm_payment',
      requestPayload
    );
  }

  reclaimOrder(order_ids): Observable<any> {
    return this.apiService.post('/api/v1/order/reclaim/', {
      order_ids: order_ids,
    });
  }

  getOrderPaymentStatus(order_id): Observable<any> {
    return this.apiService.get(`/api/v1/order/${order_id}/payment-status`);
  }

  getReturnOrders = (filterParams = null): Observable<any> => {
    const query_param = queryString.stringify(filterParams);
    return this.apiService.get(`/api/v1/order-return?${query_param}`);
  };

  returnOrder = (requestPayload): Observable<any> => {
    return this.apiService.post('/api/v1/order-return', requestPayload);
  };
  getReturnOrderById = (order_return_id): Observable<any> => {
    return this.apiService.get('/api/v1/order-return/' + order_return_id);
  };

  createKioskOrder(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/order/kiosk', requestPayload);
  }
}
