import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { ChannelActivateBcComponent } from '../activation/channel-activate-bc/channel-activate-bc.component';
import { ChannelActivateShopifyComponent } from '../activation/channel-activate-shopify/channel-activate-shopify.component';
import { ChannelActivateTcgComponent } from '../activation/channel-activate-tcg/channel-activate-tcg.component';
import { ChannelActivateAmazonSpComponent } from '../activation/channel-activate-amazon-sp/channel-activate-amazon-sp.component';
import { ChannelActivateCardtraderComponent } from '../activation/channel-activate-cardtrader/channel-activate-cardtrader.component';
import {
  CHANNEL_REF_ENUM,
  MANTLE_MODAL_NAME,
} from 'src/app/utils/enums/mantle-enums';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { ChannelActivateWcComponent } from '../activation/channel-activate-wc/channel-activate-wc.component';

@Component({
  selector: 'channel-options',
  templateUrl: './channel-options.component.html',
  styleUrls: ['./channel-options.component.scss'],
})
export class ChannelOptionsComponent implements OnInit {
  channelOptions: Array<any> = [];

  constructor(
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private mantleModalService: MantleModalService
  ) {}

  ngOnInit(): void {
    this.getChannelOptions();
  }

  getChannelOptions() {
    this.spinner.show();
    const obs = this.channelsService.getChannelOptions();
    obs.subscribe(
      (res) => {
        this.channelOptions = res;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onSelectChannel(CHANNEL_REF, index) {
    if (this.channelOptions[index].channel_status == 1) {
      switch (CHANNEL_REF) {
        case CHANNEL_REF_ENUM.TCG:
          const modalRef = this.mantleModalService.open(
            ChannelActivateTcgComponent,
            MANTLE_MODAL_NAME.TCG_ACTIVATION_MODAL,
            {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              backdrop: 'static',
            }
          );
          modalRef.componentInstance.channelOption = this.channelOptions[index];

          // this.commonService.modal_close.subscribe((data) => {
          //   if (data === 'TCG-ACTIVATION-MODAL') {
          //     modalRef.close();
          //   }
          // });

          this.closeModal();
          break;
        case CHANNEL_REF_ENUM.BC:
          const modalRefBc = this.mantleModalService.open(
            ChannelActivateBcComponent,
            MANTLE_MODAL_NAME.BC_ACTIVATION_MODAL,
            {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              backdrop: 'static',
            }
          );
          modalRefBc.componentInstance.channelOption = this.channelOptions[
            index
          ];

          // this.commonService.modal_close.subscribe((data) => {
          //   if (data === 'BC-ACTIVATION-MODAL') {
          //     modalRefBc.close();
          //   }
          // });

          this.closeModal();
          break;
        case CHANNEL_REF_ENUM.SHOPIFY:
          const modalRefShopify = this.mantleModalService.open(
            ChannelActivateShopifyComponent,
            MANTLE_MODAL_NAME.SHOPIFY_ACTIVATION_MODAL,
            {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              backdrop: 'static',
            }
          );
          modalRefShopify.componentInstance.channelOption = this.channelOptions[
            index
          ];

          // this.commonService.modal_close.subscribe((data) => {
          //   if (data === 'SHOPIFY-ACTIVATION-MODAL') {
          //     modalRefShopify.close();
          //   }
          // });
          this.closeModal();
          break;

        case CHANNEL_REF_ENUM.AMAZON_SP:
          const modalRefAmazonSp = this.mantleModalService.open(
            ChannelActivateAmazonSpComponent,
            MANTLE_MODAL_NAME.AMAZON_SP_ACTIVATION_MODAL,
            {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              backdrop: 'static',
            }
          );
          modalRefAmazonSp.componentInstance.channelOption = this.channelOptions[
            index
          ];

          // this.commonService.modal_close.subscribe((data) => {
          //   if (data === 'AMAZON-SP-ACTIVATION-MODAL') {
          //     modalRefAmazonSp.close();
          //   }
          // });
          this.closeModal();
          break;
        case CHANNEL_REF_ENUM.CARD_TRADER:
          const modalRefCt = this.mantleModalService.open(
            ChannelActivateCardtraderComponent,
            MANTLE_MODAL_NAME.CARDTRADER_ACTIVATION_MODAL,
            {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              backdrop: 'static',
            }
          );
          modalRefCt.componentInstance.channelOption = this.channelOptions[
            index
          ];
          this.closeModal();
          break;
        case CHANNEL_REF_ENUM.WOO_COMMERCE:
          const modalRefWc = this.mantleModalService.open(
            ChannelActivateWcComponent,
            MANTLE_MODAL_NAME.WOO_COMMERCE_ACTIVATION_MODAL,
            {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              backdrop: 'static',
            }
          );
          modalRefWc.componentInstance.channelOption = this.channelOptions[
            index
          ];
          this.closeModal();
          break;
          break;
        default:
          this.toastr.warning(
            'Selected Channel Not Allowed',
            'Invalid Channel'
          );
          break;
      }
    } else {
      this.toastr.warning('The Selected Channel is not Enabled');
    }
  }

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.CHANNEL_OPTIONS_MODAL
    );
  }
}
