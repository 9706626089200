<div class="card">
  <div class="card-header">
    <h3 class="card-title small">Merchant Details</h3>
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="mxForm"
        ></formly-form>
      </div>
    </div>
    <div class="row rounded mt-2" *ngIf="merchant_id">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <h5>Allocated Terminals</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mantle-terminal-list [(merchant_id)]="merchant_id"></mantle-terminal-list>
          </div>
        </div>
      </div>
    </div>
    <div class="row rounded mt-2" *ngIf="merchant_id">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <h5 class="mt-2">Available Terminals</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <plugin-mx-terminal-list [(merchant_id)]="merchant_id"></plugin-mx-terminal-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-muted">
    <div class="row">
      <div class="col-4">
        <ng-container>
          <button
            [disabled]="!mxForm.valid"
            class="btn btn-outline-info btn-sm w-50 mr-2"
            (click)="onValidate()"
          >
            <i class="fas fa-check-double"></i> Validate
          </button>
        </ng-container>
      </div>
      <div class="col-4">
        <ng-container *ngIf="!merchant_id">
          <button
            type="submit"
            [disabled]="!isvalidated"
            class="btn btn-outline-success btn-sm w-50 mr-2"
            (click)="onSave()"
          >
            Save
          </button>
        </ng-container>
      </div>
      <div class="col-4">
        <ng-container *ngIf="merchant_id">
          <button
            type="submit"
            [disabled]="!isvalidated"
            class="btn btn-outline-success btn-sm w-50 mr-2"
            (click)="onUpdate()"
          >
            Update
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>