<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <h5 class="card-title small" *ngIf="!tracking_data">
      Tracking Number: {{ tracking_number }}
    </h5>
    <br />
    <ng-container *ngIf="tracking_data">
      <div class="row">
        <div class="col-3">Tracking Number: {{ tracking_number }}</div>
        <div class="col-3">Carrier Name: {{ tracking_data.carrier_name }}</div>
        <div class="col-3">
          Status: {{ tracking_data.carrier_status_description }}
        </div>
        <div class="col-3">
          Est. Delivery Date:
          {{ tracking_data.estimated_delivery_date | date: 'short':timezone }}
        </div>
      </div>
      <div class="row small">
        <div class="col-12">
          <strong><u>Destination</u></strong>
        </div>
      </div>
      <div class="row">
        <div class="col-3">City: {{ tracking_data.destination?.city }}</div>
        <div class="col-3">
          State: {{ tracking_data.destination?.state_province }}
        </div>
        <div class="col-3">
          Postal Code: {{ tracking_data.destination?.postal_code }}
        </div>
        <div class="col-3">
          Country Code: {{ tracking_data.destination?.country_code }}
        </div>
      </div>
    </ng-container>
  </div>
  <div class="card-body">
    <div class="row bg-light">
      <div class="col-12">
        <ngx-timeline
          [itens]="tracking_timeline"
          [formatDate]="'MM/dd/yyyy HH:mm'"
          [sortDesc]="true"
          [showMenu]="true"
          [showGroup]="false"
          [theme]="'minimalist'"
        >
        </ngx-timeline>
      </div>
    </div>
  </div>
</div>
