import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public user = {
    firstName: 'Alexander',
    lastName: 'Pierce',
    image: 'assets/img/user2-160x160.jpg',
  };

  constructor(private router: Router, private apiService: ApiService, private toastr: ToastrService,public auth: AuthService) { }

  login(req) {
    this.apiService.post('/api/v1/auth', req).subscribe((res) => {
      //localStorage.setItem('token', 'LOGGED_IN');
      this.setSession(res);
      localStorage.setItem('USER_PROFILE', JSON.stringify(res.user));
      this.router.navigate(['/']);
    },
      (err) => {
        
        this.toastr.error("Login Failed")
      })
  }

  register() {
    localStorage.setItem('token', 'LOGGED_IN');
    this.router.navigate(['/']);
  }

  logout() {
    localStorage.removeItem('session_token');
    localStorage.removeItem("expires_at");
    this.auth.logout()
    //this.router.navigate(['/login']);
  }

  setSession(authResult) {
    const expiresAt = moment().add(authResult.expiresIn,'second');

    localStorage.setItem('session_token', authResult.token);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
  }

  isLoggedIn(){
    return moment().isBefore(this.getExpiration());
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
}   
}
