import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { PurchaseOrderService } from 'src/app/utils/services/purchase-order.service';
import { UserService } from 'src/app/utils/services/user.service';
import { VendorService } from 'src/app/utils/services/vendor.service';
import { MantleMultipleUpcComponent } from '../../mantle-order/mantle-multiple-upc/mantle-multiple-upc.component';
import {
  MANTLE_MODAL_NAME,
  PURCHASE_ORDER_STATUS,
} from 'src/app/utils/enums/mantle-enums';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { ISODateFormControl } from 'src/app/components/forms/form-control/ISODateFormControl';
import { ProductService } from 'src/app/utils/services/product.service';

@Component({
  selector: 'mantle-po-details',
  templateUrl: './mantle-po-details.component.html',
  styleUrls: ['./mantle-po-details.component.scss'],
})
export class MantlePoDetailsComponent implements OnInit, OnDestroy {
  @Input() order_id;
  isNewRecord: boolean = true;
  vendorData: any;
  orderDetails: any = {};
  canEdit: boolean = true;
  orderStatuses: Array<any> = [];
  updateStatuses: Array<any> = [];
  orderItems: Array<any> = [];
  itemRef: number;
  @ViewChild('upcInput') upcInputs: ElementRef;
  address: any = {
    name: [],
    street_address: [],
    street_address_2: [],
    city: [],
    state: [],
    zipcode: [],
    country_code: ['US'],
    email: [],
    phone: [],
    mobile: [],
  };
  elem: string | HTMLElement;
  multipleUPCModalSubscription: any;
  countries: Array<any> = [
    {
      country_code: 'US',
      country_name: 'USA',
    },
  ];
  stores: Array<any> = [];
  private vendorSelectedlSubscription: Subscription;
  private storesSubscription: Subscription;
  private productSearchSubscription: Subscription;
  private statusSubscription: Subscription;
  timezone: any = this.commonService.getTimeZone(true);
  constructor(
    private fb: UntypedFormBuilder,
    private vendorService: VendorService,
    private poService: PurchaseOrderService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private mantleModalService: MantleModalService,
    private toastr: ToastrService,
    private auth0Service: Auth0Service,
    private productService: ProductService,
    private inventoryService: InventoryService,
    private settingsService: SettingsService,
    private orderService: OrderService,
    @Inject(DOCUMENT) private document: any
  ) {}

  orderForm = this.fb.group({
    order_id: [],
    vendor_id: [],
    order_date: new ISODateFormControl(
      this.commonService,
      moment().tz(this.timezone).format('YYYY-MM-DDTHH:mm')
    ),
    store_id: [],
    due_date: [],
    requisitioner: [],
    shipment_method: [],
    purchase_status_id: [PURCHASE_ORDER_STATUS.DRAFT],
    sub_total: [0],
    order_total: [0],
    tax_amount: [0],
    discount_amount: [0],
    shipment_amount: [0],
    currency_code: ['USD'],
    notes: [],
    purchase_update_status_id: [],
    billing_address: this.fb.group({
      ...{
        billing_address_id: [],
      },
      ...this.address,
    }),
    shipping_address: this.fb.group({
      ...{
        shipping_address_id: [],
      },
      ...this.address,
    }),
    vendor_address: this.fb.group({
      ...{
        vendor_address_id: [],
      },
      ...this.address,
    }),
  });

  ngOnInit(): void {
    this.vendorSelectedlSubscription = this.vendorService.vendorSelected.subscribe(
      (res) => {
        this.vendorData = res;
        this.vendorSelected(res);
      }
    );
    this.storesSubscription = this.settingsService.getStores().subscribe(
      (res) => {
        this.stores = res;
      },
      (err) => {}
    );
    this.productSearchSubscription = this.orderService.product_search_result.subscribe(
      (res) => {
        this.setSelectedItem(res);
      }
    );
    this.statusSubscription = this.poService.getPOStatuses().subscribe(
      (res) => {
        this.orderStatuses = res;
        this.setupdateStatuses();
      },
      (err) => {}
    );

    this.initValues();
  }

  initValues = () => {
    if (this.order_id != null && this.order_id != undefined) {
      this.getOrderDetails();
    }

    this.onAddOrderItem();
  };

  getOrderDetails = () => {
    this.spinner.show();
    this.poService.getPOById(this.order_id).subscribe(
      (res) => {
        this.spinner.hide();
        this.patchFormValue(res);
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err?.error?.message);
      }
    );
  };

  onViewVendor(vendor_id: any = null) {
    this.vendorService.modalPoVendorDetails(vendor_id);
  }

  vendorSelected($event: any) {
    this.vendorData = $event;

    this.setVendorDetails(this.vendorData);
  }

  setVendorDetails(details) {
    this.vendorService.externalVendorSearch.next(details);
    this.vendorService.externalVendorSet.next(details);
    this.orderForm.patchValue({ vendor_id: details?.vendor_id });
    this.setAddress(details, 'vendor_address');
  }

  onSelectStore = ($event, target) => {
    this.setAddress(
      {
        name: $event.store_name,
        email: $event.store_email,
        phone: $event.store_phone,
        street_address: $event.store_address,
        street_address_2: $event.store_address_1,
        city: $event.store_citycode,
        state: $event.store_statecode,
        zipcode: $event.store_zipcode,
        country_code: $event.store_countrycode,
      },
      target
    );
  };

  setAddress = (address, target) => {
    this.orderForm.patchValue({
      [target]: address,
    });
  };

  submitOrder() {
    return new Promise((resolve, reject) => {
      let requestPayload = this.orderForm.getRawValue();
      requestPayload = {
        ...requestPayload,
        ...{
          order_items: this.orderItems,
        },
      };
      //remove last empty row added item
      requestPayload.order_items = requestPayload.order_items?.filter(
        (x) => x?.brand_id != undefined && x?.child_sku != undefined
      );
      //clean nulls
      if (requestPayload?.vendor_address?.vendor_address_id == null) {
        delete requestPayload?.vendor_address?.vendor_address_id;
      }
      if (requestPayload?.billing_address?.billing_address_id == null) {
        delete requestPayload?.billing_address?.billing_address_id;
      }
      if (requestPayload?.shipping_address?.shipping_address_id == null) {
        delete requestPayload?.shipping_address?.shipping_address_id;
      }
      this.spinner.show();
      if (
        requestPayload.order_id == null ||
        requestPayload.order_id == undefined
      ) {
        delete requestPayload.order_id;
        this.poService.createPO(requestPayload).subscribe(
          (res) => {
            this.patchFormValue(res);
            this.spinner.hide();
            resolve(res);
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
            reject(err);
          }
        );
      } else {
        this.poService.updatePO(requestPayload).subscribe(
          (res) => {
            this.patchFormValue(res);
            this.spinner.hide();
            resolve(res);
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
            reject(err);
          }
        );
      }
    });
  }

  saveAs = (purchase_status_id) => {
    this.submitOrder()
      .then((res) => {
        this.onStatusUpdate(purchase_status_id);
      })
      .catch((err) => {
        this.toastr.error(err?.error?.message);
      });
  };

  onStatusUpdate = (purchase_update_status_id) => {
    const status = this.orderStatuses.find(
      (x) => x.purchase_status_id == purchase_update_status_id
    );
    var message = `Mark PO Status as ${status.description}`;
    if (purchase_update_status_id == PURCHASE_ORDER_STATUS.COMPLETED) {
      message = message + `\tand Add Items To Inventory`;
    }
    this.mantleModalService.confirmDialog(
      `Confirm PO Status`,
      message,
      this.updateStatus,
      [purchase_update_status_id, false]
    );
  };

  updateStatus = (purchase_update_status_id, orderSaved = false) => {
    if (
      purchase_update_status_id != undefined &&
      purchase_update_status_id != null
    ) {
      if (!orderSaved) {
        this.submitOrder().then(() => {
          this.orderForm.patchValue({
            purchase_update_status_id: purchase_update_status_id,
          });
          this.updateStatus(purchase_update_status_id, true);
        });
      } else {
        this.spinner.show();
        this.poService
          .updatePOStatus({
            order_id: this.order_id,
            purchase_status_id: purchase_update_status_id,
          })
          .subscribe(
            (res) => {
              this.patchFormValue(res);
              this.spinner.hide();
            },
            (err) => {
              this.toastr.error(err.error.message);
              this.spinner.hide();
            }
          );
      }
    }
  };

  patchFormValue = (res) => {
    this.orderDetails = res;
    this.order_id = this.orderDetails?.order_id;
    //this.init_forms_data(res);
    const purchase_status_id = this.orderDetails?.purchase_status_id;
    if (
      purchase_status_id == null ||
      purchase_status_id == undefined ||
      purchase_status_id == PURCHASE_ORDER_STATUS.DRAFT ||
      purchase_status_id == PURCHASE_ORDER_STATUS.SUBMITTED ||
      purchase_status_id == PURCHASE_ORDER_STATUS.ACCEPTED
    ) {
      this.canEdit = true;
    } else {
      this.canEdit = false;
    }
    // this.orderDetails.order_date = moment
    //   .utc(res.order_date)
    //   .local()
    //   .format('YYYY-MM-DDTHH:mm');

    this.orderDetails.purchase_update_status_id = null;
    this.orderForm.patchValue(this.orderDetails);
    this.orderItems = this.orderDetails?.order_items;
    if (this.canEdit) {
      this.orderItems.forEach(async (item, index) => {
        this.setOnOrderQuanty(index);
      });
    }
    this.setVendorDetails(this.orderDetails?.vendor);
    this.setupdateStatuses();
    this.onAddOrderItem();
  };

  searchProductByUpc($event, index) {
    const upc = ($event.target as HTMLInputElement).value;
    /**
     * Prevent count duplication if enter and onChange are executed
     */
    if (this.orderItems[index].loading_inventory) return;
    if (upc?.trim()?.length) {
      this.orderItems[index].loading_inventory = true;
      this.inventoryService
        .searchProductByUpc({ upc: upc, has_inventory: false })
        .subscribe(
          (res) => {
            this.orderItems[index].loading_inventory = false;
            this.processUpcResponse(res, index);
          },
          (err) => {
            this.orderItems[index]['loading_inventory'] = false;
            this.toastr.error(err?.error?.message);
          }
        );
    }
  }

  processUpcResponse(res, index) {
    if (res.length > 0) {
      //Lauch Model to select from multiple
      if (res.length > 1) {
        this.toastr.warning('Multiple Items Founds');

        const modalRef = this.mantleModalService.open(
          MantleMultipleUpcComponent,
          MANTLE_MODAL_NAME.MULTIPLE_UPC_MODAL,
          {
            ariaLabelledBy: 'modal-basic-title',
            size: 'xl',
            backdrop: 'static',
          }
        );

        modalRef.componentInstance.searchResults = res;

        this.multipleUPCModalSubscription = this.commonService.modal_close.subscribe(
          (data: any) => {
            if (data.modalName === 'MANTLE_MODAL_NAME.MULTIPLE_UPC_MODAL') {
              modalRef.close();
              if (data.data != null) {
                this.processUpcResponseData(index, data.data);
              }
              this.multipleUPCModalSubscription.unsubscribe();
            }
          }
        );
      } else {
        //to reuse setSelectedItem Method
        const data = res[0];
        this.processUpcResponseData(index, data);
      }
    } else {
      this.toastr.error('Item Not Found');
      this.orderItems[index]['edit'] = true;
      this.orderItems[index]['has_errors'] = true;
    }
  }

  processUpcResponseData(index, data) {
    const selectedItemData = {
      itemRef: index,
      data: {
        brand_id: data.brand_id,
        child_sku: data['Child Sku'],
        parent_sku: data['Parent Sku'],
        product_name: `${data['Product Name']} - ${data['Set Name'] ?? ''} `,
      },
    };
    this.setSelectedItem(selectedItemData);
    this.changeQuantity(null, index);
  }

  setSelectedItem(data) {
    const index = data['itemRef'];
    const productData = data['data'];

    const itemAddedIndex = this.orderItems.findIndex(
      (x) => x.child_sku == productData['child_sku']
    );
    if (itemAddedIndex >= 0) {
      this.toastr.info('Selected Item Already Added');
      this.orderItems[itemAddedIndex].quantity =
        parseInt(this.orderItems[itemAddedIndex].quantity) + 1;

      this.changeQuantity(null, itemAddedIndex);
      this.clearUpcValue();
      return false;
    } else {
      this.productService
        .getProductVendorId({
          vendor_id: this.orderForm.getRawValue().vendor_id,
          brand_id: productData['brand_id'],
          child_sku: productData['child_sku'],
        })
        .subscribe({
          next: (res) => {
            const prodValues: any = {
              item_description:
                res.identifier == undefined
                  ? productData['product_name']
                  : `${res.identifier} - ${productData['product_name']}`,
              brand_id: productData['brand_id'],
              child_sku: productData['child_sku'],
              parent_sku: productData['parent_sku'],
              product_name: productData['product_name'],
              edit: false,
              loading_inventory: false,
              quantity: 1,
              onOrderQty: 0,
              vendor_identifier: res.identifier,
            };
            this.orderItems[index] = {
              ...this.orderItems[index],
              ...prodValues,
            };

            this.setOnOrderQuanty(index);
            this.onAddOrderItem();
          },
          error: (error) => {},
          complete: () => {
            this.spinner.hide();
          },
        });
    }
    return true;
  }

  changeQuantity($event, index) {
    var itemDetails = this.orderItems[index];
    if (itemDetails) {
      var total =
        parseFloat(itemDetails['quantity']) *
        parseFloat(itemDetails['unit_price']);
      itemDetails['total'] = isNaN(total) ? 0 : total.toFixed(2);
      this.calculateTotal();
    }
  }

  onAddOrderItem() {
    const emptyIndex = this.orderItems?.filter((x) => x.child_sku == undefined)
      ?.length;
    if (emptyIndex <= 0 && this.canEdit) {
      this.orderItems.push({
        edit: true,
        quantity_received: 0,
        total: 0,
      });
      this.itemRef = this.orderItems.length - 1;
    }

    this.setUpcFocus();
  }

  setOnOrderQuanty = (index) => {
    const oItem = this.orderItems[index];
    if (oItem != undefined) {
      this.poService
        .getOnOrderQuantity({
          child_sku: oItem.child_sku,
          order_id: this.order_id,
        })
        .subscribe(
          (res) => {
            this.orderItems[index].onOrderQty =
              res.quantity == null ? 0 : res.quantity;
          },
          (err) => {
            this.orderItems[index].onOrderQty = 0;
          }
        );
    }
  };

  setUpcFocus() {
    setTimeout(() => {
      this.upcInputs?.nativeElement?.focus();
      this.upcInputs?.nativeElement?.click();
    }, 1);
  }

  clearUpcValue() {
    setTimeout(() => {
      if (this.upcInputs?.nativeElement)
        this.upcInputs.nativeElement.value = null;
    }, 1);
  }

  calculateTotal() {
    var total = 0.0;
    var sub_total = 0.0;
    var sale_tax = 0.0;

    sub_total = this.orderItems?.reduce((prev: any, next: any) => {
      return (
        parseFloat(prev) + (isNaN(next.total) ? 0 : parseFloat(next.total))
      );
    }, 0);

    var discount_amount = parseFloat(this.orderForm.value.discount_amount);

    var shipment_amount = parseFloat(this.orderForm.value.shipment_amount);

    var tax_amount = parseFloat(this.orderForm.value.tax_amount);

    total =
      sub_total +
      (shipment_amount ? shipment_amount : 0) +
      (tax_amount ? tax_amount : 0) -
      (discount_amount ? discount_amount : 0);

    this.orderForm.patchValue({
      order_total: total.toFixed(2),
      sub_total: sub_total.toFixed(2),
    });
  }

  onDeleteOrderItem(index, order_item_id, order_id) {
    if (order_item_id !== null && order_item_id !== undefined) {
      this.spinner.show();
      this.poService.deletePOItem(order_item_id, order_id).subscribe(
        (res) => {
          //this.patchFormValue(res)
          this.orderItems.splice(index, 1);
          this.spinner.hide();
          this.itemRef -= 1;
          this.calculateTotal();
        },
        (err) => {
          this.spinner.hide();

          this.toastr.error(err?.error?.message, 'An Error Occurred');
        }
      );
    } else {
      this.orderItems.splice(index, 1);
      this.itemRef -= 1;
      this.calculateTotal();
      this.onAddOrderItem();
    }
  }

  onItemUpdated($event, index) {
    var itemDetails = this.orderItems[index];
    var total =
      (itemDetails['quantity_received'] > 0
        ? parseFloat(itemDetails['quantity_received'])
        : parseFloat(itemDetails['quantity'])) *
      parseFloat(itemDetails['unit_price']);
    itemDetails['total'] = isNaN(total) ? 0 : total.toFixed(2);
    this.calculateTotal();
  }

  setupdateStatuses = () => {
    this.updateStatuses = [];

    const actions = this.orderStatuses?.find(
      (x) => x.purchase_status_id == this.orderDetails?.purchase_status_id
    )?.actions;

    this.updateStatuses = this.orderStatuses?.filter((x) => {
      return actions?.includes(parseInt(x.purchase_status_id));
    });
  };

  ngOnDestroy(): void {
    this.vendorSelectedlSubscription?.unsubscribe();
    this.storesSubscription?.unsubscribe();
    this.productSearchSubscription?.unsubscribe();
    this.statusSubscription?.unsubscribe();
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.PO_DETAILS);
  }
}
