<div class="row mt-2 mb-2">
  <div class="col-6 prod-logo">
    <img src="{{to.image}}">
  </div>
  <div class="col-6" *ngIf="to.txt_property">
    <label>{{to.txt_property}}:</label>
    {{textValue}}
  </div>
  <ng-container #fieldComponent></ng-container>
</div>
