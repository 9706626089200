import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/utils/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { PRODUCT_FILTER_LEVEL } from 'src/app/utils/enums/mantle-enums';
import { ProductService } from 'src/app/utils/services/product.service';
import { ToastrService } from 'ngx-toastr';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
//import { PRODUCT_CATALOG } from 'src/app/utils/enums/mantle-enums'

@Component({
  selector: 'mantle-product-filter',
  templateUrl: './mantle-product-filter.component.html',
  styleUrls: ['./mantle-product-filter.component.scss'],
})
export class MantleProductFilterComponent implements OnInit {
  @Input() product_filter_level: PRODUCT_FILTER_LEVEL =
    PRODUCT_FILTER_LEVEL.CATALOG;
  product_brand: any = [];
  product_sets = [];
  product_sets_filtered: any = [];
  product_conditions = [];
  product_conditions_filtered: any = [];
  product_rarity = [];
  product_rarity_filtered: any = [];
  brand_id: any;
  foil: any = [
    {
      id: 'NON FOIL',
      name: 'NON FOIL',
    },
    {
      id: 'FOIL',
      name: 'FOIL',
    },
  ];

  sortByList: any = [
    {
      id: `tb."Product Name"`,
      name: 'Product Name',
    },
    {
      id: `tb."Parent Product Name"`,
      name: 'Parent Product Name',
    },
  ];

  sortOrderList: any = [
    {
      id: `asc`,
      name: 'Ascending',
    },
    {
      id: `desc`,
      name: 'Descending',
    },
  ];
  searchLimit: any = '';
  sortBy: any = '';
  sortOrder: any = '';

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private inventoryService: InventoryService,
    private productService: ProductService
  ) {}

  inventoryFilterForm = this.fb.group(
    {
      category: [],
      brand_id: [],
      set_id: [],
      condition_id: [],
      rarity_id: [],
      Language: [],
      'Product Name': [],
      'Parent Sku': [],
      'Set Name': [],
      Condition: [],
      Rarity: [],
      'Child Sku': [],
      is_active: [],
      bar_code: [],
      sell_price_low: [],
      sell_price_high: [],
      //"sell_price_low":[],
      //"sell_price_high":[],
      quantity_low: [],
      quantity_high: [],
      quantity_is: [],
      quantity_pct_sale: [],
      buy_price_low: [],
      buy_price_high: [],
      tag: [],
      manufacturer_sku: [],
      msrp_low: [],
      msrp_high: [],
      wishes_low: [],
      wishes_high: [],
      past_days: [],
      cmb_past_days: [],
      past_days_quantity: [],
      cmb_past_days_quantity: [],
      Foil: [],
      'Short Code': [],
    }
    //{ updateOn: "submit" }
  );

  fields: FormlyFieldConfig[];
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      productSets: [],
      productConditions: [],
      productRarity: [],
      productFoil: [
        {
          value: 'NON FOIL',
          label: 'NON FOIL',
        },
        {
          value: 'FOIL',
          label: 'FOIL',
        },
      ],
    },
  };

  ngOnInit(): void {
    this.init_values();
  }

  init_values() {
    this.getLookupValues();
  }

  onSubmitFilters() {
    this.spinner.show();
    var searchValues = this.commonService.getDirtyValues(
      this.inventoryFilterForm
    );

    Object.keys(searchValues).forEach((key) => {
      if (searchValues[key]?.toString()?.trim() == '') delete searchValues[key];
    });

    if (this.product_filter_level == PRODUCT_FILTER_LEVEL.INVENTORY) {
      this.inventoryService
        .inventoryProductFilter(
          searchValues,
          this.sortBy,
          this.sortOrder,
          this.searchLimit
        )
        .subscribe(
          (res) => {
            this.inventoryService.filter_result.next(res);
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
          }
        );
    } else if (this.product_filter_level == PRODUCT_FILTER_LEVEL.CATALOG) {
      this.productService
        .productFilter(
          searchValues,
          this.sortBy,
          this.sortOrder,
          this.searchLimit
        )
        .subscribe(
          (res) => {
            this.inventoryService.filter_result.next(res);
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
          }
        );
    }
  }

  getLookupValues() {
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();

        this.product_brand = res['product_brand'];
        this.product_sets = res['product_set'];
        this.product_conditions = res['product_condition'];
        this.product_rarity = res['product_rarity'];
      },
      (err) => {
        this.spinner.hide();
        //this.open_dialog()
      }
    );
  }

  changeBrand() {
    this.brand_id = this.inventoryFilterForm.value['brand_id'];
    this.commonService.catalogDataChanged.next(
      this.inventoryFilterForm.getRawValue()
    );
    if (this.brand_id) {
      this.product_sets_filtered = this.product_sets.filter(
        (x) => x.parent == this.brand_id
      );
      this.options.formState.productSets = this.product_sets_filtered?.map(
        (x) => {
          return {
            value: x.id,
            label: x.name,
          };
        }
      );
      this.product_conditions_filtered = this.product_conditions.filter(
        (x) => x.parent == this.brand_id
      );
      this.options.formState.productConditions = this.product_conditions_filtered?.map(
        (x) => {
          return {
            value: x.id,
            label: x.name,
          };
        }
      );
      this.product_rarity_filtered = this.product_rarity.filter(
        (x) => x.parent == this.brand_id
      );
      this.options.formState.productRarity = this.product_rarity_filtered?.map(
        (x) => {
          return {
            value: x.id,
            label: x.name,
          };
        }
      );
      this.getFilterMapping();
    }
  }

  getFilterMapping = () => {
    this.spinner.show();
    this.productService.getFilterMapping(this.brand_id).subscribe(
      (res) => {
        this.generateDynamicFilter(res);
        this.spinner.hide();
      },
      (err) => {
        this.toastr.error(err);
        this.spinner.hide();
      }
    );
  };

  generateDynamicFilter = (filter_mappings: Array<any>) => {
    var _fields = [];

    while (filter_mappings.length > 0) {
      var rowField = {
        fieldGroupClassName: 'row',
        fieldGroup: [],
      };
      filter_mappings.splice(0, 4)?.forEach((filterField, i) => {
        var defaultFieldConfig = {
          className: 'col-3',
          key: filterField.field_name,
          type: 'input',
          templateOptions: {
            label: filterField.label,
            placeholder: '',
            //description: filterField.label,
            required: false,
            attributes: {},
          },
          expressionProperties: {
            //'templateOptions.options': 'formState.terminals',
          },
        };
        defaultFieldConfig = this.getCustomConfig(
          defaultFieldConfig,
          filterField
        );
        rowField.fieldGroup.push(defaultFieldConfig);
      });

      _fields.push(rowField);
    }
    this.fields = _fields;
  };

  getCustomConfig = (defaultFieldConfig, filterField) => {
    if (
      [
        'Set Name',
        'set_id',
        'Condition',
        'condition_id',
        'Rarity',
        'rarity_id',
        'Foil',
      ].includes(filterField.field_name)
    ) {
      defaultFieldConfig = {
        ...defaultFieldConfig,
        ...{
          type: 'mantle-select',
        },
      };
      if (filterField.field_name != 'Foil') {
        defaultFieldConfig = {
          ...defaultFieldConfig,
          ...{
            templateOptions: {
              ...defaultFieldConfig.templateOptions,
              ...{
                multiple: true,
              }, //Yonah To Do: recurssive function to achive nested object merge
            },
          },
        };
      }
      if (
        filterField.field_name == 'Condition' ||
        filterField.field_name == 'condition_id'
      ) {
        defaultFieldConfig = {
          ...defaultFieldConfig,
          ...{
            key: 'condition_id',
            expressionProperties: {
              ...defaultFieldConfig.expressionProperties,
              ...{
                'templateOptions.options': 'formState.productConditions',
              },
            },
          },
        };
      }
      if (
        filterField.field_name == 'Set Name' ||
        filterField.field_name == 'set_id'
      ) {
        defaultFieldConfig = {
          ...defaultFieldConfig,
          ...{
            key: 'set_id',
            expressionProperties: {
              ...defaultFieldConfig.expressionProperties,
              ...{
                'templateOptions.options': 'formState.productSets',
              },
            },
          },
        };
      }
      if (
        filterField.field_name == 'Rarity' ||
        filterField.field_name == 'rarity_id'
      ) {
        defaultFieldConfig = {
          ...defaultFieldConfig,
          ...{
            key: 'rarity_id',
            expressionProperties: {
              ...defaultFieldConfig.expressionProperties,
              ...{
                'templateOptions.options': 'formState.productRarity',
              },
            },
          },
        };
      }
      if (
        filterField.field_name == 'Foil'
      ) {
        defaultFieldConfig = {
          ...defaultFieldConfig,
          ...{
            expressionProperties: {
              ...defaultFieldConfig.expressionProperties,
              ...{
                'templateOptions.options': 'formState.productFoil',
              },
            },
          },
        };
      }
    }
    if (
      filterField.data_type.includes('date') ||
      filterField.data_type.includes('timestamp')
    ) {
      defaultFieldConfig = {
        ...defaultFieldConfig,
        ...{
          templateOptions: {
            ...defaultFieldConfig.templateOptions,
            ...{
              type: 'date',
            },
          },
        },
      };
    }
    return defaultFieldConfig;
  };
}
