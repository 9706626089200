import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, of, Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AppConfig } from './app.config';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { AuthService } from '@auth0/auth0-angular';
import { SettingsService } from './settings.service';
import {
  LOCAL_TORAGE_KEYS,
  SYSTEM_SETTINGS,
  USER_PREFERENCES,
} from '../enums/mantle-enums';
import { UserPreferenceService } from './user-preference.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class Auth0Service {
  constructor(
    private config: AppConfig,
    private router: Router,
    private toastr: ToastrService,
    private storageService: StorageService,
    private userService: UserService,
    public _authService: AuthService,
    private settingsService: SettingsService,
    private userPreferenceService: UserPreferenceService,
    private _commonService: CommonService
  ) {
    // if (Number(localStorage.getItem('expires_at')) < Date.now()) {
    //   this.renewToken()
    // }
  }
  // private _auth0 = new auth0.WebAuth({
  //   clientID: this.config.getConfig('CLIENT_ID'),
  //   audience: this.config.getConfig('AUDIENCE'), //the end bit was changed
  //   domain: this.config.getConfig('CLIENT_DOMAIN'),
  //   issuer: this.config.getConfig('ISSUER'),
  //   responseType: this.config.getConfig('RESPONSETYPE'),
  //   redirectUri: this.config.getConfig('REDIRECT'),
  //   scope: this.config.getConfig('SCOPE'),
  // })

  accessToken: string;
  userProfile: any;
  expiresAt: number;
  isAdmin: boolean;
  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean = false; //???
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  loggingIn: boolean;
  // Subscribe to token expiration stream
  refreshSub: Subscription;
  routeSub: Subscription;

  setLoggedIn(value: boolean) {
    // Update login status behavior subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  login() {
    // Auth0 authorize request
    //this._auth0.authorize()
    this._authService.loginWithRedirect();
  }

  handleAuth() {
    this._authService.isAuthenticated$.subscribe(
      (result) => {
        this.loggingIn = result;

        this._authService.user$.subscribe((user) => {
          localStorage.setItem(
            LOCAL_TORAGE_KEYS.USER_PROFILE,
            JSON.stringify(user)
          );
          this._setSession(this._authService, user);
          this.userService.updateAuthoUser(user).subscribe(
            (res) => {
              localStorage.setItem(
                LOCAL_TORAGE_KEYS.USER_PROFILE,
                JSON.stringify(res)
              );
              this._authService.idTokenClaims$.subscribe((claims) => {});

              this._redirect();
            },
            (err) => {}
          );
        });
      },
      (error) => {
        this.toastr.error(JSON.stringify(error), 'Error authenticating');
        this._clearRedirect();
        this.router.navigate(['/login']);
      }
    );

    // When Auth0 hash parsed, get profile
    // this._auth0.parseHash((err, authResult) => {
    //   if (authResult && authResult.accessToken) {
    //     window.location.hash = ''
    //     this._getProfile(authResult, true)
    //   } else if (err) {
    //     this.toastr.error(err.error, 'Error authenticating')
    //     this._clearRedirect()
    //     this.router.navigate(['/login'])
    //   }
    // })
  }

  public getProfile() {
    return JSON.parse(localStorage.getItem(LOCAL_TORAGE_KEYS.USER_PROFILE));
    // this.loggingIn = true
    // // Use access token to retrieve user's profile and set session
    // this._auth0.client.userInfo(authResult.accessToken, (err, profile) => {
    //   if (profile) {
    //     localStorage.setItem('user', JSON.stringify(profile))
    //     this._setSession(authResult, profile)
    //     if (onAuth)
    //       this.userService.updateAuthoUser(profile).subscribe(res => { }, err => {  });

    //     this._redirect()
    //   } else if (err) {
    //   }
    // })
  }

  private _setSession(authResult, profile?) {
    if (profile) this.userProfile = profile;

    this._authService
      .getAccessTokenSilently({
        detailedResponse: true,
        authorizationParams: {
          audience: this.config.getConfig('AUDIENCE'),
        },
      })
      .toPromise()
      .then((jwt) => {
        this.expiresAt = jwt.expires_in * 1000 + Date.now();
        localStorage.setItem('expires_at', JSON.stringify(this.expiresAt));
        this.accessToken = jwt.access_token;
        this.storageService.setItem('auth0_access_token', this.accessToken);
        this.cacheSystemSettings();
      });
    // this.expiresAt = authResult.expiresIn * 1000 + Date.now()
    // // Store expiration in local storage to access in constructor
    // localStorage.setItem('expires_at', JSON.stringify(this.expiresAt))
    // this.accessToken = authResult.accessToken
    // this.storageService.setItem('auth0_access_token', this.accessToken)

    // // If initial login, set profile and admin information
    // if (profile) {
    //   this.userProfile = profile
    //   this.isAdmin = this._checkAdmin(profile)
    // }
    // // Update login status in loggedIn$ stream
    // this.setLoggedIn(true)
    // this.loggingIn = false
    // // Schedule access token renewal
    // this.scheduleRenewal()
  }

  private _checkAdmin(profile) {
    // // Check if the user has admin role
    // const roles = profile[this.config.getConfig('NAMESPACE')] || []
    // return roles.indexOf('admin') > -1
  }

  private _redirect() {
    this.setLoggedIn(true);
    this.loggingIn = false;

    // Redirect with or without 'tab' query parameter
    // Note: does not support additional params besides 'tab'
    const fullRedirect = decodeURI(localStorage.getItem('authRedirect'));
    const redirectArr = fullRedirect.split('?tab=');
    const navArr = [redirectArr[0] || '/'];
    const tabObj = redirectArr[1]
      ? { queryParams: { tab: redirectArr[1] } }
      : null;

    if (!tabObj) {
      this.router.navigate(navArr);
    } else {
      this.router.navigate(navArr, tabObj);
    }
    // Redirection completed; clear redirect from storage
    this._clearRedirect();
  }

  private _clearRedirect() {
    // Remove redirect from localStorage
    localStorage.removeItem('authRedirect');
  }

  private _clearExpiration() {
    // Remove token expiration from localStorage
    localStorage.removeItem('expires_at');
  }

  logout() {
    // Remove data from localStorage
    this._clearExpiration();
    this._clearRedirect();
    this.storageService.clear();
    // End Auth0 authentication session
    // this._auth0.logout({
    //   clientId: this.config.getConfig('CLIENT_ID'),
    //   returnTo: this.config.getConfig('LOGOUT'),
    // })
    this._authService.logout({
      logoutParams: {
        returnTo: this.config.getConfig('LOGOUT'),
      },
    });
  }

  get tokenValid(): boolean {
    // Check if current time is past access token's expiration
    return Date.now() < JSON.parse(localStorage.getItem('expires_at'));
  }

  cacheSystemSettings = () => {
    this.settingsService.getSystemSettings().subscribe((res) => {
      this.storageService.setItem(LOCAL_TORAGE_KEYS.SYSTEM_SETTINGS, res);
      const logoImage = res.find((x) => x.name === SYSTEM_SETTINGS.IMAGE_LOGO)
        ?.value;
      this.storageService.setItem(SYSTEM_SETTINGS.IMAGE_LOGO, logoImage);
      this._commonService.systemSettingsUpdated.next(null);
    });

    this.userService.getUserByEmail(this.userProfile.email).subscribe(
      (res) => {
        this.userPreferenceService.getUserPreferences(res.user_id).subscribe(
          (res) => {
            const timezone = res.find(
              (x) => x.name == USER_PREFERENCES.TIMEZONE
            )?.value;
            if (timezone != undefined && timezone != null) {
              this.storageService.setItem(
                LOCAL_TORAGE_KEYS.USER_TIMEZONE,
                timezone
              );
            }
          },
          (err) => {}
        );
      },
      (err) => {}
    );
  };
}
