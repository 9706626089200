<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <mantle-print
        [printSectionId]="'print-section'"
        cssClass="btn btn-outline-secondary btn-sm m-2"
        [printDelay]="1"
        (click)="printingStarted()"
      ></mantle-print>

      <button type="button" class="btn" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row mt-4">
      <div class="col-12">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="form"
        ></formly-form>
      </div>
    </div>

    <div class="row">
      <div class="col-12" id="print-section">
        <div class="row">
          <ng-container *ngFor="let productItem of productItems">
            <div class="col-3 border border-dashed">
              <ng-container *ngIf="productItem.loading">
                <mantle-loading label="Loading ..."></mantle-loading>
              </ng-container>
              <ng-container *ngIf="!productItem.loading">
                <div
                  style="text-align: center"
                  (click)="onEditItem(productItem)"
                >
                  <p style="font-family: 'Source Code Pro', monospace">
                    {{ productItem.headerText }}
                  </p>
                  <img
                    alt="{{ productItem.barCodeValue }}"
                    src="https://barcode.tec-it.com/barcode.ashx?data={{
                      productItem.barCodeValue
                    }}&code={{ model.code }}&translate-esc=on"
                    width="{{ model.width }}"
                    height="{{ model.height }}"
                  />

                  <p style="font-family: 'Source Code Pro', monospace">
                    {{ productItem.footerText }}
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="productItem.editing && !printing">
                <input
                  type="text"
                  [(value)]="productItem.barCodeValue"
                  [(ngModel)]="productItem.barCodeValue"
                  placeholder="UPC"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control form-control-sm"
                />
                <input
                  type="text"
                  [(value)]="productItem.headerText"
                  [(ngModel)]="productItem.headerText"
                  placeholder="Header Text"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control form-control-sm"
                />
                <input
                  type="text"
                  [(value)]="productItem.footerText"
                  [(ngModel)]="productItem.footerText"
                  placeholder="Footer Text"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control form-control-sm"
                />
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
