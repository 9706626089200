import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, map, catchError, of } from 'rxjs';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { RewardPointService } from 'src/app/utils/services/rewardpoint.service';
import {
  loadTiered,
  loadTieredSuccess,
  loadTieredFailure,
  upsertTiered,
  upsertTieredSuccess,
  deleteTiered,
  deleteTieredSuccess,
} from './tiered_reward_points.actions';

@Injectable()
export class TieredRewardPointEffects {
  constructor(
    private actions$: Actions,
    private rewardPointService: RewardPointService,
    private store: Store<AppState>
  ) {}

  loadTiered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTiered),
      mergeMap(() =>
        this.rewardPointService.getTiered().pipe(
          map((tiered) => loadTieredSuccess({ tiered })),
          catchError((error) => of(loadTieredFailure({ error })))
        )
      )
    )
  );

  upsertTiered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertTiered),
      mergeMap((requestPayload) =>
        this.rewardPointService.upsertTiered(requestPayload).pipe(
          map((tier) => upsertTieredSuccess({ tier })),
          catchError((error) => of(loadTieredFailure(error)))
        )
      )
    )
  );

  deleteTiered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTiered),
      mergeMap((point_tiered_id) =>
        this.rewardPointService
          .deleteTiered(point_tiered_id.point_tiered_id)
          .pipe(
            map(() => deleteTieredSuccess(point_tiered_id)),
            catchError((error) => of(loadTieredFailure(error)))
          )
      )
    )
  );
}
