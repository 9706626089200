<form [formGroup]="form">
  <div class="row">
    <div class="form-group col-md-2">
      <label class="control-label small">Reporting Period</label>
    </div>
    <div class="form-group col-md-3">
      <input
        type="number"
        class="form-control form-control-sm"
        formControlName="interval_period"
      />
    </div>
    <div class="form-group col-md-4">
      <ng-select
        [items]="intervalType"
        bindLabel="description"
        bindValue="id"
        formControlName="interval_type"
        style="height: calc(1.8125rem + 2px)"
      >
      </ng-select>
    </div>
    <div class="form-group col-md-2">
      <button
        type="button"
        class="btn btn-outline-primary btn-sm"
        (click)="getData()"
      >
        <i class="fas fa-sync"></i>
      </button>
    </div>
  </div>
</form>
