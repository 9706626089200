import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { throwError as ObservableThrowError, Observable, Subject } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AppConfig } from './app.config';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  token: string;

  currentUser: any;

  loading = false;

  apiUrl: string;

  public authType: any;

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private storageService: StorageService,
    private router: Router
  ) {
    this.apiUrl = this.config.getConfig('api_root');
    this.authType = this.config.getConfig('authType');
  }

  setHeaders(headerType, authenticate): any {
    const headersConf = {};
    if (headerType === 'json') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'json-x') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'form') {
      headersConf['Content-Type'] = 'application/x-www-form-urlencoded';
    } else if (headerType === 'multipart') {
      headersConf['Content-Type'] = 'multipart/form-data';
    } else if (headerType === 'masterDataObtained') {
      const masterDataObtained = '1900-01-01';
      headersConf['If-Modified-Since'] = masterDataObtained;
    }

    return new HttpHeaders(headersConf);
  }

  get(
    path: string,
    headerType: string = 'json',
    authenticate: boolean = false
  ): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(this.apiUrl + path, { headers }).pipe(
      catchError((error) => this._handleError(error)),
      publishLast(),
      refCount()
    );
  }

  patch(
    path: string,
    body,
    headerType: string = 'json',
    authenticate: boolean = false
  ): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.patch(this.apiUrl + path, body, { headers }).pipe(
      catchError((error) => this._handleError(error)),
      publishLast(),
      refCount()
    );
  }

  post(
    path: string,
    body,
    headerType: string = 'json',
    authenticate: boolean = false
  ): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.post(this.apiUrl + path, body, { headers }).pipe(
      catchError((error) => this._handleError(error)),
      publishLast(),
      refCount()
    );
  }

  put(
    path: string,
    body,
    headerType: string = 'json',
    authenticate: boolean = false
  ): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.put(this.apiUrl + path, body, { headers }).pipe(
      catchError((error) => this._handleError(error)),
      publishLast(),
      refCount()
    );
  }

  delete(
    path: string,
    body: any = {},
    headerType: string = 'json',
    authenticate: boolean = false
  ): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    const options = {
      headers: headers,
      body: body,
    };
    return this.http.delete(this.apiUrl + path, options).pipe(
      catchError((error) => this._handleError(error)),
      publishLast(),
      refCount()
    );
  }

  downloadPost(
    path: string,
    body: any = {},
    headerType: string = 'json',
    authenticate: boolean = false
  ): Observable<ArrayBuffer> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http
      .post(this.apiUrl + path, body, { headers, responseType: 'arraybuffer' })
      .pipe(
        //publishLast(),
        //refCount()
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  download(
    path: string,
    headerType: string = 'json',
    authenticate: boolean = false
  ): Observable<ArrayBuffer> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http
      .get(this.apiUrl + path, { headers, responseType: 'arraybuffer' })
      .pipe(
        //publishLast(),
        //refCount()
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }
  readJson(path: string) {
    return this.http.get(path).pipe(publishLast(), refCount());
  }
  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    if (err.message && err.message.indexOf('No JWT present') > -1) {
      //this.auth0Service.login()
      this.router.navigate(['/login']);
    }
    return ObservableThrowError(err);
  }
}
