<div class="card">
  <div class="card-header">
    Cash Registers
    <div class="card-tools">
      <button type="button" class="btn btn-tool">
        <button
          type="button"
          class="btn btn-success btn-sm"
          (click)="onNewRegister()"
        >
        <i class="fas fa-plus "></i>
        
          New Cash Register
        </button>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <mantle-cash-register-list></mantle-cash-register-list>
      </div>
    </div>
  </div>
</div>
