import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/services/common.service';
import { CashRegisterDetailsComponent } from './cash-register-details/cash-register-details.component';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';

@Component({
  selector: 'mantle-settings-cash-register',
  templateUrl: './settings-cash-register.component.html',
  styleUrls: ['./settings-cash-register.component.scss'],
})
export class SettingsCashRegisterComponent implements OnInit {
  cashregisterModalSubscribtion: Subscription;
  constructor(
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {}
  ngOnInit(): void {}

  onNewRegister() {
    const modalRef = this.mantleModalService.open(
      CashRegisterDetailsComponent,
      MANTLE_MODAL_NAME.MODAL_CASHREGISTER_DETAILS,
      { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' }
    );

    this.cashregisterModalSubscribtion = this.commonService.modal_close.subscribe(
      (data) => {
        if (data === MANTLE_MODAL_NAME.MODAL_CASHREGISTER_DETAILS) {
          this.commonService.cashRegisterUpdated.next(null);
          modalRef.close();
          this.cashregisterModalSubscribtion.unsubscribe();
        }
      }
    );
  }
}
