import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleCustomerComponent } from '../mantle-customer.component';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { actionSelectCustomer } from '../state/customer.actions';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mantle-btn-new-customer',
  templateUrl: './mantle-btn-new-customer.component.html',
  styleUrls: ['./mantle-btn-new-customer.component.scss'],
})
export class MantleBtnNewCustomerComponent implements OnInit {
  constructor(
    private mantleModalService: MantleModalService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  onNewCustomer() {
    this.store.dispatch(actionSelectCustomer(null));

    const modalRef = this.mantleModalService.open(
      MantleCustomerComponent,
      MANTLE_MODAL_NAME.CUSTOMER_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
      }
    );

    modalRef.componentInstance.customerData = {};
  }
}
