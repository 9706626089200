import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'channel-activate-wc',
  templateUrl: './channel-activate-wc.component.html',
  styleUrls: ['./channel-activate-wc.component.scss'],
})
export class ChannelActivateWcComponent implements OnInit {
  @Input() channelOption: any = {};
  validated: boolean = false;
  api_versions: Array<any> = [
    {
      id: 'wc/v3',
      value: 'WooCommerce (WC) 3.5+, WP 4.4 or later',
    },
    {
      id: 'wc/v2',
      value: 'WooCommerce (WC) 3.0+, WP 4.4 or later',
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private settingsService: SettingsService
  ) {}

  configForm = this.fb.group({
    channel_wc_id: [],
    channel_ref: [],
    base_url: ['', Validators.required],
    consumer_key: ['', Validators.required],
    consumer_secret: ['', Validators.required],
    version: ['wc/v3'],
    wp_store_id: [],
    channel_wc_description: ['WooCommerce Channel'],
    update_frequency: [30],
    port: [],
    created_at_min: [],
  });

  ngOnInit(): void {
    if (this.channelOption != undefined) {
      this.configForm.patchValue(this.channelOption);
    }
  }
  onValidate() {
    this.validated = false;
    this.spinner.show();
    this.channelsService
      .validateWooCommerce(this.configForm.getRawValue())
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res != undefined) {
            if (res.is_valid) {
              this.validated = true;
              res.wp_store_id = res.environment?.store_id;
              this.configForm.patchValue(res);
              this.toastr.success('Validation Successful');
            } else {
              this.validated = false;
              this.toastr.error(res.error, 'Validation Failed');
            }
          } else {
            this.validated = false;
            this.toastr.error('Validation Failed', 'Validation Failed');
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.error.message, 'An Error Occurred');
        }
      );
  }

  onSave() {
    this.spinner.show();
    const obs = this.channelsService.createWooCommerceChannel(
      this.configForm.getRawValue()
    );
    obs.subscribe(
      (res) => {
        this.configForm.patchValue(res);
        this.spinner.hide();
        this.toastr.success('Channel Added Successfully');
        this.closeModal();
        this.channelsService.channelAdded.next({});
        this.settingsService.statusMappingDialog();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  onUpdate() {
    this.spinner.show();
    const obs = this.channelsService.updateWooCommerceChannel(
      this.configForm.value
    );
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }
  onUpdateFrequency() {
    this.spinner.show();
    this.channelsService
      .updateWooCommerceChannel({
        channel_wc_id: this.configForm.value['channel_wc_id'],
        channel_ref: this.configForm.value['channel_ref'],
        update_frequency: this.configForm.value['update_frequency'],
      })
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('Frequency Update Successful');
          this.closeModal();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.error.message, 'An Error Occurred');
        }
      );
  }

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.WOO_COMMERCE_ACTIVATION_MODAL
    );
  }
}
