<div class="card">
  <div class="card-header">
    <h5>Reward Point Settings</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-md-12">
          <formly-form
            [model]="model"
            [fields]="fields"
            [options]="options"
            [form]="form"
          ></formly-form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-success btn-sm w-50"
            (click)="onSave()"
          >
            Save
          </button>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-12 mt-5">
        <reward-tiered-list></reward-tiered-list>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-5">
        <reward-product-list></reward-product-list>
      </div>
    </div>
  </div>
</div>
