<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <!-- Profile Image -->
        <div class="card card-primary card-outline">
          <div class="card-body box-profile">
            <div class="text-center">
              <img
                class="profile-user-img img-fluid img-circle"
                src="{{ userProfile.picture }}"
                alt="User profile picture"
              />
            </div>

            <h3 class="profile-username text-center">{{ userProfile.name }}</h3>

            <p class="text-muted text-center">{{ userProfile.email }}</p>

            <ul class="list-group list-group-unbordered mb-3">
              <li class="list-group-item">
                <b>Phone</b>
                <a class="float-right"> {{ userProfile.main_phone }}</a>
              </li>
              <li class="list-group-item">
                <b>Last Login</b>
                <a class="float-right">{{
                  userProfile.lastlogin | date: 'medium':timezone
                }}</a>
              </li>
            </ul>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->

        <!-- About Me Box -->
        <!--div class="card card-primary">
          <div class="card-header">
            <h3 class="card-title">About Me</h3>
          </div>
          <div class="card-body">
            <strong><i class="fas fa-book mr-1"></i> Education</strong>

            <p class="text-muted">
              B.S. in Computer Science from the University of Tennessee at
              Knoxville
            </p>

            <hr />

            <strong><i class="fas fa-map-marker-alt mr-1"></i> Location</strong>

            <p class="text-muted">Malibu, California</p>

            <hr />

            <strong><i class="fas fa-pencil-alt mr-1"></i> Skills</strong>

            <p class="text-muted">
              <span class="tag tag-danger">UI Design</span>
              <span class="tag tag-success">Coding</span>
              <span class="tag tag-info">Javascript</span>
              <span class="tag tag-warning">PHP</span>
              <span class="tag tag-primary">Node.js</span>
            </p>

            <hr />

            <strong><i class="far fa-file-alt mr-1"></i> Notes</strong>

            <p class="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              fermentum enim neque.
            </p>
          </div>
        </div-->
      </div>
      <!-- /.col -->
      <div class="col-md-9">
        <div class="card card-primary card-outline">
          <div class="card-body">
            <ul
              ngbNav
              #nav="ngbNav"
              [(activeId)]="active"
              class="nav-tabs nav nav-pills"
            >
              <li [ngbNavItem]="0">
                <a ngbNavLink class="nav-link">Preference Settings</a>
                <ng-template ngbNavContent>
                  <div class="tab-content">
                    <div class="active tab-pane">
                      <mantle-user-preference></mantle-user-preference>
                    </div>
                  </div>
                </ng-template>
              </li>
              <!--li [ngbNavItem]="1">
                <a ngbNavLink class="nav-link">Activity</a>
                <ng-template ngbNavContent>
                  <div class="tab-content">
                    <div class="active tab-pane">
                      <app-blank></app-blank>
                    </div>
                  </div>
                </ng-template>
              </li-->
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
