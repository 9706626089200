import { Component, Input } from '@angular/core';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MantleBarcodeGeneratorComponent } from '../mantle-barcode-generator/mantle-barcode-generator.component';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MantleBarcodeMultipleComponent } from '../mantle-barcode-multiple/mantle-barcode-multiple.component';

@Component({
  selector: 'btn-barcode-generator',
  templateUrl: './btn-barcode-generator.component.html',
  styleUrls: ['./btn-barcode-generator.component.scss'],
})
export class BtnBarcodeGeneratorComponent {
  @Input() multipleBarcodes: boolean = false;
  @Input() upc;
  @Input() headerText;
  @Input() footerText;
  @Input() productDetails;
  @Input() productItems: Array<any> = [];

  constructor(
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService,
    private inventoryService: InventoryService
  ) {}
  onGenerateBarcode = async () => {
    if (!this.multipleBarcodes) {
      if (this.productDetails != null && this.productDetails != undefined) {
        if (
          this.headerText == undefined &&
          'Product Name' in this.productDetails
        ) {
          this.headerText = this.productDetails['Product Name'];
        }
        if (
          this.footerText == undefined &&
          'Product Name' in this.productDetails
        ) {
          this.spinner.show();
          const res = await this.inventoryService
            .getInventoryData({
              brand_id: this.productDetails['brand_id'],
              child_sku: this.productDetails['Child Sku'],
            })
            .toPromise();
          if (res != null && res != undefined) {
            if ('sell_price' in res) {
              this.footerText = `${res.currency_code}  ${res.sell_price}`;
            }
          }
          this.spinner.hide();
        }
      }
      const modalRef = this.mantleModalService.open(
        MantleBarcodeGeneratorComponent,
        MANTLE_MODAL_NAME.BARCODE_GENERATOR_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );
      modalRef.componentInstance.barCodeValue = this.upc;
      modalRef.componentInstance.headerText = this.headerText;
      modalRef.componentInstance.footerText = this.footerText;
    } else {
      const modalRef = this.mantleModalService.open(
        MantleBarcodeMultipleComponent,
        MANTLE_MODAL_NAME.BARCODE_GENERATOR_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'xl',
          backdrop: 'static',
        }
      );
      modalRef.componentInstance.productItems = this.productItems?.filter(
        (x) => x?.child_sku != undefined
      );
    }
  };
}
