import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { ChannelStatusMappingComponent } from '../channel-status-mapping/channel-status-mapping.component';

@Component({
  selector: 'btn-status-mapping',
  templateUrl: './btn-status-mapping.component.html',
  styleUrls: ['./btn-status-mapping.component.scss']
})
export class BtnStatusMappingComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onClick(){
    this.settingsService.statusMappingDialog()
  }

}
