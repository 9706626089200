import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { EndiciaService } from 'src/app/utils/services/endicia.service';

@Component({
  selector: 'app-shipment-manifest',
  templateUrl: './shipment-manifest.component.html',
  styleUrls: ['./shipment-manifest.component.scss'],
})
export class ShipmentManifestComponent implements OnInit {
  @Input() manifest_id: any;
  manifest: any = {};
  timezone: string = this.commonService.getTimeZone();
  constructor(
    private toastr: ToastrService,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private endiciaService: EndiciaService
  ) {}

  ngOnInit(): void {
    if (this.manifest_id) {
      this.getShippingManifest();
    }
  }

  getShippingManifest() {
    const obs = this.endiciaService.getShippingManifest(this.manifest_id);
    obs.subscribe(
      (res) => {
        this.manifest = res.response_payload;
        /* if(res.label_uri){
        this.manifest.label_uri = res.label_uri.split(',')
      } */
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error('An Error Occurred');
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('MODAL-SHIPPING-MANIFEST');
  }
}
