import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'currency-edit',
  templateUrl: './currency-edit.component.html',
  styleUrls: ['./currency-edit.component.scss'],
})
export class CurrencyEditComponent implements OnInit {
  @Input() currency: any;
  @Input() isNewRecord: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  currencyForm = this.fb.group({
    currency_code: ['', Validators.required],
    description: ['', Validators.required],
  });

  ngOnInit(): void {
    this.currencyForm.patchValue(this.currency);
  }

  onUpdate() {
    const data = this.currencyForm.value;
    this.spinner.show();
    const obs = this.settingsService.updateCurrency(data);
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.currency = res;
        this.toastr.success('Updated Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onSave() {
    const data = this.currencyForm.value;
    this.spinner.show();
    const obs = this.settingsService.addCurrency(data);
    obs.subscribe(
      (res) => {
        this.isNewRecord = false;
        this.spinner.hide();
        this.currency = res;
        this.toastr.success('Saved Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('SETTINGS-EDIT-CURRENCY-MODAL');
  }
}
