<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="settingForm">
        <div class="card-header">
          <h3 class="card-title small">Settings</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="settingForm"
              ></formly-form>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div class="col-3">
              <ng-container *ngIf="!id">
                <button
                  type="submit"
                  class="btn btn-outline-success btn-sm w-50"
                  (click)="onSave()"
                >
                  Save
                </button>
              </ng-container>

              <ng-container *ngIf="id">
                <button
                  type="submit"
                  class="btn btn-outline-success btn-sm w-50"
                  (click)="onUpdate()"
                >
                  Update
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
