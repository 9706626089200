<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="customerForm" (ngSubmit)="submitCustomer()">
        <div class="card-header">
          <h3 class="card-title small">Customer Information</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="customerForm"
              ></formly-form>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div class="col-6">
              <button
                type="submit"
                class="btn btn-success btn-sm w-50"
                [disabled]="!customerForm.valid"
              >
                Save
              </button>
            </div>
            <div class="col-6">
              <!--button type="reset" class="btn btn-outline-secondary btn-sm w-50">Reset</button-->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mantle-order-list (customerOrders)="(true)"></mantle-order-list>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
