<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="storeForm">
        <div class="card-header">
          <h3 class="card-title small">Store Details</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-4">
              <label class="control-label small">Store Name</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_name"
              />
            </div>
            <div class="form-group col-4">
              <label class="control-label small">Email</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_email"
              />
            </div>
            <div class="form-group col-4">
              <label class="control-label small">Phone</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_phone"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-4">
              <label class="control-label small">Address 1</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_address"
              />
            </div>
            <div class="form-group col-4">
              <label class="control-label small">Address 2</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_address_1"
              />
            </div>
            <div class="form-group col-4">
              <label class="control-label small">City Code</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_citycode"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-4">
              <label class="control-label small">State Code</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_statecode"
              />
            </div>
            <div class="form-group col-4">
              <label class="control-label small">Zip Code</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_zipcode"
              />
            </div>
            <div class="form-group col-4">
              <label class="control-label small">Country Code</label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="store_countrycode"
                maxlength="2"
              />
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div class="col-6">
              <button
                type="submit"
                class="btn btn-outline-success btn-sm w-50"
                *ngIf="!store_id"
                (click)="createStore()"
              >
                Save
              </button>
              <button
                type="submit"
                class="btn btn-outline-success btn-sm w-50"
                *ngIf="store_id"
                (click)="updateStore()"
              >
                Update
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn btn-outline-danger btn-sm w-50"
                (click)="closeModal()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
