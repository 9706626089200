import { Component } from '@angular/core';

@Component({
  selector: 'app-mantle-dashboard-widget',
  templateUrl: './mantle-dashboard-widget.component.html',
  styleUrls: ['./mantle-dashboard-widget.component.scss']
})
export class MantleDashboardWidgetComponent {

}
