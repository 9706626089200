import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { MantleConfirmDialogComponent } from 'src/app/views/mantle-confirm-dialog/mantle-confirm-dialog.component';
import { StoreDetailsComponent } from '../store-details/store-details.component';
declare let $: any;

@Component({
  selector: 'store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss'],
})
export class StoreListComponent implements OnInit {
  storeList: Array<any> = [];
  constructor(
    private commonService: CommonService,
    private settingsService: SettingsService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init_data();
  }

  init_data() {
    this.spinner.show();
    const obs = this.settingsService.getStores();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.storeList = res;

        setTimeout(() => {
          $('.dataTable thead tr').clone(true).appendTo('.dataTable thead');
          $('.dataTable thead tr:eq(1) th').each(function (i) {
            var title = $(this).text();
            $(this).html(
              '<input type="text" style="width:100%" placeholder="' +
                title +
                '" />'
            );

            $('input', this).on('keyup change', function () {
              if (dataTable.column(i).search() !== this.value) {
                dataTable.column(i).search(this.value).draw();
              }
            });
          });

          var dataTable = $('.dataTable').DataTable({
            orderCellsTop: true,
            fixedHeader: true,
            pagingType: 'full_numbers',
            pageLength: 100,
            processing: true,
            lengthMenu: [5, 10, 20, 100, 200, 500],
          });
        }, 1);
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onEdit(store_id) {
    const modalRef = this.modalService.open(StoreDetailsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.store_id = store_id;

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'MODAL-STORE-DETAILS') {
        modalRef.close();
      }
    });
  }

  onDeleteStore(store_id) {
    const modalRef = this.modalService.open(MantleConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      backdrop: 'static',
    });

    modalRef.componentInstance.header = 'Delete Store Address';
    modalRef.componentInstance.message =
      'Are you sure want to delete this store address?';

    this.commonService.modal_close.subscribe((data: any) => {
      if (data.name === 'CONFIRM-DIALOG') {
        if (data.data.action_id == 1) {
          this.settingsService.deleteStore(store_id).subscribe(
            (data) => {
              this.toastr.success('Store Store Address Deleted');
              this.init_data();
            },
            (error) => {
              this.toastr.error('An Error Occurred');
            }
          );
        }

        modalRef.close();
      }
    });
  }
}
