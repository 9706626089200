import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { CommonService } from '../services/common.service';

@Directive({
  selector: '[IsoDateLocal]',
})
export class IsoDateLocalDirective implements OnInit, AfterViewInit {
  constructor(
    private el: ElementRef,
    private ngControl: NgControl,
    private datePipe: DatePipe,
    private commonService: CommonService
  ) {}
  isoDateValue: any;
  isSet = false;

  @HostListener('ngModelChange', ['$event']) ngModelChange($event) {
    if (!this.isSet) {
      this.isSet = true;
      this.isoDateValue = $event;
      setTimeout(() => this.patchIsoDate(), 500);
    }
  }

  /*@HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    alert('input')
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateWithTimezone = this.datePipe.transform(
      value, 'yyyy-MM-ddTHH:mm:ss.ZZZZZ', timeZone
    );
    console.log(dateWithTimezone)
    this.el.nativeElement.value = dateWithTimezone;
  }*/

  ngOnInit(): void {
    if (this.ngControl['options']?.standalone) {
      setTimeout(() => {
        this.patchIsoDate();
      }, 500);
    }
  }

  ngAfterViewInit(): void {
    if (this.ngControl['options']?.standalone) {
      setTimeout(() => {
        this.patchIsoDate();
      }, 500);
    }
  }

  patchIsoDate = () => {
    this.isoDateValue = this.isoDateValue ?? this.ngControl.control.value;
    if (this.isoDateValue) {
      this.isoDateValue = this.datePipe.transform(
        this.isoDateValue,
        'yyyy-MM-ddTHH:mm',
        this.commonService.getTimeZone()
      );
      this.ngControl.control.patchValue(this.isoDateValue);
    }
  };
}
