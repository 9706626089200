import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { StorageService } from 'src/app/utils/services/storage.service';
import { UserService } from 'src/app/utils/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('nav') nav;
  active = 0;
  userProfile: any;
  timezone: string = this.commonService.getTimeZone();
  constructor(
    public appService: AppService,
    private auth0Service: Auth0Service,
    private commonService: CommonService,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.userProfile = this.auth0Service.getProfile();
  }
}
