<label for="field.id" class="control-label">
  {{ props.label }}
  <span aria-hidden="true" class="ng-star-inserted" *ngIf="props.required"
    >*</span
  >
</label>

<tag-input
  [editable]="true"
  (onTagEdited)="onTagEdited($event)"
  (onAdd)="onTagAdded($event)"
  (onRemove)="onTagRemoved($event)"
  (onValidationError)="(onValidationError)"
  [formControl]="formControl"
  [formlyAttributes]="field"
></tag-input>

<small
  class="form-text text-muted"
  *ngIf="props.description && props.description != ''"
  >{{ props.description }}</small
>
