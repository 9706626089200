import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { ProductService } from 'src/app/utils/services/product.service';
import { MantleProductUpdateComponent } from '../../mantle-product-update/mantle-product-update.component';

@Component({
  selector: 'mantle-favorite-list',
  templateUrl: './mantle-favorite-list.component.html',
  styleUrls: ['./mantle-favorite-list.component.scss'],
})
export class MantleFavoriteListComponent implements OnInit {
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }

  favoriteList: Array<any> = [];
  favoriteCount = 0;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private inventoryService: InventoryService,
    private mantleModalService: MantleModalService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.inventoryService.favorites_updated.subscribe(() => {
      this.initData();
    });
    this.initData();
  }

  initData() {
    this.productService.getFavorites().subscribe(
      (res) => {
        try {
          this.favoriteList = res;
          this.favoriteCount = this.favoriteList.length;
        } catch (error) {}
      },
      (err) => {
        
      }
    );
  }

  toggleDropdownMenu() {
    if (this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.hideDropdownMenu();
    } else {
      this.showDropdownMenu();
    }
  }

  showDropdownMenu() {
    this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
  }

  hideDropdownMenu() {
    this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
  }

  viewProduct(brand_id, child_sku, parent_sku) {
    const modalRef = this.mantleModalService.open(
      MantleProductUpdateComponent,
      MANTLE_MODAL_NAME.PRODUCT_UPDATE_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.data = {
      brand_id: brand_id,
      parent_sku: parent_sku,
      child_sku: child_sku,
    };
  }
}
