import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CashRegisterService } from 'src/app/utils/services/cash-register.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { CashRegisterDetailsComponent } from '../cash-register-details/cash-register-details.component';
import { CashRegisterTransactionsComponent } from '../cash-register-transactions/cash-register-transactions.component';
import { Subscription } from 'rxjs';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
declare let $: any;
@Component({
  selector: 'mantle-cash-register-list',
  templateUrl: './cash-register-list.component.html',
  styleUrls: ['./cash-register-list.component.scss'],
})
export class CashRegisterListComponent implements OnInit, OnDestroy {
  @Input() isCashRegisterLog: boolean = false;
  @Input() cash_register_id: any;
  cashRegisterList: Array<any> = [];
  cashregisterModalSubscribtion: Subscription;
  transactionsModalSubscribtion: Subscription;
  cashRegisterUpdatedSubscribtion: Subscription;
  timezone: string = this.commonService.getTimeZone();
  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService,
    private toastr: ToastrService,
    private cashRegisterService: CashRegisterService
  ) {
    this.cashRegisterUpdatedSubscribtion = this.commonService.cashRegisterUpdated.subscribe(
      (_) => {
        this.initData();
      }
    );
  }
  ngOnInit(): void {
    this.initData();
  }

  initData = () => {
    this.spinner.show();
    if (!this.isCashRegisterLog) {
      this.cashRegisterService.getCashRegisters().subscribe(
        (res) => {
          this.spinner.hide();
          this.cashRegisterList = res;
          this.initDataTable();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
    } else {
      this.cashRegisterService
        .getCashRegisterLog(this.cash_register_id)
        .subscribe(
          (res) => {
            this.spinner.hide();
            //rename cash_register_users_logs to cash_register_users
            this.cashRegisterList = res.map((reg) => {
              return {
                ...{ cash_register_users: reg.cash_register_users_log },
                ...reg,
              };
            });
            this.initDataTable();
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err);
          }
        );
    }
  };

  initDataTable = () => {
    setTimeout(() => {
      if (!$.fn.dataTable.isDataTable('.dataTable')) {
        $('.dataTable').DataTable({
          orderCellsTop: true,
          fixedHeader: true,
          pagingType: 'full_numbers',
          pageLength: 100,
          processing: true,
          lengthMenu: [5, 10, 20, 100, 200, 500],
        });
      }
    }, 1);
  };

  statusDescription(status) {
    switch (parseInt(status)) {
      case 1:
        return 'Running';
        break;
      case 2:
        return 'Closed';
        break;
      case 3:
        return 'Not Available';
        break;
      default:
        return 'Unknown';
        break;
    }
  }

  onEdit(cash_register_id, cash_register_log_id = null) {
    const modalRef = this.mantleModalService.open(
      CashRegisterDetailsComponent,
      MANTLE_MODAL_NAME.MODAL_CASHREGISTER_DETAILS,
      { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' },
      this.initData
    );

    modalRef.componentInstance.cash_register_id = cash_register_id;
    if (this.isCashRegisterLog && cash_register_log_id != null) {
      modalRef.componentInstance.isCashRegisterLog = this.isCashRegisterLog;
      modalRef.componentInstance.cash_register_log_id = cash_register_log_id;
    }

    this.cashregisterModalSubscribtion = this.commonService.modal_close.subscribe(
      (data) => {
        if (data == MANTLE_MODAL_NAME.MODAL_CASHREGISTER_DETAILS) {
          modalRef.close();
          this.cashregisterModalSubscribtion.unsubscribe();
        }
      }
    );
  }

  onViewTransactions(cash_register_id, session_id = null) {
    const modalRef = this.mantleModalService.open(
      CashRegisterTransactionsComponent,
      MANTLE_MODAL_NAME.MODAL_CASHREGISTER_TRANSACTION,
      { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' }
    );

    modalRef.componentInstance.cash_register_id = cash_register_id;
    modalRef.componentInstance.session_id = session_id;

    this.transactionsModalSubscribtion = this.commonService.modal_close.subscribe(
      (data) => {
        if (data === MANTLE_MODAL_NAME.MODAL_CASHREGISTER_TRANSACTION) {
          modalRef.close();
          this.transactionsModalSubscribtion.unsubscribe();
        }
      }
    );
  }

  getAssignedUsers(cash_register_users) {
    if (cash_register_users) {
      return cash_register_users.map((x) => x.user?.short_name)?.join();
    } else return '';
  }

  onViewLogRegisters = (cash_register_id) => {
    const modalRef = this.mantleModalService.open(
      CashRegisterListComponent,
      MANTLE_MODAL_NAME.MODAL_CASHREGISTER_HIST_LIST,
      { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' },
      this.initData
    );

    modalRef.componentInstance.isCashRegisterLog = true;
    modalRef.componentInstance.cash_register_id = cash_register_id;
  };

  closeModal() {
    this.isCashRegisterLog = false;
    this.cash_register_id = null;
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.MODAL_CASHREGISTER_HIST_LIST
    );
  }

  ngOnDestroy(): void {
    this.cashRegisterUpdatedSubscribtion.unsubscribe();
  }
}
