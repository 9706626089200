import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MantleModalService } from './mantle-modal.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {
  searchDefault = new Subject();
  searchOrder = new Subject();

  constructor(
    private apiService: ApiService,
    private mantleModalService: MantleModalService
  ) {}

  getPOById(order_id): Observable<any> {
    return this.apiService.get(`/api/v1/purchase-order/${order_id}`);
  }

  createPO(requestPayload): Observable<any> {
    return this.apiService.post(`/api/v1/purchase-order`, requestPayload);
  }

  updatePO(requestPayload): Observable<any> {
    return this.apiService.patch(`/api/v1/purchase-order`, requestPayload);
  }

  updatePOStatus(requestPayload): Observable<any> {
    return this.apiService.post(
      `/api/v1/purchase-order/update-status`,
      requestPayload
    );
  }

  searchOrders(requestPayload): Observable<any> {
    return this.apiService.post(
      `/api/v1/purchase-order/search`,
      requestPayload
    );
  }

  getPOStatuses(): Observable<any> {
    return this.apiService.get(`/api/v1/purchase-order/order-status`);
  }

  deletePOItem(order_item_id, order_id): Observable<any> {
    return this.apiService.delete('/api/v1/purchase-order/order_item', {
      order_item_id: order_item_id,
      order_id: order_id,
    });
  }

  getOnOrderQuantity = (requestPayload): Observable<any> => {
    return this.apiService.post(
      '/api/v1/purchase-order/on-order-quantity',
      requestPayload
    );
  };
}
