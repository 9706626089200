import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  MANTLE_MODAL_NAME,
  SYSTEM_SETTINGS,
} from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { PurchaseOrderService } from 'src/app/utils/services/purchase-order.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
  selector: 'mantle-po-invoice',
  templateUrl: './mantle-po-invoice.component.html',
  styleUrls: ['./mantle-po-invoice.component.scss'],
})
export class MantlePoInvoiceComponent implements OnInit, OnDestroy {
  @Input() order_id;
  invoiceOrders: Array<any> = [];
  private orderSubscription: Subscription;
  logoImage: any;
  constructor(
    private poService: PurchaseOrderService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private storageService: StorageService
  ) {}
  ngOnInit(): void {
    this.initValues();
  }
  initValues = () => {
    this.logoImage = this.logoImage = this.storageService.getItem(
      SYSTEM_SETTINGS.IMAGE_LOGO
    );

    if (this.order_id != null && this.order_id != undefined) {
      this.getOrderDetails();
    }
  };
  getOrderDetails = () => {
    this.spinner.show();
    this.orderSubscription = this.poService.getPOById(this.order_id).subscribe(
      (res) => {
        this.spinner.hide();
        this.invoiceOrders.push(res);
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err?.error?.message);
      }
    );
  };

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.PO_INVOICE);
  }

  ngOnDestroy(): void {
    this.orderSubscription?.unsubscribe();
  }
}
