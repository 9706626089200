<div class="col-12 table-responsive small">
    <div class="row justify-content-center m-2">
        <div class="col-6"><h6>Sales Channel Breakdown</h6></div>
    </div>
    <table class="table table-striped dataTable">
        <thead>
        <tr>
            <th></th>
            <th>Sales Channel</th>
            <th>Orders Today</th>
            <th>Revenue Today</th>
            <th>Orders 30 Days</th>
            <th>Revenue 30 Days</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let channel of sales_channel">
        <tr>
            <td><img width="50px" height="50px" class="img-thumbnail rounded float-left" src="{{channel.channel_image_url}}"></td>
            <td>{{channel.sales_channel_description}}</td>
            <td>{{channel.orders_today}}</td>
            <td>{{channel.revenue_today}}</td>
            <td>{{channel.orders_30_days}}</td>
            <td>{{channel.revenue_30_days}}</td>
        </tr>
        </ng-container>
        </tbody>
    </table>
</div>