import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  REPORTING_LOOKUP,
  USER_PREFERENCES,
} from 'src/app/utils/enums/mantle-enums';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { ReportingService } from 'src/app/utils/services/reporting.service';
import { UserPreferenceService } from 'src/app/utils/services/user-preference.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  dashReports: Array<any> = [];
  userProfile: any;
  quickLinksEnabled: boolean = false;
  settingsSubscription: Subscription;

  constructor(
    private reportingService: ReportingService,
    private auth0Service: Auth0Service,
    private userPreferenceService: UserPreferenceService,
    private _commonService: CommonService
  ) {
    this.settingsSubscription = this._commonService.systemSettingsUpdated.subscribe(
      (_) => {
        this.initData();
      }
    );
  }

  ngOnInit() {
    this.initData();
  }
  initData = () => {
    this.dashReports = [];
    this.userProfile = this.auth0Service.getProfile();
    if (this.userProfile != null) {
      this.getDashboardReports();
      this.getUserPrefennce();
    }
  };

  getDashboardReports() {
    REPORTING_LOOKUP.REPORT_TYPE.filter((x) => x.type == 1).forEach(
      (reportType) => {
        this.reportingService
          .getReports({ report_type_id: reportType.value })
          .subscribe((res) => {
            this.dashReports.push({
              value: reportType.value,
              sortOrder: reportType.sortOrder,
              reports: res.data,
            }); //= this.dashReports.concat(res.data);
            this.reGroupData();
          });
      }
    );
  }

  getUserPrefennce = () => {
    if (this.userProfile?.user_id != undefined) {
      this.userPreferenceService
        .getUserPreferences(this.userProfile.user_id)
        .subscribe(
          (res) => {
            if (
              res.find((x) => x.name == USER_PREFERENCES.QUICK_LINKS)?.value ==
              'true'
            ) {
              this.quickLinksEnabled = true;
            }
          },
          (err) => {}
        );
    } else {
      setTimeout(() => {
        this.userProfile = this.auth0Service.getProfile();
        this.getUserPrefennce();
      }, 3000);
    }
  };

  reGroupData() {
    this.dashReports.sort((a, b) => a.sortOrder - b.sortOrder);
  }
  ngOnDestroy(): void {
    this.settingsSubscription.unsubscribe();
  }
}
