import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'shipping-stamps',
  templateUrl: './shipping-stamps.component.html',
  styleUrls: ['./shipping-stamps.component.scss']
})
export class ShippingStampsComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,) { }

  ngOnInit(): void {
  }
  
  closeModal(){
    this.commonService.modal_close.next('MODAL-STAMPS-SHIPPING-OPTIONS')
  }

}
