<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-12" *ngIf="resultCount >= MAX_RESULT">
        <div class="alert alert-warning" role="alert">
          This search criteria is returning a lot of products, only the first
          1000 are shown. Please refine your search if products are missing.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 table-responsive">
        <table class="table table-hover table-sm" id="inventoryDataTable">
          <thead>
            <tr>
              <th scope="col">&nbsp;</th>
              <th scope="col" style="width: 30%" class="nosort">Product</th>
              <th scope="col" style="width: 20%">Variant</th>
              <th scope="col">Short Code</th>
              <th scope="col">Status</th>
              <th scope="col">Total</th>
              <th scope="col">Sell Price</th>
              <!--th scope="col">Buy Price</th-->
              <th scope="col">Links</th>
            </tr>
          </thead>
          <tbody class="small">
            <ng-container *ngFor="let product of product_list">
              <tr class="table-primary">
                <td>
                  <!--img src="{{product.image_url}}" class="img-rounded" width="50" height="50"-->
                  <button
                    *ngIf="!product.toggle"
                    type="button"
                    class="btn btn-tool"
                    (click)="toggleChild(product.parent_sku)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <button
                    *ngIf="product.toggle"
                    type="button"
                    class="btn btn-tool"
                    (click)="toggleChild(product.parent_sku)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                </td>
                <td>
                  <div class="row" (click)="toggleChild(product.parent_sku)">
                    <div class="col">
                      <img
                        src="{{ product.image_url }}"
                        class="img-rounded"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div class="col">
                      <b>{{ product.name }}</b>
                    </div>
                  </div>
                </td>
                <td>
                  <b>{{ product.set_name }}</b>
                </td>
                <td>
                  <b>{{ product.shortCode }}</b>
                </td>
                <td>&nbsp;</td>
                <td>
                  <b>{{ product.total }}</b>
                </td>
                <td>&nbsp;</td>
                <!--td>&nbsp;</td-->
                <td>&nbsp;</td>
              </tr>
              <tr
                [ngStyle]="{
                  visibility: product.toggle ? 'visible' : 'collapse'
                }"
              >
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Total</td>
                <td>Sell Price</td>
                <td>&nbsp;</td>
              </tr>
              <tr
                *ngFor="let child_product of product.child_skus"
                [ngStyle]="{
                  visibility: product.toggle ? 'visible' : 'collapse'
                }"
              >
                <td>&nbsp;</td>
                <td>
                  <div class="small">
                    <button
                      class="btn btn-link btn-sm m-0 p-0 m-2"
                      (click)="
                        edit_item(
                          child_product.parent_sku,
                          child_product.child_sku,
                          child_product.brand_id
                        )
                      "
                    >
                      <i class="fas fa-external-link-alt" aria-hidden="true"></i
                      >Edit</button
                    >|
                    <button
                      class="btn btn-outline-success btn-sm m-0 p-0 m-2"
                      (click)="
                        quick_edit_save(
                          child_product.parent_sku,
                          child_product.child_sku
                        )
                      "
                      *ngIf="child_product.quick_edit"
                    >
                      <i class="fas fa-save" aria-hidden="true"></i>Update |
                    </button>
                    <button
                      class="btn btn-link btn-sm m-0 p-0 m-2"
                      (click)="
                        quick_edit_cancel(
                          child_product.parent_sku,
                          child_product.child_sku
                        )
                      "
                      *ngIf="child_product.quick_edit"
                    >
                      <i class="fas fa-times" aria-hidden="true"></i>Cancel
                    </button>
                    <button
                      class="btn btn-link btn-sm m-0 p-0 m-2"
                      (click)="
                        quick_edit_item(
                          child_product.parent_sku,
                          child_product.child_sku
                        )
                      "
                      *ngIf="!child_product.quick_edit"
                    >
                      <i class="fas fa-edit" aria-hidden="true"></i>Quick Edit |
                    </button>
                    <button
                      class="btn btn-link btn-sm m-0 p-0 m-2"
                      (click)="
                        onArchiveInventory(
                          child_product.inventory_id,
                          child_product.archived,
                          child_product.parent_sku,
                          child_product.child_sku,
                          child_product.brand_id
                        )
                      "
                      *ngIf="!child_product.quick_edit"
                    >
                      <i class="fas fa-archive" aria-hidden="true"></i>
                      {{ !child_product.archived ? 'Archive' : 'Unarchive' }}
                    </button>
                  </div>
                </td>
                <td>
                  <span>{{ child_product.name }}</span>
                </td>
                <td>
                  {{ child_product.shortCode }}
                </td>
                <td>
                  {{ child_product.status }}
                </td>
                <td>
                  &nbsp;
                  <input
                    type="number"
                    name="quantity"
                    value="{{ child_product.total }}"
                    [(ngModel)]="child_product.total"
                    class="form-control form-control-sm b-0"
                    (change)="onChangeQuickEdit($event, child_product)"
                    *ngIf="child_product.quick_edit"
                  />
                  <span *ngIf="!child_product.quick_edit">{{
                    child_product.total
                  }}</span>
                </td>
                <!--td>
                                <input type="number" name= "net_charge" value="{{child_product.net_charge}}" class="form-control form-control-sm b-0" (change)="onChangeQuickEdit($event, child_product.parent_sku, child_product.child_sku , child_product.brand_id)" *ngIf="child_product.quick_edit">
                                <span *ngIf="!child_product.quick_edit">{{child_product.net_charge}}</span>
                            </td-->
                <td>
                  &nbsp;
                  <input
                    type="number"
                    name="sell_price"
                    value="{{ child_product.sell_price }}"
                    [(ngModel)]="child_product.sell_price"
                    class="form-control form-control-sm b-0"
                    (change)="onChangeQuickEdit($event, child_product)"
                    *ngIf="child_product.quick_edit"
                  />
                  <span *ngIf="!child_product.quick_edit">{{
                    child_product.sell_price
                  }}</span>
                </td>
                <!--td>
                  <input
                    type="number"
                    name="buy_price"
                    value="{{ child_product.buy_price }}"
                    class="form-control form-control-sm b-0"
                    (change)="
                      onChangeQuickEdit(
                        $event,
                        child_product.parent_sku,
                        child_product.child_sku,
                        child_product.brand_id
                      )
                    "
                    *ngIf="child_product.quick_edit"
                  />
                  <span *ngIf="!child_product.quick_edit">{{
                    child_product.buy_price
                  }}</span>
                </td-->
                <td>
                  &nbsp;
                  <span *ngFor="let link of child_product.links">
                    <a href="{{ link.url }}" target="_blank">
                      {{ link.channel_name }} ,</a
                    >
                  </span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
