<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool">
        <button type="button" class="btn btn-success btn-sm" (click)="onAdd()">
          Add Order Status
        </button>
        <i class="fas fa-times"></i>
      </button>
    </div>
    <h5>Order Status Settings</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 table-responsive small">
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>#</th>
              <th>Description</th>
              <th>Order Editable</th>
              <th>Order In Sales Report</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let orderstatus of orderstatusList">
              <tr>
                <td>{{ orderstatus.order_status_id }}</td>
                <td>{{ orderstatus.status_description }}</td>
                <td>{{ orderstatus.editable }}</td>
                <td>{{ orderstatus.sales_include }}</td>
                <td>
                  <div class="dropdown">
                    <button
                      class="btn btn-outline btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="onEdit(orderstatus.order_status_id)"
                      >
                        <i class="fas fa-edit"></i> Edit
                      </button>
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="onDelete(orderstatus.order_status_id)"
                      >
                        <i class="fas fa-cart-plus"></i> Delete
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
