<div class="card" style="max-height: fit-content">
  <div class="card-header">
    <h6 *ngIf="orderDetails?.order_return_number">
      Return Order No#: {{ orderDetails.order_return_number }}
    </h6>

    <div class="col-10" *ngIf="activeRegisters.length > 0">
      <div class="row">
        <ng-container *ngFor="let register of activeRegisters; let i = index">
          <div class="col-4">
            <ng-toggle
              [value]="register.active"
              [width]="40"
              [color]="{ unchecked: '#939da2', checked: '#1ba841' }"
              [labels]="{ checked: '', unchecked: '' }"
              (change)="toggleActiveRegister($event, register, i)"
            ></ng-toggle>
            {{ register.description }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <fieldset [disabled]="order_return_id != undefined">
      <div class="row">
        <div class="col-12">
          <table class="table table-striped small table-responsive">
            <thead>
              <th scope="col">#</th>
              <th scope="col">Product Name</th>
              <th scope="col">Qty.</th>
              <th scope="col">Unit Price</th>
              <th scope="col">Subtotal</th>
              <th scope="col">Tax</th>
              <th scope="col">Return Qty.</th>
              <th scope="col">Restock</th>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of orderItems; index as i">
                <tr>
                  <td>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        [(value)]="item.selected"
                        [(ngModel)]="item.selected"
                        [ngModelOptions]="{
                          standalone: true
                        }"
                        (change)="onSelectItem($event, i)"
                      />
                    </div>
                  </td>
                  <td>
                    {{ item.item_description }}
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                  <td>
                    {{ item.unit_price }}
                  </td>
                  <td>
                    {{ item.total | currency }}
                  </td>
                  <td>
                    {{ item.tax_amount | currency }}
                  </td>
                  <td>
                    <div class="form-group" *ngIf="item.selected">
                      <input
                        type="number"
                        [(value)]="item.return_quantity"
                        [(ngModel)]="item.return_quantity"
                        [ngModelOptions]="{
                          standalone: true
                        }"
                        class="form-control form-control-sm"
                        min="0"
                        [(max)]="item.quantity"
                        (change)="onUpdateReturnQty($event, i)"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="form-check" *ngIf="item.selected">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        [(value)]="item.restore_quantity"
                        [(ngModel)]="item.restore_quantity"
                        [ngModelOptions]="{
                          standalone: true
                        }"
                      />
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2">
                  <div class="form-group">
                    <label for="">Return Date:</label>
                    <input
                      type="datetime-local"
                      [(ngModel)]="orderDetails.return_date"
                      [ngModelOptions]="{
                        standalone: true
                      }"
                      class="form-control form-control-sm float-end"
                      IsoDateLocal
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="row">
                    <div class="col-6">
                      <label>Total:</label>
                    </div>
                    <div class="col-6">
                      <span class="float-end">{{
                        orderDetails?.order_total | currency
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6"><label>Restocking Fee:</label></div>
                    <div class="col-6">
                      <span class="float-end">{{
                        orderDetails?.restocking_fee | currency
                      }}</span>
                    </div>
                  </div>
                </td>
                <td colspan="4">
                  <div class="row">
                    <div class="col-6">
                      <label for="partial_refund">Partial Refund:</label>
                    </div>
                    <div class="col-6">
                      <div class="form-check">
                        <input
                          id="partial_refund"
                          type="checkbox"
                          class="form-check-input"
                          [(value)]="orderDetails.partial_refund"
                          [(ngModel)]="orderDetails.partial_refund"
                          (change)="calculateTotal()"
                          [ngModelOptions]="{
                            standalone: true
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label>Return Amount:</label>
                    </div>
                    <div class="col-6">
                      <input
                        type="number"
                        [(value)]="orderDetails.return_amount"
                        [(ngModel)]="orderDetails.return_amount"
                        [disabled]="!orderDetails.partial_refund"
                        (disabledChange)="orderDetails.partial_refund = $event"
                        (change)="calculateTotal()"
                        [ngModelOptions]="{
                          standalone: true
                        }"
                        class="form-control form-control-sm float-end"
                      />
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-6">
                      <label for="return_store_credit"
                        >Refund as Store Credit:</label
                      >
                    </div>
                    <div class="col-6">
                      <div class="form-check">
                        <input
                          id="return_store_credit"
                          type="checkbox"
                          class="form-check-input"
                          [(value)]="orderDetails.return_store_credit"
                          [(ngModel)]="orderDetails.return_store_credit"
                          (change)="calculateTotal()"
                          [ngModelOptions]="{
                            standalone: true
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-6">
                      <label>Store Credit:</label>
                    </div>
                    <div class="col-6">
                      <input
                        type="number"
                        [(value)]="orderDetails.return_store_credit_amount"
                        [(ngModel)]="orderDetails.return_store_credit_amount"
                        [disabled]="true"
                        (disabledChange)="
                          orderDetails.return_store_credit = $event
                        "
                        (change)="calculateTotal()"
                        [ngModelOptions]="{
                          standalone: true
                        }"
                        class="form-control form-control-sm float-end"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;</td>
                <td colspan="2">
                  <label>Payment Method:</label>
                  <span class="float-end">{{
                    orderDetails?.payment_method?.payment_description
                  }}</span>
                  <ng-select
                    *ngIf="!orderDetails.order_return_number"
                    [items]="paymentMethod"
                    bindLabel="description"
                    bindValue="payment_method_id"
                    [(value)]="orderDetails.payment_method_id"
                    [(ngModel)]="orderDetails.payment_method_id"
                  >
                  </ng-select>
                </td>
                <td colspan="4">
                  <label>Return Reason:</label>
                  <textarea
                    class="form-control form-control-sm float-end"
                    cols="30"
                    rows="2"
                    [(value)]="orderDetails.return_reason"
                    [(ngModel)]="orderDetails.return_reason"
                    [ngModelOptions]="{
                      standalone: true
                    }"
                  ></textarea>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-3" *ngIf="order_return_id == undefined">
          <button
            type="button"
            class="btn btn-sm btn-warning"
            (click)="onReturnOrder()"
          >
            &nbsp;&nbsp;&nbsp;Return&nbsp;&nbsp;&nbsp;
          </button>
        </div>
      </div>
    </fieldset>

    <div class="row">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <button type="button" class="btn btn-link" (click)="onViewOrder()">
          View Order Details
        </button>
        <h5>Order Receipt</h5>
        <mantle-order-receipt
          [autoPrint]="false"
          [orderId]="order_id"
        ></mantle-order-receipt>
      </div>
    </div>
  </div>
</div>
