import {
  Component,
  EventEmitter,
  forwardRef,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from '@angular/forms';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { VendorService } from 'src/app/utils/services/vendor.service';

@Component({
  selector: 'mantle-vendor-search',
  templateUrl: './mantle-vendor-search.component.html',
  styleUrls: ['./mantle-vendor-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MantleVendorSearchComponent),
      multi: true,
    },
  ],
})
export class MantleVendorSearchComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  public vendor_list$: Observable<any>;
  public input$ = new Subject<any | null>();
  private vendorSearchSubscription: Subscription;
  private vendorSetSubscription: Subscription;

  loadingVendor: boolean;
  disabled: boolean;
  value: any;

  onChange: any = () => {};
  onTouched: any = () => {};

  @Output() private change = new EventEmitter();
  constructor(
    private vendorService: VendorService,
    private fb: UntypedFormBuilder
  ) {
    /*this.vendor_list$ = this.input$.pipe(
    map((term) => this.searchCustomer(term))
  );*/
  }

  vendorSearchForm = this.fb.group({
    vendor_id: [],
  });

  ngOnInit(): void {
    this.vendorSearchSubscription = this.vendorService.externalVendorSearch.subscribe(
      (vendorData) => {
        if (vendorData != undefined) {
          /*vendorData['full_name'] =
            vendorData['first_name'] + ', ' + vendorData['last_name'];
          vendorData['tooltip'] =
            'Email: ' +
            vendorData['email'] +
            ' | Phone: ' +
            vendorData['phone'] +
            ' | City : ' +
            vendorData['city'];*/
          let arr: Array<any> = new Array<any>();
          arr.push(vendorData);
          //this.input$.next(arr);

          this.vendor_list$ = of(arr);
        }
      }
    );

    this.vendorSetSubscription = this.vendorService.externalVendorSet.subscribe(
      (vendorData) => {
        this.vendorSearchForm.patchValue(vendorData);
      }
    );
  }

  searchVendor($event) {
    const term = $event?.srcElement?.value;
    if (typeof term !== 'string') {
      this.loadingVendor = false;
      return term;
    }
    const searchTerm = term ? term : '';
    this.vendor_list$ = of([]);
    if (searchTerm != '') {
      this.loadingVendor = true;
      //this.commonService.searching_customer.next(true);

      this.vendor_list$ = this.vendorService.searchVendors({
        search_term: searchTerm,
      });

      this.vendor_list$.subscribe(
        (res) => {
          res.map((res: any) => {
            /*res.full_name = res.first_name + ', ' + res.last_name;
            res.tooltip =
              'Email: ' +
              res.email +
              ' | Phone: ' +
              res.phone +
              ' | City : ' +
              res.city;*/
            return res;
          });
          //this.input$.next(res);
          this.loadingVendor = false;
        },
        (err) => {
          this.loadingVendor = false;
        }
      );
    }
  }

  changeVendor($event) {
    this.value = $event?.vendor_id;
    this.change.emit($event);
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.vendorSearchSubscription.unsubscribe();
    this.vendorSetSubscription.unsubscribe();
  }
}
