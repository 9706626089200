import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'endicia-dimension-unit-edit',
  templateUrl: './endicia-dimension-unit-edit.component.html',
  styleUrls: ['./endicia-dimension-unit-edit.component.scss'],
})
export class EndiciaDimensionUnitEditComponent implements OnInit {
  @Input() dimensionunit: any;
  @Input() isNewRecord: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  dimensionunitForm = this.fb.group({
    id: [],
    dimension_unit: ['', Validators.required],
    dimension_unit_description: ['', Validators.required],
  });

  ngOnInit(): void {
    this.dimensionunitForm.patchValue(this.dimensionunit);
  }

  onSave() {
    const data = this.dimensionunitForm.value;
    this.spinner.show();
    const obs = this.settingsService.addDimensionUnit(data);
    obs.subscribe(
      (res) => {
        this.isNewRecord = false;
        this.spinner.hide();
        this.dimensionunit = res;
        this.toastr.success('Saved Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  onUpdate() {
    const data = this.dimensionunitForm.value;
    this.spinner.show();
    const obs = this.settingsService.updateDimensionUnit(data);
    obs.subscribe(
      (res) => {
        this.isNewRecord = false;
        this.spinner.hide();
        this.dimensionunit = res;
        this.toastr.success('Updated Sucessfully');
      },
      (err) => {
        this.spinner.hide();
        
        this.toastr.error(JSON.stringify(err));
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next('SETTINGS-EDIT-DIMENSION-UNIT-MODAL');
  }
}
