import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-checkbox',
  templateUrl: './mantle-checkbox.component.html',
  styleUrls: ['./mantle-checkbox.component.scss']
})
export class MantleCheckboxComponent extends FieldType implements OnInit {

  valueChecked:boolean = false;
  constructor(private commonService:CommonService) {
    super();
  }

  ngOnInit(): void {

    this.commonService.mantle_data_toggle.subscribe((data:any) =>{
      // @ts-expect-error
      if(data.toggle == this.field.toggle){
        this.valueChecked = data.value;
      }
    });
  }

  onCheckboxChange($event){
    if ($event.target.checked) {
      $event.target.classList.add('mantle-checked');
      $event.target.classList.remove('mantle-unchecked');
    }
    else{
      $event.target.classList.remove('mantle-checked');
      $event.target.classList.add('mantle-unchecked');
    }
  }

}
