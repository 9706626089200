import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private apiService: ApiService) {}

  getFormConfig(brand_id): Observable<any> {
    return this.apiService.post('/api/v1/product/product_form', {
      brand_id: brand_id,
    });
  }

  getProductDetails(requestPayload) {
    return this.apiService.post(
      '/api/v1/product/product_details',
      requestPayload
    );
  }

  updateProductDetails(payload: any): Observable<any> {
    return this.apiService.post('/api/v1/product/update_product', payload);
  }

  productDetailsList = (payload: any): Observable<any> => {
    return this.apiService.post(
      '/api/v1/product/product_details_list',
      payload
    );
  };
  updateToggle = (payload: any): Observable<any> => {
    return this.apiService.post('/api/v1/product/update_toggle', payload);
  };

  getFavorites = (userid: any = null): Observable<any> => {
    return this.apiService.get(`/api/v1/product/favorite`);
  };

  createFavorite = (payload: any): Observable<any> => {
    return this.apiService.post('/api/v1/product/favorite', payload);
  };
  deleteFavorite = (payload: any): Observable<any> => {
    return this.apiService.delete('/api/v1/product/favorite', payload);
  };

  isFavorite = (payload: any): Observable<any> => {
    return this.apiService.post('/api/v1/product/favorite/check', payload);
  };

  getImages = (payload: any): Observable<any> => {
    return this.apiService.post('/api/v1/product/images', payload);
  };

  createImage = (payload: any): Observable<any> => {
    return this.apiService.post('/api/v1/product/image', payload);
  };

  deleteImage = (payload: any): Observable<any> => {
    return this.apiService.delete('/api/v1/product/image', payload);
  };

  productFilter = (
    requestPayload,
    sortBy = '',
    sortOrder = '',
    searchLimit = ''
  ): Observable<any> => {
    var firstQP = true;
    var endpoint = '/api/v1/product/product_filter';

    if (sortBy != null && sortBy?.trim() !== '') {
      if (firstQP) {
        endpoint = endpoint + `/?orderBy=` + sortBy;
        firstQP = false;
      } else {
        endpoint = endpoint + `&orderBy=` + sortBy;
      }
    }
    if (sortOrder != null && sortOrder.trim() !== '') {
      if (firstQP) {
        endpoint = endpoint + `/?sortOrder=` + sortOrder;
        firstQP = false;
      } else {
        endpoint = endpoint + `&sortOrder=` + sortOrder;
      }
    }
    if (
      searchLimit != null &&
      searchLimit != undefined &&
      searchLimit?.toString()?.trim() !== ''
    ) {
      if (firstQP) {
        endpoint = endpoint + `/?limit=` + searchLimit;
        firstQP = false;
      } else {
        endpoint = endpoint + `&limit=` + searchLimit;
      }
    }
    return this.apiService.post(endpoint, requestPayload);
  };

  getFilterMapping = (brand_id) => {
    return this.apiService.get(`/api/v1/product/catalog/mapping/${brand_id}`);
  };

  createFilterMapping = (requestPayload) => {
    return this.apiService.post(
      `/api/v1/product/catalog/mapping`,
      requestPayload
    );
  };

  updateFilterMapping = (requestPayload) => {
    return this.apiService.patch(
      `/api/v1/product/catalog/mapping`,
      requestPayload
    );
  };

  deleteFilterMapping = (filter_mapping_id) => {
    return this.apiService.delete(
      `/api/v1/product/catalog/mapping/${filter_mapping_id}`
    );
  };
  batchUpdateFilterMapping = (requestPayload) => {
    return this.apiService.patch(
      `/api/v1/product/catalog/mapping/batch`,
      requestPayload
    );
  };
  getCatalogFields = () => {
    return this.apiService.get(`/api/v1/product/catalog/fields`);
  };

  getCatalogFieldMapping = () => {
    return this.apiService.get(`/api/v1/product/catalog/fields-mappings`);
  };

  getroductDescriptionFields = (brand_id) => {
    return this.apiService.get(
      `/api/v1/product/catalog/description-fields/${brand_id}`
    );
  };

  updateProductDescriptionFields = (requestPayload) => {
    return this.apiService.post(
      '/api/v1/product/catalog/description-fields',
      requestPayload
    );
  };

  deleteProductVendorId = (id) => {
    return this.apiService.delete(`/api/v1/product/vendor/${id}`);
  };

  getProductVendorId = (requestPayload) => {
    return this.apiService.post('/api/v1/product/vendor', requestPayload);
  };
}
