<label for="field.id" class="control-label">
  {{ props.label }}
  <span aria-hidden="true" class="ng-star-inserted" *ngIf="props.required"
    >*</span
  >
</label>
<div class="row">
<div class="col-8">
    <input
      #file
      id="file"
      type="file"
      accept="image/*"
      class="form-control"
      [formlyAttributes]="field"
      (change)="onFileChange(file.files)"
    />
  </div>
  <div class="col-3" *ngIf="props.preview">
    <img
      [(src)]="imageSrc"
      *ngIf="imageSrc"
      class="m-2 img-fluid img-thumbnail rounded"
      style="height: 100px; width: 100px"
    />
  </div>
  <div class="col-1">
    <button
      class="btn btn-outline-danger btn-sm"
      type="button"
      *ngIf="imageSrc"
      (click)="onRemoveImage()"
    >
    <i class="fas fa-trash"></i> 
    </button>
  </div>
</div>