<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 small table-responsive">
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>Serial No#</th>
              <th>Description</th>
              <th>Store</th>
              <th>Status</th>
              <th>User (S)</th>
              <th>Balance Date</th>
              <th>Opening Balance</th>
              <th>Closing Balance</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let cashRegister of cashRegisterList">
              <tr>
                <td>{{ cashRegister.serial_number }}</td>
                <td>{{ cashRegister.description }}</td>
                <td>{{ cashRegister.store?.store_name }}</td>
                <td>{{ statusDescription(cashRegister.status) }}</td>
                <td>
                  {{ getAssignedUsers(cashRegister.cash_register_users) }}
                </td>
                <td>
                  {{ cashRegister.balance_date | date: 'short':timezone }}
                </td>
                <td>{{ cashRegister.opening_balance }}</td>
                <td>{{ cashRegister.closing_balance }}</td>
                <td>
                  <div class="dropdown">
                    <button
                      class="btn btn-outline btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <ng-container *ngIf="!isCashRegisterLog">
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="onEdit(cashRegister.cash_register_id)"
                        >
                          <i class="fas fa-edit"></i> View / Edit
                        </button>
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="
                            onViewTransactions(cashRegister.cash_register_id)
                          "
                        >
                          <i class="fas fa-eye"></i> Transactions
                        </button>
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="
                            onViewLogRegisters(cashRegister.cash_register_id)
                          "
                        >
                          <i class="fas fa-eye"></i> Historic Transactions
                        </button>
                      </ng-container>
                      <ng-container *ngIf="isCashRegisterLog">
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="
                            onEdit(null, cashRegister.cash_register_log_id)
                          "
                        >
                          <i class="fas fa-edit"></i> View / Edit
                        </button>
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="
                            onViewTransactions(
                              cashRegister.cash_register_id,
                              cashRegister.session_id
                            )
                          "
                        >
                          <i class="fas fa-eye"></i> Transactions
                        </button>
                      </ng-container>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
