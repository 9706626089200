import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { ProductService } from 'src/app/utils/services/product.service';
import formConfig from '../../../../../configs/forms/form_settings_product_filter.json';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';

@Component({
  selector: 'settings-product-filter',
  templateUrl: './settings-product-filter.component.html',
  styleUrls: ['./settings-product-filter.component.scss'],
})
export class SettingsProductFilterComponent implements OnInit, OnDestroy {
  @Input() brand_id: any;
  product_brand: any = [];
  productLineSelected: any;
  catalogFields: Array<any>;
  catalogFieldMapping: any;
  productCatlogFieldMappings: Array<any> = [];
  productFilters: Array<any> = [];
  private tblDataSubscription!: Subscription;

  mappingForm = this.fb.group({});
  fields: FormlyFieldConfig[];
  model: any = {};
  options: FormlyFormOptions = {
    formState: {},
  };

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private productService: ProductService
  ) {}
  ngOnInit(): void {
    this.init_values();
    this.tblDataSubscription = this.commonService.tabularInputRemoved.subscribe(
      (data) => {
        if (data['name'] == 'product_filter_mapping') {
          this.onDeleteFilter(data);
        }
      }
    );
  }

  init_values() {
    this.fields = formConfig;
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.product_brand = res['product_brand'];
        this.productService.getCatalogFields().subscribe(
          (res) => {
            this.catalogFields = res;
            this.productService.getCatalogFieldMapping().subscribe(
              (res) => {
                this.catalogFieldMapping = res;
                this.spinner.hide();
              },
              (err) => {
                this.spinner.hide();
                this.toastr.error(err);
              }
            );
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err);
          }
        );
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  }

  changeBrand($event: any) {
    this.productCatlogFieldMappings = [];
    this.brand_id = $event?.id;
    if (this.brand_id) this.getCatalogFieldMapping();
  }

  getCatalogFieldMapping = () => {
    this.spinner.show();
    this.productCatlogFieldMappings = this.catalogFieldMapping
      .find((x) => x.brand_id == this.brand_id)
      ?.catalog_config_fields_export_mappings?.map((field) => {
        const fieldConfig = this.catalogFields?.find(
          (c) => c.catalog_schema_field_id == field.catalog_schema_field_id
        );
        return { ...field, ...fieldConfig };
      });

    this.getFilterMapping();
  };

  getFilterMapping = () => {
    this.productService.getFilterMapping(this.brand_id).subscribe(
      (res) => {
        this.productFilters = res;
        this.productCatlogFieldMappings = this.productCatlogFieldMappings.filter(
          (x) =>
            this.productFilters.filter(
              (p) => p.catalog_schema_field_id == x.catalog_schema_field_id
            ).length < 1
        );

        this.patchForm();

        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err);
      }
    );
  };

  onAddFilter(catalog_schema_field_id: any) {
    this.spinner.show();
    const field = this.productCatlogFieldMappings.find(
      (x) => x.catalog_schema_field_id == catalog_schema_field_id
    );
    const fOrder = this.productFilters.length + 1;
    this.productService
      .createFilterMapping({
        ...{
          brand_id: this.brand_id,
          label: field.field_name,
          field_order: fOrder,
        },
        ...field,
      })
      .subscribe(
        (res) => {
          this.getFilterMapping();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err);
        }
      );
  }

  onDeleteFilter = (data) => {
    if (data['index'] >= 0) {
      const field = this.productFilters[data['index']];
      this.spinner.show();
      this.productService
        .deleteFilterMapping(field.filter_mapping_id)
        .subscribe(
          (res) => {
            this.getFilterMapping();
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err);
          }
        );
    }
  };

  onBatchUpdate() {
    const frmValues = this.mappingForm.getRawValue();
    if (frmValues?.product_filter_mapping?.length > 0) {
      this.spinner.show();
      this.productService
        .batchUpdateFilterMapping(frmValues?.product_filter_mapping)
        .subscribe(
          (res) => {
            this.getFilterMapping();
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err);
          }
        );
    }
  }

  patchForm = () => {
    this.model = {
      product_filter_mapping: this.productFilters,
    };
    this.mappingForm.patchValue(this.model);
  };

  ngOnDestroy(): void {
    this.tblDataSubscription?.unsubscribe();
  }

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.SETTINGS_PRODUCT_FILTER
    );
  }
}
