import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { AppConfig } from 'src/app/utils/services/app.config';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'channel-activate-shopify',
  templateUrl: './channel-activate-shopify.component.html',
  styleUrls: ['./channel-activate-shopify.component.scss'],
})
export class ChannelActivateShopifyComponent implements OnInit {
  @Input() channelOption: any = {};
  validated: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private config: AppConfig,
    private settingsService: SettingsService
  ) {}

  shopifyConfigForm = this.fb.group({
    channel_shopify_id: [],
    channel_ref: [],
    api_key: ['', Validators.required],
    api_password: ['', Validators.required],
    store_name: ['', Validators.required],
    channel_shopify_description: ['Shopify Channel'],
    update_frequency: [30],
    shop_id: [],
    enable_orders_webhook: [false],
    webhook_id: [],
    webhook_destination: [
      this.config.getConfig('api_root') + '/api/v1/channel/shopify/webhook',
    ],
    created_at_min: [],
  });

  ngOnInit(): void {
    if (this.channelOption != undefined) {
      setTimeout(
        () => this.shopifyConfigForm.patchValue(this.channelOption),
        0
      );
    }
  }

  onValidate() {
    this.validated = false;
    this.spinner.show();
    const obs = this.channelsService.validateShopify(
      this.shopifyConfigForm.value
    );
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        if (res != undefined) {
          if (res.is_valid) {
            this.validated = true;
            this.toastr.success('Validation Successful');
          } else {
            this.validated = false;
            this.toastr.error(res.error, 'Validation Failed');
          }
        } else {
          this.validated = false;
          this.toastr.error('Validation Failed', 'Validation Failed');
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  onSave() {
    this.spinner.show();
    const obs = this.channelsService.createShopifyChannel(
      this.shopifyConfigForm.value
    );
    obs.subscribe(
      (res) => {
        this.shopifyConfigForm.patchValue(res);
        this.spinner.hide();
        this.toastr.success('Channel Added Successfully');
        this.closeModal();
        this.channelsService.channelAdded.next({});
        this.settingsService.statusMappingDialog();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  onUpdate() {
    this.spinner.show();
    const obs = this.channelsService.updateShopifyChannel(
      this.shopifyConfigForm.value
    );
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }
  onUpdateFrequency() {
    this.spinner.show();
    const obs = this.channelsService.updateShopifyChannel({
      channel_shopify_id: this.shopifyConfigForm.value['channel_shopify_id'],
      channel_ref: this.shopifyConfigForm.value['channel_ref'],
      update_frequency: this.shopifyConfigForm.value['update_frequency'],
    });
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'An Error Occurred');
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.SHOPIFY_ACTIVATION_MODAL
    );
  }
}
