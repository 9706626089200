import { createReducer, on } from '@ngrx/store';
import { STATE_STATUS } from 'src/app/utils/enums/mantle-enums';
import { TieredRewardPointState } from 'src/app/utils/interfaces/app.state';
import {
  deleteTiered,
  deleteTieredSuccess,
  loadTiered,
  loadTieredFailure,
  loadTieredSuccess,
  upsertTiered,
  upsertTieredSuccess,
} from './tiered_reward_points.actions';

export const initialTieredState: TieredRewardPointState = {
  tiered: [],
  error: null,
  status: STATE_STATUS.PENDING,
};

export const tieredRewardPointReducer = createReducer(
  initialTieredState,
  on(loadTiered, (state) => ({ ...state, status: STATE_STATUS.LOADING })),

  on(loadTieredSuccess, (state, { tiered }) => ({
    ...state,
    tiered,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),

  on(loadTieredFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  })),

  on(upsertTiered, (state) => ({ ...state, status: STATE_STATUS.LOADING })),

  on(upsertTieredSuccess, (state, { tier }) => ({
    ...state,
    tiered: mergeStateArray(state.tiered, tier),
    activeRecord: tier,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),

  on(deleteTiered, (state) => ({ ...state, status: STATE_STATUS.LOADING })),

  on(deleteTieredSuccess, (state, { point_tiered_id }) => ({
    ...state,
    tiered: state.tiered?.filter((x) => x.point_tiered_id != point_tiered_id),
    activeRecord: null,
    error: null,
    status: STATE_STATUS.SUCCESS,
  }))
);

const mergeStateArray = (tiered: any[], tier) => {
  const cloneState = JSON.parse(JSON.stringify(tiered));

  const index = cloneState.findIndex(
    (x) => x.point_tiered_id == tier.point_tiered_id
  );
  if (index < 0) {
    cloneState.push(tier);
  } else {
    cloneState[index] = tier;
  }
  return cloneState.sort((a, b) => a.threshold - b.threshold);
};
