import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';

@Component({
  selector: 'mantle-price-trend',
  templateUrl: './price-trend.component.html',
  styleUrl: './price-trend.component.scss',
})
export class InventoryPriceTrendComponent implements OnInit, OnDestroy {
  @Input() inventoryDetails: any;
  public dataItems: any = [];
  timezone: any = this.commonService.getTimeZone();
  public loading: any = false;
  private subscription: Subscription = new Subscription();
  constructor(
    private inventoryService: InventoryService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {
    this.subscription.add(
      this.commonService.inventoryPeriodSelector.subscribe({
        next: (data: any) => {
          this.getInvetoryLog(data);
        },
        error: (err) => {},
        complete: () => {},
      })
    );
  }
  ngOnInit(): void {
    this.getInvetoryLog();
  }
  getInvetoryLog = (
    filter = { interval_period: 1, interval_type: 'months' }
  ) => {
    this.loading = true;
    this.subscription.add(
      this.inventoryService
        .getAdvancedInventory(
          'inventory_log',
          this.inventoryDetails.child_sku,
          filter
        )
        .subscribe({
          next: (res) => {
            this.dataItems = res;
          },
          error: (err) => {},
          complete: () => {
            this.loading = false;
          },
        })
    );
  };
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
