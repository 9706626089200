import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mantle-loading',
  templateUrl: './mantle-loading.component.html',
  styleUrls: ['./mantle-loading.component.scss'],
})
export class MantleLoadingComponent implements OnInit {
  @Input() label: any = "";

  constructor() {}

  ngOnInit(): void {}
}
