<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <!-- /.card-header -->
  <div class="card-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Authentication</a>
        <ng-template ngbNavContent>
          <endicia-auth></endicia-auth>
        </ng-template>
      </li>

      <li [ngbNavItem]="0">
        <a ngbNavLink>Carrier</a>
        <ng-template ngbNavContent>
          <endicia-carrier></endicia-carrier>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Content Type</a>
        <ng-template ngbNavContent>
          <endicia-contents-type></endicia-contents-type>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Delivery Confirmation Type</a>
        <ng-template ngbNavContent>
          <endicia-delivery-confirmation-type></endicia-delivery-confirmation-type>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>Dimension Unit</a>
        <ng-template ngbNavContent>
          <endicia-dimension-unit></endicia-dimension-unit>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink>Label Format</a>
        <ng-template ngbNavContent>
          <endicia-label-format></endicia-label-format>
        </ng-template>
      </li>
      <li [ngbNavItem]="6">
        <a ngbNavLink>Label Output Type</a>
        <ng-template ngbNavContent>
          <endicia-label-output-type></endicia-label-output-type>
        </ng-template>
      </li>
      <li [ngbNavItem]="7">
        <a ngbNavLink>Label Size</a>
        <ng-template ngbNavContent>
          <endicia-label-size></endicia-label-size>
        </ng-template>
      </li>
      <li [ngbNavItem]="8">
        <a ngbNavLink>Non Delivery Option</a>
        <ng-template ngbNavContent>
          <endicia-non-delivery-option></endicia-non-delivery-option>
        </ng-template>
      </li>
      <li [ngbNavItem]="9">
        <a ngbNavLink>Packaging Type</a>
        <ng-template ngbNavContent>
          <endicia-packaging-type></endicia-packaging-type>
        </ng-template>
      </li>
      <li [ngbNavItem]="10">
        <a ngbNavLink>Service Type</a>
        <ng-template ngbNavContent>
          <endicia-service-type></endicia-service-type>
        </ng-template>
      </li>
      <li [ngbNavItem]="11">
        <a ngbNavLink>Weight Unit</a>
        <ng-template ngbNavContent>
          <endicia-weight-unit></endicia-weight-unit>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
<!-- /.card -->
