<div class="card" style="background-color: transparent">
  <div class="card-header">
    <h3 class="card-title">{{ report_name }}</h3>
  </div>
  <div class="card-body">
    <table class="table table-striped dataTable small table-responsive">
      <thead>
        <tr>
          <th *ngFor="let header of headers">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dataItems">
          <td *ngFor="let header of headers">
            {{ item[header] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
