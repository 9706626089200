import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { AppConfig } from 'src/app/utils/services/app.config';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'channel-activate-cardtrader',
  templateUrl: './channel-activate-cardtrader.component.html',
  styleUrls: ['./channel-activate-cardtrader.component.scss'],
})
export class ChannelActivateCardtraderComponent implements OnInit {
  @Input() channelOption: any = {};
  validated: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private config: AppConfig,
    private settingsService: SettingsService
  ) {}

  cardTraderConfigForm = this.fb.group({
    channel_cardtrader_id: [],
    channel_ref: [],
    jwt_token: ['', Validators.required],
    shared_secret: [],
    name: [],
    channel_cardtrader_description: ['CardTrader Channel'],
    update_frequency: [30],
    id: [],
  });

  ngOnInit(): void {
    if (this.channelOption != undefined) {
      this.cardTraderConfigForm.patchValue(this.channelOption);
    }
  }

  onValidate() {
    this.validated = false;
    this.spinner.show();
    this.channelsService
      .validateCardTrader(this.cardTraderConfigForm.getRawValue())
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res != undefined) {
            if (res.is_valid) {
              this.validated = true;
              this.cardTraderConfigForm.patchValue(res);
              this.toastr.success('Validation Successful');
            } else {
              this.validated = false;
              this.toastr.error(res.error, 'Validation Failed');
            }
          } else {
            this.validated = false;
            this.toastr.error('Validation Failed', 'Validation Failed');
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(JSON.stringify(err), 'An Error Occurred');
        }
      );
  }

  onSave() {
    this.spinner.show();
    const obs = this.channelsService.createCardTraderChannel(
      this.cardTraderConfigForm.getRawValue()
    );
    obs.subscribe(
      (res) => {
        this.cardTraderConfigForm.patchValue(res);
        this.spinner.hide();
        this.toastr.success('Channel Added Successfully');
        this.closeModal();
        this.channelsService.channelAdded.next({});
        this.settingsService.statusMappingDialog();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(JSON.stringify(err), 'An Error Occurred');
      }
    );
  }

  onUpdate() {
    this.spinner.show();
    const obs = this.channelsService.updateCardTraderChannel(
      this.cardTraderConfigForm.value
    );
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }
  onUpdateFrequency() {
    this.spinner.show();
    this.channelsService.updateCardTraderChannel({
      channel_cardtrader_id: this.cardTraderConfigForm.value['channel_cardtrader_id'],
      channel_ref: this.cardTraderConfigForm.value['channel_ref'],
      update_frequency: this.cardTraderConfigForm.value['update_frequency'],
    }).subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Frequency Update Successful');
        this.closeModal();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.CARDTRADER_ACTIVATION_MODAL);
  }
}
