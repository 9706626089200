<form [formGroup]="bcConfigForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Activate BigCommerce sales channel</h5>
    </div>
    <div class="card-body">
      <div class="row small">
        <div class="col-md-12">
          <ol>
            <li><strong>Store API Accounts</strong></li>
          </ol>
          <ul>
            <li>
              How to get an API key:
              <a
                href="https://support.bigcommerce.com/s/article/Store-API-Accounts"
                target="_blank"
                title=""
                >https://support.bigcommerce.com/s/article/Store-API-Accounts</a
              >
            </li>
          </ul>
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Description</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="channel_bc_description"
            maxlength="50"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Store Key</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="store_id"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Access Token</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="access_token"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Client ID</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="client_id"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Client Secret</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="client_secret"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Orders Since</label>
          <input
            type="datetime-local"
            class="form-control form-control-sm"
            formControlName="min_date_created"
            IsoDateLocal
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Update Frequency (Minutes)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="update_frequency"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-check col-md-6">
          <input
            type="checkbox"
            class="form-check-input"
            id="enable_orders_webhook"
            formControlName="enable_orders_webhook"
          />
          <label class="form-check-label small" for="enable_orders_webhook"
            >Enable Orders Webhook</label
          >
        </div>
        <div class="form-check col-md-6">
          <input
            type="checkbox"
            class="form-check-input"
            id="enable_inventory_webhook"
            formControlName="enable_inventory_webhook"
          />
          <label class="form-check-label small" for="enable_inventory_webhook"
            >Enable Inventory Webhook</label
          >
        </div>
      </div>
      <ng-container
        *ngIf="
          bcConfigForm.value.enable_orders_webhook ||
          bcConfigForm.value.enable_inventory_webhook
        "
      >
        <div class="row small">
          <div class="form-group col-md-12">
            <blockquote>
              <ul>
                <li>
                  Following the creation of a webhook, it can take up to one
                  minute for BigCommerce to start making
                  <code>POST</code> requests to the destination server.
                </li>
                <li>
                  The <code>destination</code> URL must be served on port
                  <strong>433</strong>; custom ports are not currently
                  supported.
                </li>
              </ul>
            </blockquote>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label small">Webhook destination</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="webhook_destination"
            />
          </div>
          <div class="form-group col-md-3">
            <label class="control-label small">Webhook user</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="webhook_username"
            />
          </div>
          <div class="form-group col-md-3">
            <label class="control-label small">Webhook Password</label>
            <input
              type="password"
              class="form-control form-control-sm"
              formControlName="webhook_password"
            />
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="form-group col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onValidate()"
          >
            <i class="fas fa-check-double"></i> Validate
          </button>
        </div>
        <div class="form-group col-md-6">
          <button
            *ngIf="validated && !bcConfigForm.value.channel_bc_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onSave()"
            [disabled]="!bcConfigForm.valid"
          >
            <i class="far fa-save"></i> Save
          </button>
          <button
            *ngIf="validated && bcConfigForm.value.channel_bc_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdate()"
            [disabled]="!bcConfigForm.valid"
          >
            <i class="far fa-save"></i> Update
          </button>
          <button
            *ngIf="!validated && bcConfigForm.value.channel_bc_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdateFrequency()"
            [disabled]="!bcConfigForm.valid"
          >
            <i class="far fa-save"></i> Update Frequency
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
