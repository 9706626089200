import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/utils/interfaces/app.state';
import {
  selectCustomerById,
  selectCustomers,
  selectRecordsSummary,
  selectStateStatus,
} from '../state/customer.selectors';
import {
  editCustomer,
  listCustomers,
  actionSelectCustomer,
} from '../state/customer.actions';
import {
  MANTLE_MODAL_NAME,
  STATE_STATUS,
} from 'src/app/utils/enums/mantle-enums';
import { NgxSpinnerService } from 'ngx-spinner';
import { MantleCustomerComponent } from '../mantle-customer.component';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';

@Component({
  selector: 'mantle-customer-list',
  templateUrl: './mantle-customer-list.component.html',
  styleUrl: './mantle-customer-list.component.scss',
})
export class MantleCustomerListComponent implements OnInit {
  public customersList$: Observable<any>;
  initialized: boolean = false;
  filterParams: any = {};

  constructor(
    private store: Store<AppState>,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService
  ) {
    this.customersList$ = this.store.select(selectCustomers);
    this.store.select(selectStateStatus).subscribe((status) => {
      if (status == STATE_STATUS.LOADING) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }
  ngOnInit(): void {
    this.initTableData();
  }

  filterCustomer = async (pageLength, draw, fncallback) => {
    this.store.dispatch(
      listCustomers({
        filterParams: {
          ...this.filterParams,
          page: draw,
          limit: pageLength,
        },
      })
    );

    this.store.select(selectRecordsSummary).subscribe((summary) => {
      fncallback({
        recordsTotal: summary.recordsTotal,
        recordsFiltered: summary.recordsTotal,
      });
    });
  };

  viewCustonerDetails = async (customer_id) => {
    const tempSub = this.store
      .select(selectCustomerById(customer_id))
      .subscribe((customer) => {
        this.store.dispatch(actionSelectCustomer({ customer }));

        const modalRef = this.mantleModalService.open(
          MantleCustomerComponent,
          MANTLE_MODAL_NAME.CUSTOMER_MODAL,
          {
            ariaLabelledBy: 'modal-basic-title',
            size: 'xl',
          }
        );
      });
    tempSub.unsubscribe();
  };

  initTableData() {
    let lastPage = 0;
    let lastSearchText = '';

    try {
      if ($.fn.dataTable.isDataTable('.dataTable')) {
        var tbl = $('.dataTable').DataTable();
        //tbl.destroy();
        //tbl.draw();
        tbl.clear();
        tbl.destroy();
      }
    } catch (error) {}

    setTimeout(() => {
      $('.dataTable thead tr').clone(true).appendTo('.dataTable thead');
      $('.dataTable thead tr:eq(1) th').each((i, element) => {
        var title = $(element).text();
        var id = $(element).attr('id');

        if (id?.length) {
          $(element).html(
            '<input type="text" style="width:100%" placeholder="Search ' +
              title +
              '" id="' +
              id +
              '"/>'
          );
        }

        $('input', element).on('keyup', (event) => {
          if (event.key === 'Enter') {
            const eValue = (event.target as HTMLInputElement).value;
            if (dataTable.column(i).search() !== eValue || eValue === '') {
              this.filterParams[event.target.id] = eValue;
              if (eValue == undefined || eValue == '') {
                delete this.filterParams[event.target.id];
              }
              dataTable.draw();
            }
          }
        });
      });

      var dataTable = $('.dataTable').DataTable({
        orderCellsTop: true,
        pagingType: 'full_numbers',
        pageLength: 50,
        displayStart: lastPage,
        search: { search: lastSearchText },
        serverSide: true,
        processing: true,
        searching: false,
        ajax: (dataTablesParameters: any, callback) => {
          let start = dataTablesParameters.start;
          let length = dataTablesParameters.length;
          var currentPage = start / length + 1;
          this.filterCustomer(length, currentPage, callback);
        },
        lengthMenu: [5, 10, 20, 50, 100],
      });
    }, 1);
  }
}
