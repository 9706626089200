<form [formGroup]="cardTraderConfigForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>CardTrader sales channel</h5>
    </div>
    <div class="card-body">
      <div class="row small">
        <div class="col-md-12">
          <span
            >You can obtain [YOUR_AUTH_TOKEN] inside the settings -> APIs page
            on your CardTrader profile: <br />
            <a
              href="https://www.cardtrader.com/en/full_api_app"
              target="_blank"
              title=""
              >https://www.cardtrader.com/en/full_api_app</a
            ></span
          >
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Description</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="channel_cardtrader_description"
            maxlength="50"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">JWT TOKEN</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="jwt_token"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Shared Secret</label>
          <input
            type="password"
            class="form-control form-control-sm"
            readonly
            formControlName="shared_secret"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Name</label>
          <input
            type="text"
            class="form-control form-control-sm"
            readonly
            formControlName="name"
          />
        </div>
        <div class="form-group col-md-3">
          <label class="control-label small">Id</label>
          <input
            type="text"
            class="form-control form-control-sm"
            readonly
            formControlName="id"
          />
        </div>
        <div class="form-group col-md-3">
          <label class="control-label small">Update Frequency (Minutes)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="update_frequency"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onValidate()"
            [disabled]="!cardTraderConfigForm.valid"
          >
            <i class="fas fa-check-double"></i> Validate
          </button>
        </div>
        <div class="form-group col-md-6">
          <button
            *ngIf="
              validated && !cardTraderConfigForm.value.channel_cardtrader_id
            "
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onSave()"
            [disabled]="!cardTraderConfigForm.valid"
          >
            <i class="far fa-save"></i> Save
          </button>
          <button
            *ngIf="
              validated && cardTraderConfigForm.value.channel_cardtrader_id
            "
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdate()"
            [disabled]="!cardTraderConfigForm.valid"
          >
            <i class="far fa-save"></i> Update
          </button>
          <button
            *ngIf="cardTraderConfigForm.value.channel_cardtrader_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdateFrequency()"
          >
            <i class="far fa-save"></i> Update Frequency
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
