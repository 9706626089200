import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { upsertProductReward } from '../state/product_reward_points.actions';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { AppState } from 'src/app/utils/interfaces/app.state';
import formConfig from '../../../../../../configs/forms/form_settings_product_reward_points.json';
import { Subscription } from 'rxjs';
import { selectedActiveRecord } from '../state/product_reward_points.selectors';

@Component({
  selector: 'reward-product-details',
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss',
})
export class RewardProductDetailsComponent implements OnInit, OnDestroy {
  @Input() product;
  private subscription: Subscription = new Subscription();
  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private commonService: CommonService
  ) {
    this.subscription.add(
      this.store.select(selectedActiveRecord).subscribe((record) => {
        if (record != null) this.patchForm(record);
      })
    );
  }
  form = this.fb.group({
    point_product_id: [],
    point_id: [1],
  });
  options: FormlyFormOptions = {
    formState: {},
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  ngOnInit(): void {
    setTimeout(() => {
      this.fields = formConfig;
      setTimeout(() => {
        if (this.product) this.patchForm(this.product);
      }, 1);
    }, 1);
  }

  onSave() {
    var requestPayload = this.form.getRawValue();
    if (requestPayload.point_product_id == null)
      delete requestPayload.point_product_id;
    this.store.dispatch(upsertProductReward(requestPayload));
  }

  patchForm(value) {
    this.form.patchValue(value);
  }
  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.PRODUCT_REWARD_POINT_DETAILS
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
