import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-inventory-period-selector',
  templateUrl: './inventory-period-selector.component.html',
  styleUrl: './inventory-period-selector.component.scss',
})
export class InventoryPeriodSelectorComponent implements OnInit, OnDestroy {
  intervalType = [
    {
      id: 'hours',
      description: 'Hours',
    },
    {
      id: 'days',
      description: 'Days',
    },
    {
      id: 'weeks',
      description: 'Weeks',
    },
    {
      id: 'months',
      description: 'Months',
    },
    {
      id: 'years',
      description: 'Years',
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      //this.getData();
    });
  }

  form = this.fb.group({
    interval_period: [1],
    interval_type: ['months'],
  });

  getData = () => {
    this.commonService.inventoryPeriodSelector.next(this.form.getRawValue());
  };

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }
}
