import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { CustomerService } from 'src/app/utils/services/customer.service';
import { VendorService } from 'src/app/utils/services/vendor.service';
import formConfig from '../../../../../configs/forms/form_vendor_details.json';
import { Subscription } from 'rxjs';
import { PurchaseOrderService } from 'src/app/utils/services/purchase-order.service';

@Component({
  selector: 'mantle-po-vendor-details',
  templateUrl: './mantle-po-vendor-details.component.html',
  styleUrls: ['./mantle-po-vendor-details.component.scss'],
})
export class MantlePoVendorDetailsComponent implements OnInit, OnDestroy {
  @Input() vendor_id: any;
  vendorForm = this.fb.group({
    vendor_id: [],
  });
  options: FormlyFormOptions = {
    formState: {
      countries: [{ value: 'US', label: 'USA' }],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};
  private vendorDetailsSubscription!: Subscription;

  constructor(
    private vendorService: VendorService,
    private poService: PurchaseOrderService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.fields = formConfig;
    // setTimeout(() => {
    //   this.patchForm({});
    // }, 1);
    if (this.vendor_id) {
      this.spinner.show();
      this.vendorDetailsSubscription = this.vendorService
        .getVendorById(this.vendor_id)
        .subscribe(
          (res) => {
            this.patchForm(res);
            this.spinner.hide();
          },
          (err) => {
            this.toastr.error('An Error Occurred');
            this.spinner.hide();
          }
        );
    }
  }
  submitVendor() {
    this.spinner.show();

    const data = this.vendorForm.getRawValue();
    if (data.vendor_id) {
      this.vendorService.updateVendor(data).subscribe(
        (res) => {
          this.patchForm(res);
          this.emmitVendor();
          this.spinner.hide();
          //this.closeModal();
          this.toastr.success('Successfully updated.');
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.error?.message, 'An Error Occurred');
        }
      );
    } else {
      delete data.vendor_id;
      this.vendorService.createVendor(data).subscribe(
        (res) => {
          this.patchForm(res);
          this.emmitVendor();
          this.spinner.hide();
          this.toastr.success('Successfully created.');
          //this.closeModal();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.error?.message, 'An Error Occurred');
        }
      );
    }
  }

  emmitVendor() {
    const vendorDetails = this.vendorForm.getRawValue();
    this.vendorService.externalVendorSearch.next(vendorDetails);
    this.vendorService.externalVendorSet.next(vendorDetails);
    this.vendorService.vendorSelected.next(vendorDetails);
  }

  patchForm(value) {
    this.vendorForm.patchValue(value);
  }

  ngOnDestroy(): void {
    this.vendorDetailsSubscription?.unsubscribe();
  }
  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.PO_VENDOR_DETAILS);
  }
}
