<div class="card">
    <div class="card-header">
        <div class="card-tools">
            <button type="button" class="btn btn-tool">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <h5>Plugin & Extensions</h5>
    </div>
    <div class="card-body">
        <h6>Enabled Plugin & Extensions</h6>
        <div class="row bg-light">
            <ng-container *ngFor="let plugin of activatedPlugins; let i = index">
                <div class="col-4">
                    <figure class="figure">
                        <img src="{{plugin.image}}" class="figure-img img-thumbnail rounded" alt="..."  style="max-width: 100px; height: 100px;">
                        <figcaption class="figure-caption">
                            <div class="row">
                                <div class="col-6">
                                    <ng-toggle
                                        [value]="true"
                                        [width]="40"
                                        [color]="{unchecked: '#939da2',checked: '#1ba841'}"
                                        [labels]="{checked: '', unchecked: ''}"
                                        (change)="toggleFavorite(plugin)"
                                        ></ng-toggle>
                                </div>
                                <div class="col-6">
                                    <i class="fas fa-cog" (click)="openSettings(plugin)"></i>
                                </div>
                            </div>
                                
                                {{plugin.description}}
                        </figcaption>
                    </figure>
                </div>
            </ng-container>
        </div>

        <h6>Available Plugin & Extensions</h6>
        <div class="row bg-light">
            <ng-container *ngFor="let plugin of availablePlugins; let i = index">
                <div class="col-4">
                    <figure class="figure">
                        <img src="{{plugin.image}}" class="figure-img img-thumbnail rounded" alt="..." style="max-width: 100px; height: 100px;">
                        <figcaption class="figure-caption">
                            <div class="row">
                                <div class="col-6">
                                    <ng-toggle
                                        [value]="false"
                                        [width]="40"
                                        [color]="{unchecked: '#939da2',checked: '#1ba841'}"
                                        [labels]="{checked: '', unchecked: ''}"
                                        (change)="toggleFavorite(plugin)"
                                        ></ng-toggle>
                                </div>
                                <div class="col-6">
                                    <!--i class="fas fa-cog" (click)="openSettings(plugin)"></i-->
                                </div>
                            </div>
                            
                            {{plugin.description}}</figcaption>
                    </figure>
                </div>
            </ng-container>
        </div>
    </div>
</div>