<form [formGroup]="authForm" >
    <div class="card">
        <div class="card-header">
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <h5>SERA Authentication</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-6">
                    <label class="control-label small">Description</label>
                    <input type="text" class="form-control form-control-sm" formControlName="description" maxlength="50">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-6">
                    <label class="control-label small">Client Id</label>
                    <input type="text" class="form-control form-control-sm" formControlName="client_id">
                </div>
                <div class="form-group col-6">
                    <label class="control-label small">Client Secret</label>
                    <input type="password" name="client_secret" class="form-control form-control-sm" formControlName="client_secret">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-6">
                    <label class="control-label small">Callback URL</label>
                    <input type="text" class="form-control form-control-sm" formControlName="redirect_uri">
                </div>
                <div class="form-group col-6 mt-4" *ngIf="authSettings">
                    <a href="{{authSettings.auth_url}}/authorize?client_id={{authForm.value.client_id}}&response_type=code&redirect_uri={{authForm.value.redirect_uri}}&scope=offline_access" (click)="onSave()" target="_blank"><i class="fas fa-external-link-alt"></i> Authorization Code</a>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-6">
                    <button type="button" class="btn btn-sm btn-info w-50" (click)="onSave()"><i class="fas fa-check-double"></i> Save</button>
                </div>
            </div>
        </div>
    </div>
</form>