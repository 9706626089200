<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h5>Sales Channels</h5>
      </div>
      <div class="col-md-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Channels</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <channel-list></channel-list>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-5">
      <div class="col-md-2">
        <channel-add></channel-add>
      </div>
      <div class="col-md-2">
        <div class="row d-flex justify-content-center">
          <div class="col-md-12"><btn-status-mapping></btn-status-mapping></div>
        </div>
        <div class="row d-flex justify-content-center mt-4">
          <div class="col-md-12">
            <btn-init-channel-load></btn-init-channel-load>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
