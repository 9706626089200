<form [formGroup]="amazonConfigForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Activate Amazon Seller Partner Channel</h5>
    </div>
    <div class="card-body">
      <div class="row small">
        <div class="col-md-12">
          <span
            >How to get Started:
            <a
              href="https://developer-docs.amazon.com/sp-api/docs"
              target="_blank"
              title=""
              >https://developer-docs.amazon.com/sp-api/docs</a
            >
            <h6>Seller ID:</h6>
            <a
              href="https://sellercentral.amazon.com/sw/AccountInfo/MerchantToken/step/MerchantToken"
              target="_blank"
              title=""
              >https://sellercentral.amazon.com/sw/AccountInfo/MerchantToken/step/MerchantToken</a
            >
          </span>
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Description</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="channel_amazon_description"
            maxlength="50"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">App. Client ID *</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="selling_partner_app_client_id"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">App. Client Secret *</label>
          <input
            type="password"
            class="form-control form-control-sm"
            formControlName="selling_partner_app_client_secret"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">AWS Key ID *</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="aws_access_key_id"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">AWS Secret Access Key *</label>
          <input
            type="password"
            class="form-control form-control-sm"
            formControlName="aws_secret_access_key"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small"
            >Seller ID / Your Merchant Token *</label
          >
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="seller_id"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Refresh Token *</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="refresh_token"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">AWS Session Token</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="aws_ssession_token"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">AWS Seller Partner Role</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="aws_selling_partner_role"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Region</label>
          <select class="form-control form-control-sm" formControlName="region">
            <ng-container *ngFor="let aws_region of aws_regions">
              <option value="{{ aws_region.id }}">
                {{ aws_region.description }}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Market Place *</label>
          <select
            class="form-control form-control-sm"
            formControlName="marketplace_id"
          >
            <ng-container *ngFor="let aws_market_place of aws_market_places">
              <option value="{{ aws_market_place.id }}">
                {{ aws_market_place.description }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Orders Since</label>
          <input
            type="datetime-local"
            class="form-control form-control-sm"
            formControlName="created_at_min"
            IsoDateLocal
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Update Frequency (Minutes)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="update_frequency"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-check col-md-4">
          <input
            type="checkbox"
            class="form-check-input"
            id="use_sandbox"
            formControlName="use_sandbox"
          />
          <label class="form-check-label small" for="use_sandbox"
            >Use Sandbox</label
          >
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onValidate()"
            [disabled]="!amazonConfigForm.valid"
          >
            <i class="fas fa-check-double"></i> Validate
          </button>
        </div>
        <div class="form-group col-md-6">
          <button
            *ngIf="validated && !amazonConfigForm.value.channel_sp_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onSave()"
            [disabled]="!amazonConfigForm.valid"
          >
            <i class="far fa-save"></i> Save
          </button>
          <button
            *ngIf="validated && amazonConfigForm.value.channel_sp_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdate()"
            [disabled]="!amazonConfigForm.valid"
          >
            <i class="far fa-save"></i> Update
          </button>
          <button
            *ngIf="!validated && amazonConfigForm.value.channel_sp_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdateFrequency()"
            [disabled]="!amazonConfigForm.valid"
          >
            <i class="far fa-save"></i> Update Frequency
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
