<div class="card">
  <fieldset [disabled]="!canEdit">
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">From Address</label>
          <ng-select
            [items]="storeAddresses"
            bindLabel="store_name"
            bindValue="id"
            (change)="onSelectAddress($event)"
          >
          </ng-select>
        </div>
        <div class="col-md-6">
          <label class="control-label small">To Address</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <ng-container *ngIf="fromAddress">
                <table>
                  <tr>
                    <td>{{ fromAddress.store_name }}</td>
                  </tr>
                  <tr>
                    <td>{{ fromAddress.store_address }}</td>
                  </tr>
                  <tr>
                    <td>{{ fromAddress.store_address_1 }}</td>
                  </tr>
                  <tr>
                    <td>{{ fromAddress.store_citycode }}</td>
                  </tr>
                  <tr>
                    <td>{{ fromAddress.store_statecode }}</td>
                  </tr>
                  <tr>
                    <td>{{ fromAddress.store_zipcode }}</td>
                  </tr>
                  <tr>
                    <td>{{ fromAddress.store_countrycode }}</td>
                  </tr>
                </table>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <ng-container *ngIf="orderDetails">
            <div class="row">
              <div class="col-md-12 table-responsive">
                <table>
                  <tr>
                    <td>
                      {{ orderDetails.shipment_address.shipment_first_name }}
                      {{ orderDetails.shipment_address.shipment_last_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{
                        orderDetails.shipment_address.shipment_street_address
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{
                        orderDetails.shipment_address.shipment_street_address_2
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ orderDetails.shipment_address.shipment_city }}</td>
                  </tr>
                  <tr>
                    <td>{{ orderDetails.shipment_address.shipment_state }}</td>
                  </tr>
                  <tr>
                    <td>
                      {{ orderDetails.shipment_address.shipment_zipcode }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ orderDetails.shipment_address.shipment_country_code }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="line"></div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <button
            class="btn btn-sm btn-outline-primary mt-2 w-50"
            *ngIf="!validFromAddress"
            (click)="validateAddress('FROM')"
          >
            Validate From Address
          </button>
          <i
            class="far fa-check-circle {{ fromHighlight }} text"
            *ngIf="validFromAddress"
            ><small>
              {{ fromValidationMessage }}
            </small></i
          >
        </div>
        <div class="col-md-6">
          <button
            class="btn btn-sm btn-outline-primary mt-2 w-50"
            *ngIf="!validToAddress"
            (click)="validateAddress('TO')"
          >
            Validate To Address
          </button>
          <i
            class="far fa-check-circle {{ toHighlight }} text"
            *ngIf="validToAddress"
            ><small>
              {{ toValidationMessage }}
            </small></i
          >
        </div>
      </div>
    </div>
  </fieldset>
</div>
