import { Component } from '@angular/core';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MantlePoDetailsComponent } from '../mantle-po-details/mantle-po-details.component';

@Component({
  selector: 'mantle-btn-new-po',
  templateUrl: './mantle-btn-new-po.component.html',
  styleUrls: ['./mantle-btn-new-po.component.scss']
})
export class MantleBtnNewPoComponent {
  constructor(private mantleModalService: MantleModalService,){

  }
onNewPO() {
  const modalRef = this.mantleModalService.open(
    MantlePoDetailsComponent,
    MANTLE_MODAL_NAME.PO_DETAILS,
    {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: 'static',
    }
  );
}

}
