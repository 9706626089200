<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Dashboard</h1>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-4" *ngIf="quickLinksEnabled">
        <mantle-quick-links></mantle-quick-links>
      </div>
    </div>
    <ng-container *ngFor="let category of dashReports; let i = index">
      <div class="row">
        <ng-container *ngFor="let report of category.reports; let i = index">
          <div [ngClass]="category.value != '1' ? 'col-lg-6 col-6' : 'col-lg-3 col-6'">
            <mantle-report-widget
              [report_id]="report.report_id"
            ></mantle-report-widget>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</section>
<!-- /.content -->
