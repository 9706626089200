<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <h5>Channel Status Mappings</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="form-group col-md-12">
        <label class="control-label">Sales Channel</label>
        <ng-select
          [items]="sales_channels"
          [multiple]="false"
          bindLabel="sales_channel_description"
          bindValue="sales_channel_id"
          (change)="changeSalesChannel($event)"
        >
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>Order Status</span>
      </div>
      <div class="col-md-6">
        <span>Channel Statuses</span>
      </div>
    </div>
    <div *ngIf="salesChannelSelected">
      <ng-container *ngFor="let order_status of order_statuses; let i = index">
        <div class="row m-2">
          <div class="col-md-6">
            <ng-select
              [items]="order_statuses"
              [multiple]="false"
              [disabled]="true"
              bindLabel="description"
              bindValue="order_status_id"
              [(ngModel)]="order_statuses[i].order_status_id"
            >
            </ng-select>
          </div>
          <div class="col-md-6">
            <ng-select
              [items]="channel_order_status"
              [multiple]="true"
              bindLabel="channel_status_description"
              bindValue="channel_status_code"
              [(ngModel)]="sales_channel_statuses[i].status_map"
              (change)="onChangeMapping($event, order_status.order_status_id)"
            >
            </ng-select>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
