<div class="card">
    <div class="card-body">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="0">
          <a ngbNavLink>Product Filters Mapping</a>
          <ng-template ngbNavContent>
            <settings-product-filter></settings-product-filter>
          </ng-template>
        </li>
  
        <li [ngbNavItem]="1">
          <a ngbNavLink>Product Description</a>
          <ng-template ngbNavContent>
            <settings-product-description></settings-product-description>
          </ng-template>
        </li>
      </ul>
  
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
  <!-- /.card -->
  